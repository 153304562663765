import React, { FunctionComponent, useEffect, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Dialog, Divider, ListItemIcon, Menu, MenuItem, Grow } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { LabelsDesktop } from "../../attributes/labels/LabelsDesktop";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import styles from "./styles.module.scss";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useGetImage } from "../../../hooks/images";
import { ImageCache } from "../../../models/ImageCache";
import { MusicVideoRounded, DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { LabelDialog } from "../../attributes/labels/LabelDialog";
import { useIsMobile } from "../../../hooks/isMobile";
import { useContextMenu } from "../../../hooks/contextMenu";
import { ILabel } from "../../../hooks/labels";

export const AlbumLabelListSection: FunctionComponent<IAlbumLabelListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const isMobile = useIsMobile();
  const [isLabelDialogOpen, setIsLabelDialogOpen] = useState(false);
  const [isLabelsDesktopOpen, setIsLabelsDesktopOpen] = useState(false);
  const [isLoadingLabel, setIsLoadingLabel] = useState(false);
  const [isCatalogueNumberDialogOpen, setIsCatalogueNumberDialogOpen] = useState(false);
  const [isLoadingCatalogueNumber, setIsLoadingCatalogueNumber] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { updateAlbum } = useUpdateAlbum();
  const { getImage } = useGetImage();
  const [labelImage, setLabelImage] = useState<string>();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const fetchLabelImage = async () => {
    const label = album.label;
    setLabelImage(label?.thumbnail);
    if (!label?.hasImage) return;
    const response = await getImage({
      name: label?.uuid,
      width: ImageCache.smallLabelImageSize,
      height: ImageCache.smallLabelImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      if (label.uuid != album.label?.uuid) return;
      setLabelImage(response.body);
    }
  };
  const updateCatalogueNumber = async (newValue: string | null) => {
    setIsLoadingCatalogueNumber(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      catalogueNumber: newValue
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.catalogueNumber = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingCatalogueNumber(false);
  };
  const updateLabel = async (newValue: ILabel | undefined) => {
    setIsLabelsDesktopOpen(false);
    setIsLoadingLabel(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      labelId: newValue?.uuid ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.labelId = newValue?.uuid;
      album.label = newValue;
      onAttributeUpdated(album);
    }
    setIsLoadingLabel(false);
  };
  useEffect(() => {
    fetchLabelImage().then();
  }, [album.label]);
  return (
    <div>
      {!(isPublic && album.label === undefined) && (
        <div>
          <RightDetailListItem
            disabled={isPublic}
            title={strings.label_title}
            detail={album.label?.name ?? strings.unknown}
            detailImage={labelImage}
            onClick={event => {
              if (album.label) {
                handleContextMenu(event);
              } else {
                setIsLabelsDesktopOpen(true);
              }
            }}
            isLoading={isLoadingLabel}
          />
          <Divider variant={"inset"} />
        </div>
      )}
      <RightDetailListItem
        disabled={isPublic}
        title={strings.catalogue_number_title}
        detail={
          album.catalogueNumber && album.catalogueNumber != ""
            ? album.catalogueNumber
            : strings.unknown
        }
        onClick={() => setIsCatalogueNumberDialogOpen(true)}
        isLoading={isLoadingCatalogueNumber}
      />
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsLabelDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <MusicVideoRounded />
          </ListItemIcon>
          {strings.label_view}
        </MenuItem>
        {!isPublic && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsLabelsDesktopOpen(true);
            }}
            dense
          >
            <ListItemIcon>
              <DriveFileRenameOutlineRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.change_label}
          </MenuItem>
        )}
      </Menu>
      {album.label && (
        <LabelDialog
          label={album.label}
          isOpen={isLabelDialogOpen}
          setIsOpen={setIsLabelDialogOpen}
          canEdit={!isPublic}
        />
      )}
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isLabelsDesktopOpen}
        onClose={() => setIsLabelsDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <LabelsDesktop
            handleClose={() => {
              setIsLabelsDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={album.label?.uuid}
            onLabelSelected={updateLabel}
            onClearValue={() => updateLabel(undefined)}
          />
        </div>
      </Dialog>
      <ModifyGenericAttributeDialog
        title={strings.catalogue_number_title}
        description={strings.catalogue_number_message}
        placeholder={strings.catalogue_number_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isCatalogueNumberDialogOpen}
        setOpen={setIsCatalogueNumberDialogOpen}
        isNumber={false}
        value={album.catalogueNumber}
        onChange={async newValue => {
          await updateCatalogueNumber(newValue);
        }}
        onClearValue={async () => {
          await updateCatalogueNumber(null);
        }}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </div>
  );
};

export interface IAlbumLabelListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
