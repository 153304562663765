import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Card, Dialog, DialogActions, DialogContent, Grow, Toolbar } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { CloseRounded } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/isMobile";
import { strings } from "../../../../localization/LocalizedStrings";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { FieldTitle } from "../../../Text/FieldTitle";
import { TextField } from "../../../TextField";
import { SwitchListItem } from "../../../SwitchListItem";
import { ContainedButton } from "../../../ContainedButton";
import { AlertDialog } from "../../../AlertDialog";
import { PlaybackSpeedHelper } from "../../../../models/PlaybackSpeedHelper";
import { AlbumFormatsDesktop } from "../../../attributes/albumFormats/AlbumFormatsDesktop";
import { Counter } from "../../../Counter";
import { IAlbumContent, AlbumContentType } from "../../../../hooks/albumContents";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useAddAlbumContent } from "../../../../hooks/albumContents/useAddAlbumContent";
import { useModifyAlbumContent } from "../../../../hooks/albumContents/useModifyAlbumContent";
import { FreeTextListItem } from "../../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../../attributes/ModifyFreeTextAttributeDialog";

export const ModifyAlbumContentDialog: FunctionComponent<IModifyAlbumContentDialogProps> = ({
  albumId,
  open,
  setOpen,
  albumContent,
  onUpdate
}) => {
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [isAlbumFormatDesktopOpen, setIsAlbumFormatDesktopOpen] = useState(false);
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { addAlbumContent } = useAddAlbumContent();
  const { modifyAlbumContent } = useModifyAlbumContent();
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const possiblePlaybackSpeeds = [0, 1, 2];
  const formik = useFormik({
    initialValues: {
      name: albumContent?.name,
      quantity: albumContent?.quantity ?? 1,
      color: albumContent?.color,
      type: albumContent?.type ?? "disc",
      playbackSpeedId: albumContent?.playbackSpeed?.id,
      albumFormat: albumContent?.albumFormat,
      description: albumContent?.description
    },
    enableReinitialize: true,
    onSubmit: async ({
      name,
      quantity,
      color,
      playbackSpeedId,
      albumFormat,
      description,
      type
    }) => {
      setIsLoading(true);
      let statusCode, responseBody;
      if (albumContent) {
        const { status, body } = await modifyAlbumContent({
          uuid: albumContent.uuid,
          albumId,
          name,
          quantity,
          color,
          playbackSpeedId,
          description,
          order: albumContent.order,
          type: type as AlbumContentType,
          albumFormatId: type == "disc" ? albumFormat?.uuid ?? null : undefined
        });
        statusCode = status;
        responseBody = body;
      } else {
        const { status, body } = await addAlbumContent({
          albumId,
          name,
          quantity,
          color,
          playbackSpeedId,
          description,
          albumFormatId: albumFormat?.uuid,
          type: type as AlbumContentType
        });
        statusCode = status;
        responseBody = body;
      }
      if (!StatusCodesHelper.isSuccessful(statusCode)) {
        setAlertMessage(responseBody.message);
        setAlertIsOpen(true);
      } else {
        onUpdate(responseBody);
        handleClose();
        toast.success(strings.generic_update_success);
      }
      setIsLoading(false);
    }
  });
  useEffect(() => {
    if (!open) return;
    formik.resetForm();
  }, [open]);
  const handleTypeChange = (event: React.MouseEvent<HTMLElement>, type: AlbumContentType) => {
    if (type !== null) {
      formik.setFieldValue("type", type);
    }
  };
  const handlePlaybackSpeedChange = (
    event: React.MouseEvent<HTMLElement>,
    playbackSpeedId: number
  ) => {
    formik.setFieldValue("playbackSpeedId", playbackSpeedId);
  };
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{strings.album_contents_title}</h3>
          <Box sx={{ flexGrow: 1 }} />
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.album_content_type.toUpperCase()}</FieldTitle>
          <SwitchListItem
            className={styles.visibility}
            labels={[strings.album_content_disc, strings.album_content_other]}
            values={["disc", "other"]}
            value={formik.values.type}
            disabled={isLoading}
            fullWidth={true}
            disableGutters
            handleChange={handleTypeChange}
          />
          <FieldTitle className={styles.itemTitle}>
            {strings.album_content_quantity.toUpperCase()}
          </FieldTitle>
          <Counter
            initialValue={albumContent?.quantity ?? 1}
            minimumValue={1}
            nullable={true}
            disabled={isLoading}
            onValueChanged={newValue => formik.setFieldValue("quantity", newValue)}
          />
          {formik.values.type == "other" && (
            <>
              <FieldTitle>{strings.name_title.toUpperCase()}</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="name"
                  name="name"
                  size="small"
                  autoFocus={!isMobile}
                  disabled={isLoading}
                  placeholder={strings.name_title}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </>
          )}
          {formik.values.type == "disc" && (
            <>
              <FieldTitle className={styles.itemTitle}>
                {strings.album_format_title.toUpperCase()}
              </FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="albumFormat"
                  name="albumFormat"
                  size="small"
                  readOnly
                  disabled={isLoading}
                  placeholder={strings.maf_short_name_placeholder}
                  value={formik.values.albumFormat?.name ?? ""}
                  onClick={() => setIsAlbumFormatDesktopOpen(true)}
                  enterAction={() => setIsAlbumFormatDesktopOpen(true)}
                />
              </div>
              <FieldTitle>{strings.playback_speed.toUpperCase()}</FieldTitle>
              <SwitchListItem
                className={styles.visibility}
                labels={possiblePlaybackSpeeds.map(value => PlaybackSpeedHelper.getNameById(value))}
                values={possiblePlaybackSpeeds}
                value={formik.values.playbackSpeedId}
                fullWidth={true}
                disableGutters
                handleChange={handlePlaybackSpeedChange}
                disabled={isLoading}
              />
              <FieldTitle>
                {formik.values.quantity > 1
                  ? strings.disc_color_plural_title.toUpperCase()
                  : strings.disc_color_singular_title.toUpperCase()}
              </FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="color"
                  name="color"
                  size="small"
                  disabled={isLoading}
                  placeholder={
                    formik.values.quantity > 1
                      ? strings.disc_color_plural_title
                      : strings.disc_color_singular_title
                  }
                  value={formik.values.color}
                  onChange={formik.handleChange}
                  enterAction={formik.submitForm}
                />
              </div>
            </>
          )}
          <FieldTitle>{strings.collection_description.toUpperCase()}</FieldTitle>
          <Card className={styles.descriptionCard}>
            <FreeTextListItem
              hideTitle
              disabled={isLoading}
              detail={formik.values.description ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyDescriptionDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit} disabled={isLoading}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumFormatDesktopOpen}
        onClose={() => setIsAlbumFormatDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumFormatsDesktop
            handleClose={() => {
              setIsAlbumFormatDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 3}
            preselectedId={formik.values.albumFormat?.uuid}
            onAlbumFormatSelected={async albumFormat => {
              setIsAlbumFormatDesktopOpen(false);
              await formik.setFieldValue("albumFormat", albumFormat);
            }}
            onClearValue={async () => {
              setIsAlbumFormatDesktopOpen(false);
              await formik.setFieldValue("albumFormat", null);
            }}
          />
        </div>
      </Dialog>
      <ModifyFreeTextAttributeDialog
        title={strings.collection_description}
        description={strings.edit_description_description}
        placeholder={strings.collection_description}
        value={formik.values.description}
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        onChange={newValue => formik.setFieldValue("description", newValue)}
        onClearValue={() => formik.setFieldValue("description", null)}
      />
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyAlbumContentDialogProps {
  albumId: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  albumContent?: IAlbumContent;
  onUpdate: (albumContent: IAlbumContent) => void;
}
