import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { AppBar, Box, Button, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { MainDrawer } from "../../components/MainDrawer";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { AlertDialog } from "../../components/AlertDialog";
import { StatsDetail } from "../../components/stats/StatsDetail";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useIsMounted } from "../../hooks/isMounted";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { ICollection } from "../../hooks/collections";
import { CollectionsDialog } from "../../components/collections/CollectionsDialog";
import { ResponsiveIcon } from "../../components/ResponsiveIcon";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { ToolbarBox } from "../../components/toolbar/ToolbarBox";
import { useIsMobile } from "../../hooks/isMobile";
import { IStats, useCreateStats } from "../../hooks/stats/useCreateStats";
import { format } from "date-fns";
import { useCurrentPrivateCollection } from "../../hooks/collections/useCollectionManager";
import { useUser, useUserProfile } from "../../hooks/session";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { useIsScreenWide } from "../../hooks/isScreenWide";

export const Stats: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.option_stats}`;
  }, []);
  const isMounted = useIsMounted();
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { createStats } = useCreateStats();
  const [stats, setStats] = useState<IStats>();
  const [currentPrivateCollection] = useCurrentPrivateCollection.useState();
  const [selectedCollection, setSelectedCollection] = useState<ICollection | undefined>(
    currentPrivateCollection
  );
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const onError = (body: any) => {
    setAlertMessage(body.message);
    setIsAlertOpen(true);
  };
  const fetchStats = async () => {
    setIsLoading(true);
    const { body, status } = await createStats({
      collectionId: selectedCollection?.uuid
    });
    if (StatusCodesHelper.isSuccessful(status) && isMounted.current) {
      setStats(body);
    } else {
      onError(body);
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setStats(undefined);
    fetchStats().then();
  }, [selectedCollection]);
  const goToUserProfile = () => navigate(RoutesBuilder.userProfile());
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              style={{ marginRight: isMobile ? 0 : 8 }}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={"stats"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
          />
          {!isMobile && <h3 className={styles.title}>{strings.option_stats}</h3>}
          <ToolbarBox>
            <Button
              sx={{ maxHeight: 36, maxWidth: isMobile ? 80 : undefined }}
              onClick={() => {
                setIsCollectionsDialogOpen(true);
              }}
            >
              <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <p className={styles.collectionTitle}>
                  {selectedCollection?.name ?? strings.collection_all_albums}
                </p>
              </Box>
              <ResponsiveIcon icon={ExpandCircleDownOutlined} />
            </Button>
          </ToolbarBox>
          {stats && (
            <div className={styles.lastUpdated}>
              <p>{strings.stats_last_updated}</p>
              <p className={styles.lastUpdatedDate}>
                {format(stats.createdUnixTimestamp * 1000, "d MMMM yyyy, HH:mm", {
                  locale: strings.fnsLocale
                })}
              </p>
            </div>
          )}
          <Box sx={{ paddingLeft: 1, width: theme.spacing(4) }}>
            {isLoading && <CustomLoadingIndicator />}
          </Box>
          <Box flexGrow={1} />
          <UserProfileCard
            isPublic={false}
            userProfile={currentUserProfile}
            onClick={() => {
              if (!currentUserProfile || currentUser?.role == "guest") return;
              goToUserProfile();
            }}
          />
        </Toolbar>
      </AppBar>
      <div className={styles.mainBody}>
        <div
          className={styles.detailBody}
          style={{ backgroundColor: theme.palette.background.default }}
        >
          <StatsDetail stats={stats} isLoading={isLoading} />
          <AppCredits />
        </div>
      </div>
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        canModifyCollections={false}
        displayStandardCollections={true}
        onCollectionSelected={collection => {
          setIsCollectionsDialogOpen(false);
          setSelectedCollection(collection);
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </MainContent>
  );
};
