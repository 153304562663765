import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { IUserProfile } from "../../hooks/userProfiles";
import { ImageCache } from "../../models/ImageCache";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useGetImage } from "../../hooks/images";
import { useIsMounted } from "../../hooks/isMounted";
import styles from "./styles.module.scss";
import placeholderAvatar from "../../images/generic_sleeve.jpg";
import { LazyImageAspectFit } from "../LazyImageAspectFit";
import { Avatar, Skeleton, useTheme } from "@mui/material";

export const UserAvatar: FunctionComponent<IUserAvatarProps> = ({
  className,
  style,
  userProfile,
  onClick
}) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const fetchImage = async () => {
    if (!isMounted.current) return;
    setImage(undefined);
    if (!userProfile || !userProfile.hasImage) return;
    const response = await getImage({
      name: userProfile.uuid,
      width: ImageCache.smallAvatarImageSize,
      height: ImageCache.smallAvatarImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      if (!isMounted.current) return;
      setImage(response.body);
    }
  };
  useEffect(() => {
    fetchImage().then();
  }, [userProfile]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {userProfile ? (
        <Avatar
          className={className}
          style={style}
          sx={{
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
          }}
          onClick={onClick}
        >
          <LazyImageAspectFit
            className={styles.avatar}
            defaultImage={placeholderAvatar}
            thumbnail={userProfile.thumbnail}
            image={image}
            alt={"Avatar"}
          />
        </Avatar>
      ) : (
        <Skeleton variant={"circular"} className={className} />
      )}
    </div>
  );
};

export interface IUserAvatarProps {
  className?: string;
  style?: CSSProperties;
  userProfile?: IUserProfile;
  onClick?: () => void;
}
