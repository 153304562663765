import prefsImage from "../../../../images/documentation/order-album-prefs-es.png";
import albumImage from "../../../../images/documentation/unordered-album-es.png";

export const doc_order_albums_es = {
  title: "Ordenando álbumes",
  home_title: "Ordenar tus álbumes",
  prefs_image: prefsImage,
  album_image: albumImage,
  description: `Una vez que creás un nuevo álbum, la lista de álbumes se actualiza mostrando el álbum nuevo al final de la sección del artista. Este comportamiento puede cambiarse en tu perfil de usuario con la opción 'Ordenar automáticamente álbumes nuevos'. Si la cambiás a 'NO' entonces cuando agregues un nuevo álbum va a mostrarse al inicio de la sección del artista con una advertencia. Esto significa que es álbum todavía no está ordenado, ya que por detrás le fue asignado un órden igual a -1. Esto es útil especialmente si te preocupa tener tus álbumes ordenados por tu criterio favorito, ya que esta advertencia sólo va a desaparecer cuando ordenes manualmente el álbum.
  
`,
  drag_description: `Para odenar manualmente tus álbumes, simplemente arrastralos en la lista de álbumes. Esta operación sólo puede ser ejecutada cuando no haya ningún filtro aplicado, o cuando los únicos elementos de filtro sean de artistas.
  
`
};
