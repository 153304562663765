import { useBackend } from "../../services";

export const useModifyAlbumImage = () => {
  const { put } = useBackend();
  const modifyAlbumImage = (parameters: IModifyAlbumImageParameters) =>
    put("albumImages", parameters);
  return { modifyAlbumImage };
};

export interface IModifyAlbumImageParameters {
  uuid: string;
  description: string;
}
