import { useBackend } from "../../services";

export const useAddAlbumImage = () => {
  const { post } = useBackend();
  const addAlbumImage = (parameters: IAddAlbumImageParameters) => post("albumImages", parameters);
  return { addAlbumImage };
};

export interface IAddAlbumImageParameters {
  albumId: string;
  kind: string;
  description: string;
  order: number;
}
