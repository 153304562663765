import { useBackend } from "../../services";

export const useDeleteAlbumFormat = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbumFormat = (parameters: IDeleteAlbumFormatParameters) =>
    deleteRequest("albumFormats", parameters);
  return { deleteAlbumFormat };
};

export interface IDeleteAlbumFormatParameters {
  uuid: string;
}
