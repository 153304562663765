import React, { FunctionComponent } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Login } from "../../pages/Login";

export const LoginAgainDialog: FunctionComponent<ILengthTextFieldProps> = ({
  isOpen,
  setIsOpen,
  closeOnTouch,
  loginCallback
}) => {
  return (
    <Dialog
      maxWidth={"md"}
      open={isOpen}
      onClose={() => {
        if (closeOnTouch ?? true) {
          setIsOpen(false);
        }
      }}
    >
      <DialogContent style={{ padding: 0, overflow: "hidden", minWidth: 480 }}>
        <Login isMinimalist={true} loginCallback={loginCallback} />
      </DialogContent>
    </Dialog>
  );
};

export interface ILengthTextFieldProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  closeOnTouch?: boolean;
  loginCallback: () => void;
}
