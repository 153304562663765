import { useBackend } from "../../services";

export const useSendEmail = () => {
  const { post } = useBackend();
  return async (props: ISendEmailProps) => post("admin/sendEmail", props);
};

export interface ISendEmailProps {
  to?: string;
  subject?: string;
  body?: string;
}
