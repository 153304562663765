import importDiscogsImage from "../../../../images/documentation/import-discogs-es.png";

export const doc_import_from_discogs_es = {
  title: "Importar álbumes desde Discogs",
  import_image: importDiscogsImage,
  description: `Si tenés una cuenta en Discogs podés importar tus álbumes a Piper. Seleccioná la opción desde el menú desplegable y un diálogo se va a abrir.
  
  Si es la primera vez que lo hacés el navegador te va a redirigir a Discogs para que inicies sesión y des permiso a Piper para poder ver tu colección.
  
  Luego de aceptar vas a poder elegir de qué carpeta de Discogs importar tus álbumes. Esto es especialmente útil si querés importar solamente algunos de tus álbumes. Si ya tenés una parte de tu colección importada podés seleccionar la opción de saltear los álbumes con el mismo ID de release. Esto va a asegurar que esos álbumes no se dupliquen en el proceso.
  
  Si tenés un plan de suscripción premium, podés seleccionar la opción para traer las imágenes de los álbumes directamente desde Discogs (sólo la tapa). Esto también va a hacer que el proceso sea más lento.
  
  Una vez seleccionada la carpeta de Discogs vas a poder seleccionar opcionalmente una colección en particular donde se asignarán todos los álbumes importados. De cualquier manera siempre los vas a encontrar en la colección de 'Todos los álbumes'.
  
  Tené en cuenta que la importación puede tardar algunos minutos dependiendo de la cantidad de álbumes a importar. El proceso se va a ejecutar en nuestros servidores por lo que podés cerrar la ventana y seguir usando la aplicación mientras la importación sigue su curso. Si volvés a ingresar a la opción de importación vas a poder ver el progreso o cancelar.
  
  Los álbumes importados no van a tener todos los parámetros cargados, así que vas a tener que revisarlos para hacer las últimas modificaciones a tu gusto.
  
`
};
