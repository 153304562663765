import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import { useAddSellValue } from "../../../../hooks/sellValues/useAddSellValue";
import { useModifySellValue } from "../../../../hooks/sellValues/useModifySellValue";
import { useDeleteSellValue } from "../../../../hooks/sellValues/useDeleteSellValue";
import { ISellValue } from "../../../../hooks/sellValues";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { FieldTitle } from "../../../Text/FieldTitle";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { ModifyFreeTextAttributeDialog } from "../../ModifyFreeTextAttributeDialog";
import { FreeTextListItem } from "../../../FreeTextListItem";
import { getAnalytics, logEvent } from "firebase/analytics";

export const ModifySellValueDialog: FunctionComponent<IModifySellValueDialogProps> = ({
  open,
  setOpen,
  sellValue,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.msv_new_title);
  const { addSellValue } = useAddSellValue();
  const { modifySellValue } = useModifySellValue();
  const { deleteSellValue } = useDeleteSellValue();
  const [isLoading, setIsLoading] = useState(false);
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };
  const validationSchema = Yup.object({
    group: Yup.string().required(strings.msv_missing_error),
    dollars: Yup.number().typeError(strings.not_number_error).required(strings.msv_missing_error)
  });
  const formik = useFormik({
    initialValues: {
      group: sellValue?.group ?? "",
      dollars: sellValue?.dollars ?? 0,
      description: sellValue?.description ?? ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ group, dollars, description }) => {
      setIsLoading(true);
      if (sellValue) {
        const { status, body } = await modifySellValue({
          uuid: sellValue.uuid,
          group: group,
          dollars: dollars,
          description: description
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_sellvalue_action", { isNew: false });
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addSellValue({
          group: group,
          dollars: dollars,
          description: description
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_sellvalue_action", { isNew: true });
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!sellValue) return;
    setIsLoading(true);
    const { status, body } = await deleteSellValue({ uuid: sellValue.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(sellValue);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(sellValue ? strings.sell_value_title : strings.msv_new_title);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {sellValue && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.msv_group_title}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="group"
              name="group"
              size="small"
              autoFocus={!isMobile}
              placeholder={strings.msv_group_placeholder}
              value={formik.values.group}
              onChange={formik.handleChange}
              enterAction={formik.submitForm}
            />
          </div>
          <FieldTitle>{strings.msv_dollars_title}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="dollars"
              name="dollars"
              size="small"
              placeholder={strings.msv_dollars_placeholder}
              value={formik.values.dollars}
              onChange={formik.handleChange}
              enterAction={formik.submitForm}
            />
          </div>
          <FieldTitle>{strings.msv_description_title}</FieldTitle>
          <Card className={styles.profileCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.description ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyDescriptionDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <ModifyFreeTextAttributeDialog
        title={strings.collection_description}
        description={strings.edit_description_description}
        placeholder={strings.collection_description}
        value={formik.values.description}
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        onChange={newValue => formik.setFieldValue("description", newValue)}
        onClearValue={() => formik.setFieldValue("description", null)}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifySellValueDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  sellValue?: ISellValue;
  onNewItem?: (item: ISellValue) => void;
  onModifiedItem?: (item: ISellValue) => void;
  onDeletedItem?: (item: ISellValue) => void;
}
