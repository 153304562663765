import styles from "./styles.module.scss";
import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { Box, Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { ICollection } from "../../../hooks/collections";
import { CollectionHelper } from "../../../models/CollectionHelper";
import { useGetImage } from "../../../hooks/images";
import { ImageCache } from "../../../models/ImageCache";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import genericImage from "../../../images/collection.jpg";
import { CollectionStats } from "../CollectionStats";
import { DiscountTag } from "../../DiscountTag";
import classNames from "classnames";

export const CollectionCard: FunctionComponent<ICollectionCardProps> = ({
  className,
  style,
  collection,
  disabled,
  onClick,
  onContextMenu,
  children
}) => {
  const theme = useTheme();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [imageUpdateCount, setImageUpdateCount] = useState(0);
  const fetchImage = async () => {
    if (!collection.hasImage) return;
    const response = await getImage({
      name: collection.uuid,
      width: ImageCache.smallCollectionImageWidth,
      height: ImageCache.smallCollectionImageHeight
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    if (imageUpdateCount > 0) {
      setImage(undefined);
      fetchImage().then();
    } else {
      setImageUpdateCount(state => state + 1);
    }
  }, [collection]);
  return (
    <Card
      className={classNames(styles.card, className)}
      style={style}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "flex-start"
        }}
        disabled={disabled}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <LazyImageAspectFit
          className={styles.image}
          image={image}
          thumbnail={collection.thumbnail}
          defaultImage={genericImage}
          beforeLoad={fetchImage}
          alt={collection.name}
        />
        <div className={styles.nameContainer} />
        <Stack direction={"row"} className={styles.nameStack}>
          <p className={styles.name}>{collection.name}</p>
          <Box flexGrow={1} />
          {collection.discount > 0 && (
            <DiscountTag className={styles.discountBox} percentage={collection.discount} />
          )}
        </Stack>
        <p className={styles.description} style={{ color: theme.palette.secondary.main }}>
          {collection.description}
        </p>
        <Box flexGrow={1} />
        <Stack className={styles.bottomStack} direction={"row"}>
          {collection.visibility == "public" && (
            <p
              className={styles.visibility}
              style={{ backgroundColor: theme.palette.colors?.disabled }}
            >
              {CollectionHelper.getVisibilityDescription(collection)}
            </p>
          )}
          <Box flexGrow={1} />
          <CollectionStats className={styles.collectionStats} collection={collection} />
        </Stack>
      </CardActionArea>
      {children}
    </Card>
  );
};

export interface ICollectionCardProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  collection: ICollection;
  disabled?: boolean;
  onClick?: (event: React.TouchEvent | React.MouseEvent) => void;
  onContextMenu: (event: React.TouchEvent | React.MouseEvent) => void;
}
