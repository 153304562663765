export const terms_en = {
  title: "Terms of Service",
  title_short: "T. of Service",
  block1: `Piper started as a personal project that gradually grew and continues to do so day by day. Piper and its domain ldelosheros.com are owned by Lautaro de los Heros ("Piper", "We", "Our, "Us") and it's provided to you ("The user", "You", "Your) under these terms of service. The conditions in this Terms of Service document apply to all users who use the application, both in its web and mobile versions.
  
  If you have additional questions or require more information about these terms, do not hesitate to contact us.
  
  `,
  consent_title: "Consent",
  consent: `By using our app you agree to our terms of service without objection.
  
  `,
  service_title: "About the service",
  service: `Piper is an application that allows its users to upload information about their music collection, such as albums, artists, record labels and more, as well as certain personal data protected according to our Privacy Policy.
  
  Piper also offers its users the possibility to freely share content from their collections with other users.
  
  `,
  data_title: "About the data",
  data: `All data stored in Piper has a backup that is updated every day, except for errors or causes beyond Piper's control. This backup serves as a return point in case of extreme need or to search for historical data of a user who so requires. This does not mean that this information is available at all times, but you can get in touch for more details.
  
  You can consult our Privacy Policy to find out how we handle your data.
  
  Piper's responsibilities with respect to this data are specified in these terms of service below.
  
  `,
  subscriptions_title: "About subscriptions",
  subscriptions: `Subscriptions are the only way to meet Piper's production costs and to keep the app ad-free.
  
  All subscriptions have an automatic monthly charge. The modality and exact date of these charges depend on the payment platform chosen at the time of purchase of the subscription.
  
  The current subscription amount is refundable only if the user expresses his intention to refund up to 48 hours after the subscription is charged, and provided that the terms of service of this document are being complied with. In the event of a refund, the subscription will be automatically canceled and the user will no longer have the benefits of the subscription at that very moment.
  
  If the user wishes to cancel the subscription, they can do so from the payment platform or by contacting support. In this case, the subscription is canceled and no more payments will be processed from that date, but the user will be able to continue using the benefits of the subscription until the expiration date of the period that was already paid for. In case of cancellation there is no possibility of refund.
  
  The monthly charge amount of the subscriptions can be modified and its modification will be communicated through Piper's official notification channel up to 7 days in advance. The new amount will be in effect for all new subscriptions and active subscriptions will be charged on the next applicable charge.
  
  The amount of the subscriptions can have a temporary discount. This discount can be modified and its modification will be communicated through Piper's official notification channel up to 7 days in advance. The new amount will be in effect for all new subscriptions and active subscriptions will be charged on the next applicable charge.
  
  Whenever a subscribed user with more albums or attributes than the free limit cancels their subscription, they won't be able to update or modify those resources on the app. The user won't be able to use the album search functionality, the album filter, nor get random albums until they either renew their subscription or remove albums and attributes until they are below the free plan threshold.
  
  `,
  users_title: "About the users",
  users: `In order to be a Piper user, the following requirements must be met without exception:
  `,
  users_1: "Be over 13 years of age (or the legal minimum age in your territory).",
  users_2: "Always act in good faith.",
  users_3:
    "Always provide true information and that it does not go against good taste and good ethics, or that violates the rights of third parties.",
  users_4: "Under no circumstances is it allowed to attempt to violate or deceive the system.",
  users_5: "The contact and complaint channels must not be abused or misused.",
  users_6:
    "No data should be accessed or collected from our system in an automated manner or without proper permission.",
  users_7:
    "You must not exchange or request usernames and/or passwords from other users or appropriate access tokens.",
  users_8: "It is prohibited to sell or license products purchased from this application.",
  users_9:
    "You must not engage in abuse, aggression, or any type of aggressive action against any other user, whether by messaging or any relevant means.",
  users_10:
    "You must stop using the application immediately if you commit any illegal, deceptive, discriminatory or fraudulent act, or in the case of helping a third party to do so.",
  users_11: `You must not violate any of the points established in these terms of service.
  
  `,
  warranties_title: "Disclaimer of warranties",
  warranties: `By using this application you accept and understand that:
  `,
  warranties_1: `You use the service at your own risk. The service is offered "as is" and "as available", we disclaim any type of explicit or implicit warranty.`,
  warranties_2:
    "We make no warranty of any kind that the service will meet your expectations, that the service will be uninterrupted, timely, secure or error-free, that the results obtained from the use of the service will be accurate or reliable.",
  warranties_3:
    "We do not offer any warranty regarding the quality of the product, its services or the information provided, or that the material purchased or obtained through the service will meet your expectations.",
  warranties_4:
    "We do not offer a warranty that the errors present in the system will be fixed at some point.",
  warranties_5: `We do not promote or endorse any ideas or messages in user-generated content on the service.
  
  `,
  rights_title: "Rights and responsibilities",
  rights: `Piper reserves the right to temporarily or permanently disable or suspend any user who does not comply with any of the terms expressed in this document.
  
  Piper reserves the right to remove any content that it deems inappropriate or that does not comply with any of the terms expressed in this document.
  
  Piper reserves the right to partially or totally modify any username if a situation of identity theft is identified or for any other reason that is deemed pertinent, informing the user involved in a pertinent manner.
  
  Piper reserves the right to cancel the user's subscription if it deems appropriate, notifying the user accordingly.
  
  Piper reserves the right to decide whether or not to grant a refund, evaluating the particular case of the user.
  
  Piper reserves all rights not expressly granted.
  
  Piper provides its products "as is", and to the extent permitted by law, we do not guarantee that our services will function securely or without error, interruption, delay or imperfection. Similarly, to the extent permitted by law, WE DISCLAIM ALL LIABILITY, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We have no control or influence over what people do or say. Furthermore, we are not responsible for their behavior or actions, whether online or offline, or for the content they share, including content that may be offensive, inappropriate, obscene, illegal, or objectionable.
  
  Piper limits its liability to the fullest extent permitted by applicable law, and under no circumstances will we be liable for any loss of profit, revenue, information or data; and for consequential, special, indirect, exemplary, punitive, or incidental damages arising out of these Terms of Service (from any cause and from any liability, including negligence), even if we have been advised of the possibility of such damages.
  
  `,
  changes_title: "Changes to these Terms of Service",
  changes: `We may update our Terms of Service from time to time. Therefore, we recommend that you check this page periodically for any changes. We will notify you of any changes by posting the new Terms of Service on this page. These changes are effective immediately after they are posted on this page, any user who continues to use the app after the changes are posted will be bound by the new terms.
  
  `,
  contact_title: "Contact us",
  contact: `If you have any questions or suggestions about our Terms of Service, feel free to contact us.
  
  If you witness any situation that may go against these terms you can report it using the same contact channel.
  
  
  `,
  last_update: "Last update: January 4th, 2024"
};
