import { useBackend } from "../../services";

export const useUnassignTagFromAlbum = () => {
  const { deleteRequest } = useBackend();
  const unassignTagFromAlbum = (parameters: IUnassignTagFromAlbumParameters) =>
    deleteRequest("albums/assignTag", parameters);
  return { unassignTagFromAlbum };
};

export interface IUnassignTagFromAlbumParameters {
  uuid: string;
  tagId: string;
}
