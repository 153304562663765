import { strings } from "../localization/LocalizedStrings";

export class CollectionBannedFieldsManager {
  static shared = new CollectionBannedFieldsManager();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  public bannedFields = () => [
    {
      id: "label",
      name: strings.label_title
    },
    {
      id: "store",
      name: strings.store_title
    },
    {
      id: "albumContents",
      name: strings.album_contents_title
    },
    {
      id: "tags",
      name: strings.tags_title
    },
    {
      id: "isGatefold",
      name: strings.collection_banned_field_is_gatefold
    },
    {
      id: "isClean",
      name: strings.collection_banned_field_is_clean
    },
    {
      id: "purchasePrice",
      name: strings.purchase_price_title
    },
    {
      id: "sellValue",
      name: strings.sell_value_title
    },
    {
      id: "revenue",
      name: strings.revenue_title
    },
    {
      id: "purchaseDate",
      name: strings.purchase_date_title
    },
    {
      id: "impact",
      name: strings.impact_title
    },
    {
      id: "notes",
      name: strings.notes_title
    }
  ];
  getNames(ids: string[]) {
    return ids.map(id => {
      for (const bannedField of this.bannedFields()) {
        if (bannedField.id == id) {
          return bannedField.name;
        }
      }
      return "";
    });
  }
  getId(name: string) {
    for (const bannedField of this.bannedFields()) {
      if (bannedField.name == name) {
        return bannedField.id;
      }
    }
    return "";
  }
}

export interface ICollectionBannedField {
  id: string;
  name: string;
}
