import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";

export const BuyMeACoffeeButton: FunctionComponent = () => {
  return (
    <div>
      <script
        type="text/javascript"
        src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js"
        data-name="bmc-button"
        data-slug="ldelosheros"
      ></script>
      <a
        className={styles.buyMeACoffeeLink}
        href="https://www.buymeacoffee.com/ldelosheros"
        target="_blank"
      >
        <img
          className={styles.buyMeACoffeeButton}
          src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
          alt="Buy Me A Coffee"
        />
      </a>
    </div>
  );
};
