import optionImage from "../../../../images/documentation/album-remove-filtered-es.png";

export const doc_remove_filtered_albums_es = {
  title: "Eliminar varios álbumes",
  option_image: optionImage,
  description: `Si hacés click derecho sobre el botón de 'limpiar filtro' vas a ver la opción de eliminar todos los álbumes filtrados.
  
  Este proceso va a verificar el filtro actualmente aplicado y, después de una confirmación, va a eliminar todos los álbumes que caen dentro de ese filtro.
  
  Si no hay ningún filtro aplicado, esto va a eliminar TODOS TUS ÁLBUMES.
  
  Si realizaste este proceso por error, contactá a soporte. Vamos a revisar tu caso y ver si es posible recuperar parte de los álbumes eliminados.
  
  `
};
