import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

export const OAuthLoginButton: FunctionComponent<IOAuthLoginButtonProps> = ({
  className,
  props,
  iconClassName,
  imageSrc,
  buttonText,
  onClick
}) => {
  return (
    <div className={classNames(styles.buttonOAuth, className)} onClick={onClick} {...props}>
      <div className={styles.imageContainer}>
        <img className={classNames(styles.iconOAuth, iconClassName)} src={imageSrc} alt={""} />
      </div>
      <div className={styles.textContainer}>
        <p>
          <span>{buttonText}</span>
        </p>
      </div>
    </div>
  );
};

export interface IOAuthLoginButtonProps {
  className?: string;
  props?: any;
  iconClassName?: string;
  imageSrc: string;
  buttonText: string;
  onClick?: () => void;
}
