import { useBackend } from "../../services";

export const useCopyPublicAlbum = () => {
  const { post } = useBackend();
  const copyPublicAlbum = (parameters: ICopyPublicAlbumParameters) =>
    post("albums/copyPublic", parameters);
  return { copyPublicAlbum };
};

export interface ICopyPublicAlbumParameters {
  name: string;
  artistName: string;
  originalYear?: string;
  releaseYear?: string;
  country?: string;
  length?: number;
  tracks?: number;
  catalogueNumber?: string;
  isGatefold?: boolean;
  hasInsert?: boolean;
  hasLyrics?: boolean;
  discogsReleaseId?: string;
  labelName?: string;
  sleeveGradeId?: number;
  mediaGradeId?: number;
  albumTypeName?: string;
  notes?: string;
}
