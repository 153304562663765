import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { SvgIcon, useTheme } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import genericSpine from "../../../images/generic_spine.png";
import { stringToColor } from "../../../models/StringFormat";
import { IAlbum } from "../../../hooks/albums";
import { IShelving } from "../../../hooks/shelvings";

export const AlbumSpineDraggable: FunctionComponent<IAlbumSpineDraggableProps> = ({
  isDragDisabled,
  album,
  shelving,
  section,
  index
}) => {
  const theme = useTheme();
  return (
    <div className={styles.spineImageContainer}>
      <Draggable
        isDragDisabled={isDragDisabled}
        key={`${album.uuid}_${index}`}
        draggableId={`${section}_${index}`}
        index={index}
      >
        {provided => (
          <div
            className={
              shelving?.orientation == "vertical"
                ? styles.spineDraggableDivVertical
                : styles.spineDraggableDivHorizontal
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              className={
                shelving?.orientation == "vertical"
                  ? styles.spineDragHandleVertical
                  : styles.spineDragHandleHorizontal
              }
              style={{
                borderColor: theme.palette.background.default,
                visibility: isDragDisabled ? "hidden" : "visible"
              }}
            >
              <SvgIcon
                component={DragIndicator}
                style={{
                  fontSize: "14px",
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.primary.main
                }}
              />
            </div>
            <LazyImageAspectFit
              className={
                shelving?.orientation == "vertical"
                  ? styles.spineImageVertical
                  : styles.spineImageHorizontal
              }
              style={{ borderColor: theme.palette.background.default }}
              defaultImage={genericSpine}
              alt={album.name}
              forceVertical={shelving ? shelving.orientation == "horizontal" : true}
              forceHorizontal={shelving ? shelving.orientation == "vertical" : false}
              tintColor={stringToColor(album.name)}
              waitUntilImageIsFinal
            />
          </div>
        )}
      </Draggable>
    </div>
  );
};

export interface IAlbumSpineDraggableProps {
  isDragDisabled: boolean;
  album: IAlbum;
  shelving: IShelving;
  section: number;
  index: number;
}
