import { useBackend } from "../../services";

export const useDeleteShelving = () => {
  const { deleteRequest } = useBackend();
  const deleteShelving = (parameters: IDeleteShelvingParameters) =>
    deleteRequest("shelvings", parameters);
  return { deleteShelving };
};

export interface IDeleteShelvingParameters {
  uuid: string;
}
