import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { Divider, ListSubheader, Skeleton, Stack } from "@mui/material";

export const SkeletonAlbumList: FunctionComponent<ISkeletonAlbumListProps> = ({
  albumCount,
  disableHeader
}) => {
  return (
    <div>
      {!disableHeader && (
        <ListSubheader
          className={styles.subheader}
          sx={{
            backgroundColor: "background.default",
            lineHeight: 2
          }}
        >
          <Skeleton />
        </ListSubheader>
      )}
      {Array.from({ length: albumCount ?? 20 }, (_, number) => (
        <li key={`li_${number}`}>
          <ul className={styles.ul}>
            <Stack direction={"row"} style={{ width: "100%" }}>
              <Skeleton variant={"rectangular"} width={64} height={64} />
              <Stack flexGrow={1} sx={{ marginTop: "-4px" }}>
                <Skeleton className={styles.name} />
                <Skeleton className={styles.subtitle} />
                <Skeleton className={styles.grades} />
              </Stack>
            </Stack>
          </ul>
          <Divider variant="inset" />
        </li>
      ))}
    </div>
  );
};

interface ISkeletonAlbumListProps {
  albumCount?: number;
  disableHeader?: boolean;
}
