import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { strings } from "../../localization/LocalizedStrings";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { ToolbarBox } from "../toolbar/ToolbarBox";
import androidLogo from "../../images/android-logo-white.png";

export const MobileButtons: FunctionComponent = () => {
  return (
    <div>
      <a className={styles.appStoreButton} target={"_blank"} href={"https://apple.co/3P2tpt7"}>
        <img alt={"App Store"} src={strings.about_piper.app_store_button} />
      </a>
      <a
        className={styles.androidButtonContainer}
        target={"_blank"}
        href={`${window.location.protocol}//${
          window.location.host
        }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.about("android")}`}
      >
        <ToolbarBox className={styles.androidButton}>
          <img className={styles.androidButtonImage} alt={"Android"} src={androidLogo} />
          <p className={styles.androidButtonTitle}>Android</p>
        </ToolbarBox>
      </a>
    </div>
  );
};
