import { useBackend } from "../../services";

export const useRemoveAlbumFromShelfSegment = () => {
  const { deleteRequest } = useBackend();
  const removeAlbumFromShelfSegment = (parameters: IRemoveAlbumFromShelfSegmentParameters) =>
    deleteRequest("shelfSegments/albums", parameters);
  return { removeAlbumFromShelfSegment };
};

export interface IRemoveAlbumFromShelfSegmentParameters {
  uuid: string;
  albumId: string;
}
