import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationRecoverPassword: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_users_recover_password.title}</h1>
      <p>{strings.doc_users_recover_password.description}</p>
    </div>
  );
};
