import React, { FunctionComponent } from "react";
import {
  Box,
  Card,
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip
} from "@mui/material";
import styles from "./styles.module.scss";
import { AddCircleOutlineRounded, HelpOutlineRounded, SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";
import { strings } from "../../localization/LocalizedStrings";
import { CustomLoadingIndicator } from "../CustomLoadingIndicator";

export const SelectChipsListItem: FunctionComponent<ISelectChipsListItemProps> = ({
  className,
  disableGutters,
  icon,
  title,
  chips,
  isLoading,
  onAdd,
  onDelete,
  helpHref,
  addDisabled,
  removeDisabled
}) => {
  return (
    <ListItem className={className} disableGutters={disableGutters}>
      <div className={styles.itemDiv}>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          {icon && (
            <ListItemIcon sx={{ alignSelf: "center" }}>
              <ResponsiveIcon icon={icon} />
            </ListItemIcon>
          )}
          <Stack
            direction={"column"}
            sx={{ flexGrow: 1, width: "100%" }}
            spacing={helpHref ? 0 : 1}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <ListItemText className={styles.title} disableTypography secondary={title} />
              {helpHref && (
                <Tooltip title={strings.help} arrow disableInteractive>
                  <IconButton sx={{ marginLeft: "4px" }} href={helpHref} target={"_blank"}>
                    <ResponsiveIcon icon={HelpOutlineRounded} />
                  </IconButton>
                </Tooltip>
              )}
              <Box flexGrow={1} />
            </Stack>
            <Card className={styles.chipsCard}>
              {chips?.map((chip, index) => (
                <Tooltip
                  key={`tooltip_${title}_${index}`}
                  title={chip.description}
                  slotProps={{ tooltip: { sx: { fontWeight: "normal" } } }}
                  arrow
                  disableInteractive
                >
                  <Box key={`${title}_${index}`} className={styles.chipBox}>
                    <Chip
                      className={styles.chip}
                      label={chip.name}
                      onDelete={removeDisabled ? undefined : () => onDelete(chip.id)}
                      disabled={isLoading}
                    />
                  </Box>
                </Tooltip>
              ))}
              {!addDisabled && (
                <Tooltip title={strings.add_attribute} arrow disableInteractive>
                  <Box key={"chip_add"} className={styles.chipBox}>
                    <Chip
                      className={styles.addChip}
                      icon={
                        isLoading ? (
                          <CustomLoadingIndicator className={styles.loadingIndicator} />
                        ) : (
                          <AddCircleOutlineRounded />
                        )
                      }
                      disabled={isLoading}
                      onClick={onAdd}
                      sx={{
                        width: "32px",
                        "& .MuiChip-label": {
                          display: "none"
                        },
                        "& .MuiChip-icon": {
                          margin: 0
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Card>
          </Stack>
        </Stack>
      </div>
    </ListItem>
  );
};

export interface IChipElement {
  id: string;
  name: string;
  description?: string;
}

export interface ISelectChipsListItemProps {
  className?: string;
  disableGutters?: boolean;
  icon?: SvgIconComponent;
  title: string;
  chips?: IChipElement[];
  isLoading?: boolean;
  onAdd: () => void;
  onDelete: (label: string) => void;
  helpHref?: string;
  addDisabled?: boolean;
  removeDisabled?: boolean;
}
