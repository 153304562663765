import { useBackend } from "../../services";

export const useVerifyEmailCode = () => {
  const { post } = useBackend();
  const verifyEmailCode = (parameters: IVerifyEmailCodeParameters) =>
    post("emailVerificationCode", parameters);
  return { verifyEmailCode };
};

export interface IVerifyEmailCodeParameters {
  email: string;
  password?: string;
  code: string;
}
