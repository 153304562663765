import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, Dialog, DialogContent, Grid, Grow, Stack, Toolbar } from "@mui/material";
import styles from "./styles.module.scss";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { useGetImage } from "../../../../hooks/images";
import { ImageCache } from "../../../../models/ImageCache";
import genericImage from "../../../../images/generic_label.png";
import { strings } from "../../../../localization/LocalizedStrings";
import { ContainedButton } from "../../../ContainedButton";
import { ILabel } from "../../../../hooks/labels";
import { ModifyLabelDialog } from "../ModifyLabelDialog";
import { CloseRounded, DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { LazyImageAspectFit } from "../../../LazyImageAspectFit";

export const LabelDialog: FunctionComponent<ILabelDialogProps> = ({
  label,
  isOpen,
  setIsOpen,
  canEdit,
  onModifiedItem,
  onDeletedItem
}) => {
  const isMobile = useIsMobile();
  const [myLabel, setMyLabel] = useState(label);
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [thumbnail, setThumbnail] = useState(label.thumbnail);
  const [labelToModify, setLabelToModify] = useState<ILabel>();
  const [isModifyLabelDialogOpen, setIsModifyLabelDialogOpen] = useState(false);
  useEffect(() => {
    setThumbnail(myLabel.thumbnail);
    fetchImage().then();
  }, [myLabel.uuid, myLabel.hasImage, myLabel.thumbnail]);
  useEffect(() => {
    setMyLabel(label);
  }, [label]);
  const currentLabelUuid = useRef("");
  const fetchImage = async () => {
    currentLabelUuid.current = myLabel.uuid;
    setImage(undefined);
    if (!myLabel.hasImage) return;
    const response = await getImage({
      name: myLabel.uuid,
      width: ImageCache.bigLabelImageSize,
      height: ImageCache.bigLabelImageSize
    });
    // Discard image if label changed
    if (currentLabelUuid.current != myLabel.uuid) return;
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Grow}
    >
      <div className={styles.dialog}>
        <Toolbar>
          <h3>{myLabel.name}</h3>
          <Box flexGrow={1} />
          {canEdit && (
            <ToolbarButton
              onClick={() => {
                setLabelToModify(myLabel);
                setIsModifyLabelDialogOpen(true);
              }}
              tooltip={strings.label_edit}
              icon={DriveFileRenameOutlineRounded}
            />
          )}
          <ToolbarButton
            onClick={() => {
              setIsOpen(false);
            }}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
      </div>
      <DialogContent dividers>
        {myLabel ? (
          <Stack spacing={2}>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <LazyImageAspectFit
                className={styles.image}
                style={{ width: isMobile ? "100%" : "50%" }}
                imageStyle={{ borderRadius: "6%" }}
                image={image}
                thumbnail={thumbnail}
                defaultImage={genericImage}
                alt={myLabel.name}
              />
              <Stack style={{ width: isMobile ? "100%" : "50%" }}>
                <Grid container>
                  <Grid item xs className={styles.redirectGridItem}>
                    <ContainedButton
                      className={styles.redirectButton}
                      disabled={myLabel.discogsId?.length === 0}
                      href={`https://www.discogs.com/label/${myLabel.discogsId}`}
                      target={"_blank"}
                      component={"a"}
                    >
                      {strings.open_on_discogs}
                    </ContainedButton>
                  </Grid>
                  <Grid item xs className={styles.redirectGridItem}>
                    <ContainedButton
                      className={styles.redirectButton}
                      disabled={!myLabel.website || myLabel.website.length === 0}
                      href={myLabel.website}
                      target={"_blank"}
                    >
                      {strings.ma_website_button_title}
                    </ContainedButton>
                  </Grid>
                </Grid>
                <Box flexGrow={1} />
              </Stack>
            </Stack>
            <p className={styles.profile}>{myLabel.profile}</p>
          </Stack>
        ) : (
          <Box sx={{ height: 200 }} />
        )}
      </DialogContent>
      <ModifyLabelDialog
        open={isModifyLabelDialogOpen}
        setOpen={setIsModifyLabelDialogOpen}
        label={labelToModify}
        originalImageData={image}
        onModifiedItem={newItem => {
          if (onModifiedItem) {
            onModifiedItem(newItem);
          }
          setMyLabel(newItem);
        }}
        onDeletedItem={onDeletedItem}
      />
    </Dialog>
  );
};

export interface ILabelDialogProps {
  label: ILabel;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  canEdit: boolean;
  onModifiedItem?: (label: ILabel) => void;
  onDeletedItem?: (label: ILabel) => void;
}
