import { useBackend } from "../../services";

export const useDeleteSellValue = () => {
  const { deleteRequest } = useBackend();
  const deleteSellValue = (parameters: IDeleteSellValueParameters) =>
    deleteRequest("sellValues", parameters);
  return { deleteSellValue };
};

export interface IDeleteSellValueParameters {
  uuid: string;
}
