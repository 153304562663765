import React, { FunctionComponent, useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import styles from "./styles.module.scss";
import queryString from "query-string";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { useProcessMercadoPagoSubscription } from "../../hooks/mercadopago/useProcessMercadoPagoSubscription";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { strings } from "../../localization/LocalizedStrings";
import { AlertDialog } from "../../components/AlertDialog";
import { useSessionManager } from "../../hooks/session";
import { LoginAgainDialog } from "../../components/LoginAgainDialog";
import { StatusCodes } from "http-status-codes";

export const PaymentPage: FunctionComponent = () => {
  useEffect(() => {
    document.title = strings.app_name;
  }, []);
  const navigate = useNavigate();
  const { closeSession } = useSessionManager();
  const { processMercadoPagoSubscription } = useProcessMercadoPagoSubscription();
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const processPayment = () => {
    const queryParams = queryString.parse(location.search);
    const subscriptionId = queryParams.preapproval_id;
    if (typeof subscriptionId != "string") {
      navigate(RoutesBuilder.home());
    } else {
      processPaymentFromMercadoPago(subscriptionId).then();
    }
  };
  useEffect(() => {
    processPayment();
  }, []);
  const processPaymentFromMercadoPago = async (subscriptionId: string) => {
    const { status, body } = await processMercadoPagoSubscription({
      externalSubscriptionId: subscriptionId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(strings.subscription_success);
    } else if (status == StatusCodes.FORBIDDEN) {
      setIsSignInAgainDialogOpen(true);
    } else {
      setAlertMessage(body.message);
    }
    setIsAlertOpen(true);
  };
  return (
    <Backdrop className={styles.backdrop} open={true}>
      <CustomLoadingIndicator />
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        closeOnTouch={false}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          processPayment();
        }}
      />
      <AlertDialog
        title={strings.app_name}
        message={alertMessage}
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
        onConfirm={() => {
          closeSession();
          navigate(RoutesBuilder.home());
        }}
      />
    </Backdrop>
  );
};
