export const doc_subscriptions_en = {
  title: "Subscriptions",
  home_title: "How subscriptions work",
  description: `To keep Piper up to date and free of advertisements we rely on our subscription system. Users can use our app for free with certain limitations and can opt to subscribe using one of our available plans to have no limits and access new funcitonalities.
  
  You can choose from these 3 plans we have available:
  
  `,
  free_plan: "Free plan: ",
  free_plan_description: `Every user begins with this plan when they sign up. You won't be charged while you stay on this plan but you will have the following limitations:
  
  `,
  free_plan_body_1: "300 albums.",
  free_plan_body_2: "100 artists.",
  free_plan_body_3: "100 labels.",
  free_plan_body_4: "100 stores.",
  free_plan_body_5: "20 formats.",
  free_plan_body_6: "20 album types.",
  free_plan_body_7: "50 selling values.",
  free_plan_body_8: "100 exchange rates.",
  free_plan_body_9: "3 collections.",
  free_plan_body_10: "5 shelvings.",
  free_plan_end: `
  
  Whenever you meet any of these limitations the system will let you know that you reached the maximum for that resource when you try to add a new item. You always can remove an item to add a different one at any time.
  
  If at some point you had an active payed subscription and you had more albums/attributes than the limits of the free plan and then you cancelled your subscription, you will find that the app will not let you modify any instance of that resource, so you won't be able to update any of them until you regularize the situation. In this scenario you won't be able to search for albums, filter them, nor get random albums. Don't worry though, all your data is still there and you will be able to view your whole collection. Once you remove enough items so you fall again inside the free plan limitations, or you renew your subscription, you will be able to use Piper as before.
      
  `,
  collector_plan: "Collector plan: ",
  collector_plan_description: `With this subscription you won't have any limits on the amounts of albums and attributes you can manage, but it does not have the benefits of the 'Premium' plan.
  
  `,
  premium_plan: "Premium plan: ",
  premium_plan_description: `The 'Premium' subscription includes all the benefits from the 'Collector' plan, and you can also do these things:
  
  `,
  premium_plan_body_1: "Upload additional images for your albums using the image gallery.",
  premium_plan_body_2: `Use images from Discogs when importing albums.
  
  `,
  access: `To access to your current subscription status or purchase another subscription you can go to your user profile.
  
`
};
