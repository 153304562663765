import { useBackend } from "../../services";

export const useCreateUserNotification = () => {
  const { post } = useBackend();
  return async (props: ICreateUserNotificationProps) => post("admin/createUserNotification", props);
};

export interface ICreateUserNotificationProps {
  userId?: string;
  titleEs: string;
  titleEn: string;
  messageEs: string;
  messageEn: string;
  link?: string;
  severity: string;
}
