import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Skeleton, Grow, Toolbar, Card } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { CloseRounded, InboxRounded } from "@mui/icons-material";
import { useIsMounted } from "../../../hooks/isMounted";
import styles from "./styles.module.scss";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { SearchBar } from "../../SearchBar";
import { IUserNotification, useGetUserNotifications } from "../../../hooks/userNotifications";
import { UserNotificationItem } from "../UserNotificationItem";
import { AlertDialog } from "../../AlertDialog";
import { useUserProfile } from "../../../hooks/session";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";

export const UserNotificationsDialog: FunctionComponent<IUserNotificationsDialogProps> = ({
  open,
  setOpen
}) => {
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  const [userProfile, setUserProfile] = useUserProfile.useState();
  const [loading, setLoading] = useState(false);
  const [userNotifications, resetUserNotifications, setUserNotifications] = useGetUserNotifications(
    setLoading,
    isMounted.current
  );
  const [selectedUserNotification, setSelectedUserNotification] = useState<IUserNotification>();
  const [isUserNotificationAlertOpen, setIsUserNotificationAlertOpen] = useState(false);
  const [filteredUserNotifications, setFilteredUserNotifications] = useState<IUserNotification[]>();
  const [searchText, setSearchText] = useState("");
  const onCancel = () => {
    setOpen(false);
  };
  const refreshFilteredUserNotifications = () => {
    if (searchText == "") {
      setFilteredUserNotifications(userNotifications);
      return;
    }
    setFilteredUserNotifications(
      userNotifications?.filter(userNotification => {
        return (
          userNotification.title.toLowerCase().includes(searchText.toLowerCase()) ||
          userNotification.message.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredUserNotifications();
  }, [searchText]);
  useEffect(() => {
    if (!open || !isMounted.current) return;
    resetUserNotifications();
  }, [open]);
  useEffect(() => {
    refreshFilteredUserNotifications();
    if (userNotifications.length > 0) {
      updateUserProfileUnreadCount();
    }
  }, [userNotifications]);
  const updateUserProfileUnreadCount = () => {
    if (userProfile) {
      const newCount = userNotifications.filter(
        userNotification => !userNotification.isRead
      ).length;
      if (newCount != userProfile.unreadNotifications) {
        setUserProfile({
          ...userProfile,
          unreadNotifications: newCount
        });
      }
    }
  };
  const searchBar = () => (
    <SearchBar
      className={styles.searchBar}
      onSearchRequested={setSearchText}
      onSearchDismissed={() => setSearchText("")}
      timed={false}
      minimumSearchLength={0}
      autoFocus={!isMobile}
    />
  );
  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onCancel} TransitionComponent={Grow}>
      <Toolbar>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <h3 style={{ marginRight: 8 }}>{strings.user_notifications_title}</h3>
            {!isMobile && searchBar()}
            <Box flexGrow={1} />
            <ToolbarButton
              loading={loading}
              onClick={() => {
                setOpen(false);
              }}
              tooltip={strings.close}
              icon={CloseRounded}
            />
          </div>
          {isMobile && searchBar()}
        </div>
      </Toolbar>
      <Card className={styles.contentCard}>
        <DialogContent sx={{ padding: isMobile ? 0 : 1 }}>
          {filteredUserNotifications &&
            filteredUserNotifications.map(userNotification => (
              <UserNotificationItem
                userNotification={userNotification}
                onClick={() => {
                  setSelectedUserNotification(userNotification);
                  setIsUserNotificationAlertOpen(true);
                }}
                onUpdatedUserNotification={newUserNotification => {
                  const newUserNotifications = userNotifications.map(userNotification =>
                    newUserNotification.uuid == userNotification.uuid
                      ? newUserNotification
                      : userNotification
                  );
                  setUserNotifications(newUserNotifications);
                }}
              />
            ))}
          {loading &&
            Array.from({ length: 10 }, (value, index) => (
              <div className={styles.cardContainer}>
                <Skeleton
                  key={index}
                  height={60}
                  variant={"rectangular"}
                  className={styles.collectionCard}
                />
              </div>
            ))}
        </DialogContent>
        <ContentUnavailableNotice
          isLoading={loading}
          items={filteredUserNotifications}
          emptyIcon={InboxRounded}
          emptyTitle={strings.empty_user_notifications}
          emptyDescription={""}
          searchText={searchText}
        />
      </Card>
      <AlertDialog
        title={selectedUserNotification?.title}
        message={selectedUserNotification?.message ?? ""}
        open={isUserNotificationAlertOpen}
        setOpen={setIsUserNotificationAlertOpen}
        link={selectedUserNotification?.link}
        linkButtonText={
          selectedUserNotification?.severity == "message" ? strings.view_message : undefined
        }
        maxWidth={"xs"}
      />
    </Dialog>
  );
};

export interface IUserNotificationsDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}
