import { useBackend } from "../../services";

export const useSignup = () => {
  const { post } = useBackend();
  const signup = (parameters: ISignupParameters) => post("signup", parameters);
  return { signup };
};

export interface ISignupParameters {
  email: string;
  password: string;
}
