import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationAlbumsAddBarcode: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_albums_add_barcode.title}</h1>
      <p>{strings.doc_albums_add_barcode.description}</p>
      <img
        className={styles.imageCreate}
        src={strings.doc_albums_add_barcode.main_buttons_toolbar_image}
      />
      <section id={"scan"} />
      <h2>{strings.doc_albums_add_barcode.scan_title}</h2>
      <p>{strings.doc_albums_add_barcode.scan_description}</p>
      <section id={"search"} />
      <h2>{strings.doc_albums_add_barcode.search_title}</h2>
      <p>{strings.doc_albums_add_barcode.search_description}</p>
    </div>
  );
};
