import { useBackend } from "../../services";
import { IFilterAlbumsParameters, IGetAlbumsParameters, useAlbumStorage } from "./useAlbumStorage";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";

export const useGetAlbums = <T>(
  isActive: boolean,
  isPublic: boolean,
  isFiltering: boolean,
  pageSize: number,
  onLoading: (isLoading: boolean) => void,
  onError: (body: any) => void,
  shareLink?: string | null | (string | null)[],
  searchText?: string,
  filterElements?: FilterElement[],
  sortOption?: FilterSortOption,
  sortOrder?: FilterSortOrder,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const getAlbums = (parameters: IGetAlbumsParameters, filters: IFilterAlbumsParameters) =>
    get("albums", { ...parameters, ...filters });
  const getPublicAlbums = (parameters: IGetAlbumsParameters, filters: IFilterAlbumsParameters) =>
    get("albums/shared/public", { ...parameters, ...filters });
  const [
    albums,
    setAlbums,
    resetAlbumStorage,
    moveAlbum,
    deleteAlbum,
    noMoreAlbums,
    isFetchingAlbums
  ] = useAlbumStorage(
    isActive,
    isPublic,
    isFiltering,
    pageSize,
    isPublic ? getPublicAlbums : getAlbums,
    onLoading,
    onError,
    shareLink,
    searchText,
    filterElements,
    sortOption,
    sortOrder,
    dependencies
  );
  return [
    albums,
    setAlbums,
    resetAlbumStorage,
    moveAlbum,
    deleteAlbum,
    noMoreAlbums,
    isFetchingAlbums
  ] as const;
};
