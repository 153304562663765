import albumListImage from "../../../images/documentation/album-list-es.png";
import appStoreButton from "../../../images/AppStoreButton_ES.svg";

export const about_piper_es = {
  title: "¿Qué es Piper?",
  block1: `¡Gracias por usar Piper! Acá vas  apoder encontrar un resumen de todo lo que podés hacer con esta aplicación, cómo podés administrar tu colección de música, hacerla personal, e incluso compartirla con el resto del mundo. Recordá que siempre podés ingresar como un invitado y ver cómo luce una colección completa en Piper.
  
  `,
  album_list_image: albumListImage,
  block2: `Con Piper vas a poder agregar uno a uno todos los álbumes pertenecientes a tu colección. O si ya tenés tu colección cargada en Discogs vas a poder importarla directamente en pocos pasos.
  
  Por cada álbum vas a poder dar seguimiento de su artista, sello discográfico, lista de canciones, notas y más.
  
  Usá la poderosa herramienta del Mobiliario para cargar muebles, en los cuales vas a poder ubicar tus álbumes tal cual los tenés organizados en tu habitación. Podés definir a tu gusto la cantidad de estantes y segmentos de cada uno de tus muebles, para que sean una réplica exacta de tu mueble real.
  
  Si tu moneda es distinta a USD vas a poder cargar precios de compra y venta en tu moneda local y luego el sistema va a recalcular toda la información ajustada por inflación usando cambios de moneda. Piper te permite definir escalas de precios de venta para que puedas categorizar tus álbumes y así ajustar el precio en un sólo lugar, y definido en dólares, así que se va a ajustar automáticamente al último cambio de moneda.
  
  Usá el poderoso filtro de Piper para buscar entre tus álbumes combinando atributos, e incluso cambiando el criterio de agrupación y el orden. Vas a poder ordenar toda tu colección por año, precio de compra, artista, duración, ¡y más!
  
  Organizá tus álbumes en distintas colecciones, y definilas entre privadas o públicas para que los demás usuarios puedan ver una parte de tu colección.
  
  En la sección de estadísticas vas a poder conocer mejor a tu colección, teniendo la posibilidad de revisar totales, promedios y gráficos completos.
  
  Escaneá el código de barras de un álbum para agregarlo a Piper automáticamente. También podés hacer búsquedas por texto directamente en la base de datos de Discogs y agregar álbumes de esa manera.
  
  Generá códigos QR (y links) privados o públicos para acceder rápidamente a un álbum o colección.
  
  Reproducí directamente desde Piper cualquiera de tus álbumes usando YouTube.
  
  Explorá álbumes y colecciones de otros usuarios desde la sección de exhibición.
  
  
  Y no termina ahí. Explorá el resto de la documentación (todavía en progreso) para saber todo lo que podés hacer o, mejor aún, empezá a usar Piper para descubrir todo lo que tiene para ofrecer.
  
  `,
  mobile_title: "¡Usá Piper desde tu teléfono!",
  mobile_description: `Piper también funciona en tu dispositivo móvil. A continuación te explicamos cómo podés acceder dependiendo de la plataforma que elijas: 
  
  `,
  android_title: "Android",
  android_description_1: `Para usar Piper en Android abrí tu navegador web y navegá a `,
  android_description_2: `
  Una vez que cargó la página buscá en las opciones del navegador por la opción para guardar el ícono de aplicación en tu escritorio.
  
  Cerrá el navegador y abrí Piper desde el nuevo ícono.
  
  `,
  ios_title: "iOS",
  ios_description: `¡Piper está disponible en App Store!
  
  Escaneá el código y descargá la aplicación o usá el botón:
`,
  app_store_button: appStoreButton
};
