import { useBackend } from "../../services";

export const useAssignTagToAlbum = () => {
  const { post } = useBackend();
  const assignTagToAlbum = (parameters: IAssignTagToAlbumParameters) =>
    post("albums/assignTag", parameters);
  return { assignTagToAlbum };
};

export interface IAssignTagToAlbumParameters {
  uuid: string;
  tagId: string;
}
