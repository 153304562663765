import { useBackend } from "../../services";

export const useAddCollection = () => {
  const { post } = useBackend();
  const addCollection = (parameters: IAddCollectionParameters) => post("collections", parameters);
  return { addCollection };
};

export interface IAddCollectionParameters {
  name: string;
  description?: string;
  visibility: "public" | "private";
  bannedFields?: string[];
  discount: number;
}
