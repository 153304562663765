import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationImageGallery: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_image_gallery.title}</h1>
      <p>{strings.doc_image_gallery.description}</p>
    </div>
  );
};
