import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, Skeleton } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { IMediaGrade, useGetMediaGrades } from "../../../../hooks/mediaGrades";
import { GradeCard } from "../../GradeCard";
import { AttributeDesktop } from "../../AttributeDesktop";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const MediaGradesDesktop: FunctionComponent<IMediaGradesDesktopProps> = ({
  handleClose,
  columnWidth,
  preselectedId,
  onMediaGradeSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const mediaGrades = useGetMediaGrades(setLoading, []);
  const [filteredMediaGrades, setFilteredMediaGrades] = useState<IMediaGrade[]>();
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    refreshFilteredMediaGrades();
  }, [mediaGrades]);
  const refreshFilteredMediaGrades = () => {
    if (searchText == "") {
      setFilteredMediaGrades(mediaGrades);
      return;
    }
    setFilteredMediaGrades(
      mediaGrades?.filter(mediaGrade => {
        return (
          mediaGrade.description.toLowerCase().includes(searchText.toLowerCase()) ||
          +searchText == mediaGrade.points ||
          mediaGrade.goldmineGrade.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredMediaGrades();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={false}
      items={filteredMediaGrades}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.MEDIA_GRADES)}
      emptyString={strings.empty_media_grades}
      title={strings.attribute_media_grades_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      onClearValue={onClearValue}
    >
      {filteredMediaGrades?.map(mediaGrade => (
        <Grid item key={mediaGrade.id} xs={columnWidth}>
          <GradeCard
            points={mediaGrade.points}
            goldmineGrading={mediaGrade.goldmineGrade}
            description={mediaGrade.description}
            selected={preselectedId == mediaGrade.id}
            onClick={() => {
              if (onMediaGradeSelected) {
                onMediaGradeSelected(mediaGrade);
              }
            }}
          />
        </Grid>
      ))}
      {loading &&
        Array.from({ length: 11 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 132 }} className={styles.skeleton} />
          </Grid>
        ))}
    </AttributeDesktop>
  );
};

export interface IMediaGradesDesktopProps {
  handleClose?: () => void;
  columnWidth: GridSize;
  preselectedId?: number;
  onMediaGradeSelected?: (mediaGrade: IMediaGrade) => void;
  onClearValue?: () => void;
}
