import React, { FunctionComponent, useEffect, useState } from "react";
import { strings } from "../../../localization/LocalizedStrings";
import { Card, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import styles from "./styles.module.scss";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { SettingsRounded } from "@mui/icons-material";
import { PreferencesDialog } from "../../PreferencesDialog";
import { TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { CustomTreeItem } from "../../CustomTreeItem";
import { MobileButtons } from "../../MobileButtons";
import { isArray } from "lodash";
import { BuyMeACoffeeButton } from "../../BuyMeACoffeeButton";

export const DocumentationOptions: FunctionComponent<IDocumentationOptionsProps> = ({
  className,
  defaultExpanded,
  defaultSelected,
  hidePreferences
}) => {
  const navigate = useNavigate();
  const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [selected, setSelected] = useState(defaultSelected);
  useEffect(() => {
    setExpanded(defaultExpanded);
    setSelected(defaultSelected);
  }, [defaultExpanded, defaultSelected]);
  return (
    <List className={className} style={{ overflow: "auto", marginBottom: "12px" }}>
      <Card sx={{ margin: 2 }}>
        <TreeView
          sx={{ userSelect: "none" }}
          expanded={expanded}
          selected={[selected]}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={(event: any, nodeIds: string[] | string) => {
            if (isArray(nodeIds)) return;
            const index = expanded.indexOf(nodeIds);
            const copyExpanded = [...expanded];
            if (index === -1) {
              copyExpanded.push(nodeIds);
            } else {
              copyExpanded.splice(index, 1);
            }
            setExpanded(copyExpanded);
          }}
        >
          <CustomTreeItem
            nodeId={"about"}
            label={strings.about_piper.title}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.about()}`
              )
            }
            classes={{ label: "parent" }}
          />
          <CustomTreeItem
            nodeId={"users_parent"}
            label={strings.doc_users_sign_up.title_users}
            classes={{ label: "parent" }}
          >
            <CustomTreeItem
              nodeId={"users_sign_up"}
              label={strings.doc_users_sign_up.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.users.signUp()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"users_guest"}
              label={strings.doc_users_guest.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.users.guest()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"users_recover_password"}
              label={strings.doc_users_recover_password.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.users.recoverPassword()}`
                )
              }
            />
          </CustomTreeItem>
          <CustomTreeItem
            nodeId={"album_parent"}
            label={strings.doc_albums.title}
            classes={{ label: "parent" }}
          >
            <CustomTreeItem
              nodeId={"album_home"}
              label={strings.doc_albums.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.home()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_filter"}
              label={strings.doc_filter_albums.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.filterAlbums()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_order"}
              label={strings.doc_order_albums.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.orderAlbums()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_marks"}
              label={strings.doc_marking_albums.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.markingAlbums()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_scan_barcode"}
              label={strings.doc_albums_add_barcode.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.scanBarcode()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_import_discogs"}
              label={strings.doc_import_from_discogs.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromDiscogs()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_import_excel"}
              label={strings.doc_import_from_excel.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromExcel()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_image_gallery"}
              label={strings.doc_image_gallery.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.imageGallery()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_remove_filtered_albums"}
              label={strings.doc_remove_filtered_albums.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.removeFilteredAlbums()}`
                )
              }
            />
          </CustomTreeItem>
          <CustomTreeItem
            nodeId={"album_attributes_parent"}
            label={strings.doc_album_attributes.title}
            classes={{ label: "parent" }}
          >
            <CustomTreeItem
              nodeId={"album_attributes_home"}
              label={strings.doc_album_attributes.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.albumAttributes.home()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"album_attributes_artists"}
              label={strings.doc_artists.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.albumAttributes.artists()}`
                )
              }
            />
          </CustomTreeItem>
          <CustomTreeItem
            nodeId={"collections_parent"}
            label={strings.doc_collections.title}
            classes={{ label: "parent" }}
          >
            <CustomTreeItem
              nodeId={"collections_home"}
              label={strings.doc_collections.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.home()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"collections_attributes"}
              label={strings.doc_collections_attributes.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.attributes()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"collections_public_collections"}
              label={strings.doc_collections_public_collections.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.publicCollections()}`
                )
              }
            />
          </CustomTreeItem>
          <CustomTreeItem
            nodeId={"userProfile"}
            label={strings.doc_user_profile.title}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.userProfile()}`
              )
            }
            classes={{ label: "parent" }}
          />
          <CustomTreeItem
            nodeId={"subscriptions_parent"}
            label={strings.doc_subscriptions.title}
            classes={{ label: "parent" }}
          >
            <CustomTreeItem
              nodeId={"subscriptions_home"}
              label={strings.doc_subscriptions.home_title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.subscriptions.home()}`
                )
              }
            />
            <CustomTreeItem
              nodeId={"subscriptions_mercado_pago"}
              label={strings.doc_mercado_pago_subscriptions.title}
              onClick={() =>
                navigate(
                  `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.subscriptions.mercadoPago()}`
                )
              }
            />
          </CustomTreeItem>
          <CustomTreeItem
            nodeId={"policy"}
            label={strings.privacy_policy.title}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.policy()}`
              )
            }
            classes={{ label: "parent" }}
          />
          <CustomTreeItem
            nodeId={"terms"}
            label={strings.terms.title}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.terms()}`
              )
            }
            classes={{ label: "parent" }}
          />
        </TreeView>
      </Card>
      {!hidePreferences && (
        <Card sx={{ margin: 2 }}>
          <ListItemButton
            key={"Preferences"}
            onClick={() => {
              setIsPreferencesDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={SettingsRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_preferences}
            />
          </ListItemButton>
          <PreferencesDialog
            isOpen={isPreferencesDialogOpen}
            setIsOpen={setIsPreferencesDialogOpen}
            canRemoveAccount={false}
          />
        </Card>
      )}
      <MobileButtons />
      <BuyMeACoffeeButton />
    </List>
  );
};

export interface IDocumentationOptionsProps {
  className?: string;
  defaultExpanded: string[];
  defaultSelected: string;
  hidePreferences?: boolean;
}
