import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar } from "@mui/material";
import { ITrack } from "../../../hooks/albums";
import { strings } from "../../../localization/LocalizedStrings";
import { ContainedButton } from "../../ContainedButton";
import { AddCircleOutlineRounded, CloseRounded, DownloadRounded } from "@mui/icons-material";
import { TrackItem } from "../TrackItem";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import styles from "../../albums/AlbumList/styles.module.scss";
import { arrayMoveMutable } from "array-move";
import { AlertDialog } from "../../AlertDialog";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { v4 as uuidV4 } from "uuid";

export const TrackListDialog: FunctionComponent<ITrackListDialogProps> = ({
  trackList,
  open,
  setOpen,
  fetchFromDiscogsEnabled,
  onChange,
  onFetchFromDiscogs
}) => {
  const trackListCopy = () =>
    trackList?.map(track => ({ ...track })) ?? [{ order: -1, name: strings.generic_track_name }];
  const [tracks, setTracks] = useState(trackListCopy());
  const [didMakeUpdates, setDidMakeUpdates] = useState(false);
  const bottomReference = useRef<HTMLHeadingElement>(null);
  const [oldTracksLength, setOldTracksLength] = useState(0);
  const [isOpenFetchFromDiscogsConfirmationAlert, setIsOpenFetchFromDiscogsConfirmationAlert] =
    useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const scrollToBottom = () => {
    bottomReference.current?.scrollIntoView({ behavior: "smooth" });
  };
  const onCancel = () => {
    if (didMakeUpdates) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    setOpen(false);
  };
  const onSubmit = () => {
    onChange(tracks);
    setOpen(false);
  };
  useEffect(() => {
    if (oldTracksLength < tracks.length) {
      scrollToBottom();
    }
    setOldTracksLength(tracks.length);
  }, [tracks.length]);
  useEffect(() => {
    if (!open) return;
    setTracks(trackListCopy());
    setDidMakeUpdates(false);
  }, [open]);
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onCancel} TransitionComponent={Grow}>
      <Toolbar>
        <h3 style={{ marginRight: 8 }}>{strings.track_list_title}</h3>
        <ToolbarButton
          disabled={!fetchFromDiscogsEnabled}
          onClick={() => {
            setIsOpenFetchFromDiscogsConfirmationAlert(true);
          }}
          tooltip={
            fetchFromDiscogsEnabled
              ? strings.fetch_from_discogs
              : strings.fetch_from_discogs_disabled
          }
          icon={DownloadRounded}
        />
        <ToolbarButton
          onClick={() => {
            setTracks(tracks => [
              ...tracks,
              {
                order: -1,
                name: strings.generic_track_name
              }
            ]);
            setDidMakeUpdates(true);
          }}
          tooltip={strings.add_track}
          icon={AddCircleOutlineRounded}
        />
        <Box flexGrow={1} />
        <ToolbarButton onClick={onCancel} tooltip={strings.close} icon={CloseRounded} />
      </Toolbar>
      <DragDropContext
        onDragEnd={result => {
          if (!result.destination || result.source.index == result.destination.index) {
            return;
          }
          arrayMoveMutable(tracks, result.source.index, result.destination.index);
        }}
      >
        <DialogContent
          dividers
          sx={{
            height: "90vh",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <Droppable droppableId={"trackList"}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tracks.map((track, index) => (
                  <Draggable draggableId={`draggable.${index}`} index={index}>
                    {provided => (
                      <div
                        className={styles.albumItemContainer}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TrackItem
                          key={uuidV4()}
                          track={track}
                          onUpdate={() => setDidMakeUpdates(true)}
                          onDelete={() => {
                            const newTracks = tracks.filter((_, i) => i !== index);
                            setTracks(newTracks);
                            setDidMakeUpdates(true);
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div ref={bottomReference} />
              </div>
            )}
          </Droppable>
        </DialogContent>
      </DragDropContext>
      <DialogActions>
        <ContainedButton type={"submit"} onClick={onSubmit}>
          {strings.save}
        </ContainedButton>
      </DialogActions>
      <AlertDialog
        message={strings.fetch_tracks_from_discogs_confirmation}
        open={isOpenFetchFromDiscogsConfirmationAlert}
        setOpen={setIsOpenFetchFromDiscogsConfirmationAlert}
        isConfirm={true}
        onConfirm={() => {
          onFetchFromDiscogs();
          setOpen(false);
        }}
      />
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={() => setOpen(false)}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
    </Dialog>
  );
};

export interface ITrackListDialogProps {
  trackList?: ITrack[];
  open: boolean;
  setOpen: (state: boolean) => void;
  fetchFromDiscogsEnabled: boolean;
  onChange: (newTrackList: ITrack[]) => void;
  onFetchFromDiscogs: () => void;
}
