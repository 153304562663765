import { useBackend } from "../../services";

export const useModifyShelf = () => {
  const { put } = useBackend();
  const modifyShelf = (parameters: IModifyShelfParameters) => put("shelves", parameters);
  return { modifyShelf };
};

export interface IModifyShelfParameters {
  uuid: string;
  name: string;
  description?: string;
}
