import { useBackend } from "../../services";
import { ICollection } from "./interfaces";

export const useReorderCollections = () => {
  const { post } = useBackend();
  const reorderCollections = (collections: ICollection[]) => {
    const sortedUuids = collections.map(collection => collection.uuid);
    return post("collections/reorder", { sortedUuids });
  };
  return { reorderCollections };
};
