import { useBackend } from "../../services";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";
import { IShelving } from "./interfaces";

export const useGetShelvings = (setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const [currentUser] = useUser.useState();
  const getShelvings = (parameters: IGetPaginatedAttributeProps) => get("shelvings", parameters);
  return useGetPaginatedAttributes<IShelving>(
    PageSizeHelper.shelvings(currentUser?.role ?? UserRole.common),
    getShelvings,
    setLoading,
    true,
    undefined
  );
};
