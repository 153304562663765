import { useBackend } from "../../services";
import { IAlbumFormat } from "./interfaces";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import {
  IGetPaginatedAttributeProps,
  IPublicGetPaginatedAttributeProps
} from "../attributes/interfaces";
import { useCollectionManager } from "../collections/useCollectionManager";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";

export const useGetAlbumFormats = (isPublic: boolean, setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const { getCurrentCollection } = useCollectionManager();
  const [currentUser] = useUser.useState();
  const getAlbumFormats = (parameters: IGetPaginatedAttributeProps) =>
    get("albumFormats", parameters);
  const getPublicAlbumFormats = (parameters: IPublicGetPaginatedAttributeProps) =>
    get("albumFormats/shared/public", parameters);
  return useGetPaginatedAttributes<IAlbumFormat>(
    PageSizeHelper.albumFormats(currentUser?.role ?? UserRole.common),
    isPublic ? getPublicAlbumFormats : getAlbumFormats,
    setLoading,
    true,
    getCurrentCollection(isPublic)?.uuid
  );
};
