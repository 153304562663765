import { useBackend } from "../../services";

export const useProcessMercadoPagoSubscription = () => {
  const { put } = useBackend();
  const processMercadoPagoSubscription = async (
    parameters: IProcessMercadoPagoSubscriptionParameters
  ) => {
    return put("mercadopago/subscription", parameters);
  };
  return { processMercadoPagoSubscription };
};

export interface IProcessMercadoPagoSubscriptionParameters {
  externalSubscriptionId: string;
}
