import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { IAlbum, SellStatus, useGetAlbum, useGetAlbums } from "../../hooks/albums";
import { AlbumList } from "../../components/albums/AlbumList";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  SwipeableDrawer,
  Toolbar,
  useTheme
} from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { SearchBar } from "../../components/SearchBar";
import {
  AddCircleOutlineRounded,
  CasinoOutlined,
  CloseRounded,
  DeleteSweepRounded,
  DocumentScannerOutlined,
  DragIndicator,
  ExpandCircleDownOutlined,
  FilterAltOffRounded,
  FilterAltRounded,
  ListAltRounded,
  RefreshRounded,
  ShareRounded,
  StarOutlineRounded,
  StarRounded
} from "@mui/icons-material";
import { MainDrawer } from "../../components/MainDrawer";
import { AlbumDetail } from "../../components/albums/AlbumDetail";
import { AddAlbumDialog } from "../../components/albums/AddAlbumDialog";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { AlertDialog } from "../../components/AlertDialog";
import { ModifyIncrementalAttributeDialog } from "../../components/attributes/ModifyIncrementalAttributeDialog";
import { AlbumsFilterDialog } from "../../components/albums/AlbumsFilter";
import {
  FilterElement,
  FilterSortOption,
  FilterSortOrder,
  FilterType
} from "../../models/FilterElement";
import { useIsMounted } from "../../hooks/isMounted";
import queryString from "query-string";
import { CollectionsDialog } from "../../components/collections/CollectionsDialog";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetSharedContent } from "../../hooks/share/useGetSharedContent";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ResponsiveIcon } from "../../components/ResponsiveIcon";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { ToolbarBox } from "../../components/toolbar/ToolbarBox";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { ToolbarRawButton } from "../../components/toolbar/ToolbarRawButton";
import { useIsMobile } from "../../hooks/isMobile";
import {
  useCollectionManager,
  useCurrentPrivateCollection,
  useCurrentPublicCollection
} from "../../hooks/collections/useCollectionManager";
import { usePublicUserProfile, useUser, useUserProfile } from "../../hooks/session";
import { useGetPrivateContent } from "../../hooks/share/useGetPrivateContent";
import { BarcodeScannerDialog } from "../../components/barcodeScanner/BarcodeScannerDialog";
import { useShareLink } from "../../hooks/share/useShareLink";
import { PublicUserProfileDialog } from "../../components/userProfiles/PublicUserProfileDialog";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { useStarCollection } from "../../hooks/users/useStarCollection";
import { useUnstarCollection } from "../../hooks/users/useUnstarCollection";
import { useIncrementCollectionVisits } from "../../hooks/collections/useIncrementCollectionVisits";
import { useContextMenu } from "../../hooks/contextMenu";
import { useDeleteAlbumsByFilter } from "../../hooks/albums/useDeleteAlbumsByFilter";
import { ModifyGenericAttributeDialog } from "../../components/attributes/ModifyGenericAttributeDialog";
import { DiscountTag } from "../../components/DiscountTag";
import { useAddAlbum } from "../../hooks/albums/useAddAlbum";
import { useCopyPublicAlbum } from "../../hooks/albums/useCopyPublicAlbum";
import { useUpdateAlbum } from "../../hooks/albums/useUpdateAlbum";
import { useCopyImage } from "../../hooks/images/useCopyImage";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { UserRole } from "../../hooks/users";
import {
  useAlbumFilterManager,
  useCurrentPrivateFilterElements,
  useCurrentPrivateFilterSortOption,
  useCurrentPrivateFilterSortOrder,
  useCurrentPrivateSearchText
} from "../../hooks/albums/useAlbumFilterManager";
import { getAnalytics, logEvent } from "firebase/analytics";

export const Albums: FunctionComponent<IAlbumsPageProps> = ({ isPublic, onPlayFromYouTube }) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [currentPublicUserProfile, setCurrentPublicUserProfile] = usePublicUserProfile.useState();
  const [shouldRefreshAlbumList, setShouldRefreshAlbumList] = useState(false);
  const [needMoreAllAlbums, setNeedMoreAllAlbums] = useState(false);
  const {
    getCurrentFilterElements,
    getCurrentFilterSortOption,
    getCurrentFilterSortOrder,
    getCurrentSearchText
  } = useAlbumFilterManager(false);
  const [, setCurrentPrivateFilterElements] = useCurrentPrivateFilterElements.useState();
  const [filterElements, setFilterElements] = useState<FilterElement[]>(
    getCurrentFilterElements(isPublic)
  );
  const [, setCurrentPrivateFilterSortOption] = useCurrentPrivateFilterSortOption.useState();
  const [sortOption, setSortOption] = useState(getCurrentFilterSortOption(isPublic));
  const [, setCurrentPrivateFilterSortOrder] = useCurrentPrivateFilterSortOrder.useState();
  const [sortOrder, setSortOrder] = useState(getCurrentFilterSortOrder(isPublic));
  const [, setCurrentPrivateSearchText] = useCurrentPrivateSearchText.useState();
  const [searchText, setSearchText] = useState(getCurrentSearchText(isPublic));
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSharedAlbum, setIsLoadingSharedAlbum] = useState(false);
  const [isStarLoading, setIsStarLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isAlbumListDrawerOpen, setIsAlbumListDrawerOpen] = useState(isMobile);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const [shouldClearSearchText, setShouldClearSearchText] = useState(false);
  const [randomAlbumQuantity, setRandomAlbumQuantity] = useState(10);
  const [isRandomAlbumsAlertOpen, setIsRandomAlbumsAlertOpen] = useState(false);
  const [isAlbumsFilterDialogOpen, setIsAlbumsFilterDialogOpen] = useState(false);
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [isDeleteAlbumsDialogOpen, setIsDeleteAlbumsDialogOpen] = useState(false);
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const { addAlbum } = useAddAlbum();
  const { copyPublicAlbum } = useCopyPublicAlbum();
  const { copyImage } = useCopyImage();
  const { getAlbum } = useGetAlbum();
  const { updateAlbum } = useUpdateAlbum();
  const [selectedAlbum, setSelectedAlbum] = useState<IAlbum>();
  const { deleteAlbumsByFilter } = useDeleteAlbumsByFilter();
  const { getCurrentCollection, isCurrentCollectionShareable } = useCollectionManager();
  const [currentPublicCollection, setCurrentPublicCollection] =
    useCurrentPublicCollection.useState();
  const [currentPrivateCollection, setCurrentPrivateCollection] =
    useCurrentPrivateCollection.useState();
  const [scrollToTopAlbumList, setScrollToTopAlbumList] = useState(false);
  const { getSharedContent } = useGetSharedContent();
  const { incrementCollectionVisits } = useIncrementCollectionVisits();
  const { getPrivateContent } = useGetPrivateContent();
  const { starCollection } = useStarCollection();
  const { unstarCollection } = useUnstarCollection();
  const [isAddAlbumDialogOpen, setIsAddAlbumDialogOpen] = useState(false);
  const [isBarcodeScannerDialogOpen, setIsBarcodeScannerDialogOpen] = useState(false);
  const [shareLinkComponents, fetchShareLink, , isShareLoading] = useShareLink(
    getCurrentCollection(isPublic)?.name
  );
  const [isPublicUserProfileDialogOpen, setIsPublicUserProfileDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const location = useLocation();
  useEffect(() => {
    const mainTitle = isPublic ? strings.option_marketplace : strings.albums_title;
    if (!selectedAlbum) {
      document.title = `${strings.app_name} - ${mainTitle}`;
    } else {
      document.title = `${strings.app_name} - ${selectedAlbum.name}`;
    }
  }, [isPublic, selectedAlbum]);
  useEffect(() => {
    if (!isPublic) {
      setCurrentPrivateFilterElements(filterElements);
    }
  }, [filterElements]);
  useEffect(() => {
    if (!isPublic) {
      setCurrentPrivateFilterSortOption(sortOption);
    }
  }, [sortOption]);
  useEffect(() => {
    if (!isPublic) {
      setCurrentPrivateFilterSortOrder(sortOrder);
    }
  }, [sortOrder]);
  useEffect(() => {
    if (!isPublic) {
      setCurrentPrivateSearchText(searchText);
    }
  }, [searchText]);
  useEffect(() => {
    setFilterElements(getCurrentFilterElements(isPublic));
    setSortOption(getCurrentFilterSortOption(isPublic));
    setSortOrder(getCurrentFilterSortOrder(isPublic));
    setSearchText(getCurrentSearchText(isPublic));
  }, [isPublic]);
  const onError = (body: any) => {
    if (isMounted.current) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
  };
  const onLoading = (isLoading: boolean) => {
    if (isMounted.current) {
      setIsLoading(isLoading);
    }
  };
  const isFilterUnapplied = () => {
    return (
      (!filterElements || filterElements.length == 0) &&
      sortOption == FilterSortOption.ARTIST &&
      sortOrder == FilterSortOrder.ASCENDANT &&
      (!searchText || searchText === "")
    );
  };
  const [
    albums,
    setAlbumsOnStorage,
    resetAlbumsStorage,
    moveAlbums,
    deleteAlbumFromStorage,
    noMoreAlbums,
    isFetchingAlbums
  ] = useGetAlbums(
    true,
    isPublic,
    !isFilterUnapplied(),
    sortOption == FilterSortOption.RANDOM
      ? randomAlbumQuantity
      : PageSizeHelper.albums(currentUser?.role ?? UserRole.common),
    onLoading,
    onError,
    queryString.parse(location.search).link,
    searchText,
    filterElements,
    sortOption,
    sortOrder,
    [needMoreAllAlbums]
  );
  const resetAlbumStorage = () => {
    setScrollToTopAlbumList(state => !state);
    resetAlbumsStorage();
  };
  const getNewAlbums = (albums: IAlbum[][] | undefined, newAlbum: IAlbum) => {
    if (!albums) return undefined;
    return albums.map(section => {
      return section.map(album => (album.uuid == newAlbum.uuid ? newAlbum : album));
    });
  };
  const setAlbums = (newAlbum: IAlbum) => {
    setSelectedAlbum(newAlbum);
    setAlbumsOnStorage(getNewAlbums(albums, newAlbum));
  };
  const setNeedMoreAlbums = () => {
    if (isMounted.current) {
      setNeedMoreAllAlbums(state => !state);
    }
  };
  const isThereNoMoreAlbums = () => {
    if (isMounted.current) {
      return noMoreAlbums;
    }
  };
  useEffect(() => {
    resetAlbumStorage();
    setNeedMoreAlbums();
  }, [searchText, isPublic, currentPrivateCollection, currentPublicCollection?.uuid]);
  useEffect(() => {
    setSelectedAlbum(undefined);
  }, [isPublic]);
  const isFilteredOnlyByArtists = () => {
    if (
      !filterElements ||
      sortOption != FilterSortOption.ARTIST ||
      (searchText !== "" && searchText !== null)
    ) {
      return false;
    }
    for (const filterElement of filterElements) {
      if (filterElement.filterType != FilterType.ARTIST) {
        return false;
      }
    }
    return true;
  };
  const canMoveAlbums = () => {
    return (
      !isMobile &&
      !isLoading &&
      !isPublic &&
      (currentPrivateCollection === undefined || currentPrivateCollection?.uuid === undefined) &&
      (isFilterUnapplied() || isFilteredOnlyByArtists())
    );
  };
  const onAlbumDeleted = (deletedAlbum: IAlbum, section?: number, index?: number) => {
    if (section && index) {
      deleteAlbumFromStorage(section, index);
    } else {
      resetAlbumStorage();
      setNeedMoreAlbums();
    }
    if (selectedAlbum?.uuid == deletedAlbum.uuid) {
      setSelectedAlbum(undefined);
    }
    toast.success(strings.album_deleted);
  };
  const onAlbumCopy = async (album: IAlbum) => {
    setIsLoading(true);
    const { status, body } = await addAlbum({
      ...album,
      artistId: album.artist.uuid,
      collections: undefined
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (album.hasImage) {
        const thumbnail = await copyImage({ source: album.uuid, destination: body.uuid });
        if (thumbnail) {
          body.hasImage = true;
          body.thumbnail = thumbnail;
        }
      }
      toast.success(strings.album_created);
      setSelectedAlbum(body);
      resetAlbumStorage();
      setNeedMoreAlbums();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const onAlbumCopyPublic = async (album: IAlbum) => {
    setIsLoading(true);
    const { status, body } = await copyPublicAlbum({
      ...album,
      artistName: album.artist.name,
      labelName: album.label?.name,
      albumTypeName: album.albumType?.name
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      logEvent(analytics, "copy_album", {
        user_id: currentUserProfile.userId,
        album_id: album.uuid
      });
      if (album.hasImage) {
        await copyImage({ source: album.uuid, destination: body.uuid });
      }
      toast.success(strings.album_created);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const onAlbumMarkAs = async (album: IAlbum, sellStatus: SellStatus | null) => {
    setIsLoading(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      sellStatus
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      album.sellStatus = sellStatus ?? undefined;
      if (selectedAlbum?.uuid == album.uuid) {
        setSelectedAlbum(body);
      }
      setAlbums(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    setIsDragging(false);
    const sourceSection = +result.draggableId.split("_")[0];
    const destinationSection = +result.destination.droppableId.split("_")[1];
    if (result.source.index == result.destination.index || sourceSection !== destinationSection)
      return;
    await moveAlbums(sourceSection, result.source.index, result.destination.index);
    setShouldRefreshAlbumList(state => !state);
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.oauth_token && queryParams.oauth_verifier) {
      setIsOpenDiscogsImport(true);
    }
    if (window.location.pathname == RoutesBuilder.share()) {
      const shareLink = queryParams.link;
      if (typeof shareLink == "string") {
        fetchSharedContent(shareLink).then();
      } else {
        navigate(RoutesBuilder.albums.list());
      }
    } else if (window.location.pathname == RoutesBuilder.private()) {
      const privateLink = queryParams.link;
      if (typeof privateLink == "string") {
        fetchPrivateContent(privateLink).then();
      } else {
        navigate(RoutesBuilder.albums.list());
      }
    }
  }, []);
  const fetchSharedContent = async (shareLink: string) => {
    setIsLoadingSharedAlbum(true);
    const { status, body } = await getSharedContent({ shareLink: shareLink });
    if (StatusCodesHelper.isSuccessful(status)) {
      incrementCollectionVisits(body.collection.uuid).then();
      setCurrentPublicCollection(body.collection);
      setCurrentPublicUserProfile(body.userProfile);
      if (body.album) {
        setSelectedAlbum(body.album);
      }
    } else {
      navigate(RoutesBuilder.notFound());
    }
    setIsLoadingSharedAlbum(false);
  };
  const fetchPrivateContent = async (privateLink: string) => {
    setIsLoadingSharedAlbum(true);
    const { status, body } = await getPrivateContent({ privateLink: privateLink });
    if (StatusCodesHelper.isSuccessful(status)) {
      setSelectedAlbum(body.album);
    } else {
      navigate(RoutesBuilder.notFound());
    }
    setIsLoadingSharedAlbum(false);
  };
  const currentAlbumUuid = useRef("");
  const getAlbumDetails = async () => {
    currentAlbumUuid.current = selectedAlbum?.uuid;
    if (!selectedAlbum) return;
    const { status, body } = await getAlbum(selectedAlbum.uuid);
    // Discard result if album changed
    if (currentAlbumUuid.current !== selectedAlbum?.uuid) return;
    if (StatusCodesHelper.isSuccessful(status)) {
      setSelectedAlbum(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
  };
  const onNewAlbum = (newAlbum: IAlbum) => {
    resetAlbumStorage();
    setNeedMoreAlbums();
    setSelectedAlbum(newAlbum);
  };
  useEffect(() => {
    if (isPublic) return;
    getAlbumDetails().then();
  }, [selectedAlbum?.uuid]);
  const goToUserProfile = () => {
    navigate(RoutesBuilder.userProfile());
  };
  const processStarResponse = (status: number, body: any) => {
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      if (currentPublicCollection?.uuid) {
        setCurrentPublicCollection({
          ...currentPublicCollection,
          isStarred: !currentPublicCollection?.isStarred
        });
      }
    } else {
      toast.error(body.message);
    }
    setIsStarLoading(false);
  };
  const deleteAlbums = async () => {
    setIsLoading(true);
    const { status, body } = await deleteAlbumsByFilter(
      currentPrivateCollection?.uuid,
      sortOption,
      sortOrder,
      filterElements
    );
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      resetAlbumStorage();
      setNeedMoreAlbums();
      setSelectedAlbum(undefined);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const albumList = () => (
    <DragDropContext onDragStart={() => setIsDragging(true)} onDragEnd={onDragEnd}>
      <AlbumList
        isPublic={isPublic}
        shouldRefresh={shouldRefreshAlbumList}
        albums={albums}
        setIsLoading={setIsLoading}
        selectedAlbumId={selectedAlbum?.uuid}
        sortOption={sortOption}
        canMoveAlbums={canMoveAlbums()}
        canDropAlbums={true}
        canLoadMoreAlbums={!isDragging}
        setNeedMoreAlbums={setNeedMoreAlbums}
        onAlbumSelected={album => {
          setIsAlbumListDrawerOpen(false);
          if (selectedAlbum?.uuid != album.uuid) {
            setSelectedAlbum(album);
          } else {
            setSelectedAlbum(undefined);
          }
        }}
        onAlbumDeleted={onAlbumDeleted}
        onAlbumCopy={isPublic ? onAlbumCopyPublic : onAlbumCopy}
        onAlbumMarkAs={onAlbumMarkAs}
        noMoreAlbums={isThereNoMoreAlbums()}
        albumCount={sortOption == FilterSortOption.RANDOM ? randomAlbumQuantity : undefined}
        onNewAlbum={() => setIsAddAlbumDialogOpen(true)}
        onScanBarcode={() => setIsBarcodeScannerDialogOpen(true)}
        onImportFromDiscogs={() => setIsOpenDiscogsImport(true)}
        onImportFromExcel={() => setIsOpenExcelImport(true)}
        onFilterAlbums={() => setIsAlbumsFilterDialogOpen(true)}
        onChangeCollection={() => setIsCollectionsDialogOpen(true)}
        onEditProfile={goToUserProfile}
        onPlayFromYouTube={onPlayFromYouTube}
        scrollToTopTrigger={scrollToTopAlbumList}
        isForLayout={false}
      />
    </DragDropContext>
  );
  const toolbarButtons = () => (
    <Stack direction={"row"} style={{ alignItems: "center" }}>
      <ToolbarBox
        style={{ marginLeft: isMobile ? 0 : undefined, marginRight: isMobile ? 0 : undefined }}
      >
        <ToolbarRawButton
          onClick={() => {
            if (isLoading) return;
            resetAlbumStorage();
            setNeedMoreAlbums();
          }}
          tooltip={strings.refresh_list}
          icon={RefreshRounded}
        />
        <Divider orientation="vertical" flexItem />
        <ToolbarRawButton
          onClick={() => setIsAlbumsFilterDialogOpen(true)}
          tooltip={strings.option_filter_albums}
          icon={FilterAltRounded}
          highlighted={!isFilterUnapplied()}
        />
        <Divider orientation="vertical" flexItem />
        <Badge
          overlap={"circular"}
          badgeContent={sortOption == FilterSortOption.RANDOM ? randomAlbumQuantity : 0}
          color={"error"}
        >
          <ToolbarRawButton
            onClick={() => setIsRandomAlbumsAlertOpen(true)}
            tooltip={strings.option_random_albums}
            icon={CasinoOutlined}
            highlighted={sortOption == FilterSortOption.RANDOM}
          />
        </Badge>
      </ToolbarBox>
      <SearchBar
        className={styles.searchBar}
        onSearchRequested={setSearchText}
        onSearchDismissed={() => setSearchText("")}
        timed
        onLoading={onLoading}
        shouldClearSearchText={shouldClearSearchText}
        forceThisText={searchText}
        disabled={isFetchingAlbums}
      />
    </Stack>
  );
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              className={styles.piperLogo}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={isPublic ? "publicAlbums" : "albums"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
            onFilterAlbumsClicked={() => setIsAlbumsFilterDialogOpen(true)}
            onRandomAlbumsClicked={() => setIsRandomAlbumsAlertOpen(true)}
            onNewAlbumClicked={() => setIsAddAlbumDialogOpen(true)}
            onBarcodeScanClicked={() => setIsBarcodeScannerDialogOpen(true)}
          />
          {isMobile && (
            <ToolbarButton
              onClick={() => setIsAlbumListDrawerOpen(true)}
              tooltip={strings.open_albums_list}
              icon={ListAltRounded}
            />
          )}
          <Badge
            overlap={"circular"}
            badgeContent={
              <DiscountTag percentage={getCurrentCollection(isPublic)?.discount ?? 0} />
            }
            invisible={(getCurrentCollection(isPublic)?.discount ?? 0) == 0}
          >
            <ToolbarBox
              style={{ marginLeft: isMobile ? 0 : 16, marginRight: isMobile ? 0 : undefined }}
            >
              <Button
                sx={{ maxHeight: isMobile ? 30 : 36, maxWidth: isMobile ? 60 : undefined }}
                onClick={() => {
                  if (isPublic) {
                    if (!currentPublicUserProfile) return;
                    setIsPublicUserProfileDialogOpen(true);
                  } else {
                    setIsCollectionsDialogOpen(true);
                  }
                }}
              >
                <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <div className={styles.collectionTitle}>
                    {getCurrentCollection(isPublic) ? (
                      <p className={styles.collectionTitleText}>
                        {getCurrentCollection(isPublic)?.name}
                      </p>
                    ) : isPublic ? (
                      <CustomLoadingIndicator />
                    ) : (
                      <p className={styles.collectionTitleText}>{strings.collection_all_albums}</p>
                    )}
                  </div>
                </Box>
                {!isPublic && <ResponsiveIcon icon={ExpandCircleDownOutlined} />}
              </Button>
            </ToolbarBox>
          </Badge>
          {(!isMobile || isPublic) && isCurrentCollectionShareable(isPublic) && (
            <ToolbarBox className={styles.shareBox}>
              {isPublic && (
                <>
                  <ToolbarRawButton
                    onClick={() => {
                      const collectionId = currentPublicCollection?.uuid;
                      if (!collectionId) return;
                      setIsStarLoading(true);
                      if (currentPublicCollection?.isStarred) {
                        unstarCollection({ collectionId }).then(response =>
                          processStarResponse(response.status, response.body)
                        );
                      } else {
                        starCollection({ collectionId }).then(response =>
                          processStarResponse(response.status, response.body)
                        );
                      }
                    }}
                    tooltip={
                      currentPublicCollection?.isStarred
                        ? strings.unstar_collection
                        : strings.star_collection
                    }
                    loading={isStarLoading}
                    icon={currentPublicCollection?.isStarred ? StarRounded : StarOutlineRounded}
                  />
                  <Divider flexItem orientation={"vertical"} />
                </>
              )}
              <ToolbarRawButton
                onClick={() => {
                  const collectionId = getCurrentCollection(isPublic)?.uuid;
                  if (!collectionId) return;
                  fetchShareLink(collectionId, undefined).then();
                }}
                loading={isShareLoading}
                tooltip={strings.share_collection}
                icon={ShareRounded}
                iconClassName={styles.shareButtonIcon}
              />
            </ToolbarBox>
          )}
          {!isPublic && (
            <ToolbarBox>
              <ToolbarRawButton
                onClick={() => setIsAddAlbumDialogOpen(true)}
                tooltip={strings.add_album}
                icon={AddCircleOutlineRounded}
              />
              <Divider flexItem orientation={"vertical"} />
              <ToolbarRawButton
                tooltip={strings.option_scan_barcode}
                icon={DocumentScannerOutlined}
                onClick={() => setIsBarcodeScannerDialogOpen(true)}
              />
            </ToolbarBox>
          )}
          {!isMobile && toolbarButtons()}
          <ToolbarButton
            style={{ marginLeft: isMobile ? 0 : undefined, marginRight: isMobile ? 0 : undefined }}
            onClick={() => {
              setSortOption(FilterSortOption.ARTIST);
              setSortOrder(FilterSortOrder.ASCENDANT);
              setSearchText("");
              setShouldClearSearchText(state => !state);
              setFilterElements([]);
              resetAlbumStorage();
              setNeedMoreAlbums();
            }}
            onContextMenu={handleContextMenu}
            disabled={isFilterUnapplied() || isLoading}
            loading={isLoading}
            tooltip={isLoading ? "" : strings.clear_filter}
            icon={FilterAltOffRounded}
            color={theme.palette.colors?.remove}
          />
          {!isPublic && (
            <Menu {...menuProps}>
              <MenuItem
                disabled={isLoading}
                onClick={() => {
                  handleMenuClose();
                  setIsDeleteAlbumsDialogOpen(true);
                }}
                sx={{ color: theme.palette.colors?.remove }}
                dense
              >
                <ListItemIcon sx={{ color: theme.palette.colors?.remove }}>
                  <DeleteSweepRounded />
                </ListItemIcon>
                {strings.album_delete_all}
              </MenuItem>
            </Menu>
          )}
          <ModifyGenericAttributeDialog
            title={strings.album_delete_all}
            description={
              filterElements
                ? strings.album_delete_all_filter_confirmation_message
                : strings.album_delete_all_confirmation_message
            }
            placeholder={strings.album_delete_all_placeholder}
            missingText={strings.field_missing_short}
            canClear={false}
            open={isDeleteAlbumsDialogOpen}
            setOpen={setIsDeleteAlbumsDialogOpen}
            isNumber={false}
            onChange={deleteAlbums}
            matchText={strings.album_delete_all_placeholder}
          />
          <Box flexGrow={1} />
          <UserProfileCard
            isPublic={isPublic}
            userProfile={isPublic ? currentPublicUserProfile : currentUserProfile}
            onClick={() => {
              if (!isPublic) {
                if (!currentUserProfile || currentUser?.role == "guest") return;
                goToUserProfile();
              } else {
                if (!currentPublicUserProfile) return;
                setIsPublicUserProfileDialogOpen(true);
              }
            }}
          />
        </Toolbar>
      </AppBar>
      <PanelGroup className={styles.mainBody} autoSaveId={"persistence"} direction={"horizontal"}>
        {!isMobile && (
          <Panel
            className={styles.albumListContainer}
            style={{ backgroundColor: theme.palette.background.default }}
            minSize={6}
            defaultSize={28}
            maxSize={40}
          >
            {albumList()}
          </Panel>
        )}
        <PanelResizeHandle
          className={styles.resizeHandle}
          style={{
            backgroundColor: theme.palette.colors?.main
          }}
        >
          {!isMobile && (
            <SvgIcon
              component={DragIndicator}
              sx={{ alignSelf: "center", color: theme.palette.secondary.main, fontSize: 14 }}
            />
          )}
        </PanelResizeHandle>
        <Panel
          className={styles.albumDetail}
          style={{ backgroundColor: theme.palette.colors?.main }}
        >
          <AlbumDetail
            isPublic={isPublic}
            isMobile={isMobile}
            isLoadingSharedAlbum={isLoadingSharedAlbum}
            album={selectedAlbum}
            onAttributeUpdated={setAlbums}
            onArtistUpdated={() => {
              resetAlbumStorage();
              setNeedMoreAlbums();
            }}
            onPlayFromYouTube={onPlayFromYouTube}
            onNewAlbum={() => setIsAddAlbumDialogOpen(true)}
            onScanBarcode={() => setIsBarcodeScannerDialogOpen(true)}
            onImportFromDiscogs={() => setIsOpenDiscogsImport(true)}
            onImportFromExcel={() => setIsOpenExcelImport(true)}
            onFilterAlbums={() => setIsAlbumsFilterDialogOpen(true)}
            onRandomAlbums={() => setIsRandomAlbumsAlertOpen(true)}
            onChangeCollection={() => setIsCollectionsDialogOpen(true)}
            onEditProfile={goToUserProfile}
            onCopy={async () => {
              if (!selectedAlbum) return;
              await onAlbumCopy(selectedAlbum);
            }}
            onDelete={async () => {
              if (!selectedAlbum) return;
              await onAlbumDeleted(selectedAlbum);
            }}
            onMarkAs={async sellStatus => {
              if (!selectedAlbum) return;
              await onAlbumMarkAs(selectedAlbum, sellStatus);
            }}
          />
          <AppCredits />
        </Panel>
      </PanelGroup>
      <AddAlbumDialog
        open={isAddAlbumDialogOpen}
        setOpen={setIsAddAlbumDialogOpen}
        callback={onNewAlbum}
      />
      <ModifyIncrementalAttributeDialog
        title={strings.option_random_albums}
        description={strings.random_albums_message}
        canClear={false}
        open={isRandomAlbumsAlertOpen}
        setOpen={setIsRandomAlbumsAlertOpen}
        value={randomAlbumQuantity}
        maxValue={30}
        onChange={quantity => {
          if (!quantity) return;
          setRandomAlbumQuantity(quantity);
          setSortOption(FilterSortOption.RANDOM);
          resetAlbumStorage();
          setNeedMoreAlbums();
        }}
      />
      <AlbumsFilterDialog
        isPublic={isPublic}
        isOpen={isAlbumsFilterDialogOpen}
        setIsOpen={setIsAlbumsFilterDialogOpen}
        isLayout={false}
        onFilterApplied={(filterElements, sortOption, sortOrder) => {
          setFilterElements(filterElements);
          setSortOption(sortOption);
          setSortOrder(sortOrder);
          resetAlbumStorage();
          setNeedMoreAlbums();
        }}
      />
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        filterElements={filterElements}
        filteredSearchText={searchText}
        canModifyCollections={false}
        displayStandardCollections={true}
        onCollectionSelected={collection => {
          setIsCollectionsDialogOpen(false);
          setCurrentPrivateCollection(collection);
          setSelectedAlbum(undefined);
          setIsAlbumListDrawerOpen(true);
        }}
      />
      {isMobile && (
        <SwipeableDrawer
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default
            }
          }}
          ModalProps={{ keepMounted: true }}
          anchor={"left"}
          open={isAlbumListDrawerOpen}
          onOpen={() => setIsAlbumListDrawerOpen(true)}
          onClose={() => setIsAlbumListDrawerOpen(false)}
        >
          <div
            style={{ width: "80vw", overflow: "hidden", display: "flex", flexDirection: "column" }}
          >
            <AppBar position={"sticky"}>
              <Toolbar className={styles.toolbar} disableGutters>
                {toolbarButtons()}
                <Box flexGrow={1} />
                <ToolbarButton
                  style={{
                    marginLeft: isMobile ? 0 : undefined,
                    marginRight: isMobile ? 0 : undefined
                  }}
                  loading={isLoading}
                  onClick={() => setIsAlbumListDrawerOpen(false)}
                  tooltip={strings.close}
                  icon={CloseRounded}
                />
              </Toolbar>
            </AppBar>
            {albumList()}
          </div>
        </SwipeableDrawer>
      )}
      {shareLinkComponents()}
      <BarcodeScannerDialog
        isOpen={isBarcodeScannerDialogOpen}
        setIsOpen={setIsBarcodeScannerDialogOpen}
        onNewAlbum={onNewAlbum}
      />
      {currentPublicUserProfile && (
        <PublicUserProfileDialog
          userProfile={currentPublicUserProfile}
          isOpen={isPublicUserProfileDialogOpen}
          setIsOpen={setIsPublicUserProfileDialogOpen}
          onPublicCollectionChanged={() => {
            setIsPublicUserProfileDialogOpen(false);
            setSelectedAlbum(undefined);
          }}
          showCollectionInfo={true}
          showSendMessageButton
        />
      )}
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </MainContent>
  );
};

export interface IAlbumsPageProps {
  isPublic: boolean;
  onPlayFromYouTube: (title: string, videoIds: string[]) => void;
}
