export const doc_user_profile_en = {
  title: "User profile",
  home_title: "User profile",
  description: `You can customize your user profile by selecting the profile card on the top right corner of the screen, or selecting the option on the drawer menu.
  
`,
  notifications_title: "Notifications",
  notifications_description: `Inside the user profile page you will find the notifications button. There you will find any official notifications sent to you, regarding general information, pricing changes or breaking news.
  
`,
  update_avatar_title: "Update your avatar",
  update_avatar_description: `To update your avatar just select it. The system will ask for a new image. The image size shall not be bigger than 10MiB.
  
`,
  username_title: "Update your username",
  username_description: `After you create your account, your username will be equal to your email address. You can update your username inside the user profile page. The new username has to be between 8 and 20 characters long, has to start with a letter and can't have special characters except for underscores.
  
`,
  email_title: "Update your email",
  email_description: `You can change your current email address inside the user profile page. Enter the new email address on the bottom text field and then select the save button. The new email will then be pending confirmation. If it passed some time since your last sign in, the system may ask you to enter your credentials again.
  
  At this stage, your email is still not changed, to do so you need to click on the verify button and enter the code we sent to your new email address. Once the code is verified, the new email will override the current one and you will be ready to go.
  
`,
  password_title: "Update your password",
  password_description: `Select the password field inside the user profile page to change it for a new one. A new dialog will ask for the current password and a new one that you will need to enter twice to prevent typing errors. If it passed some time since your last sign in, the system may ask you to enter your credentials again.
  
`,
  bio_title: "Bio",
  bio_description: `You can enter any information about you and your collection in your bio. This will be available to everyone on the Exhibition. If you search for your username on the Exhibition and select your profile you will see your public profile and what everyone is able to see about yourself.
  
`,
  currency_title: "Currency",
  currency_description: `Inside your user profile you will be able to change your currency. This will have effect on your whole collection and all your public collections will use this currency to show any monetary amounts if you decided to show them. Take into account that changing the currency will not update any amounts, only the currency symbol and code displayed.
  
  This currency will also be the one specified on your exchange rates, but take into account that changing the currency will not update in any way the amount of the exchange rates, only the currency symbol and code.
  
`,
  decimals_title: "Decimals for monetary amounts",
  decimals_description: `With this option you can decide whether the amounts displayed with your currency show decimals or not.
  
`,
  impacts_description_title: "Show rating descriptions",
  impacts_description_description: `With this option you can decide whether you want ratings to show their description alongside the star rating, or just the stars.
  
`,
  album_id_title: "Show album ID",
  album_id_description: `If you activate this option the internal album ID will be displayed at the bottom of each album detail. You can use this for personal reference or to refer to an album when contacting support.
  
`,
  colors_title: "Colors for non-colored discs",
  colors_description: `Enter here all the colors that you don't want to be counted as 'Colored albums' on your statistics. Enter each color individually and exactly as you entered on the albums.
    
`,
  countries_title: "Countries to hide from album list",
  countries_description: `Enter here all the countries that you don't want to be displayed on the album list. These countries will still be displayed on the album detail.
    
`
};
