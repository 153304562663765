import { useBackend } from "../../services";

export const useDeleteLabel = () => {
  const { deleteRequest } = useBackend();
  const deleteLabel = (parameters: IDeleteLabelParameters) => deleteRequest("labels", parameters);
  return { deleteLabel };
};

export interface IDeleteLabelParameters {
  uuid: string;
}
