import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { ListItem, Stack, useTheme } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { ICollection } from "../../../hooks/collections";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { CollectionCard } from "../CollectionCard";

export const CollectionItem: FunctionComponent<ICollectionItemProps> = ({
  cardClassName,
  collection,
  selected,
  canMove,
  onContextMenu,
  onSelection,
  children
}) => {
  const theme = useTheme();
  return (
    <ListItem className={styles.item}>
      <Stack className={styles.container} direction={"row"} sx={{ width: "100%" }}>
        <div className={styles.dragIconContainer}>
          {canMove && (
            <ResponsiveIcon
              className={styles.dragIcon}
              style={{ color: theme.palette.secondary.main }}
              icon={DragIndicator}
            />
          )}
        </div>
        <CollectionCard
          className={cardClassName}
          style={{
            borderColor: theme.palette.colors?.highlightBorder,
            borderWidth: 4,
            borderStyle: selected ? "double" : "none"
          }}
          collection={collection}
          onClick={onSelection}
          onContextMenu={onContextMenu}
          children={children}
        />
      </Stack>
    </ListItem>
  );
};

export interface ICollectionItemProps {
  children?: React.ReactNode;
  cardClassName?: string;
  collection: ICollection;
  selected: boolean;
  canMove: boolean;
  onContextMenu?: (event: React.TouchEvent | React.MouseEvent) => void;
  onSelection: () => void;
}
