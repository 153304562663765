import { useBackend } from "../../services";

export const useAddStore = () => {
  const { post } = useBackend();
  const addStore = (parameters: IAddStoreParameters) => post("stores", parameters);
  return { addStore };
};

export interface IAddStoreParameters {
  name: string;
  address?: string;
  website?: string;
  profile?: string;
}
