import { UserRole } from "../hooks/users";

export const PageSizeHelper = {
  albums: (role: UserRole) => (role == UserRole.common ? 30 : 40),
  artists: (role: UserRole) => (role == UserRole.common ? 30 : 30),
  albumFormats: (role: UserRole) => (role == UserRole.common ? 20 : 20),
  albumTypes: (role: UserRole) => (role == UserRole.common ? 20 : 20),
  tags: (role: UserRole) => (role == UserRole.common ? 20 : 20),
  labels: (role: UserRole) => (role == UserRole.common ? 30 : 30),
  stores: (role: UserRole) => (role == UserRole.common ? 30 : 30),
  sellValues: (role: UserRole) => (role == UserRole.common ? 20 : 30),
  exchangeRates: (role: UserRole) => (role == UserRole.common ? 30 : 40),
  collections: (role: UserRole) => (role == UserRole.common ? 3 : 20),
  shelvings: (role: UserRole) => (role == UserRole.common ? 5 : 20),
  shelves: (role: UserRole) => (role == UserRole.common ? 5 : 5),
  shelfSegments: (role: UserRole) => (role == UserRole.common ? 5 : 5),
  shelfSegmentAlbums: (role: UserRole) => (role == UserRole.common ? 40 : 40),
  userNotifications: () => 20,
  messages: () => 3,
  chats: () => 20,
  chatMessages: () => 30,
  userProfiles: () => 20
};
