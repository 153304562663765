import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";
import {
  ArticleRounded,
  ForumRounded,
  CloseRounded,
  MenuBookRounded,
  PaidRounded,
  TodayRounded
} from "@mui/icons-material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { FreeTextListItem } from "../../FreeTextListItem";
import placeholderAvatar from "../../../images/generic_sleeve.jpg";
import { useGetImage } from "../../../hooks/images";
import { ImageCache } from "../../../models/ImageCache";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { usePublicUserProfile, useUser } from "../../../hooks/session";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import { PublicCollectionsGrid } from "../../marketplace/PublicCollectionsGrid";
import { useGetPublicCollections } from "../../../hooks/collections/useGetPublicCollections";
import { IUserProfile } from "../../../hooks/userProfiles";
import { useNavigate } from "react-router-dom";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { useGetShareLink } from "../../../hooks/share";
import { useIsMobile } from "../../../hooks/isMobile";
import { useIsMounted } from "../../../hooks/isMounted";
import { format } from "date-fns";
import { RoutesBuilder } from "../../../models/RoutesBuilder";

export const PublicUserProfileDialog: FunctionComponent<IPublicUserProfileDialogProps> = ({
  userProfile,
  isOpen,
  setIsOpen,
  onPublicCollectionChanged,
  showCollectionInfo,
  showSendMessageButton
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [currentUser] = useUser.useState();
  const [, setCurrentUserProfile] = usePublicUserProfile.useState();
  const [currentPublicCollection, setCurrentPublicCollection] =
    useCurrentPublicCollection.useState();
  const { getShareLink } = useGetShareLink();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [publicCollections, resetPublicCollections] = useGetPublicCollections(
    userProfile.userId,
    setIsLoading,
    isMounted.current
  );
  const fetchImage = async () => {
    setImage(undefined);
    if (!userProfile.hasImage) return;
    const response = await getImage({
      name: userProfile.uuid,
      width: ImageCache.bigAvatarImageSize,
      height: ImageCache.bigAvatarImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    if (!isOpen) return;
    fetchImage().then();
  }, [isOpen]);
  useEffect(() => {
    resetPublicCollections();
  }, [userProfile]);
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <Avatar
          sx={{
            width: isMobile ? 36 : 50,
            height: isMobile ? 36 : 50,
            marginRight: 2,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
          }}
        >
          <LazyImageAspectFit
            className={styles.avatar}
            defaultImage={placeholderAvatar}
            thumbnail={userProfile.thumbnail}
            image={image}
            alt={"Avatar"}
          />
        </Avatar>
        <h3 className={styles.title}>{userProfile.username}</h3>
        <Box flexGrow={1} />
        {currentUser?.role != "guest" &&
          showSendMessageButton &&
          userProfile.userId != currentUser?.uuid && (
            <ToolbarButton
              onClick={() => navigate(`${RoutesBuilder.chats()}?userId=${userProfile.userId}`)}
              tooltip={strings.chat_new}
              icon={ForumRounded}
            />
          )}
        <ToolbarButton
          onClick={() => setIsOpen(false)}
          tooltip={strings.close}
          icon={CloseRounded}
          loading={isLoading}
        />
      </Toolbar>
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }} dividers>
        {showCollectionInfo && (
          <Card className={styles.section}>
            <FreeTextListItem
              key={"description"}
              icon={ArticleRounded}
              title={currentPublicCollection?.name}
              detail={currentPublicCollection?.description ?? "--"}
            />
          </Card>
        )}
        <Card className={styles.section}>
          <RightDetailListItem
            key={"user_since"}
            icon={TodayRounded}
            title={strings.user_since_title}
            detail={format(userProfile.createdUnixTimestamp * 1000, "d MMMM yyyy", {
              locale: strings.fnsLocale
            })}
          />
          <Divider variant={"inset"} />
          <FreeTextListItem
            key={"bio"}
            icon={MenuBookRounded}
            title={strings.bio_title}
            detail={userProfile.bio ?? "--"}
          />
        </Card>
        <Card className={styles.section}>
          <RightDetailListItem
            key={"currency"}
            icon={PaidRounded}
            title={strings.currency_title}
            detail={userProfile.currency.code ?? strings.unknown}
          />
        </Card>
        <p className={styles.collectionsTitle}>{strings.collections_title}</p>
        <Card className={styles.publicCollectionsCard} sx={{ marginBottom: 4 }} elevation={15}>
          <PublicCollectionsGrid
            isLoading={isLoading}
            publicCollections={publicCollections.map(publicCollection => ({
              collection: publicCollection.collection,
              userProfile: publicCollection.userProfile
            }))}
            showUser={false}
            columnWidth={isMobile ? 12 : 6}
            skeletonCount={2}
            onCollectionSelected={async publicCollection => {
              setIsLoading(true);
              const { status, body } = await getShareLink({
                collectionId: publicCollection.collection.uuid
              });
              if (StatusCodesHelper.isSuccessful(status)) {
                setCurrentUserProfile(publicCollection.userProfile);
                setCurrentPublicCollection(publicCollection.collection);
                navigate(body.relativeShareLink);
                if (onPublicCollectionChanged) {
                  onPublicCollectionChanged();
                }
              }
              setIsLoading(false);
            }}
          />
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export interface IPublicUserProfileDialogProps {
  userProfile: IUserProfile;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  onPublicCollectionChanged?: () => void;
  showCollectionInfo: boolean;
  showSendMessageButton: boolean;
}
