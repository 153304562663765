import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";

export const useCheckPassword = () => {
  const { post } = useBackend();
  return async (password: string) => {
    const { status, body } = await post("checkPassword", { password: password });
    if (StatusCodesHelper.isSuccessful(status)) {
      return body as ICheckPasswordResult;
    } else {
      return undefined;
    }
  };
};

export interface ICheckPasswordResult {
  id: number;
  value: string;
  contains: string[];
  length: number;
}
