import { useBackend } from "../../services";

export const useAddAlbumFormat = () => {
  const { post } = useBackend();
  const addAlbumFormat = (parameters: IAddAlbumFormatParameters) =>
    post("albumFormats", parameters);
  return { addAlbumFormat };
};

export interface IAddAlbumFormatParameters {
  name: string;
  shortName: string;
}
