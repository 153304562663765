import { useBackend } from "../../services";

export const useResendVerificationCode = () => {
  const { put } = useBackend();
  const resendVerificationCode = (parameters: IResendVerificationCodeParameters) =>
    put("emailVerificationCode", parameters);
  return { resendVerificationCode };
};

export interface IResendVerificationCodeParameters {
  email: string;
}
