import { useBackend } from "../../services";
import { IAlbumImage } from "./interfaces";

export const useReorderAlbumImages = () => {
  const { post } = useBackend();
  const reorderAlbumImages = (albumImages: IAlbumImage[]) => {
    const sortedUuids = albumImages.map(albumImage => albumImage.uuid);
    return post("albumImages/reorder", { sortedUuids });
  };
  return { reorderAlbumImages };
};
