export const doc_image_gallery_es = {
  title: "Galería de imágenes",
  description: `Si tenés una cuenta 'Premium' podés usar la galería de imágenes para personalizar aun más tus álbumes. Vas a poder agregar imágenes de contratapa y lomo y hasta 50 imágenes adicionales para cada uno de tus álbumes.
  
  Las imágenes van a estar disponibles también en la exhibición para cada álbum que esté asociado a una colección pública.
  
  Para editar una imagen simplemente seleccionala, o hacé click derecho (mantené presionado en el teléfono) y elegí la opción de editar. También podés arrastrar la imagen nueva sobre la vieja para actualizarla.
  
  Usando el menú contextual del click derecho también podés reordenar las imágenes, ver su detalle, o cambiar el nombre.
  
  `
};
