import { useBackend } from "../../services";
import { ICollection } from "../collections";

export const useImportDiscogsCollection = () => {
  const { post } = useBackend();
  return async (parameters: IImportDiscogsCollectionParameters) => {
    return await post("discogs/import", parameters);
  };
};

export interface IImportDiscogsCollectionParameters {
  username: string;
  folderId: number;
  collections: ICollection[];
  shouldUploadImage: boolean;
  shouldSkipDuplicates: boolean;
}
