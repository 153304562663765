import { useBackend } from "../../services";

export const useDeleteTag = () => {
  const { deleteRequest } = useBackend();
  const deleteTag = (parameters: IDeleteTagParameters) => deleteRequest("tags", parameters);
  return { deleteTag };
};

export interface IDeleteTagParameters {
  uuid: string;
}
