import { useBackend } from "../../services";

export const useSavePushNotificationToken = () => {
  const { post } = useBackend();
  const savePushNotificationToken = (parameters: ISavePushNotificationTokenParameters) =>
    post("pushNotificationTokens", { ...parameters, source: "Web" });
  return { savePushNotificationToken };
};

export interface ISavePushNotificationTokenParameters {
  token: string;
  deviceId: string;
}
