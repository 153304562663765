import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, Skeleton } from "@mui/material";
import { AccountBoxRounded } from "@mui/icons-material";
import { IUserProfile } from "../../../hooks/userProfiles";
import { useGetUserProfilesByText } from "../../../hooks/userProfiles/useGetUserProfilesByText";
import { AttributeDesktop } from "../../attributes/AttributeDesktop";
import { strings } from "../../../localization/LocalizedStrings";
import { PublicUserProfileCard } from "../../marketplace/PublicUserProfileCard";
import { PageSizeHelper } from "../../../models/PageSizeHelper";

export const UserProfilesDesktop: FunctionComponent<IStoresDesktopProps> = ({
  handleClose,
  columnWidth,
  onUserProfileSelected
}) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userProfiles, resetUserProfiles, , noMoreUserProfiles] = useGetUserProfilesByText(
    searchText,
    setLoading
  );
  useEffect(() => {
    resetUserProfiles();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={true}
      items={userProfiles}
      emptyIcon={AccountBoxRounded}
      emptyString={strings.public_user_profiles_empty}
      title={strings.chat_new}
      loading={loading}
      onSearchRequested={setSearchText}
      timed
    >
      {userProfiles?.map(userProfile => (
        <Grid item key={userProfile.uuid} xs={columnWidth}>
          <PublicUserProfileCard
            userProfile={userProfile}
            onClick={() => onUserProfileSelected(userProfile)}
          />
        </Grid>
      ))}
      {(loading || !noMoreUserProfiles) &&
        Array.from({ length: PageSizeHelper.userProfiles() }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
    </AttributeDesktop>
  );
};

export interface IStoresDesktopProps {
  handleClose?: () => void;
  columnWidth: number;
  onUserProfileSelected: (userProfile: IUserProfile) => void;
}
