import { useBackend } from "../../services";
import { ITrack, SellStatus } from "./interfaces";

export const useUpdateAlbum = () => {
  const { put } = useBackend();
  const updateAlbum = (parameters: IUpdateAlbumParameters) => put("albums", parameters);
  return { updateAlbum };
};

export interface IUpdateAlbumParameters {
  uuid: string;
  name?: string;
  catalogueNumber?: string | null;
  originalYear?: string | null;
  releaseYear?: string | null;
  country?: string | null;
  tracks?: number | null;
  discogsReleaseId?: string | null;
  length?: number;
  purchasePrice?: number | null;
  purchaseDate?: number | null;
  isGatefold?: boolean;
  hasInsert?: boolean;
  hasLyrics?: boolean;
  isClean?: boolean;
  labelId?: string | null;
  storeId?: string | null;
  artistId?: string;
  sleeveGradeId?: number | null;
  mediaGradeId?: number | null;
  sellValueId?: string | null;
  albumTypeId?: string | null;
  impactId?: number | null;
  order?: number;
  notes?: string;
  albumTracks?: ITrack[];
  sellStatus?: SellStatus | null;
}
