import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { AppBar, Box, Card, List, SvgIcon, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { AlertDialog } from "../../components/AlertDialog";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { DragIndicator } from "@mui/icons-material";
import { useIsMobile } from "../../hooks/isMobile";
import { FreeTextListItem } from "../../components/FreeTextListItem";
import { FormikProvider, useFormik } from "formik";
import { ModifyFreeTextAttributeDialog } from "../../components/attributes/ModifyFreeTextAttributeDialog";
import { ContainedButton } from "../../components/ContainedButton";
import {
  useRefreshHighlights,
  useBackupDatabase,
  useUpdateMercadoPagoPayments,
  useClearThumbnails,
  useGetMercadoPagoSubscriptionByEmail,
  useCreateUserNotification,
  useSendEmail,
  useSendTestEmail,
  useUpdateApplePayments
} from "../../hooks/admin";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useGetAppleSubscriptionByEmail } from "../../hooks/admin/useGetAppleSubscriptionByEmail";
import { useSendNotificationEmails } from "../../hooks/admin/useSendNotificationEmails";
import { useCreateChatNotifications } from "../../hooks/admin/useCreateChatNotifications";

export const AdminConsole: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ADMIN`;
  }, []);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isLocked, setIsLocked] = useState(true);
  const formik = useFormik({
    initialValues: {
      to: undefined,
      subject: undefined,
      body: undefined,
      messageEs: undefined,
      messageEn: undefined,
      severity: undefined,
      link: undefined
    },
    onSubmit: () => {
      // Do nothing
    }
  });
  const [isFreeTextDialogOpen, setIsFreeTextDialogOpen] = useState(false);
  const [currentField, setCurrentField] = useState("to");
  const [currentFieldValue, setCurrentFieldValue] = useState<string | undefined>();
  const refreshHighlights = useRefreshHighlights();
  const backupDatabase = useBackupDatabase();
  const clearThumbnails = useClearThumbnails();
  const updateMercadoPagoPayments = useUpdateMercadoPagoPayments();
  const updateApplePayments = useUpdateApplePayments();
  const getMercadoPagoSubscriptionByEmail = useGetMercadoPagoSubscriptionByEmail();
  const getAppleSubscriptionByEmail = useGetAppleSubscriptionByEmail();
  const createUserNotification = useCreateUserNotification();
  const createChatNotifications = useCreateChatNotifications();
  const sendEmail = useSendEmail();
  const sendTestEmail = useSendTestEmail();
  const sendNotificationEmails = useSendNotificationEmails();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const processResponse = (status: number, body: any) => {
    if (StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(JSON.stringify(body));
    } else {
      setAlertMessage(body.message);
    }
    setIsAlertOpen(true);
  };
  const buttonsList = () => (
    <div style={{ display: "flex", flexDirection: "column", width: "97%" }}>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          if (
            !formik.values.subject ||
            !formik.values.body ||
            !formik.values.severity ||
            !formik.values.messageEs ||
            !formik.values.messageEn
          )
            return;
          setIsLoading(true);
          const { status, body } = await createUserNotification({
            messageEn: formik.values.messageEn,
            messageEs: formik.values.messageEs,
            severity: formik.values.severity,
            titleEn: formik.values.body,
            titleEs: formik.values.subject,
            userId: formik.values.to,
            link: formik.values.link
          });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Send notification
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          if (!formik.values.to || !formik.values.subject || !formik.values.body) return;
          setIsLoading(true);
          const { status, body } = await sendEmail({
            to: formik.values.to,
            subject: formik.values.subject,
            body: formik.values.body
          });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Send email
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          if (!formik.values.body) return;
          setIsLoading(true);
          const { status, body } = await sendTestEmail({ body: formik.values.body });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Send TEST email
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await sendNotificationEmails();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Send daily notification emails
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await createChatNotifications();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Create chat notifications
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await refreshHighlights({
            collectionsQuantity: 5,
            albumsQuantity: 5
          });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Refresh highlights
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await backupDatabase();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Backup Database
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await updateMercadoPagoPayments();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Update Mercado Pago payments
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await updateApplePayments();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Update Apple payments
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          setIsLoading(true);
          const { status, body } = await clearThumbnails();
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Clear thumbnails
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          if (!formik.values.to) return;
          setIsLoading(true);
          const { status, body } = await getMercadoPagoSubscriptionByEmail({
            email: formik.values.to
          });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Get MercadoPago subscription by email
      </ContainedButton>
      <ContainedButton
        className={styles.button}
        disabled={isLocked}
        onClick={async () => {
          if (!formik.values.to) return;
          setIsLoading(true);
          const { status, body } = await getAppleSubscriptionByEmail({ email: formik.values.to });
          processResponse(status, body);
          setIsLoading(false);
        }}
      >
        Get Apple subscription by email
      </ContainedButton>
    </div>
  );
  return (
    <MainContent showingPersistentDrawer={false}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          <PiperLogoButton
            className={styles.piperLogo}
            onClick={() => {
              //
            }}
          />
          <h3 className={styles.title}>Admin console</h3>
          <Box sx={{ paddingLeft: 1, width: theme.spacing(4) }}>
            {isLoading && <CustomLoadingIndicator />}
          </Box>
          <Box flexGrow={1} />
          <ContainedButton onClick={() => setIsLocked(state => !state)}>Lock</ContainedButton>
        </Toolbar>
      </AppBar>
      <FormikProvider value={formik}>
        <div className={styles.mainBody}>
          <div
            className={styles.detailBody}
            style={{ backgroundColor: theme.palette.background.default }}
          >
            <Card className={styles.mainContainer}>
              <PanelGroup className={styles.mainDiv} direction={"horizontal"}>
                {!isMobile && (
                  <Panel
                    minSize={30}
                    defaultSize={34}
                    maxSize={48}
                    style={{ display: "flex", overflow: "auto", paddingBottom: 16 }}
                  >
                    <List disablePadding className={styles.list}>
                      {buttonsList()}
                    </List>
                  </Panel>
                )}
                {!isMobile && (
                  <PanelResizeHandle className={styles.resizeHandle}>
                    <SvgIcon
                      component={DragIndicator}
                      sx={{
                        alignSelf: "center",
                        color: theme.palette.secondary.main,
                        fontSize: 14
                      }}
                    />
                  </PanelResizeHandle>
                )}
                <Panel className={isMobile ? styles.buttonsDivMobile : styles.buttonsDiv}>
                  {isMobile && buttonsList()}
                  <List>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.to ?? "TO / EMAIL / USER ID"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("to");
                          setCurrentFieldValue(formik.values.to);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.subject ?? "SUBJECT / TITLE ES"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("subject");
                          setCurrentFieldValue(formik.values.subject);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.body ?? "BODY / TITLE EN"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("body");
                          setCurrentFieldValue(formik.values.body);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.messageEs ?? "MESSAGE ES"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("messageEs");
                          setCurrentFieldValue(formik.values.messageEs);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.messageEn ?? "MESSAGE EN"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("messageEn");
                          setCurrentFieldValue(formik.values.messageEn);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={
                          formik.values.severity ?? "SEVERITY: info | pricing | critical | message"
                        }
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("severity");
                          setCurrentFieldValue(formik.values.severity);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                    <Card>
                      <FreeTextListItem
                        hideTitle
                        detail={formik.values.link ?? "LINK"}
                        maxLines={4}
                        onClick={() => {
                          setCurrentField("link");
                          setCurrentFieldValue(formik.values.link);
                          setIsFreeTextDialogOpen(true);
                        }}
                      />
                    </Card>
                  </List>
                </Panel>
              </PanelGroup>
            </Card>
            <AppCredits />
          </div>
        </div>
        <ModifyFreeTextAttributeDialog
          title={currentField}
          description={""}
          placeholder={"--"}
          value={currentFieldValue}
          open={isFreeTextDialogOpen}
          setOpen={setIsFreeTextDialogOpen}
          onChange={newValue => formik.setFieldValue(currentField, newValue)}
          onClearValue={() => formik.setFieldValue(currentField, undefined)}
        />
      </FormikProvider>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </MainContent>
  );
};
