export const doc_image_gallery_en = {
  title: "Image gallery",
  description: `If you have a 'Premium' account you can use the image gallery to further customize your albums. You will be able to add the back cover and spine images and up to 50 additional images for each one of your albums.
  
  The images will also be available on the exhibition for every album that is associated to a public collection.
  
  To edit an image just select it, or right click (hold press on mobile) and select the edit option. You can also drag and drop the new image into the old one to update it.
  
  Using the right click context menu you can also reorder the images, see its detail, or change the display name.
  
  `
};
