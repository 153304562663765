import React, { FunctionComponent } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { HelpOutlineRounded, SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";
import { strings } from "../../localization/LocalizedStrings";

export const SwitchListItem: FunctionComponent<ISwitchListItemProps> = ({
  className,
  icon,
  title,
  labels,
  values,
  value,
  fullWidth,
  disableGutters,
  handleChange,
  helpHref,
  disabled
}) => {
  const theme = useTheme();
  return (
    <ListItem className={className} disableGutters={disableGutters}>
      <div className={styles.itemDiv}>
        {icon && (
          <ListItemIcon>
            <ResponsiveIcon icon={icon} />
          </ListItemIcon>
        )}
        {title && <ListItemText disableTypography className={styles.title} primary={title} />}
        {helpHref && (
          <Tooltip title={strings.help} arrow disableInteractive>
            <IconButton sx={{ marginLeft: "4px" }} href={helpHref} target={"_blank"}>
              <ResponsiveIcon icon={HelpOutlineRounded} />
            </IconButton>
          </Tooltip>
        )}
        <Box flexGrow={1} />
        <ToggleButtonGroup
          value={value}
          fullWidth={fullWidth}
          exclusive
          onChange={handleChange}
          sx={{ height: "30px" }}
          disabled={disabled}
        >
          {labels.map((label, index) => (
            <ToggleButton
              key={`toggle_${index}`}
              className={styles.toggleButton}
              sx={{ color: theme.palette.colors?.disabled }}
              value={values[index]}
            >
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </ListItem>
  );
};

export interface ISwitchListItemProps {
  className?: string;
  icon?: SvgIconComponent;
  title?: string;
  labels: string[];
  values: any[];
  value: any;
  fullWidth: boolean;
  disableGutters?: boolean;
  handleChange: (event: React.MouseEvent<HTMLElement>, newValue: any) => void;
  helpHref?: string;
  disabled?: boolean;
}
