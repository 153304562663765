import { useBackend } from "../../services";

export const useRefreshHighlights = () => {
  const { post } = useBackend();
  return async (props: IRefreshHighlightsProps) => post("admin/highlights", props);
};

interface IRefreshHighlightsProps {
  collectionsQuantity: number;
  albumsQuantity: number;
}
