import { useBackend } from "../../services";
import { AlbumContentType } from "./interfaces";

export const useModifyAlbumContent = () => {
  const { put } = useBackend();
  const modifyAlbumContent = (parameters: IModifyAlbumContentParameters) =>
    put("albumContents", parameters);
  return { modifyAlbumContent };
};

export interface IModifyAlbumContentParameters {
  uuid: string;
  albumId: string;
  quantity?: number;
  albumFormatId?: string;
  name?: string;
  color?: string;
  description?: string;
  playbackSpeedId?: number;
  order: number;
  type: AlbumContentType;
}
