import { ISleeveGrade } from "../hooks/sleeveGrades";
import { strings } from "../localization/LocalizedStrings";
import { IMediaGrade } from "../hooks/mediaGrades";

export const AlbumGradeHelper = {
  getSleeveGradeDescription: (sleeveGrade: ISleeveGrade) => {
    if (sleeveGrade.id == -1) {
      return strings.unknown;
    } else if (sleeveGrade.id == 10) {
      return strings.mint;
    }
    return `${sleeveGrade.points} (${sleeveGrade.goldmineGrade})`;
  },
  getMediaGradeDescription: (mediaGrade: IMediaGrade) => {
    if (mediaGrade.id == -1) {
      return strings.unknown;
    } else if (mediaGrade.id == 10) {
      return strings.mint;
    }
    return `${mediaGrade.points} (${mediaGrade.goldmineGrade})`;
  }
};
