import React, { FunctionComponent, useState } from "react";
import {
  Box,
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useTheme
} from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import {
  AssignmentIndRounded,
  InventoryRounded,
  KeyRounded,
  PersonAddRounded,
  PublicRounded,
  SettingsRounded
} from "@mui/icons-material";
import { PreferencesDialog } from "../PreferencesDialog";
import { ResponsiveIcon } from "../ResponsiveIcon";
import styles from "./styles.module.scss";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { DiscreetLink } from "../DiscreetLink";
import { MobileButtons } from "../MobileButtons";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { DrawerHeader } from "../DrawerHeader";
import { BuyMeACoffeeButton } from "../BuyMeACoffeeButton";

export const LoginDrawer: FunctionComponent<ILoginDrawerProps> = ({
  isOpen,
  setIsOpen,
  onClickLoginAsGuest,
  onClickCreateNewUser,
  onClickRecoverPassword,
  onClickExplore
}) => {
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState(false);
  const list = () => (
    <Box sx={{ width: 310, overflow: "auto" }} role="presentation">
      <List style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
        <Card sx={{ margin: 1 }}>
          <ListItemButton key={"NewUser"} onClick={onClickCreateNewUser}>
            <ListItemIcon>
              <ResponsiveIcon icon={PersonAddRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.login_create_user}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton key={"RecoverPassword"} onClick={onClickRecoverPassword}>
            <ListItemIcon>
              <ResponsiveIcon icon={KeyRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.login_recover_password}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton key={"Guest"} onClick={onClickLoginAsGuest}>
            <ListItemIcon>
              <ResponsiveIcon icon={AssignmentIndRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.login_guest}
            />
          </ListItemButton>
        </Card>
        <Card sx={{ margin: 1 }}>
          <ListItemButton key={"Explore"} onClick={onClickExplore}>
            <ListItemIcon>
              <ResponsiveIcon icon={PublicRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.shortcuts_marketplace}
            />
          </ListItemButton>
        </Card>
        <Card sx={{ margin: 1 }}>
          <ListItemButton
            key={"Preferences"}
            onClick={() => {
              setIsPreferencesDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={SettingsRounded} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.option_preferences}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <DiscreetLink to={RoutesBuilder.documentation.home()}>
            <ListItemButton key={"Documentation"}>
              <ListItemIcon>
                <ResponsiveIcon icon={InventoryRounded} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={styles.itemTitle}
                primary={strings.documentation}
              />
            </ListItemButton>
          </DiscreetLink>
        </Card>
        <MobileButtons />
        <BuyMeACoffeeButton />
      </List>
    </Box>
  );
  return (
    <div>
      <SwipeableDrawer
        anchor={"left"}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.colors?.drawerBackgroundColor,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.7)"
          }
        }}
        variant={isScreenWide ? "persistent" : "temporary"}
      >
        <DrawerHeader setIsOpen={setIsOpen} />
        {list()}
      </SwipeableDrawer>
      <PreferencesDialog
        isOpen={isPreferencesDialogOpen}
        setIsOpen={setIsPreferencesDialogOpen}
        canRemoveAccount={false}
      />
    </div>
  );
};

export interface ILoginDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClickLoginAsGuest: () => void;
  onClickCreateNewUser: () => void;
  onClickRecoverPassword: () => void;
  onClickExplore: () => void;
}
