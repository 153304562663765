import { useBackend } from "../../services";

export const useAddMessageForAlbum = () => {
  const { post } = useBackend();
  const addMessageForAlbum = (parameters: IAddMessageForAlbumParameters) =>
    post("messages/album", parameters);
  return { addMessageForAlbum };
};

export interface IAddMessageForAlbumParameters {
  albumId: string;
  collectionId?: string;
  body: string;
  replyToMessageId?: string;
}
