import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationUserProfile: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_user_profile.title}</h1>
      <p>{strings.doc_user_profile.description}</p>
      <section id={"notifications"} />
      <h2>{strings.doc_user_profile.notifications_title}</h2>
      <p>{strings.doc_user_profile.notifications_description}</p>
      <section id={"avatar"} />
      <h2>{strings.doc_user_profile.update_avatar_title}</h2>
      <p>{strings.doc_user_profile.update_avatar_description}</p>
      <section id={"username"} />
      <h2>{strings.doc_user_profile.username_title}</h2>
      <p>{strings.doc_user_profile.username_description}</p>
      <section id={"email"} />
      <h2>{strings.doc_user_profile.email_title}</h2>
      <p>{strings.doc_user_profile.email_description}</p>
      <section id={"password"} />
      <h2>{strings.doc_user_profile.password_title}</h2>
      <p>{strings.doc_user_profile.password_description}</p>
      <section id={"bio"} />
      <h2>{strings.doc_user_profile.bio_title}</h2>
      <p>{strings.doc_user_profile.bio_description}</p>
      <section id={"currency"} />
      <h2>{strings.doc_user_profile.currency_title}</h2>
      <p>{strings.doc_user_profile.currency_description}</p>
      <section id={"decimals"} />
      <h2>{strings.doc_user_profile.decimals_title}</h2>
      <p>{strings.doc_user_profile.decimals_description}</p>
      <section id={"ratingDescriptions"} />
      <h2>{strings.doc_user_profile.impacts_description_title}</h2>
      <p>{strings.doc_user_profile.impacts_description_description}</p>
      <section id={"albumId"} />
      <h2>{strings.doc_user_profile.album_id_title}</h2>
      <p>{strings.doc_user_profile.album_id_description}</p>
      <section id={"colors"} />
      <h2>{strings.doc_user_profile.colors_title}</h2>
      <p>{strings.doc_user_profile.colors_description}</p>
      <section id={"countries"} />
      <h2>{strings.doc_user_profile.countries_title}</h2>
      <p>{strings.doc_user_profile.countries_description}</p>
    </div>
  );
};
