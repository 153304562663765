import { useBackend } from "../../services";

export const useModifyTag = () => {
  const { put } = useBackend();
  const modifyTag = (parameters: IModifyTagParameters) => put("tags", parameters);
  return { modifyTag };
};

export interface IModifyTagParameters {
  uuid: string;
  name: string;
  description?: string;
}
