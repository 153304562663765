import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationOrderAlbums: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_order_albums.title}</h1>
      <img className={styles.image} src={strings.doc_order_albums.prefs_image} />
      <p>{strings.doc_order_albums.description}</p>
      <img className={styles.imageOrder} src={strings.doc_order_albums.album_image} />
      <p>{strings.doc_order_albums.drag_description}</p>
    </div>
  );
};
