import { useBackend } from "../../services";

export const useUnstarCollection = () => {
  const { deleteRequest } = useBackend();
  const unstarCollection = (parameters: IUnstarCollectionParameters) =>
    deleteRequest("user/starCollection", parameters);
  return { unstarCollection };
};

export interface IUnstarCollectionParameters {
  collectionId: string;
}
