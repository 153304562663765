import { useBackend } from "../../services";

export const useModifyShelfSegment = () => {
  const { put } = useBackend();
  const modifyShelfSegment = (parameters: IModifyShelfSegmentParameters) =>
    put("shelfSegments", parameters);
  return { modifyShelfSegment };
};

export interface IModifyShelfSegmentParameters {
  uuid: string;
  name: string;
  description?: string;
}
