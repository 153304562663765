import React, { FunctionComponent } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Card, Stack, useTheme } from "@mui/material";
import styles from "./styles.module.scss";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card>
        <p style={{ margin: 10, fontWeight: "bold" }}>{`${label}: ${payload[0].value}`}</p>
      </Card>
    );
  }
  return null;
};

export const BarChartGraph: FunctionComponent<IBarChartGraphProps> = ({
  title,
  data,
  dataKey,
  colorForIndex
}) => {
  const theme = useTheme();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <p className={styles.title}>{title}</p>
        <ResponsiveContainer>
          <BarChart
            className={styles.chart}
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: -20,
              bottom: 5
            }}
          >
            <CartesianGrid stroke="#808080" strokeDasharray="5 5" />
            <XAxis dataKey="name" stroke={theme.palette.primary.main} />
            <YAxis stroke={theme.palette.primary.main} />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Bar dataKey={dataKey} isAnimationActive={true}>
              {data.map((value, index) => (
                <Cell fill={colorForIndex(index)} key={`cell-${index}`} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Stack>
    </div>
  );
};

export interface IBarChartGraphProps {
  title: string;
  data: any[];
  dataKey: string;
  colorForIndex: (index: number) => string;
}
