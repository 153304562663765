import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { ISellValue, useGetSellValues } from "../../../../hooks/sellValues";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifySellValueDialog } from "../ModifySellValueDialog";
import { SellValueCard } from "../SellValueCard";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const SellValuesDesktop: FunctionComponent<ISellValuesDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onSellValueSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const [sellValues, resetSellValues, setSellValues, noMoreSellValues] = useGetSellValues(
    isPublic,
    setLoading
  );
  const [filteredSellValues, setFilteredSellValues] = useState<ISellValue[]>();
  const [isAddSellValueDialogOpen, setIsAddSellValueDialogOpen] = useState(false);
  const [isModifySellValueDialogOpen, setIsModifySellValueDialogOpen] = useState(false);
  const [selectedSellValue, setSelectedSellValue] = useState<ISellValue>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredSellValues();
  }, [sellValues]);
  const refreshFilteredSellValues = () => {
    if (searchText == "") {
      setFilteredSellValues(sellValues);
      return;
    }
    setFilteredSellValues(
      sellValues?.filter(sellValue => {
        return (
          sellValue.group.toLowerCase().includes(searchText.toLowerCase()) ||
          sellValue.dollars.toString().toLowerCase().includes(searchText.toLowerCase()) ||
          sellValue.description.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredSellValues();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredSellValues}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.SELL_VALUES)}
      emptyString={isPublic ? strings.empty_sell_values_public : strings.empty_sell_values}
      addItemString={strings.add_attribute}
      title={strings.attribute_sell_values_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddSellValueDialogOpen(true)}
      refreshAction={resetSellValues}
      onClearValue={onClearValue}
    >
      {filteredSellValues?.map(sellValue => (
        <Grid item key={sellValue.uuid} xs={columnWidth}>
          <SellValueCard
            sellValue={sellValue}
            selected={preselectedId == sellValue.uuid}
            onClick={() => {
              if (onSellValueSelected) {
                onSellValueSelected(sellValue);
              } else {
                setSelectedSellValue(sellValue);
                setIsModifySellValueDialogOpen(true);
              }
            }}
            onContextMenu={event => {
              setSelectedSellValue(sellValue);
              handleContextMenu(event);
            }}
          />
        </Grid>
      ))}
      {(loading || !noMoreSellValues) &&
        Array.from({ length: 40 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 120 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifySellValueDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifySellValueDialog
        open={isAddSellValueDialogOpen}
        setOpen={setIsAddSellValueDialogOpen}
        onNewItem={newSellValue => {
          const newSellValues = sellValues;
          newSellValues.push(newSellValue);
          newSellValues.sort((a, b) =>
            a.group.localeCompare(b.group, undefined, { ignorePunctuation: true })
          );
          setSellValues(newSellValues);
          refreshFilteredSellValues();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifySellValueDialog
        open={isModifySellValueDialogOpen}
        setOpen={setIsModifySellValueDialogOpen}
        sellValue={selectedSellValue}
        onModifiedItem={newSellValue => {
          const newSellValues = sellValues.map(sellValue =>
            sellValue.uuid == newSellValue.uuid ? newSellValue : sellValue
          );
          newSellValues.sort((a, b) =>
            a.group.localeCompare(b.group, undefined, { ignorePunctuation: true })
          );
          setSellValues(newSellValues);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedSellValue => {
          const newSellValues = sellValues.filter(
            sellValue => sellValue.uuid != deletedSellValue.uuid
          );
          setSellValues(newSellValues);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface ISellValuesDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: GridSize;
  preselectedId?: string;
  onSellValueSelected?: (sellValue: ISellValue) => void;
  onClearValue?: () => void;
}
