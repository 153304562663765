import { Button, SvgIcon, Tooltip, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import piperTextImage from "../../images/piper-text.png";
import { MenuRounded } from "@mui/icons-material";
import { strings } from "../../localization/LocalizedStrings";
import { useIsMobile } from "../../hooks/isMobile";
import styles from "./styles.module.scss";
import classNames from "classnames";

export const PiperLogoButton: FunctionComponent<IPiperLogoButtonProps> = ({
  className,
  style,
  onClick
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <Tooltip title={isMobile ? "" : strings.open_drawer} arrow disableInteractive>
      <Button
        className={classNames(className, styles.button)}
        style={{
          ...style,
          height: isMobile ? 38 : 44,
          width: isMobile ? 38 : 92,
          minWidth: 0,
          borderRadius: 10,
          backgroundColor: theme.palette.background.default
        }}
        onClick={onClick}
      >
        <SvgIcon fontSize={"small"} component={MenuRounded} />
        {!isMobile && (
          <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={piperTextImage} alt={"Piper"} />
        )}
        <p className={styles.menuText}>{strings.menu}</p>
      </Button>
    </Tooltip>
  );
};

export interface IPiperLogoButtonProps {
  className?: string;
  style?: any;
  onClick: () => void;
}
