import { useBackend } from "../../services";

export const useDeleteAlbum = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbum = (parameters: IDeleteAlbumParameters) => deleteRequest("albums", parameters);
  return { deleteAlbum };
};

export interface IDeleteAlbumParameters {
  uuid: string;
}
