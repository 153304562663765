import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationSignUp: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_users_sign_up.title}</h1>
      <p>{strings.doc_users_sign_up.description}</p>
      <ul className={styles.indentedText}>
        <li>{strings.doc_users_sign_up.description_1}</li>
        <li>{strings.doc_users_sign_up.description_2}</li>
        <li>{strings.doc_users_sign_up.description_3}</li>
      </ul>
      <section id={"signUpThirdParty"} />
      <h2>{strings.doc_users_sign_up.sign_up_third_party_title}</h2>
      <img
        className={styles.imageFloatRight}
        style={{ maxWidth: "24%" }}
        src={strings.doc_users_sign_up.sign_up_third_party_image}
      />
      <p>{strings.doc_users_sign_up.sign_up_third_party_description}</p>
      <section id={"signUpWithPiper"} />
      <h2>{strings.doc_users_sign_up.sign_up_piper_title}</h2>
      <img
        className={styles.imageFloatRight}
        style={{ maxWidth: "40%" }}
        src={strings.doc_users_sign_up.sign_up_piper_image}
      />
      <p>{strings.doc_users_sign_up.sign_up_piper_description}</p>
    </div>
  );
};
