import { ICurrency } from "./interfaces";
import { createState } from "state-pool";

export const useCurrencies = createState<ICurrency[] | undefined>(undefined);

export const useCurrenciesManager = () => {
  const [currencies] = useCurrencies.useState();
  const getCurrenciesStringList = () => {
    if (!currencies) return [];
    return currencies.map(currency => `${currency.code} \[${currency.symbol}\]`);
  };
  const getCurrenciesCodeList = () => {
    if (!currencies) return [];
    return currencies.map(currency => currency.code);
  };
  const getCurrency = (code: string) => {
    return currencies?.find(currency => currency.code == code);
  };
  return { getCurrenciesStringList, getCurrenciesCodeList, getCurrency };
};
