import { useBackend } from "../../services";

export const useDeleteShelf = () => {
  const { deleteRequest } = useBackend();
  const deleteShelf = (parameters: IDeleteShelfParameters) => deleteRequest("shelves", parameters);
  return { deleteShelf };
};

export interface IDeleteShelfParameters {
  uuid: string;
}
