import image from "../../../../images/documentation/mark-albums-en.png";

export const doc_marking_albums_en = {
  title: "Marking albums",
  image: image,
  description: `You can mark your albums to add some visual indications to the album list. Each type of marking will have a different aspect on the list so try them all and use them to your convenience.
  
  Markings are specially useful for public collections that you use to sell your albums, since you can easily communicate if an album is already reserved, if it has been sold, or if it's only available to pre-order.
  
  `
};
