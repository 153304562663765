import { useBackend } from "../../services";

export const useModifyCollection = () => {
  const { put } = useBackend();
  const modifyCollection = (parameters: IModifyCollectionParameters) =>
    put("collections", parameters);
  return { modifyCollection };
};

export interface IModifyCollectionParameters {
  uuid: string;
  name?: string;
  description?: string;
  visibility?: "public" | "private";
  bannedFields?: string[];
  discount?: number;
}
