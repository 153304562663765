import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useCurrencies } from "./useCurrenciesManager";

export const useGetCurrencies = () => {
  const { get } = useBackend();
  const [, setCurrencies] = useCurrencies.useState();
  const getCurrencies = async () => {
    const { status, body } = await get("currencies");
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrencies(body);
    }
  };
  return { getCurrencies };
};
