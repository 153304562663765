import { useBackend } from "../../services";

export const useUpdateEmail = () => {
  const { put } = useBackend();
  const updateEmail = (parameters: IUpdateEmailParameters) => put("user/email", parameters);
  return { updateEmail };
};

export interface IUpdateEmailParameters {
  email: string;
}
