import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "../DocumentationFilterAlbums/styles.module.scss";

export const DocumentationMarkingAlbums: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_marking_albums.title}</h1>
      <p>{strings.doc_marking_albums.description}</p>
      <img className={styles.image} src={strings.doc_marking_albums.image} />
    </div>
  );
};
