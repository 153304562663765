import { useBackend } from "../../services";

export const useAddChat = () => {
  const { post } = useBackend();
  const addChat = (parameters: IAddChatParameters) => post("chats", parameters);
  return { addChat };
};

export interface IAddChatParameters {
  userIds: string[];
}
