import { ICollection } from "./index";
import { createState } from "state-pool";
import { useEffect } from "react";

export const useCurrentPrivateCollection = createState<ICollection | undefined>(() => {
  const collectionString = localStorage.getItem("currentCollection");
  if (collectionString != null) {
    return JSON.parse(collectionString);
  }
});
export const useCurrentPublicCollection = createState<ICollection | undefined>(undefined);

export const useCollectionManager = () => {
  const [currentPrivateCollection] = useCurrentPrivateCollection.useState();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  useEffect(() => {
    if (currentPrivateCollection === undefined) {
      localStorage.removeItem("currentCollection");
    } else {
      localStorage.setItem("currentCollection", JSON.stringify(currentPrivateCollection));
    }
  }, [currentPrivateCollection]);
  const getCurrentCollection = (isPublic: boolean) => {
    return isPublic ? currentPublicCollection : currentPrivateCollection;
  };
  const isCurrentCollectionShareable = (isPublic: boolean) => {
    if (isPublic) {
      return currentPublicCollection?.visibility == "public";
    }
    return currentPrivateCollection?.visibility == "public";
  };
  return { getCurrentCollection, isCurrentCollectionShareable };
};
