import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, useTheme } from "@mui/material";
import classNames from "classnames";

export const GoldmineRatingCard: FunctionComponent<IGoldmineRatingCardProps> = ({
  className,
  points,
  goldmineGrading
}) => {
  const theme = useTheme();
  const [color, setColor] = useState(theme.palette.colors?.goldmineGray);
  useEffect(() => {
    if (points == 0) {
      setColor(theme.palette.colors?.goldmineGray);
    } else if (points < 6) {
      setColor(theme.palette.colors?.goldmineRed);
    } else if (points < 9) {
      setColor(theme.palette.colors?.goldmineGreen);
    } else if (points <= 10) {
      setColor(theme.palette.colors?.goldmineBlue);
    } else {
      setColor(theme.palette.colors?.goldmineMint);
    }
  }, [points, theme.palette]);
  return (
    <Card
      className={classNames(styles.card, className)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color
      }}
    >
      <p className={styles.description}>{goldmineGrading}</p>
    </Card>
  );
};

export interface IGoldmineRatingCardProps {
  className?: string;
  points: number;
  goldmineGrading: string;
}
