import { useBackend } from "../../services";

export const useAssignAlbumToCollection = () => {
  const { post } = useBackend();
  const assignAlbumToCollection = (parameters: IAssignAlbumToCollectionParameters) =>
    post("albums/assignToCollection", parameters);
  return { assignAlbumToCollection };
};

export interface IAssignAlbumToCollectionParameters {
  uuid: string;
  collectionId: string;
}
