import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea, Grid, Stack } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";

export const ShortcutItem: FunctionComponent<IShortcutItemProps> = ({
  className,
  icon,
  title,
  onClick
}) => {
  return (
    <Grid item xs className={className}>
      <Card
        className={styles.card}
        elevation={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CardActionArea sx={{ padding: 3, height: "100%" }} onClick={onClick}>
          <Stack spacing={2} className={styles.stack}>
            <ResponsiveIcon icon={icon} />
            <p className={styles.title}>{title}</p>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export interface IShortcutItemProps {
  className?: string;
  icon: SvgIconComponent;
  title: string;
  onClick?: () => void;
}
