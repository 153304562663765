import React, { FunctionComponent } from "react";
import { Card, Stack, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { useUserProfile } from "../../../hooks/session";
import { OAuthLoginButton } from "../../OAuthLoginButton";

export const SubscriptionOptionCard: FunctionComponent<ISubscriptionOptionCardProps> = ({
  price,
  currency,
  imageSrc,
  buttonText,
  description,
  optionText,
  onClick
}) => {
  const theme = useTheme();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  return (
    <Card className={styles.card}>
      <Stack className={styles.textStack} spacing={2}>
        <p className={styles.option} style={{ color: theme.palette.secondary.main }}>
          {strings.pay_option} {optionText}
        </p>
        <p className={styles.price}>
          {currencyFormatter.format(price, currentUserProfile, false, 2, currency, true)}{" "}
          {strings.per_month}
        </p>
        <OAuthLoginButton
          className={styles.payButton}
          imageSrc={imageSrc}
          buttonText={buttonText}
          onClick={onClick}
        />
        <p className={styles.description}>{description}</p>
      </Stack>
    </Card>
  );
};

export interface ISubscriptionOptionCardProps {
  price: number;
  currency: string;
  imageSrc: string;
  buttonText: string;
  description: string;
  optionText: string;
  onClick: () => void;
}
