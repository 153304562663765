import { useBackend } from "../../services";

export const useDeleteMessage = () => {
  const { deleteRequest } = useBackend();
  const deleteMessage = (parameters: IDeleteMessageParameters) =>
    deleteRequest("messages", parameters);
  return { deleteMessage };
};

export interface IDeleteMessageParameters {
  uuid: string;
}
