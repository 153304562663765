import { strings } from "../../localization/LocalizedStrings";
import { IUserProfile } from "../userProfiles";

export const useCurrencyFormatter = () => {
  const format = (
    number: number | undefined,
    userProfile: IUserProfile | undefined,
    isPublic: boolean,
    maximumFractionDigits = 0,
    forcedCurrency?: string,
    forceFractionDigits?: boolean
  ) => {
    if (number === undefined || userProfile === undefined) return strings.unknown;
    const minimumFractionDigitsByProfile = userProfile?.showDecimals
      ? number % 1 == 0
        ? 0
        : Math.min(2, maximumFractionDigits)
      : 0;
    const maximumFractionDigitsByProfile = userProfile?.showDecimals ? maximumFractionDigits : 0;
    const formatter = new Intl.NumberFormat(strings.currencyLocale, {
      style: "currency",
      currency: forcedCurrency ? forcedCurrency : userProfile?.currency?.code ?? "USD",
      currencyDisplay: forcedCurrency || isPublic ? "code" : "narrowSymbol",
      currencySign: "accounting",
      minimumFractionDigits: forceFractionDigits
        ? maximumFractionDigits
        : minimumFractionDigitsByProfile,
      maximumFractionDigits: forceFractionDigits
        ? maximumFractionDigits
        : maximumFractionDigitsByProfile
    });
    return formatter
      .format(number)
      .replace(/^(\D+)/, "$1 ")
      .replace(/\s+/, " ");
  };

  return { format };
};
