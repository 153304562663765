import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Card, Dialog, Divider, Grow, ListItemText, Stack } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { AlertDialog } from "../../AlertDialog";
import styles from "./styles.module.scss";
import { AlbumTypesDesktop } from "../../attributes/albumTypes/AlbumTypesDesktop";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../hooks/isMobile";
import { IAlbumType } from "../../../hooks/albumTypes";
import { AlbumContentsTable } from "../contents/AlbumContentsTable";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { ModifyAlbumContentDialog } from "../contents/ModifyAlbumContentDialog";
import { IAlbumContent } from "../../../hooks/albumContents";
import { arrayMoveImmutable } from "array-move";
import { useReorderAlbumContents } from "../../../hooks/albumContents/useReorderAlbumContents";
import { useDeleteAlbumContent } from "../../../hooks/albumContents/useDeleteAlbumContent";
import { SelectChipsListItem } from "../../SelectChipsListItem";
import { TagsDesktop } from "../../attributes/tags/TagsDesktop";
import { useAssignTagToAlbum } from "../../../hooks/albums/useAssignTagToAlbum";
import { useUnassignTagFromAlbum } from "../../../hooks/albums/useUnassignTagFromAlbum";

export const AlbumFormatListSection: FunctionComponent<IAlbumFormatListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const isMobile = useIsMobile();
  const { updateAlbum } = useUpdateAlbum();
  const { reorderAlbumContents } = useReorderAlbumContents();
  const { deleteAlbumContent } = useDeleteAlbumContent();
  const { assignTagToAlbum } = useAssignTagToAlbum();
  const { unassignTagFromAlbum } = useUnassignTagFromAlbum();
  const [selectedAlbumContent, setSelectedAlbumContent] = useState<IAlbumContent>();
  const [isLoadingAlbumContents, setIsLoadingAlbumContents] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isAlbumTypesDialogOpen, setIsAlbumTypesDialogOpen] = useState(false);
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
  const [isAlbumContentDeleteConfirmationAlertOpen, setIsAlbumContentDeleteConfirmationAlertOpen] =
    useState(false);
  const [isCreateAlbumContentDialogOpen, setIsCreateAlbumContentDialogOpen] = useState(false);
  const [isModifyAlbumContentDialogOpen, setIsModifyAlbumContentDialogOpen] = useState(false);
  const [isLoadingAlbumType, setIsLoadingAlbumType] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const createAlbumContent = (newAlbumContent: IAlbumContent) => {
    if (album.albumContents) {
      album.albumContents.push(newAlbumContent);
    } else {
      album.albumContents = [newAlbumContent];
    }
    onAttributeUpdated(album);
  };
  const updateAlbumContent = (newAlbumContent: IAlbumContent) => {
    album.albumContents = album.albumContents?.map(albumContent =>
      albumContent.uuid == newAlbumContent.uuid ? newAlbumContent : albumContent
    );
    onAttributeUpdated(album);
  };
  const removeAlbumContent = async () => {
    setIsLoadingAlbumContents(true);
    const { status, body } = await deleteAlbumContent({ uuid: selectedAlbumContent.uuid });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    } else {
      album.albumContents = album.albumContents?.filter(
        albumContent => selectedAlbumContent.uuid != albumContent.uuid
      );
      onAttributeUpdated(album);
      toast.success(strings.generic_update_success);
    }
    setIsLoadingAlbumContents(false);
  };
  const moveAlbumContents = async (fromIndex: number, toIndex: number) => {
    const newlyOrderedAlbumContents = arrayMoveImmutable(album.albumContents, fromIndex, toIndex);
    setIsLoadingAlbumContents(true);
    const { status, body } = await reorderAlbumContents(newlyOrderedAlbumContents);
    if (StatusCodesHelper.isSuccessful(status)) {
      album.albumContents = newlyOrderedAlbumContents;
      onAttributeUpdated(album);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingAlbumContents(false);
  };
  const updateAlbumType = async (newValue: IAlbumType | undefined) => {
    setIsAlbumTypesDialogOpen(false);
    setIsLoadingAlbumType(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      albumTypeId: newValue?.uuid ?? null
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.albumTypeId = newValue?.uuid;
      album.albumType = newValue;
      onAttributeUpdated(album);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingAlbumType(false);
  };
  const addTag = async (tagId: string) => {
    setIsTagsDialogOpen(false);
    setIsLoadingTags(true);
    const { status, body } = await assignTagToAlbum({
      uuid: album.uuid,
      tagId: tagId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.tags = body.tags;
      onAttributeUpdated(album);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingTags(false);
  };
  const removeTag = async (tagId: string) => {
    setIsTagsDialogOpen(false);
    setIsLoadingTags(true);
    const { status, body } = await unassignTagFromAlbum({
      uuid: album.uuid,
      tagId: tagId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.album_update_success);
      album.tags = body.tags;
      onAttributeUpdated(album);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingTags(false);
  };
  return (
    <div>
      {!(isPublic && album.albumContents === undefined) && (
        <div className={styles.tableDiv}>
          <Stack className={styles.header} direction={"row"} spacing={2} sx={{ width: "100%" }}>
            <ListItemText
              disableTypography
              className={styles.title}
              secondary={strings.album_contents_title}
              sx={{ flexGrow: 0 }}
            />
            {!isPublic && (
              <ToolbarButton
                tooltip={strings.add_content}
                icon={AddCircleOutlineRounded}
                loading={isLoadingAlbumContents}
                onClick={() => setIsCreateAlbumContentDialogOpen(true)}
              />
            )}
          </Stack>
          <Card sx={{ width: "100%" }}>
            <AlbumContentsTable
              isPublic={isPublic}
              album={album}
              onModify={albumContent => {
                setSelectedAlbumContent(albumContent);
                setIsModifyAlbumContentDialogOpen(true);
              }}
              onMoveUp={index => moveAlbumContents(index, index - 1)}
              onMoveDown={index => moveAlbumContents(index, index + 1)}
              onDelete={albumContent => {
                setSelectedAlbumContent(albumContent);
                setIsAlbumContentDeleteConfirmationAlertOpen(true);
              }}
            />
          </Card>
        </div>
      )}
      {!(isPublic && album.tags === undefined) && (
        <>
          <SelectChipsListItem
            className={styles.switchListItem}
            title={strings.tags_title}
            chips={album.tags?.map(tag => ({
              id: tag.uuid,
              name: tag.name,
              description: tag.description
            }))}
            isLoading={isLoadingTags}
            onAdd={() => setIsTagsDialogOpen(true)}
            onDelete={removeTag}
            addDisabled={isPublic}
            removeDisabled={isPublic}
          />
          <Divider variant={"inset"} />
        </>
      )}
      <RightDetailListItem
        disabled={isPublic}
        title={strings.album_type_title}
        detail={album.albumType ? album.albumType.name : strings.unknown}
        onClick={() => setIsAlbumTypesDialogOpen(true)}
        isLoading={isLoadingAlbumType}
      />
      <ModifyAlbumContentDialog
        albumId={album.uuid}
        open={isCreateAlbumContentDialogOpen}
        setOpen={setIsCreateAlbumContentDialogOpen}
        onUpdate={createAlbumContent}
      />
      <ModifyAlbumContentDialog
        albumContent={selectedAlbumContent}
        albumId={album.uuid}
        open={isModifyAlbumContentDialogOpen}
        setOpen={setIsModifyAlbumContentDialogOpen}
        onUpdate={updateAlbumContent}
      />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumTypesDialogOpen}
        onClose={() => setIsAlbumTypesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumTypesDesktop
            handleClose={() => setIsAlbumTypesDialogOpen(false)}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={album.albumType?.uuid}
            onAlbumTypeSelected={updateAlbumType}
            onClearValue={() => updateAlbumType(undefined)}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isTagsDialogOpen}
        onClose={() => setIsTagsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <TagsDesktop
            handleClose={() => setIsTagsDialogOpen(false)}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            onTagSelected={tag => addTag(tag.uuid)}
            preselectedIds={album.tags?.map(tag => tag.uuid)}
          />
        </div>
      </Dialog>
      <AlertDialog
        message={strings.album_content_delete_confirmation}
        open={isAlbumContentDeleteConfirmationAlertOpen}
        setOpen={setIsAlbumContentDeleteConfirmationAlertOpen}
        isConfirm
        onConfirm={removeAlbumContent}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumFormatListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
