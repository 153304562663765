import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationFilterAlbums: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_filter_albums.title}</h1>
      <img className={styles.image} src={strings.doc_filter_albums.bar_image} />
      <p>{strings.doc_filter_albums.description}</p>
      <p>{strings.doc_filter_albums.order_description}</p>
      <img className={styles.imageOrder} src={strings.doc_filter_albums.order_image} />
      <img className={styles.imageFloatRight} src={strings.doc_filter_albums.elements_image} />
      <p>{strings.doc_filter_albums.elements_description}</p>
    </div>
  );
};
