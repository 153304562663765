export const doc_mercado_pago_subscriptions_en = {
  title: "Subscribe using Mercado Pago",
  description: `Piper is integrated with Mercado Pago so you can pay your subscription using that platform. This kind of payment is available only on some countries of South America.
  
  If you have an account on Mercado Pago you can choose to pay using your existing account and you will be able to select any of your cards or even pay using your available funds. It's important that once the payment is done you use the button to go back to the site so we can register the subscription in our database. If you fail to do so you won't see the subscription on Piper, but don't worry, just contact us in that case and we will fix it for you as soon as possible. 
  
  The subscription can be managed entirely in the Mercado Pago platform. You can review your payments, when will your next charge be, cancel the subscription and more.
  
  The subscriptions are charged on the first day of each month. When you make the first payment, the amount paid will be proportional to the number of days left to the first day of the next month.
  
  If you cancel your subscription at any time you will still have your privileges until the first day of the next month.
  
`
};
