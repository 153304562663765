export const doc_import_from_excel_en = {
  title: "Import albums from a spreadsheet",
  description_1: `You can import your albums to Piper from a spreadsheet file. Just select the option from the menu drawer and a dialog will open.
  
  Once inside the import window, select a file with the 'xlsx' extension. Spreadsheet files can be exported to this format in most programs.
  
  This file needs to have each album on a separate row, and its attributes have to be positioned on each column on a specific order. Each column can be either mandatory (this column needs to have data on each row), or optional (this column can be empty for some or all rows, but it has to be present, so leave an empty column if you don't use that attribute for your albums.)
  
  IMPORTANT: The first row of the file will be skipped, so use it if you want to put headers on each column. Put all albums inside a unique table. Avoid having trailing empty rows.
  
  All attributes that apply will be matched by name with what you have on Piper. If it already exists, it will be used. Otherwise, a new one will be created.
  
  You can use the following template and fill it with your albums:
`,
  description_2: `The columns inside the file are explained below. Only the first two are mandatory.
  `,
  column_1: `Album name (Mandatory)
The name of the album. It can't be empty.
  `,
  column_2: `Artist name (Mandatory)
The name of the artist for this album. It can't be empty.
  `,
  column_3: `Label name (Optional)
The name of the label for this album.
  `,
  column_4: `Catalogue number (Optional)
The catalogue number for this album.
  `,
  column_5: `Original year (Optional)
The original release year for this album.
  `,
  column_6: `Release year (Optional)
The specific release year for this album.
  `,
  column_7: `Country (Optional)
The country of origin for this album.
  `,
  column_8: `Jacket grading (Optional)
The grading for the album's jacket in numerical points. This follows the standard Golmine grading system with the following criteria, enter any of the following numbers or leave it blank:
    
    (1) = P-
    (2) = P
    (3) = G-
    (4) = G
    (5) = G+
    (6) = VG
    (7) = VG+
    (8) = VG++
    (9) = NM-
    (10) = NM
    (11) = M
  `,
  column_9: `Media grading (Optional)
The grading for the album's disc in numerical points. This follows the standard Golmine grading system with the following criteria, enter any of the following numbers or leave it blank:
    
    (1) = P-
    (2) = P
    (3) = G-
    (4) = G
    (5) = G+
    (6) = VG
    (7) = VG+
    (8) = VG++
    (9) = NM-
    (10) = NM
    (11) = M
  `,
  column_10: `Played (Optional)
Whether the album is played or not. Fill the cell with anything for YES, or leave it blank for NO.
  `,
  column_11: `Insert (Optional)
Whether the album has insert or not. Fill the cell with anything for YES, or leave it blank for NO.
  `,
  column_12: `Lyrics (Optional)
Whether the album has lyrics or not. Fill the cell with anything for YES, or leave it blank for NO.
  `,
  column_13: `Gatefold (Optional)
Whether the album is gatefold or not. Fill the cell with anything for YES, or leave it blank for NO.
  `,
  column_14: `Purchase price (Optional)
The price of the album at the time of purchase. Enter the number in your local currency without the currency symbol.
  `,
  column_15: `Selling value (Optional)
The name of the selling value group for this album.
  `,
  column_16: `Purchase date (Optional)
The date when the album was purchased. Make sure this cell has the date format.
  `,
  column_17: `Store (Optional)
The store where the album was purchased.
  `,
  column_18: `Number of discs (Optional)
The number of discs for this album.
  `,
  column_19: `Disc color (Optional)
The color of the disc for this album.
  `,
  column_20: `Format (Optional)
The complete name of the album's format. For example: 'Long Play.'
  `,
  column_21: `Album type (Optional)
The name of the album's type. For example: 'Demo.'
  `,
  column_22: `Number of tracks (Optional)
The number of tracks for this album.
  `,
  column_23: `Length (Optional)
The length of the album in seconds.
  `,
  column_24: `Rating (Optional)
The rating given to the album. Enter the number of stars as seen below:

    (1) = ☆☆☆☆★
    (2) = ☆☆☆★★
    (3) = ☆☆★★★
    (4) = ☆★★★★
    (5) = ★★★★★
  `,
  column_25: `Notes (Optional)
Enter here any notes for this album.
  `
};
