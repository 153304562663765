import { useBackend } from "../../services";

export const useModifyAlbumFormat = () => {
  const { put } = useBackend();
  const modifyAlbumFormat = (parameters: IModifyAlbumFormatParameters) =>
    put("albumFormats", parameters);
  return { modifyAlbumFormat };
};

export interface IModifyAlbumFormatParameters {
  uuid: string;
  name: string;
  shortName: string;
}
