import React, { FunctionComponent, useState } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  Divider,
  SelectChangeEvent,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import {
  CloseRounded,
  EmailRounded,
  InfoOutlined,
  LaptopMacRounded,
  LightbulbRounded,
  MemoryRounded,
  PersonOffRounded,
  TranslateRounded
} from "@mui/icons-material";
import { RightDetailListItem } from "../RightDetailListItem";
import { AlertDialog } from "../AlertDialog";
import { ImageCache } from "../../models/ImageCache";
import { LanguageManager } from "../../models/LanguageManager";
import { SwitchListItem } from "../SwitchListItem";
import { ThemeManager, ThemeMode } from "../../models/ThemeManager";
import { SelectListItem } from "../SelectListItem";
import { AppCredits } from "../AppCredits";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { DiscreetLink } from "../DiscreetLink";
import { useSessionManager, useUser } from "../../hooks/session";
import { useDeleteUser } from "../../hooks/users/useDeleteUser";
import { ModifyGenericAttributeDialog } from "../attributes/ModifyGenericAttributeDialog";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { StatusCodes } from "http-status-codes";
import { LoginAgainDialog } from "../LoginAgainDialog";
import { useNavigate } from "react-router-dom";

export const PreferencesDialog: FunctionComponent<IPreferencesDialogProps> = ({
  isOpen,
  setIsOpen,
  canRemoveAccount
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { closeSession } = useSessionManager();
  const [currentUser] = useUser.useState();
  const { deleteUser } = useDeleteUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isCacheAlertOpen, setIsCacheAlertOpen] = useState(false);
  const [isCacheLoading, setIsCacheLoading] = useState(false);
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleClose = () => {
    if (isLoading) return;
    setIsOpen(false);
  };
  const handleThemeChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as ThemeMode;
    ThemeManager.setTheme(newValue);
  };
  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, isEnglish: boolean) => {
    if (isEnglish !== null) {
      LanguageManager.setLanguage(isEnglish ? "en-US" : "es-ES");
      window.location.reload();
    }
  };
  const deleteAccount = async () => {
    setIsLoading(true);
    const { status, body } = await deleteUser();
    if (StatusCodesHelper.isSuccessful(status)) {
      closeSession();
      navigate(RoutesBuilder.signIn());
    } else if (status == StatusCodes.FORBIDDEN) {
      setIsSignInAgainDialogOpen(true);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} TransitionComponent={Grow}>
      <Toolbar>
        <h3>{strings.settings_preferences_title}</h3>
        <Box flexGrow={1} />
        <ToolbarButton
          loading={isLoading}
          onClick={handleClose}
          tooltip={strings.close}
          icon={CloseRounded}
        />
      </Toolbar>
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
        <Card className={styles.section}>
          <SelectListItem
            key={"appearance"}
            icon={LightbulbRounded}
            title={strings.theme_option}
            labels={ThemeManager.listOfThemeNames()}
            values={ThemeManager.listOfThemeModes()}
            value={ThemeManager.currentThemeMode()}
            handleChange={handleThemeChange}
            disabled={isLoading}
          />
          <Divider variant={"inset"} />
          <SwitchListItem
            key={"language"}
            className={styles.switchListItem}
            icon={TranslateRounded}
            title={strings.language_title}
            labels={["English", "Español"]}
            values={[true, false]}
            value={strings.locale == "en-US"}
            fullWidth={false}
            handleChange={handleLanguageChange}
            disabled={isLoading}
          />
        </Card>
        {currentUser && canRemoveAccount && (
          <Card className={styles.section}>
            <RightDetailListItem
              key={"remove_account"}
              icon={PersonOffRounded}
              title={strings.remove_account_title}
              titleColor={theme.palette.colors?.remove}
              onClick={() => setIsDeleteAccountDialogOpen(true)}
              disabled={isLoading}
            />
          </Card>
        )}
        <Card className={styles.section}>
          <a href={`mailto: ${strings.support_mail}`} style={{ textDecoration: "none" }}>
            <RightDetailListItem
              key={"mail_support"}
              icon={EmailRounded}
              title={strings.contact_support_title}
              leftSubtitle={strings.support_mail}
              disabled={isLoading}
            />
          </a>
        </Card>
        <Card className={styles.section}>
          <RightDetailListItem
            key={"clear_cache"}
            icon={MemoryRounded}
            title={strings.clear_cache_title}
            detail={""}
            isLoading={isCacheLoading}
            onClick={() => {
              setIsCacheAlertOpen(true);
            }}
            disabled={isLoading}
          />
          <Divider variant={"inset"} />
          <DiscreetLink
            to={`${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.about()}`}
            target={"_blank"}
          >
            <RightDetailListItem
              key={"about"}
              icon={InfoOutlined}
              title={strings.about_title}
              disabled={isLoading}
            />
          </DiscreetLink>
          <Divider variant={"inset"} />
          <RightDetailListItem
            key={"app_version"}
            icon={LaptopMacRounded}
            title={strings.app_version_title}
            detail={process.env.REACT_APP_VERSION}
            disabled={isLoading}
          />
        </Card>
        <AppCredits className={styles.credits} />
      </DialogContent>
      <AlertDialog
        message={strings.settings_cache_confirmation}
        open={isCacheAlertOpen}
        setOpen={setIsCacheAlertOpen}
        isConfirm
        onConfirm={async () => {
          setIsCacheLoading(true);
          await ImageCache.shared.clear();
          setIsCacheLoading(false);
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.remove_account_title}
        description={strings.remove_account_confirmation_message}
        placeholder={strings.remove_account_title}
        missingText={strings.field_missing_short}
        canClear={false}
        open={isDeleteAccountDialogOpen}
        setOpen={setIsDeleteAccountDialogOpen}
        isNumber={false}
        onChange={deleteAccount}
        matchText={strings.remove_account_title}
      />
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          await deleteAccount();
        }}
      />
      <AlertDialog
        title={strings.app_name}
        message={alertMessage}
        open={isAlertOpen}
        setOpen={setIsAlertOpen}
      />
    </Dialog>
  );
};

export interface IPreferencesDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  canRemoveAccount: boolean;
}
