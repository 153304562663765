import React, { FunctionComponent } from "react";
import { Grid, Skeleton } from "@mui/material";
import styles from "./styles.module.scss";
import { IUserProfile } from "../../../hooks/userProfiles";
import { useIsMobile } from "../../../hooks/isMobile";
import { PublicUserProfileCard } from "../PublicUserProfileCard";

export const PublicUserProfilesGrid: FunctionComponent<IPublicUserProfilesGridProps> = ({
  userProfiles,
  onUserProfileSelected,
  skeletonCount
}) => {
  const isMobile = useIsMobile();
  return (
    <Grid container spacing={2}>
      {userProfiles && userProfiles.length > 0
        ? userProfiles?.map((userProfile, index) => (
            <Grid key={index} item xs={isMobile ? 12 : 12 / 5}>
              <PublicUserProfileCard
                userProfile={userProfile}
                onClick={() => onUserProfileSelected(userProfile)}
              />
            </Grid>
          ))
        : Array.from({ length: skeletonCount }, (value, index) => (
            <Grid key={index} item xs={isMobile ? 12 : 12 / 5} className={styles.item}>
              <Skeleton variant={"rectangular"} className={styles.skeleton} />
            </Grid>
          ))}
    </Grid>
  );
};

export interface IPublicUserProfilesGridProps {
  userProfiles: IUserProfile[] | undefined;
  onUserProfileSelected: (userProfile: IUserProfile) => void;
  skeletonCount: number;
}
