import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationImportFromDiscogs: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_import_from_discogs.title}</h1>
      <img className={styles.image} src={strings.doc_import_from_discogs.import_image} />
      <p>{strings.doc_import_from_discogs.description}</p>
    </div>
  );
};
