import React, { FunctionComponent, useEffect } from "react";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import { ContainedButton } from "../../components/ContainedButton";
import { Box } from "@mui/material";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { AppCredits } from "../../components/AppCredits";
import piperTextImage from "../../images/piper-text.png";

export const NotFoundPage: FunctionComponent = () => {
  useEffect(() => {
    document.title = strings.app_name;
  }, []);
  const navigate = useNavigate();
  return (
    <div className={styles.mainDiv}>
      <div className={styles.mainContent}>
        <img className={styles.logoImage} src={piperTextImage} alt={"Piper"} />
        <h2 className={styles.title}>{strings.greetings}</h2>
        <h4 className={styles.subtitle}>{strings.not_found_subtitle}</h4>
        <p className={styles.text}>{strings.not_found_message}</p>
        <ContainedButton
          className={styles.backButton}
          onClick={() => navigate(RoutesBuilder.home())}
        >
          {strings.back_to_app}
        </ContainedButton>
        <Box flexGrow={1} />
        <p className={styles.footer}>{strings.not_found_queries}</p>
        <a className={styles.footer} href={`mailto: ${strings.support_mail}`}>
          {strings.support_mail}
        </a>
      </div>
      <AppCredits />
    </div>
  );
};
