import { UserRole } from "../hooks/users";
import { strings } from "../localization/LocalizedStrings";

export const UserUtilities = {
  roleName: (role?: UserRole) => {
    switch (role) {
      case UserRole.admin:
        return strings.role_admin;
      case UserRole.common:
        return strings.role_common;
      case UserRole.collector:
        return strings.role_collector;
      case UserRole.premium:
        return strings.role_premium;
      case UserRole.guest:
        return strings.role_guest;
      default:
        return strings.unknown;
    }
  }
};
