import React, { FunctionComponent, useEffect } from "react";
import { Badge, Card, CardActionArea, Skeleton, Stack } from "@mui/material";
import { IUserProfile, useGetUserProfile } from "../../../hooks/userProfiles";
import { UserAvatar } from "../../UserAvatar";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMobile } from "../../../hooks/isMobile";
import { useUser } from "../../../hooks/session";
import { useGetUser } from "../../../hooks/users/useGetUser";
import { UserUtilities } from "../../../models/UserUtilities";

export const UserProfileCard: FunctionComponent<IUserProfileCardProps> = ({
  isPublic,
  userProfile,
  onClick
}) => {
  const [currentUser] = useUser.useState();
  const { getUser } = useGetUser();
  const { getUserProfile } = useGetUserProfile();
  const isMobile = useIsMobile();
  const username = () => {
    if (isPublic) {
      return userProfile?.username;
    }
    if (currentUser?.role == "guest") {
      return strings.guest_title;
    }
    return userProfile?.username;
  };
  useEffect(() => {
    if (!currentUser) {
      getUser().then();
    }
    getUserProfile().then();
  }, []);
  return (
    <Card sx={{ flexShrink: 0, height: isMobile ? 42 : undefined, overflow: "visible" }}>
      <CardActionArea
        sx={{ paddingLeft: "2px", paddingRight: isMobile ? "2px" : 1 }}
        onClick={onClick}
      >
        <Stack direction={"row"} spacing={1} className={styles.stack}>
          <Badge
            overlap={"circular"}
            badgeContent={userProfile?.unreadNotifications}
            color={"error"}
          >
            <UserAvatar
              className={styles.avatar}
              style={{ width: isMobile ? 30 : 38, height: isMobile ? 30 : 38 }}
              userProfile={userProfile}
            />
          </Badge>
          {!isMobile && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              {isPublic && currentUser && (
                <p className={styles.publicUsernamePrefix}>{strings.public_username_prefix}</p>
              )}
              {userProfile ? (
                <>
                  <p className={styles.username}>{username()}</p>
                  {!isPublic && currentUser && (
                    <p className={styles.role}>{UserUtilities.roleName(currentUser.role)}</p>
                  )}
                </>
              ) : (
                <Skeleton variant={"text"} className={styles.skeletonText} />
              )}
            </div>
          )}
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface IUserProfileCardProps {
  isPublic: boolean;
  userProfile?: IUserProfile;
  onClick: () => void;
}
