import { useBackend } from "../../services";

export const useMarkReadUserNotification = () => {
  const { patch } = useBackend();
  const markReadUserNotification = (parameters: IMarkReadUserNotificationParameters) =>
    patch("userNotifications", parameters);
  return { markReadUserNotification };
};

export interface IMarkReadUserNotificationParameters {
  uuid: string;
  isRead: boolean;
}
