import React, { FunctionComponent } from "react";
import { CardActionArea, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const GenericFilterComponent: FunctionComponent<IGenericFilterComponentProps> = ({
  value,
  onClick
}) => {
  const theme = useTheme();
  return (
    <CardActionArea
      className={styles.filterComponentTitle}
      sx={{
        borderWidth: 1,
        borderColor: theme.palette.colors?.border,
        borderStyle: "solid"
      }}
      onClick={onClick}
    >
      <p
        className={styles.genericFilterTitle}
        style={{ color: value ? theme.palette.primary.main : theme.palette.colors?.disabled }}
      >
        {value ?? strings.no_value}
      </p>
    </CardActionArea>
  );
};

export interface IGenericFilterComponentProps {
  value?: string;
  onClick: (event: React.MouseEvent) => void;
}
