import deleteImage from "../../../../images/documentation/collection-remove-es.png";
import image from "../../../../images/documentation/collection-button-es.png";

export const doc_collections_es = {
  title: "Colecciones",
  home_title: "¿Qué son las colecciones?",
  image: image,
  description: `Podés dividir tu colección princial en diferentes colecciones. Cada colección puede mostrar un aspecto distinto de tus álbumes, vas a tener estadísticas por separado para cada una y vas a poder filtrarlas individualmente.

  Un álbum puede ser parte de ninguna, una o múltiples colecciones, pero la instancia del álbum es única. Esto significa que asignar un álbum a múltiples colecciones no duplica el álbum.

  La colección de 'Todos los álbumes' siempre va a contener a todos los álbumes que tengas, así que si eliminás o cambiás colecciones nunca vas a perder la referencia a un álbum.  

  Creá colecciones por ubicación física, por género, gusto personal, lo que quieras. Pensá en las colecciones como ventanas a tu colección princial, donde cada ventana muestra una vista distinta.  
  
`,
  create_title: "Agregar una colección",
  create_description: `Para crear una nueva colección seleccioná el botón de colección en la barra de herramientas en la página de 'Mis álbumes'.

  Se va a abrir una nueva ventana donde vas a poder ver la lista de colecciones que tenés, y en la barra de herramientas vas a encontrar el botón para agregar una nueva.  
  
  Cuando estás creando una nueva colección podés modificar su imagen, nombre y descripción.  

  Una vez que confirmás, un texto rojo va a aparecer debajo de cualquier campo mandatorio que no esté completo. Una vez que todos los campos mandatorios estén completos, la colección va a ser agregada.  

  Cuando estás actualizando una colección existente, van a estar disponibles unos atributos adicionales. Vas a poder establecer a la colección como 'pública' y así dejarla disponible en la Exhibición. Si hacés eso, asegurate de verificar el atributo de campos ocultos, y seleccioná todos los atributos que quieras ocultar del resto de los usuarios.  
  
`,
  delete_image: deleteImage,
  delete_title: "Eliminar una colección",
  delete_description: `Para eliminar una colección, hacele click derecho desde la lista de colecciones y seleccioná la opción para eliminarla. Va a aparecer un diálogo de confirmación donde podés verificar que el nombre corresponda con la colección que querés eliminar.

  Este proceso es irreversible, así que asegurate de estar eliminando la colección correcta. Todos los álbumes asignados a esa colección van a ser desasignados automáticamente. Si alguien tenía acceso a un link compartido de la colección eliminada, ya no va a poder acceder.
  
`
};
