import { useBackend } from "../../services";
import { ICollection } from "../collections";

export const useImportFromSpreadsheet = () => {
  const { upload } = useBackend();
  const importFromSpreadsheet = async (file: File, collections: ICollection[]) => {
    const collectionsJson = JSON.stringify(collections);
    return upload("xlsx", "spreadsheet.xlsx", file, "collections", collectionsJson);
  };
  return { importFromSpreadsheet };
};
