const zeroPad = (number: number, places: number) => String(number).padStart(places, "0");

export const TimeInterval = {
  getSeconds: (formattedTime: string) => {
    const split = formattedTime.split(":");
    let seconds = 0;
    let multiplier = 1;
    while (split.length > 0) {
      seconds += multiplier * parseInt(split.pop() ?? "", 10);
      multiplier *= 60;
    }
    return seconds;
  },
  toHHMMSS: (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const aux = seconds % 3600;
    const minutes = Math.floor(aux / 60);
    const newSeconds = seconds % 60;
    return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(newSeconds, 2)}`;
  },
  toMMSS: (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const newSeconds = seconds % 60;
    return `${minutes}:${zeroPad(newSeconds, 2)}`;
  },
  getCanonicalTimestamp: (unixTimestamp?: number) => {
    if (!unixTimestamp) {
      return undefined;
    }
    const date = new Date(unixTimestamp * 1000);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }
};
