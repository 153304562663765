import barImage from "../../../../images/documentation/filter-bar-image.png";
import orderImage from "../../../../images/documentation/filter-order-en.png";
import elementsImage from "../../../../images/documentation/filter-elements-en.png";

export const doc_filter_albums_en = {
  title: "Albums filter",
  home_title: "Filter your albums",
  bar_image: barImage,
  order_image: orderImage,
  elements_image: elementsImage,
  description: `Piper offers a powerful filter to view and organize your collection from different perspectives, so you can analyze the data from different angles. To open the filter window just click on the filter button on the toolbar.
  
`,
  order_description: `The first field allow you to select in which order your album list will be displayed. The default option is by artist, and the albums inside each artist will be displayed sorted by your own defined order (See the 'Order your albums' documentation.) The other order options include by label, purchase price, revenue, length, etc. In these cases, the albums will be grouped by the option selected and will be sorted by year and name inside each section.
  
  The second field will let you decide if the option selected will be applied in ascending or descending order.
  
`,
  elements_description: `The next section displays two tables. The one on the left displays all the possible filter options that can be applied. These allow you to select a specific label, year, selling value, that you want to restrict you album list to. In order to effectively apply any of these filter elements, select the checkbox of all the elements you want to apply and click on the right arrow. On the same note, select any elements you want to remove from the filter from the table on the right and click on the left arrow. You can also enter the desired value on any of the filter elements and they will be applied automatically.
  
  Each filter element will be required in the search criteria so, for example, if you have selected artist 'X' and label 'Y' the albums displayed will be the ones having artist 'X' AND having label 'X'.
  
  This is not true if you select multiple elements of the same kind though, in that case the search criteria will include all the albums that match any of those elements. So, for example, if you select artist 'X' and artist 'Y' the albums displayed will be the ones having artist 'X' OR having artist 'Y'.
  
`
};
