import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyTagDialog } from "../ModifyTagDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";
import { ITag, useGetTags } from "../../../../hooks/tags";
import { TagCard } from "../TagCard";

export const TagsDesktop: FunctionComponent<ITagsDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedIds,
  onTagSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const [tags, resetTags, setTags, noMoreTags] = useGetTags(isPublic, setLoading);
  const [filteredTags, setFilteredTags] = useState<ITag[]>();
  const [isAddTagDialogOpen, setIsAddTagDialogOpen] = useState(false);
  const [isModifyTagDialogOpen, setIsModifyTagDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredTags();
  }, [tags]);
  const refreshFilteredTags = () => {
    if (searchText == "") {
      setFilteredTags(tags);
      return;
    }
    setFilteredTags(
      tags?.filter(tag => {
        return tag.name.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredTags();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredTags}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.TAGS)}
      emptyString={isPublic ? strings.empty_tags_public : strings.empty_tags}
      addItemString={strings.add_attribute}
      title={strings.tags_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddTagDialogOpen(true)}
      refreshAction={resetTags}
      onClearValue={onClearValue}
    >
      {filteredTags?.map(tag => (
        <Grid item key={tag.uuid} xs={columnWidth}>
          <TagCard
            tag={tag}
            onClick={() => {
              if (onTagSelected) {
                onTagSelected(tag);
              } else {
                setSelectedTag(tag);
                setIsModifyTagDialogOpen(true);
              }
            }}
            onContextMenu={event => {
              setSelectedTag(tag);
              handleContextMenu(event);
            }}
            selected={preselectedIds?.includes(tag.uuid)}
          />
        </Grid>
      ))}
      {(loading || !noMoreTags) &&
        Array.from({ length: 20 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyTagDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifyTagDialog
        open={isAddTagDialogOpen}
        setOpen={setIsAddTagDialogOpen}
        onNewItem={newTag => {
          const newTags = tags;
          newTags.push(newTag);
          newTags.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setTags(newTags);
          refreshFilteredTags();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyTagDialog
        open={isModifyTagDialogOpen}
        setOpen={setIsModifyTagDialogOpen}
        tag={selectedTag}
        onModifiedItem={newTag => {
          const newTags = tags.map(tag => (tag.uuid == newTag.uuid ? newTag : tag));
          newTags.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setTags(newTags);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedTag => {
          const newTags = tags.filter(tag => tag.uuid != deletedTag.uuid);
          setTags(newTags);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface ITagsDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: number;
  preselectedIds?: string[];
  onTagSelected?: (tag: ITag) => void;
  onClearValue?: () => void;
}
