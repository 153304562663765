import { useBackend } from "../../services";

export const useGetShareLink = () => {
  const { get } = useBackend();
  const getShareLink = (parameters: IGetShareLinkParameters) => get("share/shareLink", parameters);
  return { getShareLink };
};

export interface IGetShareLinkParameters {
  albumId?: string;
  collectionId: string;
}
