import React, { FunctionComponent, useEffect } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../../TextField";
import { CloseRounded, CleaningServicesRounded } from "@mui/icons-material";
import { ContainedButton } from "../../ContainedButton";
import { strings } from "../../../localization/LocalizedStrings";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";

export const ModifyGenericAttributeDialog: FunctionComponent<
  IModifyGenericAttributeDialogProps
> = ({
  title,
  description,
  placeholder,
  missingText,
  canClear,
  open,
  setOpen,
  isNumber,
  value,
  onChange,
  onClearValue,
  matchText
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const onCancel = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    value: isNumber
      ? Yup.number().typeError(strings.not_number_error).required(missingText).nullable()
      : Yup.string().required(missingText).nullable()
  });
  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);
  const formik = useFormik({
    initialValues: {
      value: value ?? ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ value }) => {
      if (matchText && value != matchText) return;
      if (formik.dirty && value) {
        onChange(value);
        setOpen(false);
      }
    },
    validationSchema: validationSchema
  });
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onCancel} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{title}</h3>
          <Box flexGrow={1} />
          {canClear && (
            <ToolbarButton
              onClick={() => {
                if (onClearValue) {
                  onClearValue();
                }
                setOpen(false);
              }}
              tooltip={strings.clear_value}
              icon={CleaningServicesRounded}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton onClick={onCancel} tooltip={strings.close} icon={CloseRounded} />
        </Toolbar>
        <form>
          <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, overflow: "hidden" }}>
            <p className={styles.description}>{description}</p>
            <div className={styles.textField}>
              <TextField
                id="value"
                name="value"
                size="small"
                autoFocus={!isMobile}
                placeholder={placeholder}
                value={formik.values.value}
                onChange={formik.handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ContainedButton
              type={"submit"}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
            >
              {strings.alert_confirm}
            </ContainedButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormikProvider>
  );
};

export interface IModifyGenericAttributeDialogProps {
  title: string;
  description: string;
  placeholder: string;
  missingText: string;
  canClear: boolean;
  open: boolean;
  setOpen: (state: boolean) => void;
  isNumber: boolean;
  value?: string;
  onChange: (newValue: string) => void;
  onClearValue?: () => void;
  matchText?: string;
}
