import React, { FunctionComponent, useState } from "react";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { strings } from "../../localization/LocalizedStrings";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "@mui/lab";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./styles.module.scss";
import { omit } from "lodash";

export const DatePickerField: FunctionComponent<IDatePickerFieldProps> = ({
  className,
  value,
  onChange
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  return (
    <LocalizationProvider
      localeText={
        strings.datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
      }
      dateAdapter={DateAdapter}
      adapterLocale={strings.fnsLocale}
    >
      <MobileDatePicker
        slotProps={{
          actionBar: {
            actions: ["clear", "accept"]
          }
        }}
        format={"d MMMM yyyy"}
        value={value}
        onChange={onChange}
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        slots={{
          textField: params => (
            <TextField
              {...omit(params, "onChange", "onError")}
              className={className}
              placeholder={strings.date_missing}
              variant={"filled"}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "4px",
                transition: "0.3s all",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)"
                },
                height: "40px"
              }}
              onClick={() => setIsDatePickerOpen(true)}
              inputProps={{
                ...params.inputProps,
                className: styles.input,
                style: { paddingLeft: 14, paddingTop: 9 }
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  );
};

export interface IDatePickerFieldProps {
  className?: string;
  title: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
}
