import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import { strings } from "../../../localization/LocalizedStrings";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { TextField } from "../../TextField";
import { AlertDialog } from "../../AlertDialog";
import { toast } from "react-toastify";
import { FieldTitle } from "../../Text/FieldTitle";
import { ContainedButton } from "../../ContainedButton";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { FreeTextListItem } from "../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../attributes/ModifyFreeTextAttributeDialog";
import { IShelf } from "../../../hooks/shelves";
import { useAddShelfSegment } from "../../../hooks/shelfSegments/useAddShelfSegment";
import { useModifyShelfSegment } from "../../../hooks/shelfSegments/useModifyShelfSegment";
import { IShelfSegment } from "../../../hooks/shelfSegments";
import { getAnalytics, logEvent } from "firebase/analytics";

export const ModifyShelfSegmentDialog: FunctionComponent<IModifyShelfSegmentDialogProps> = ({
  open,
  setOpen,
  shelfSegment,
  shelf,
  callback,
  onDelete
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.shelf_segment_new_title);
  const { addShelfSegment } = useAddShelfSegment();
  const { modifyShelfSegment } = useModifyShelfSegment();
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.field_missing)
  });
  const formik = useFormik({
    initialValues: {
      name: shelfSegment?.name ?? "",
      description: shelfSegment?.description
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ name, description }) => {
      setIsLoading(true);
      let statusCode, responseBody;
      if (shelfSegment) {
        const { status, body } = await modifyShelfSegment({
          uuid: shelfSegment.uuid,
          name: name,
          description: description
        });
        statusCode = status;
        responseBody = body;
      } else {
        const { status, body } = await addShelfSegment({
          name: name,
          description: description,
          shelfId: shelf.uuid
        });
        statusCode = status;
        responseBody = body;
      }
      if (!StatusCodesHelper.isSuccessful(statusCode)) {
        logEvent(analytics, "shelfsegment_action");
        setAlertMessage(responseBody.message);
        setAlertIsOpen(true);
      } else {
        if (callback != undefined) {
          callback(responseBody);
        }
        handleClose();
        toast.success(strings.generic_update_success);
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  useEffect(() => {
    if (!open) return;
    setDialogTitle(shelfSegment ? strings.shelf_segment_title : strings.shelf_segment_new_title);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {shelfSegment && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.name_title.toUpperCase()}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="name"
              name="name"
              size="small"
              autoFocus={!isMobile}
              placeholder={strings.shelf_segment_name_placeholder}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <FieldTitle>{strings.collection_description.toUpperCase()}</FieldTitle>
          <Card className={styles.descriptionCard}>
            <FreeTextListItem
              hideTitle
              detail={formik.values.description ?? "--"}
              maxLines={4}
              onClick={() => setIsModifyDescriptionDialogOpen(true)}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <ModifyFreeTextAttributeDialog
        title={strings.collection_description}
        description={strings.edit_description_description}
        placeholder={strings.collection_description}
        value={formik.values.description}
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        onChange={newValue => formik.setFieldValue("description", newValue)}
        onClearValue={() => formik.setFieldValue("description", null)}
      />
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyShelfSegmentDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  shelfSegment?: IShelfSegment;
  shelf: IShelf;
  callback?: (newSegment: IShelfSegment) => void;
  onDelete?: () => void;
}
