import React, { useState, useMemo, useCallback } from "react";
import { MenuProps } from "@mui/material";

function isTouchEvent(e: React.TouchEvent | React.MouseEvent): e is React.TouchEvent {
  return e && "touches" in e;
}

function isMouseEvent(e: React.TouchEvent | React.MouseEvent): e is React.MouseEvent {
  return e && "screenX" in e;
}

export const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const handleContextMenu = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    let x = 0;
    let y = 0;
    if (isMouseEvent(event)) {
      x = event.nativeEvent.clientX;
      y = event.nativeEvent.clientY;
    }
    if (isTouchEvent(event)) {
      x = event.nativeEvent.touches[0].clientX;
      y = event.nativeEvent.touches[0].clientY;
    }
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: x - 2,
            mouseY: y - 4
          }
        : null
    );
  }, []);
  const handleMenuClose = useCallback(() => {
    setContextMenu(null);
  }, []);
  const menuProps = useMemo((): MenuProps => {
    return {
      open: contextMenu !== null ?? false,
      onClose: handleMenuClose,
      anchorReference: "anchorPosition",
      anchorPosition:
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
    };
  }, [contextMenu]);
  return { menuProps, handleContextMenu, handleMenuClose };
};
