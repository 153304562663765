import React, { FunctionComponent } from "react";
import { Grid, Skeleton } from "@mui/material";
import { IPublicCollection } from "../../../hooks/collections";
import { PublicCollectionCard } from "../PublicCollectionCard";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";

export const PublicCollectionsGrid: FunctionComponent<IPublicCollectionsGridProps> = ({
  isLoading,
  publicCollections,
  onCollectionSelected,
  columnWidth,
  skeletonCount,
  showUser
}) => {
  return (
    <Grid container spacing={2}>
      {publicCollections && publicCollections.length > 0 ? (
        publicCollections?.map((publicCollection, index) => (
          <Grid key={index} item xs={columnWidth}>
            <PublicCollectionCard
              publicCollection={publicCollection}
              onClick={() => onCollectionSelected(publicCollection)}
              showUser={showUser}
            />
          </Grid>
        ))
      ) : isLoading ? (
        Array.from({ length: skeletonCount }, (value, index) => (
          <Grid key={index} item xs={columnWidth} className={styles.item}>
            <Skeleton variant={"rectangular"} className={styles.skeleton} />
          </Grid>
        ))
      ) : (
        <p className={styles.noItemsText}>{strings.no_public_collections}</p>
      )}
    </Grid>
  );
};

export interface IPublicCollectionsGridProps {
  isLoading: boolean;
  publicCollections: IPublicCollection[] | undefined;
  onCollectionSelected: (publicCollection: IPublicCollection) => void;
  columnWidth: number;
  skeletonCount: number;
  showUser: boolean;
}
