import React, { FunctionComponent } from "react";
import { DocumentationPage, IDocumentationPageProps } from "./component";

export const DocumentationPageContainer: FunctionComponent<IDocumentationPageProps> = ({
  defaultExpanded,
  defaultSelected,
  children
}) => {
  return (
    <DocumentationPage defaultExpanded={defaultExpanded} defaultSelected={defaultSelected}>
      {children}
    </DocumentationPage>
  );
};
