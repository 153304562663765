import styles from "./styles.module.scss";
import { Button, ButtonGroup, Stack, useTheme } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";

export const Counter: FunctionComponent<ICounterProps> = ({
  className,
  initialValue,
  minimumValue,
  maximumValue,
  nullable,
  disabled,
  onValueChanged
}) => {
  const theme = useTheme();
  const [incrementalNumber, setIncrementalNumber] = useState(initialValue);
  return (
    <Stack
      className={className}
      direction={"row"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <p className={styles.number}>{incrementalNumber >= minimumValue ? incrementalNumber : "-"}</p>
      <ButtonGroup
        variant="contained"
        disabled={disabled}
        size={"medium"}
        sx={{ height: "36px" }}
        color={"secondary"}
      >
        <Button
          className={styles.incrementalButton}
          sx={{
            color: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.colors?.counterBackground} !important`
          }}
          onClick={() => {
            if (incrementalNumber > minimumValue) {
              onValueChanged(incrementalNumber - 1);
              setIncrementalNumber(incrementalNumber - 1);
            } else if (nullable) {
              onValueChanged(null);
              setIncrementalNumber(minimumValue - 1);
            }
          }}
        >
          <ResponsiveIcon icon={RemoveRounded} />
        </Button>
        <Button
          className={styles.incrementalButton}
          sx={{
            color: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.colors?.counterBackground} !important`
          }}
          onClick={() => {
            if (incrementalNumber < (maximumValue ?? Number.MAX_SAFE_INTEGER)) {
              onValueChanged(incrementalNumber + 1);
              setIncrementalNumber(incrementalNumber + 1);
            }
          }}
        >
          <ResponsiveIcon icon={AddRounded} />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export interface ICounterProps {
  className?: string;
  initialValue: number;
  minimumValue: number;
  maximumValue?: number;
  nullable: boolean;
  disabled?: boolean;
  onValueChanged: (newValue: number | null) => void;
}
