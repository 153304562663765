import { useTheme } from "@mui/material";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export const DiscreetLink: FunctionComponent<any> = props => {
  const theme = useTheme();
  return (
    <Link
      {...props}
      style={{
        textDecoration: "none",
        color: theme.palette.primary.main,
        pointerEvents: props.disabled ? "none" : "auto"
      }}
    />
  );
};
