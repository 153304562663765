import React, { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../../pages/Documentation/styles.module.scss";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMobile } from "../../../hooks/isMobile";

export const DocumentationBreadcrumb: FunctionComponent = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter(x => x);
  const breadcrumbNameMap: { [key: string]: string } = {
    "/doc": strings.documentation,
    "/doc/about": strings.about_piper.title,
    "/doc/users": strings.doc_users_sign_up.title_users,
    "/doc/users/signUp": strings.doc_users_sign_up.home_title,
    "/doc/users/guest": strings.doc_users_guest.title,
    "/doc/users/recoverPassword": strings.doc_users_recover_password.title,
    "/doc/album": strings.doc_albums.title,
    "/doc/album/filter": strings.doc_filter_albums.title,
    "/doc/album/order": strings.doc_order_albums.title,
    "/doc/album/scanBarcode": strings.doc_albums_add_barcode.title,
    "/doc/album/importFromDiscogs": strings.doc_import_from_discogs.title,
    "/doc/album/importFromSpreadsheet": strings.doc_import_from_excel.title,
    "/doc/album/imageGallery": strings.doc_image_gallery.title,
    "/doc/album/removeFiltered": strings.doc_remove_filtered_albums.title,
    "/doc/album/marks": strings.doc_marking_albums.title,
    "/doc/albumAttributes": strings.doc_album_attributes.title,
    "/doc/albumAttributes/artists": strings.doc_artists.title,
    "/doc/collections": strings.doc_collections.title,
    "/doc/collections/attributes": strings.doc_collections_attributes.home_title,
    "/doc/collections/public": strings.doc_collections_public_collections.title,
    "/doc/userProfile": strings.doc_user_profile.title,
    "/doc/subscriptions": strings.doc_subscriptions.title,
    "/doc/subscriptions/mercadoPago": strings.doc_mercado_pago_subscriptions.title,
    "/doc/policy": strings.privacy_policy.title,
    "/doc/terms": strings.terms.title
  };
  return (
    <Breadcrumbs
      className={styles.breadcrumbs}
      separator={<NavigateNextRoundedIcon style={{ color: "white" }} fontSize="small" />}
      maxItems={isMobile ? 1 : 4}
    >
      {pathNames.map((value, index) => {
        const last = index === pathNames.length - 1;
        const to = `/${pathNames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography style={{ color: "white" }} key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <Link style={{ color: theme.palette.colors?.breadcrumb }} to={to} key={to}>
            {breadcrumbNameMap[to]}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
