import { useBackend } from "../../services";

export const useModifyStore = () => {
  const { put } = useBackend();
  const modifyStore = (parameters: IModifyStoreParameters) => put("stores", parameters);
  return { modifyStore };
};

export interface IModifyStoreParameters {
  uuid: string;
  name: string;
  address?: string;
  website?: string;
  profile?: string;
}
