import { useBackend } from "../../services";

export const useAddTag = () => {
  const { post } = useBackend();
  const addTag = (parameters: IAddTagParameters) => post("tags", parameters);
  return { addTag };
};

export interface IAddTagParameters {
  name: string;
  description?: string;
}
