import React, { FunctionComponent } from "react";
import { ListItemButton, Stack } from "@mui/material";
import styles from "./styles.module.scss";
import { useTheme } from "@mui/material";
import { IShelfSegmentAlbum } from "../../../../hooks/shelfSegments";
import { strings } from "../../../../localization/LocalizedStrings";

export const AlbumLocationItem: FunctionComponent<IAlbumLocationItemProps> = ({
  shelfSegmentAlbum,
  onClick
}) => {
  const theme = useTheme();
  return (
    <div className={styles.container}>
      <ListItemButton disableGutters onClick={onClick}>
        <Stack direction={"row"} flexGrow={1} spacing={1} alignItems={"center"}>
          <p className={styles.shelving}>{shelfSegmentAlbum.shelfSegment.shelf.shelving.name}</p>
          <Stack className={styles.labelStack}>
            <p
              className={styles.topLabel}
              style={{
                color: theme.palette.secondary.main,
                fontStyle: "italic"
              }}
            >
              {`${strings.shelf_title} ${shelfSegmentAlbum.shelfSegment.shelf.order + 1}`}
            </p>
            <p className={styles.bottomLabel}>{shelfSegmentAlbum.shelfSegment.shelf.name}</p>
          </Stack>
          <Stack className={styles.labelStack}>
            <p
              className={styles.topLabel}
              style={{
                color: theme.palette.secondary.main,
                fontStyle: "italic"
              }}
            >
              {`${strings.shelf_segment_title} ${shelfSegmentAlbum.shelfSegment.order + 1}`}
            </p>
            <p className={styles.bottomLabel}>{shelfSegmentAlbum.shelfSegment.name}</p>
          </Stack>
          <Stack className={styles.labelStack}>
            <p
              className={styles.topLabel}
              style={{
                color: theme.palette.secondary.main,
                textAlign: "center"
              }}
            >
              {strings.shelf_segment_position_title}
            </p>
            <p className={styles.bottomLabel} style={{ textAlign: "center" }}>
              {shelfSegmentAlbum.order + 1}
            </p>
          </Stack>
        </Stack>
      </ListItemButton>
    </div>
  );
};

export interface IAlbumLocationItemProps {
  shelfSegmentAlbum: IShelfSegmentAlbum;
  onClick: () => void;
}
