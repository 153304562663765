import { useBackend } from "../../services";
import { ShelfSegmentAlbumShape } from "./interfaces";

export const useAddAlbumToShelfSegment = () => {
  const { post } = useBackend();
  const addAlbumToShelfSegment = (parameters: IAddAlbumToShelfSegmentParameters) =>
    post("shelfSegments/albums", parameters);
  return { addAlbumToShelfSegment };
};

export interface IAddAlbumToShelfSegmentParameters {
  uuid: string;
  albumId: string;
  shape: ShelfSegmentAlbumShape;
  order: number;
}
