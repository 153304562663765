import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { strings } from "../../localization/LocalizedStrings";

export const useCopyImage = () => {
  const { post } = useBackend();
  const copyImage = async (parameters: ICopyImageParameters) => {
    const { status, body } = await post("images/copy", parameters);
    if (StatusCodesHelper.isSuccessful(status)) {
      return body.thumbnail;
    }
    toast.error(body.message ?? strings.image_upload_error);
    return undefined;
  };
  return { copyImage };
};

export interface ICopyImageParameters {
  source: string;
  destination: string;
}
