import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { Card, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const DocumentationCollections: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_collections.title}</h1>
      <img className={styles.imageCreate} src={strings.doc_collections.image} />
      <p>{strings.doc_collections.description}</p>
      <section id={"create"} />
      <h2>{strings.doc_collections.create_title}</h2>
      <p>{strings.doc_collections.create_description}</p>
      <section id={"delete"} />
      <h2>{strings.doc_collections.delete_title}</h2>
      <img className={styles.imageFloatRight} src={strings.doc_collections.delete_image} />
      <p>{strings.doc_collections.delete_description}</p>
      <section id={"more"} />
      <h2>{strings.doc_more_options_title}</h2>
      <List className={styles.list}>
        <Card sx={{ margin: 2 }}>
          <ListItemButton
            key={"Attributes"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.attributes()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_collections_attributes.title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"PublicCollections"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.collections.publicCollections()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_collections_public_collections.title}
            />
          </ListItemButton>
        </Card>
      </List>
    </div>
  );
};
