import React, { FunctionComponent } from "react";
import { Field, FieldAttributes } from "formik";
import { TextField as TextInput } from "formik-mui";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { omit } from "lodash";
import MaskedInput from "react-text-mask";
import { useTheme } from "@mui/material";

export const TextField: FunctionComponent<ITextFieldProps> = ({
  enterAction,
  forceWhite,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Field
      className={classNames(
        styles.textField,
        props.className,
        props.mask ? styles.maskedStyle : undefined
      )}
      component={props.mask ? MaskedInput : TextInput}
      variant={"outlined"}
      size={"small"}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
          if (enterAction) {
            event.preventDefault();
            enterAction();
          }
        }
      }}
      InputProps={{
        className: styles.input,
        readOnly: props.readOnly,
        style: { color: forceWhite ? "white" : theme.palette.primary.main }
      }}
      {...omit(props, "className", "InputProps")}
    />
  );
};

export interface ITextFieldProps extends FieldAttributes<any> {
  className?: string;
  mask?: any;
  readOnly?: boolean;
  enterAction?: () => void;
  forceWhite?: boolean;
}
