import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { FreeTextField } from "../../FreeTextField";
import { ContainedButton } from "../../ContainedButton";
import { strings } from "../../../localization/LocalizedStrings";
import { CleaningServicesRounded, CloseRounded } from "@mui/icons-material";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { AlertDialog } from "../../AlertDialog";

export const ModifyFreeTextAttributeDialog: FunctionComponent<
  IModifyFreeTextAttributeDialogProps
> = ({
  title,
  description,
  placeholder,
  open,
  setOpen,
  value,
  onChange,
  onClearValue,
  onCancel
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const tryToClose = () => {
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    onCancelledByUser();
  };
  const onCancelledByUser = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };
  const formik = useFormik({
    initialValues: {
      value: value ?? ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ value }) => {
      onChange(value);
      setOpen(false);
    }
  });
  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{title}</h3>
          <Box flexGrow={1} />
          {onClearValue && (
            <ToolbarButton
              onClick={() => {
                if (onClearValue) {
                  onClearValue();
                }
                setOpen(false);
              }}
              tooltip={strings.clear_value}
              icon={CleaningServicesRounded}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton onClick={tryToClose} tooltip={strings.close} icon={CloseRounded} />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <p className={styles.description}>{description}</p>
          <div>
            <FreeTextField
              id="value"
              name="value"
              className={styles.textField}
              autoFocus={!isMobile}
              placeholder={placeholder}
              value={formik.values.value}
              onChange={formik.handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={onCancelledByUser}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
    </FormikProvider>
  );
};

export interface IModifyFreeTextAttributeDialogProps {
  title: string;
  description: string;
  placeholder: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  value?: string;
  onChange: (newValue: string) => void;
  onClearValue?: () => void;
  onCancel?: () => void;
}
