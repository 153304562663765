import React, { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const MainContent: FunctionComponent<IMainContentProps> = ({
  className,
  showingPersistentDrawer,
  children
}) => {
  return (
    <div
      className={classNames(
        showingPersistentDrawer ? styles.mainContentWide : styles.mainContent,
        className
      )}
    >
      {children}
    </div>
  );
};

export interface IMainContentProps {
  children: React.ReactNode;
  className?: string;
  showingPersistentDrawer: boolean;
}
