import React, { FunctionComponent, useState } from "react";
import styles from "./styles.module.scss";
import { IAlbum } from "../../../hooks/albums";
import {
  Box,
  CardActionArea,
  Dialog,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Grow,
  Stack,
  Tooltip
} from "@mui/material";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { AlertDialog } from "../../AlertDialog";
import { ArtistsDesktop } from "../../attributes/artists/ArtistsDesktop";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { ArtistDialog } from "../../attributes/artists/ArtistDialog";
import {
  AccountBoxRounded,
  DriveFileRenameOutlineRounded,
  QrCode2Rounded,
  ShareRounded
} from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { useIsMobile } from "../../../hooks/isMobile";
import { useContextMenu } from "../../../hooks/contextMenu";
import {
  useCollectionManager,
  useCurrentPrivateCollection
} from "../../../hooks/collections/useCollectionManager";
import { CollectionHelper } from "../../../models/CollectionHelper";
import { useShareLink } from "../../../hooks/share/useShareLink";

export const AlbumHeaderListSection: FunctionComponent<IAlbumHeaderListSectionProps> = ({
  isPublic,
  album,
  onNameUpdated,
  onArtistUpdated
}) => {
  const isMobile = useIsMobile();
  const { updateAlbum } = useUpdateAlbum();
  const [currentPrivateCollection] = useCurrentPrivateCollection.useState();
  const { getCurrentCollection, isCurrentCollectionShareable } = useCollectionManager();
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [isArtistDialogOpen, setIsArtistDialogOpen] = useState(false);
  const [isArtistsDesktopOpen, setIsArtistsDesktopOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shareLinkComponents, fetchShareLink, fetchPrivateLink, isShareLoading] = useShareLink(
    album.name
  );
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  return (
    <ListItem sx={{ padding: "0 !important" }}>
      <Stack spacing={0} flexGrow={1}>
        <Stack direction={"row"}>
          <CardActionArea
            disabled={isPublic}
            className={styles.albumName}
            onClick={() => {
              setIsNameDialogOpen(true);
            }}
          >
            {album.name}
          </CardActionArea>
          <Box className={styles.loadingDiv}>
            {isLoading || isShareLoading ? (
              <CustomLoadingIndicator className={styles.loadingIndicator} />
            ) : (
              <Stack sx={{ height: "100%" }} direction={"row"}>
                {isCurrentCollectionShareable(isPublic) &&
                  (isPublic ||
                    CollectionHelper.albumBelongsTo(currentPrivateCollection, album)) && (
                    <Tooltip title={strings.share_album} arrow disableInteractive>
                      <IconButton
                        className={styles.shareButton}
                        onClick={() => {
                          const collectionId = getCurrentCollection(isPublic)?.uuid;
                          if (!collectionId) return;
                          fetchShareLink(collectionId, album.uuid).then();
                        }}
                      >
                        <ResponsiveIcon className={styles.shareIcon} icon={ShareRounded} />
                      </IconButton>
                    </Tooltip>
                  )}
                {!isPublic && (
                  <Tooltip title={strings.get_private_link} arrow disableInteractive>
                    <IconButton
                      className={styles.shareButton}
                      onClick={() => {
                        fetchPrivateLink(album.uuid).then();
                      }}
                    >
                      <ResponsiveIcon icon={QrCode2Rounded} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
        <CardActionArea className={styles.artistName} onClick={handleContextMenu}>
          {album.artist.name}
        </CardActionArea>
      </Stack>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsArtistDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <AccountBoxRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.artist_view}
        </MenuItem>
        {!isPublic && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsArtistsDesktopOpen(true);
            }}
            dense
          >
            <ListItemIcon>
              <DriveFileRenameOutlineRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.change_artist}
          </MenuItem>
        )}
      </Menu>
      <ArtistDialog
        artist={album.artist}
        isOpen={isArtistDialogOpen}
        setIsOpen={setIsArtistDialogOpen}
        canEdit={!isPublic}
      />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isArtistsDesktopOpen}
        onClose={() => setIsArtistsDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ArtistsDesktop
            handleClose={() => {
              setIsArtistsDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={album.artist.uuid}
            onArtistSelected={async artist => {
              setIsArtistsDesktopOpen(false);
              setIsLoading(true);
              const { body, status } = await updateAlbum({
                uuid: album.uuid,
                artistId: artist.uuid
              });
              if (!StatusCodesHelper.isSuccessful(status)) {
                setAlertMessage(body.message);
                setIsAlertOpen(true);
              } else {
                toast.success(strings.album_update_success);
                album.artist = artist;
                onArtistUpdated();
              }
              setIsLoading(false);
            }}
          />
        </div>
      </Dialog>
      <ModifyGenericAttributeDialog
        title={strings.name_title}
        description={strings.name_detail}
        placeholder={strings.name_placeholder}
        missingText={strings.field_missing}
        value={album.name}
        canClear={false}
        open={isNameDialogOpen}
        setOpen={setIsNameDialogOpen}
        isNumber={false}
        onChange={async newValue => {
          setIsLoading(true);
          const { body, status } = await updateAlbum({ uuid: album.uuid, name: newValue });
          if (!StatusCodesHelper.isSuccessful(status)) {
            setAlertMessage(body.message);
            setIsAlertOpen(true);
          } else {
            toast.success(strings.album_update_success);
            album.name = newValue;
            onNameUpdated(album);
          }
          setIsLoading(false);
        }}
      />
      {shareLinkComponents()}
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </ListItem>
  );
};

export interface IAlbumHeaderListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onNameUpdated: (album: IAlbum) => void;
  onArtistUpdated: () => void;
}
