import React, { FunctionComponent } from "react";
import { Card, useTheme } from "@mui/material";
import styles from "./styles.module.scss";
import { IMessage } from "../../../hooks/messages";
import { useUser } from "../../../hooks/session";
import { format } from "date-fns";

export const ChatBubble: FunctionComponent<IChatBubbleProps> = ({ message, position }) => {
  const theme = useTheme();
  const [currentUser] = useUser.useState();
  const isOwn = (message: IMessage) => {
    return message.authorUserProfile.userId == currentUser?.uuid;
  };
  const getStyle = () => {
    if (isOwn(message)) {
      switch (position) {
        case "first":
          return `${styles.bubble} ${styles.right} ${styles.rightFirst}`;
        case "middle":
          return `${styles.bubble} ${styles.right} ${styles.rightMiddle}`;
        case "last":
          return `${styles.bubble} ${styles.right} ${styles.rightLast}`;
        case "unique":
          return `${styles.bubble} ${styles.right} ${styles.rightUnique}`;
      }
    } else {
      switch (position) {
        case "first":
          return `${styles.bubble} ${styles.left} ${styles.leftFirst}`;
        case "middle":
          return `${styles.bubble} ${styles.left} ${styles.leftMiddle}`;
        case "last":
          return `${styles.bubble} ${styles.left} ${styles.leftLast}`;
        case "unique":
          return `${styles.bubble} ${styles.left} ${styles.leftUnique}`;
      }
    }
  };
  return (
    <Card
      elevation={1}
      className={getStyle()}
      style={{
        background: isOwn(message) ? theme.palette.colors?.ownChat : theme.palette.colors?.otherChat
      }}
    >
      <p>{message.body}</p>
      <span className={styles.date} style={{ color: theme.palette.secondary.main }}>
        {format(message.createdUnixTimestamp * 1000, "HH:mm")}
      </span>
    </Card>
  );
};

type ChatBubblePosition = "first" | "middle" | "last" | "unique";

interface IChatBubbleProps {
  message: IMessage;
  position: ChatBubblePosition;
}
