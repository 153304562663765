import React, { FunctionComponent } from "react";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { Stack, useTheme } from "@mui/material";
import QRAppStoreBlack from "../../../images/QR-AppStore-Black.png";
import QRAppStoreWhite from "../../../images/QR-AppStore-White.png";

export const AboutPiper: FunctionComponent = () => {
  const theme = useTheme();
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.about_piper.title}</h1>
      <p>{strings.about_piper.block1}</p>
      <Stack className={styles.imageFloatLeft} spacing={4}>
        <iframe
          height={240}
          style={{ border: "none" }}
          src={`https://www.youtube.com/embed/${strings.tutorial_video_id}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Tutorial video"
        />
        <img alt={"Album List"} src={strings.about_piper.album_list_image} />
      </Stack>
      <p>{strings.about_piper.block2}</p>
      <section id={"mobile"} />
      <h2>{strings.about_piper.mobile_title}</h2>
      <p>{strings.about_piper.mobile_description}</p>
      <section id={"android"} />
      <h3>{strings.about_piper.android_title}</h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        {strings.about_piper.android_description_1}
        <a target={"_blank"} href={"https://www.piper.ldelosheros.com"}>
          https://piper.ldelosheros.com
        </a>
      </p>
      <p>{strings.about_piper.android_description_2}</p>
      <section id={"ios"} />
      <h3>{strings.about_piper.ios_title}</h3>
      <p>{strings.about_piper.ios_description}</p>
      <div className={styles.iosShare}>
        <img
          className={styles.qrAppStore}
          alt={"QR Code"}
          src={theme.palette.mode == "light" ? QRAppStoreWhite : QRAppStoreBlack}
        />
        <a target={"_blank"} href={"https://apple.co/3P2tpt7"}>
          <img
            className={styles.appStoreButton}
            alt={"App Store"}
            src={strings.about_piper.app_store_button}
          />
        </a>
      </div>
    </div>
  );
};
