import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { UserAvatar } from "../../UserAvatar";
import { IChat } from "../../../hooks/chats";
import { useUserProfile } from "../../../hooks/session";
import { IUserProfile } from "../../../hooks/userProfiles";

export const ChatListItem: FunctionComponent<IChatListItemProps> = ({
  chat,
  selected,
  disabled,
  backgroundColor,
  elevation,
  hideReadMark,
  onClick
}) => {
  const theme = useTheme();
  const [currentUserProfile] = useUserProfile.useState();
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [isRead, setIsRead] = useState(false);
  useEffect(() => {
    const myUser = chat.chatUsers.filter(
      chatUser => chatUser.userProfile.userId == currentUserProfile?.userId
    )[0];
    setIsRead(myUser?.lastVisitUnixTimestamp >= (chat.lastUpdateUnixTimestamp ?? 0));
    const otherUser = chat.chatUsers.filter(
      chatUser => chatUser.userProfile.userId != currentUserProfile?.userId
    )[0];
    setUserProfile(otherUser?.userProfile);
  }, [chat]);
  return (
    <Card
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 2,
        marginLeft: selected ? 4 : 0,
        transition: "0.3s all",
        backgroundColor:
          backgroundColor ?? selected
            ? theme.palette.colors?.highlight
            : theme.palette.background.paper
      }}
      elevation={elevation ?? 1}
    >
      {!isRead && !hideReadMark && (
        <div
          className={styles.readMark}
          style={{ backgroundColor: theme.palette.colors?.messageNotification }}
        />
      )}
      <CardActionArea
        className={styles.card}
        disabled={disabled}
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            alignSelf: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            height: "100%"
          }}
        >
          <UserAvatar className={styles.avatar} userProfile={userProfile} />
          <Stack sx={{ position: "relative" }}>
            <p className={styles.name}>{userProfile?.username}</p>
            <p className={styles.lastMessage} style={{ color: theme.palette.secondary.main }}>
              {chat.lastMessage?.body}
            </p>
          </Stack>
        </div>
      </CardActionArea>
    </Card>
  );
};

export interface IChatListItemProps {
  chat: IChat;
  selected: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  elevation?: number;
  hideReadMark?: boolean;
  onClick?: () => void;
}
