import { useBackend } from "../../services";

export const useAddAlbumType = () => {
  const { post } = useBackend();
  const addAlbumType = (parameters: IAddAlbumTypeParameters) => post("albumTypes", parameters);
  return { addAlbumType };
};

export interface IAddAlbumTypeParameters {
  name: string;
  showOnAlbumList: boolean;
}
