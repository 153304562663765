import { strings } from "../localization/LocalizedStrings";
import { IArtist } from "../hooks/artists";

export const ArtistUtilities = {
  getDatesString: (
    artist: IArtist,
    monthStyle: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
  ) => {
    let birthDateString: string | undefined = undefined;
    let deathDateString: string | undefined = undefined;
    const formatter = new Intl.DateTimeFormat(strings.locale, {
      day: "numeric",
      month: monthStyle,
      year: "numeric"
    });
    if (artist.birthDate) {
      const birthDate = new Date(artist.birthDate * 1000);
      birthDateString = formatter.format(birthDate);
    }
    if (artist.deathDate) {
      const deathDate = new Date(artist.deathDate * 1000);
      deathDateString = formatter.format(deathDate);
    }
    if (birthDateString) {
      if (deathDateString) {
        return `${birthDateString} - ${deathDateString}`;
      }
      return birthDateString;
    }
    if (deathDateString) {
      return deathDateString;
    }
  }
};
