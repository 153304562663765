import React, { FunctionComponent, useEffect } from "react";
import { MainContent } from "../../components/MainContent";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import piperTextImage from "../../images/piper-text.png";

export const MaintenancePage: FunctionComponent = () => {
  useEffect(() => {
    document.title = strings.app_name;
  }, []);
  return (
    <div className={styles.mainDiv}>
      <MainContent className={styles.mainContent} showingPersistentDrawer={false}>
        <img className={styles.logoImage} src={piperTextImage} alt={"Piper"} />
        <h2 className={styles.title}>{strings.greetings}</h2>
        <h4 className={styles.subtitle}>{strings.maintenance_message}</h4>
      </MainContent>
    </div>
  );
};
