import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Box, useTheme } from "@mui/material";
import { FieldAttributes } from "formik";
import classNames from "classnames";

export const ToolbarBox: FunctionComponent<FieldAttributes<any>> = props => {
  const theme = useTheme();
  return (
    <Box
      className={classNames(styles.toolbarBox, props.className)}
      style={{
        borderColor: theme.palette.colors?.disabled,
        backgroundColor: theme.palette.background.paper,
        ...props.style
      }}
    >
      {props.children}
    </Box>
  );
};
