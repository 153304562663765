import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea } from "@mui/material";
import { UserAvatar } from "../../UserAvatar";
import { IUserProfile } from "../../../hooks/userProfiles";

export const PublicUserProfileCard: FunctionComponent<IPublicUserProfileCardProps> = ({
  userProfile,
  onClick
}) => {
  return (
    <Card
      className={styles.card}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CardActionArea
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            height: "100%"
          }}
        >
          <UserAvatar className={styles.avatar} userProfile={userProfile} />
          <p className={styles.name}>{userProfile.username}</p>
        </div>
      </CardActionArea>
    </Card>
  );
};

export interface IPublicUserProfileCardProps {
  userProfile: IUserProfile;
  onClick: () => void;
}
