import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { strings } from "../../localization/LocalizedStrings";
import { ImageCache } from "../../models/ImageCache";

export const useUploadImage = () => {
  const { upload } = useBackend();
  const uploadImage = async (
    parameters: IUploadImageParameters,
    bigWidth: number,
    bigHeight: number,
    smallWidth: number,
    smallHeight: number
  ) => {
    const { status, body } = await upload("images", `${parameters.name}.png`, parameters.file);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.image_upload_success);
      await ImageCache.shared.removeImage(`${parameters.name}_${bigWidth}_${bigHeight}`);
      await ImageCache.shared.removeImage(`${parameters.name}_${smallWidth}_${smallHeight}`);
      return body.thumbnail;
    }
    toast.error(body.message ?? strings.image_upload_error);
    return undefined;
  };
  return { uploadImage };
};

export interface IUploadImageParameters {
  name: string;
  file: File;
}
