import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Card, CardActionArea, Grid, GridSize, Skeleton, useTheme } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { IImpact, useGetImpacts } from "../../../../hooks/impacts";
import { AttributeDesktop } from "../../AttributeDesktop";
import { ImpactHelper } from "../../../../models/ImpactHelper";
import { useUserProfile } from "../../../../hooks/session";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const ImpactsDesktop: FunctionComponent<IImpactsDesktopProps> = ({
  handleClose,
  columnWidth,
  preselectedId,
  onImpactSelected,
  onClearValue
}) => {
  const theme = useTheme();
  const [currentUserProfile] = useUserProfile.useState();
  const [loading, setLoading] = useState(true);
  const impacts = useGetImpacts(setLoading, []);
  const [filteredImpacts, setFilteredImpacts] = useState<IImpact[]>();
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    refreshFilteredImpacts();
  }, [impacts]);
  const refreshFilteredImpacts = () => {
    if (searchText == "") {
      setFilteredImpacts(impacts);
      return;
    }
    setFilteredImpacts(
      impacts?.filter(impact => {
        return impact.description.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredImpacts();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={false}
      items={filteredImpacts}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.IMPACTS)}
      emptyString={strings.empty_impacts}
      title={strings.attribute_impacts_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      onClearValue={onClearValue}
    >
      {filteredImpacts?.map(impact => (
        <Grid item key={impact.id} xs={columnWidth}>
          <Card sx={{ height: 64 }} elevation={5}>
            <CardActionArea
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
              style={{
                borderColor: theme.palette.colors?.highlightBorder,
                borderWidth: 4,
                borderStyle: preselectedId == impact.id ? "double" : "none"
              }}
              onClick={() => {
                if (onImpactSelected) {
                  onImpactSelected(impact);
                }
              }}
            >
              <p className={styles.name}>
                {ImpactHelper.starsString(impact) +
                  (currentUserProfile?.shouldShowImpactDescriptions
                    ? `\n${impact.description}`
                    : "")}
              </p>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {loading &&
        Array.from({ length: 6 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
    </AttributeDesktop>
  );
};

export interface IImpactsDesktopProps {
  handleClose?: () => void;
  columnWidth: GridSize;
  preselectedId?: number;
  onImpactSelected?: (impact: IImpact) => void;
  onClearValue?: () => void;
}
