import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import {
  Card,
  CardActionArea,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  useTheme
} from "@mui/material";
import { IDiscogsRelease } from "../../../hooks/discogs";
import { strings } from "../../../localization/LocalizedStrings";
import genericSleeveImage from "../../../images/generic_sleeve.jpg";
import { useContextMenu } from "../../../hooks/contextMenu";
import { AddCircleOutlineRounded, ArrowOutwardRounded } from "@mui/icons-material";
import useLongPress from "../../../hooks/longPress";

export const DiscogsReleaseGridItem: FunctionComponent<IDiscogsReleaseGridItemProps> = ({
  release,
  disabled,
  onClick
}) => {
  const theme = useTheme();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const handleLongPress = useLongPress({ onLongPress: handleContextMenu });
  return (
    <Grid item md={12}>
      <Card elevation={3}>
        <CardActionArea
          disabled={disabled}
          className={styles.card}
          onClick={handleContextMenu}
          onContextMenu={handleContextMenu}
          {...handleLongPress}
          sx={{ height: "100%" }}
        >
          <img
            className={styles.albumImage}
            src={release.cover_image}
            alt={release.title}
            onError={(event: any) => {
              event.target.src = genericSleeveImage;
              event.onerror = null;
            }}
          />
          <Stack className={styles.rightContainer}>
            <p className={styles.name} style={{ color: theme.palette.primary.main }}>
              {release.title}
            </p>
            <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
              {release.format_quantity}×{release.format[0] ?? strings.albums_plural} •{" "}
              {release.year ?? "----"} • {release.country}
            </p>
          </Stack>
        </CardActionArea>
      </Card>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onClick();
          }}
          dense
        >
          <ListItemIcon>
            <AddCircleOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.add_album}
        </MenuItem>
        <a
          href={`https://www.discogs.com/release/${release.id}`}
          target={"_blank"}
          style={{ textDecoration: "none", color: theme.palette.primary.main, display: "flex" }}
        >
          <MenuItem onClick={() => handleMenuClose()} dense>
            <ListItemIcon>
              <ArrowOutwardRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.open_on_discogs}
          </MenuItem>
        </a>
      </Menu>
    </Grid>
  );
};

export interface IDiscogsReleaseGridItemProps {
  release: IDiscogsRelease;
  disabled: boolean;
  onClick: () => void;
}
