import { useBackend } from "../../services";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { IChat } from "./interfaces";

export const useGetChats = (setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const getChats = (parameters: IGetPaginatedAttributeProps) => get("chats", parameters);
  return useGetPaginatedAttributes<IChat>(PageSizeHelper.chats(), getChats, setLoading, true);
};
