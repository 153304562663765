import React, { FunctionComponent, useState } from "react";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const BooleanFilterComponent: FunctionComponent<IBooleanFilterComponentProps> = ({
  value,
  onChange
}) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(value);
  return (
    <ToggleButtonGroup
      value={isActive}
      exclusive
      sx={{ height: "30px", width: "100%" }}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          if (value !== undefined) {
            setIsActive(newValue);
          }
          onChange(newValue);
        }
      }}
    >
      <ToggleButton
        className={styles.filterToggle}
        sx={{ color: theme.palette.colors?.disabled, width: "50%" }}
        value={true}
      >
        {strings.stats_pie_yes}
      </ToggleButton>
      <ToggleButton
        className={styles.filterToggle}
        sx={{ color: theme.palette.colors?.disabled, width: "50%" }}
        value={false}
      >
        {strings.stats_pie_no}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export interface IBooleanFilterComponentProps {
  value: boolean | undefined;
  onChange: (newValue: boolean) => void;
}
