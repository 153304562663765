import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Divider,
  IconButton,
  List,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Tooltip,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";
import {
  AccountBoxRounded,
  BusinessCenterRounded,
  ContactEmergencyRounded,
  ContactMailRounded,
  DragIndicator,
  DriveFileRenameOutlineRounded,
  ExitToAppRounded,
  FlagCircleRounded,
  LowPriorityRounded,
  MailOutlineRounded,
  MenuBookRounded,
  MoneyRounded,
  PaidRounded,
  PinOutlined,
  RadioButtonCheckedOutlined,
  SettingsRounded,
  StarsRounded,
  TodayRounded
} from "@mui/icons-material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { SwitchListItem } from "../../SwitchListItem";
import { UpdateEmailDialog } from "../../UpdateEmailDialog";
import { UpdatePasswordDialog } from "../../UpdatePasswordDialog";
import { useGetUserProfile, useModifyUserProfile } from "../../../hooks/userProfiles";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { AlertDialog } from "../../AlertDialog";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { SelectListItem } from "../../SelectListItem";
import { FreeTextListItem } from "../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../attributes/ModifyFreeTextAttributeDialog";
import { SelectChipsListItem } from "../../SelectChipsListItem";
import { toast } from "react-toastify";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { UserUtilities } from "../../../models/UserUtilities";
import { SubscriptionsDialog } from "../../subscriptions/SubscriptionsDialog";
import { MySubscriptionDialog } from "../../subscriptions/MySubscriptionDialog";
import { UserRole } from "../../../hooks/users";
import {
  usePublicUserProfile,
  useSessionManager,
  useUser,
  useUserProfile
} from "../../../hooks/session";
import {
  useCurrencies,
  useCurrenciesManager
} from "../../../hooks/currencies/useCurrenciesManager";
import { useGetCurrencies } from "../../../hooks/currencies";
import { format } from "date-fns";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useIsMobile } from "../../../hooks/isMobile";
import { PublicCollectionsGrid } from "../../marketplace/PublicCollectionsGrid";
import { IPublicCollection } from "../../../hooks/collections";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { useGetShareLink } from "../../../hooks/share";
import { useGetPublicCollections } from "../../../hooks/collections/useGetPublicCollections";
import { useIsMounted } from "../../../hooks/isMounted";
import { useGetUser } from "../../../hooks/users/useGetUser";
import { ImageDropzone } from "../../ImageDropzone";
import { ImageCache } from "../../../models/ImageCache";
import placeholderAvatar from "../../../images/generic_sleeve.jpg";
import { useGetImage, useUploadImage } from "../../../hooks/images";
import { PreferencesDialog } from "../../PreferencesDialog";
import { ResponsiveIcon } from "../../ResponsiveIcon";

export const UserProfileDetail: FunctionComponent<IUserProfileDetailProps> = ({ setIsLoading }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const { modifyUserProfile } = useModifyUserProfile();
  const [currentUser] = useUser.useState();
  const { getImage } = useGetImage();
  const { uploadImage } = useUploadImage();
  const [image, setImage] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useUserProfile.useState();
  const { getUser } = useGetUser();
  const { getUserProfile } = useGetUserProfile();
  const { closeSession } = useSessionManager();
  const { getCurrencies } = useGetCurrencies();
  const [currencies] = useCurrencies.useState();
  const { getCurrency, getCurrenciesCodeList, getCurrenciesStringList } = useCurrenciesManager();
  const [, setCurrentPublicUserProfile] = usePublicUserProfile.useState();
  const [, setCurrentPublicCollection] = useCurrentPublicCollection.useState();
  const { getShareLink } = useGetShareLink();
  const [isLoadingPublicCollections, setIsLoadingPublicCollections] = useState(false);
  const [publicCollectionsAreSet, setPublicCollectionsAreSet] = useState(false);
  const [publicCollections, resetPublicCollections] = useGetPublicCollections(
    currentUserProfile?.userId ?? "",
    setIsLoadingPublicCollections,
    currentUserProfile !== undefined && isMounted.current
  );
  const [shouldOpenFileDialog, setShouldOpenFileDialog] = useState(false);
  const [isUpdateUsernameDialogOpen, setIsUpdateUsernameDialogOpen] = useState(false);
  const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState(false);
  const [isUpdateEmailDialogOpen, setIsUpdateEmailDialogOpen] = useState(false);
  const [isUpdatePasswordDialogOpen, setIsUpdatePasswordDialogOpen] = useState(false);
  const [isSubscriptionsDialogOpen, setIsSubscriptionsDialogOpen] = useState(false);
  const [isMySubscriptionDialogOpen, setIsMySubscriptionDialogOpen] = useState(false);
  const [isNoColorNameDialogOpen, setIsNoColorNameDialogOpen] = useState(false);
  const [isCountriesToHideDialogOpen, setIsCountriesToHideDialogOpen] = useState(false);
  const [isBioDialogOpen, setIsBioDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    if (!currentUser) {
      getUser().then();
    }
    if (!currentUserProfile) {
      getUserProfile().then();
    }
  }, []);
  useEffect(() => {
    if (currentUser?.role == UserRole.guest) {
      navigate(RoutesBuilder.home());
    }
  }, [currentUser]);
  useEffect(() => {
    if (publicCollectionsAreSet) return;
    setPublicCollectionsAreSet(true);
    resetPublicCollections();
  }, [currentUserProfile]);
  useEffect(() => {
    setPublicCollectionsAreSet(false);
  }, []);
  const fetchImage = async () => {
    setImage(undefined);
    if (!currentUserProfile || !currentUserProfile.hasImage) return;
    const response = await getImage({
      name: currentUserProfile.uuid,
      width: ImageCache.smallAvatarImageSize,
      height: ImageCache.smallAvatarImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    fetchImage().then();
  }, [currentUserProfile]);
  const showCollection = async (publicCollection: IPublicCollection) => {
    setIsLoading(true);
    const { status, body } = await getShareLink({
      collectionId: publicCollection.collection.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrentPublicUserProfile(undefined);
      setCurrentPublicCollection(undefined);
      navigate(body.relativeShareLink);
    }
    setIsLoading(false);
  };
  const updateProfile = async (newValue: Record<string, any>) => {
    setIsLoading(true);
    const { status, body } = await modifyUserProfile(newValue);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setCurrentUserProfile(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const handleCurrencyChange = async (event: SelectChangeEvent) => {
    const code = event.target.value as string;
    const newValue = getCurrency(code);
    setIsLoading(true);
    const { status, body } = await modifyUserProfile({
      uuid: currentUserProfile?.uuid,
      currency: newValue
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setCurrentUserProfile(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const handleOrderNewAlbumsChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean) => {
    if (newValue !== null) {
      updateProfile({
        uuid: currentUserProfile?.uuid,
        orderNewAlbums: newValue
      }).then();
    }
  };
  const handleShouldShowImpactsDescriptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    if (newValue !== null) {
      updateProfile({
        uuid: currentUserProfile?.uuid,
        shouldShowImpactDescriptions: newValue
      }).then();
    }
  };
  const handleShouldShowAlbumIdChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    if (newValue !== null) {
      updateProfile({
        uuid: currentUserProfile?.uuid,
        shouldShowAlbumId: newValue
      }).then();
    }
  };
  const handleShowDecimalsChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean) => {
    if (newValue !== null) {
      updateProfile({
        uuid: currentUserProfile?.uuid,
        showDecimals: newValue
      }).then();
    }
  };
  const handleReceiveEmailNotificationsChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    if (newValue !== null) {
      updateProfile({
        uuid: currentUserProfile?.uuid,
        receiveEmailNotifications: newValue
      }).then();
    }
  };
  useEffect(() => {
    if (!currencies) {
      getCurrencies().then();
    }
  }, []);
  const profileList = () => (
    <List className={styles.list}>
      <div className={styles.avatarContainer}>
        <Avatar
          className={styles.avatar}
          sx={{
            marginLeft: 2,
            marginBottom: 2,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
          }}
        >
          <ImageDropzone
            className={styles.dropzone}
            disabled={isLoadingImage}
            onImageChanged={async file => {
              if (!currentUserProfile) return;
              setIsLoadingImage(true);
              const newThumbnail = await uploadImage(
                { name: currentUserProfile.uuid, file },
                ImageCache.bigAvatarImageSize,
                ImageCache.bigAvatarImageSize,
                ImageCache.smallAvatarImageSize,
                ImageCache.smallAvatarImageSize
              );
              if (newThumbnail) {
                setCurrentUserProfile({
                  ...currentUserProfile,
                  hasImage: true,
                  thumbnail: newThumbnail
                });
                await fetchImage();
              }
              setIsLoadingImage(false);
            }}
            placeholderImage={placeholderAvatar}
            isLoading={isLoadingImage}
            shouldOpenFileDialog={shouldOpenFileDialog}
            initialImage={
              image
                ? `data:image/png;base64,${image}`
                : currentUserProfile?.thumbnail
                  ? `data:image/png;base64,${currentUserProfile?.thumbnail}`
                  : undefined
            }
          />
          <Stack className={styles.hoverButtonsStack} direction={"row"} spacing={1}>
            <Tooltip title={strings.edit_avatar} arrow disableInteractive>
              <IconButton
                className={styles.hoverButton}
                disabled={isLoadingImage}
                style={{ backgroundColor: theme.palette.colors?.counterBackground }}
                onClick={() => setShouldOpenFileDialog(state => !state)}
              >
                <ResponsiveIcon icon={DriveFileRenameOutlineRounded} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Avatar>
      </div>
      <Card elevation={5} className={isMobile ? styles.sectionMobile : styles.section}>
        <RightDetailListItem
          key={"update_username"}
          icon={AccountBoxRounded}
          title={strings.username_title}
          detail={currentUserProfile?.username ?? ""}
          onClick={() => {
            setIsUpdateUsernameDialogOpen(true);
          }}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          key={"update_email"}
          icon={ContactMailRounded}
          title={strings.email_title}
          leftSubtitle={currentUser?.email ?? ""}
          onClick={() => {
            setIsUpdateEmailDialogOpen(true);
          }}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          key={"update_password"}
          icon={ContactEmergencyRounded}
          title={strings.update_password_title}
          detail={"● ● ● ● ●"}
          onClick={() => {
            setIsUpdatePasswordDialogOpen(true);
          }}
        />
        <Divider variant={"inset"} />
        {currentUserProfile && (
          <RightDetailListItem
            key={"user_since"}
            icon={TodayRounded}
            title={strings.user_since_title}
            detail={format(currentUserProfile.createdUnixTimestamp * 1000, "d MMMM yyyy", {
              locale: strings.fnsLocale
            })}
          />
        )}
        <Divider variant={"inset"} />
        <RightDetailListItem
          key={"update_role"}
          icon={BusinessCenterRounded}
          title={strings.update_role_title}
          detail={UserUtilities.roleName(currentUser?.role)}
          onClick={() => {
            if (currentUser?.role == UserRole.common) {
              setIsSubscriptionsDialogOpen(true);
            } else {
              setIsMySubscriptionDialogOpen(true);
            }
          }}
        />
      </Card>
      <Card elevation={5} className={isMobile ? styles.sectionMobile : styles.section}>
        <FreeTextListItem
          icon={MenuBookRounded}
          title={strings.bio_title}
          detail={currentUserProfile?.bio ?? "--"}
          maxLines={6}
          onClick={() => {
            setIsBioDialogOpen(true);
          }}
        />
      </Card>
      <Card elevation={5} className={isMobile ? styles.sectionMobile : styles.section}>
        <SelectListItem
          key={"currency"}
          icon={PaidRounded}
          title={strings.currency_title}
          labels={getCurrenciesStringList()}
          values={getCurrenciesCodeList()}
          value={currentUserProfile?.currency.code}
          handleChange={handleCurrencyChange}
        />
        <Divider variant={"inset"} />
        <SwitchListItem
          key={"receive_email_notifications"}
          className={styles.switchListItem}
          icon={MailOutlineRounded}
          title={strings.settings_receive_email_notifications}
          labels={[strings.no, strings.yes]}
          values={[false, true]}
          value={currentUserProfile?.receiveEmailNotifications}
          fullWidth={false}
          handleChange={handleReceiveEmailNotificationsChange}
        />
        <Divider variant={"inset"} />
        <SwitchListItem
          key={"show_decimals"}
          className={styles.switchListItem}
          icon={MoneyRounded}
          title={strings.settings_show_decimals}
          labels={[strings.no, strings.yes]}
          values={[false, true]}
          value={currentUserProfile?.showDecimals}
          fullWidth={false}
          handleChange={handleShowDecimalsChange}
        />
        <Divider variant={"inset"} />
        <SwitchListItem
          key={"order_new_albums"}
          className={styles.switchListItem}
          icon={LowPriorityRounded}
          title={strings.settings_order_new_albums}
          labels={[strings.no, strings.yes]}
          values={[false, true]}
          value={currentUserProfile?.orderNewAlbums}
          fullWidth={false}
          handleChange={handleOrderNewAlbumsChange}
          helpHref={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.orderAlbums()}`}
        />
        <Divider variant={"inset"} />
        <SwitchListItem
          key={"show_rating_description"}
          className={styles.switchListItem}
          icon={StarsRounded}
          title={strings.settings_should_show_impact_descriptions}
          labels={[strings.no, strings.yes]}
          values={[false, true]}
          value={currentUserProfile?.shouldShowImpactDescriptions}
          fullWidth={false}
          handleChange={handleShouldShowImpactsDescriptionChange}
        />
        <Divider variant={"inset"} />
        <SwitchListItem
          key={"show_album_id"}
          className={styles.switchListItem}
          icon={PinOutlined}
          title={strings.settings_should_show_album_id}
          labels={[strings.no, strings.yes]}
          values={[false, true]}
          value={currentUserProfile?.shouldShowAlbumId}
          fullWidth={false}
          handleChange={handleShouldShowAlbumIdChange}
        />
        <Divider variant={"inset"} />
        <SelectChipsListItem
          icon={RadioButtonCheckedOutlined}
          className={styles.switchListItem}
          title={strings.no_color_names_title}
          chips={currentUserProfile?.noColorNames?.map(color => ({
            id: color,
            name: color
          }))}
          helpHref={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.userProfile(
            "colors"
          )}`}
          onAdd={() => {
            setIsNoColorNameDialogOpen(true);
          }}
          onDelete={label => {
            const noColorNames = [...(currentUserProfile?.noColorNames ?? [])];
            const index = noColorNames?.indexOf(label);
            if (index !== undefined && index >= 0) {
              noColorNames?.splice(index, 1);
            }
            updateProfile({
              uuid: currentUserProfile?.uuid,
              noColorNames: noColorNames
            }).then();
          }}
        />
        <Divider variant={"inset"} />
        <SelectChipsListItem
          icon={FlagCircleRounded}
          className={styles.switchListItem}
          title={strings.countries_to_hide_title}
          chips={currentUserProfile?.countriesToHide?.map(country => ({
            id: country,
            name: country
          }))}
          helpHref={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.userProfile(
            "countries"
          )}`}
          onAdd={() => {
            setIsCountriesToHideDialogOpen(true);
          }}
          onDelete={label => {
            const countriesToHide = [...(currentUserProfile?.countriesToHide ?? [])];
            const index = countriesToHide?.indexOf(label);
            if (index !== undefined && index >= 0) {
              countriesToHide?.splice(index, 1);
            }
            updateProfile({
              uuid: currentUserProfile?.uuid,
              countriesToHide: countriesToHide
            }).then();
          }}
        />
      </Card>
      <Card elevation={5} className={isMobile ? styles.sectionMobile : styles.section}>
        <RightDetailListItem
          key={"Preferences"}
          icon={SettingsRounded}
          title={strings.option_preferences}
          onClick={() => {
            setIsPreferencesDialogOpen(true);
          }}
        />
        <Divider variant={"inset"} />
        <RightDetailListItem
          key={"CloseSession"}
          icon={ExitToAppRounded}
          title={strings.option_close_session}
          onClick={() => {
            closeSession();
            navigate(RoutesBuilder.signIn());
          }}
        />
      </Card>
      <ModifyGenericAttributeDialog
        title={strings.username_title}
        description={strings.modify_username_message}
        placeholder={strings.username_title}
        missingText={strings.field_missing}
        value={currentUserProfile?.username}
        canClear={false}
        open={isUpdateUsernameDialogOpen}
        setOpen={setIsUpdateUsernameDialogOpen}
        isNumber={false}
        onChange={newValue => {
          updateProfile({
            uuid: currentUserProfile?.uuid,
            username: newValue
          }).then();
        }}
      />
      <UpdateEmailDialog open={isUpdateEmailDialogOpen} setOpen={setIsUpdateEmailDialogOpen} />
      <UpdatePasswordDialog
        open={isUpdatePasswordDialogOpen}
        setOpen={setIsUpdatePasswordDialogOpen}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.bio_title}
        description={strings.bio_message}
        placeholder={strings.bio_title}
        open={isBioDialogOpen}
        setOpen={setIsBioDialogOpen}
        value={currentUserProfile?.bio}
        onChange={newValue => {
          updateProfile({
            uuid: currentUserProfile?.uuid,
            bio: newValue
          }).then();
        }}
        onClearValue={() => {
          updateProfile({
            uuid: currentUserProfile?.uuid,
            bio: ""
          }).then();
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.no_color_name_title}
        description={strings.no_color_name_message}
        placeholder={strings.disc_color_placeholder}
        missingText={strings.field_missing}
        canClear={false}
        open={isNoColorNameDialogOpen}
        setOpen={setIsNoColorNameDialogOpen}
        isNumber={false}
        onChange={newValue => {
          const noColorNames = [...(currentUserProfile?.noColorNames ?? [])];
          noColorNames?.push(newValue);
          updateProfile({
            uuid: currentUserProfile?.uuid,
            noColorNames: noColorNames
          }).then();
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.countries_to_hide_title}
        description={strings.countries_to_hide_message}
        placeholder={strings.country_placeholder}
        missingText={strings.field_missing}
        canClear={false}
        open={isCountriesToHideDialogOpen}
        setOpen={setIsCountriesToHideDialogOpen}
        isNumber={false}
        onChange={newValue => {
          const countriesToHide = [...(currentUserProfile?.countriesToHide ?? [])];
          countriesToHide?.push(newValue);
          updateProfile({
            uuid: currentUserProfile?.uuid,
            countriesToHide: countriesToHide
          }).then();
        }}
      />
      <SubscriptionsDialog
        isOpen={isSubscriptionsDialogOpen}
        setIsOpen={setIsSubscriptionsDialogOpen}
      />
      <MySubscriptionDialog
        isOpen={isMySubscriptionDialogOpen}
        setIsOpen={setIsMySubscriptionDialogOpen}
      />
      <PreferencesDialog
        isOpen={isPreferencesDialogOpen}
        setIsOpen={setIsPreferencesDialogOpen}
        canRemoveAccount
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </List>
  );
  return (
    <Card className={styles.mainContainer}>
      <PanelGroup className={styles.mainDiv} direction={"horizontal"}>
        {!isMobile && currentUserProfile && (
          <Panel
            minSize={40}
            defaultSize={50}
            maxSize={60}
            style={{ display: "flex", overflow: "auto", paddingBottom: 16 }}
          >
            {profileList()}
          </Panel>
        )}
        {!isMobile && (
          <PanelResizeHandle className={styles.resizeHandle}>
            <SvgIcon
              component={DragIndicator}
              sx={{ alignSelf: "center", color: theme.palette.secondary.main, fontSize: 14 }}
            />
          </PanelResizeHandle>
        )}
        <Panel className={isMobile ? styles.graphsDivMobile : styles.graphsDiv}>
          {isMobile && currentUserProfile && profileList()}
          {currentUserProfile && (
            <div>
              <h4 className={styles.publicCollectionsTitle}>{strings.public_collections_title}</h4>
              <Card elevation={5} className={styles.publicCollectionsCard}>
                <PublicCollectionsGrid
                  isLoading={isLoadingPublicCollections}
                  publicCollections={publicCollections.map(publicCollection => ({
                    collection: publicCollection.collection,
                    userProfile: publicCollection.userProfile
                  }))}
                  onCollectionSelected={showCollection}
                  columnWidth={isMobile ? 12 : 6}
                  skeletonCount={4}
                  showUser={false}
                />
              </Card>
            </div>
          )}
        </Panel>
      </PanelGroup>
    </Card>
  );
};

export interface IUserProfileDetailProps {
  setIsLoading: (isLoading: boolean) => void;
}
