import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Stack, SvgIcon, useTheme } from "@mui/material";
import { AddCircleOutlineRounded, Search, SvgIconComponent } from "@mui/icons-material";
import { StringFormat } from "../../../models/StringFormat";
import { strings } from "../../../localization/LocalizedStrings";
import { ShortcutItem } from "../../ShortcutItem";

export const ContentUnavailableNotice: FunctionComponent<IContentUnavailableNoticeProps> = ({
  isLoading,
  defaultVisible = false,
  items,
  emptyIcon,
  emptyTitle,
  emptyDescription,
  addButtonTitle,
  searchText,
  onAddItem
}) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(defaultVisible);
  useEffect(() => {
    if (items === undefined || isLoading) {
      setVisible(defaultVisible);
      return;
    }
    setVisible(items.length == 0);
  }, [items]);
  return (
    <>
      {!items?.length && !isLoading && visible && (
        <Stack className={styles.noItemsStack}>
          <SvgIcon
            component={searchText ? Search : emptyIcon}
            className={styles.noItemsIcon}
            style={{ color: theme.palette.primary.subtitle }}
          />
          <p className={styles.noItems}>
            {searchText ? StringFormat(strings.empty_search_title, searchText) : emptyTitle}
          </p>
          <p className={styles.noItemsSubtitle} style={{ color: theme.palette.primary.subtitle }}>
            {searchText ? strings.empty_search_description : emptyDescription}
          </p>
          {!searchText && addButtonTitle && onAddItem && (
            <ShortcutItem
              className={styles.addButton}
              icon={AddCircleOutlineRounded}
              title={addButtonTitle}
              onClick={onAddItem}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export interface IContentUnavailableNoticeProps {
  isLoading: boolean;
  defaultVisible?: boolean;
  items: any[] | undefined;
  emptyIcon: SvgIconComponent;
  emptyTitle: string;
  emptyDescription: string;
  addButtonTitle?: string;
  searchText: string | undefined;
  onAddItem?: () => void;
}
