import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { AppBar, Box, Toolbar, useTheme } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { ToolbarRawButton } from "../toolbar/ToolbarRawButton";
import { strings } from "../../localization/LocalizedStrings";
import piperTextImage from "../../images/piper-text.png";

export const DrawerHeader: FunctionComponent<IDrawerHeaderProps> = ({ setIsOpen }) => {
  const theme = useTheme();
  return (
    <AppBar position={"sticky"} sx={{ backgroundColor: theme.palette.colors?.appBar }}>
      <Toolbar sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
        <Box className={styles.closeButtonBox}>
          <ToolbarRawButton
            tooltip={strings.close}
            icon={CloseRounded}
            onClick={() => setIsOpen(false)}
            buttonClassName={styles.closeButton}
          />
        </Box>
        <img
          className={styles.logoImage}
          style={{
            margin: 8,
            height: 44,
            alignSelf: "center"
          }}
          src={piperTextImage}
          alt={"Piper"}
        />
      </Toolbar>
    </AppBar>
  );
};

export interface IDrawerHeaderProps {
  setIsOpen: (isOpen: boolean) => void;
}
