import { useBackend } from "../../services";

export const useLogin = () => {
  const { post } = useBackend();
  const login = (parameters: ILoginParameters) => post("login", parameters);
  return { login };
};

export interface ILoginParameters {
  email: string;
  password: string;
}
