import { useBackend } from "../../services";

export const useAddReportForMessage = () => {
  const { post } = useBackend();
  const addReportForMessage = (parameters: IAddReportForMessageParameters) =>
    post("reports/message", parameters);
  return { addReportForMessage };
};

export interface IAddReportForMessageParameters {
  messageId: string;
  body: string;
}
