import { useBackend } from "../../services";

export const useSignInWithApple = () => {
  const { post } = useBackend();
  const signInWithApple = (parameters: ISignInWithAppleParameters) =>
    post("login/apple", parameters);
  return { signInWithApple };
};

export const useSignUpWithApple = () => {
  const { post } = useBackend();
  const signUpWithApple = (parameters: ISignInWithAppleParameters) =>
    post("signup/apple", parameters);
  return { signUpWithApple };
};

export interface ISignInWithAppleParameters {
  token: string;
  validateEmail?: string;
}
