import React, { useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { strings } from "../../localization/LocalizedStrings";
import { useGetShareLink } from "./useGetShareLink";
import { useQRCode } from "../qrCode";
import { ImageDetailDialog } from "../../components/ImageDetailDialog";
import { AlertDialog } from "../../components/AlertDialog";
import { useGetPrivateLink } from "./useGetPrivateLink";

export const useShareLink = (fileName: string | undefined) => {
  const [qrCodeTitle, setQRCodeTitle] = useState("");
  const [qrCodeMessage, setQRCodeMessage] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [qrCodeWithoutStyle, setQRCodeWithoutStyle] = useState(false);
  const qrBase64 = useQRCode(qrCodeData, qrCodeWithoutStyle);
  const [isImageDetailDialogOpen, setIsImageDetailDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getShareLink } = useGetShareLink();
  const { getPrivateLink } = useGetPrivateLink();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const fetchShareLink = async (collectionId: string, albumId: string | undefined) => {
    setIsLoading(true);
    const { status, body } = await getShareLink({
      albumId: albumId,
      collectionId: collectionId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setQRCodeTitle(strings.qr_code_public_title);
      setQRCodeMessage(strings.qr_code_public_message);
      processLink(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const fetchPrivateLink = async (albumId: string) => {
    setIsLoading(true);
    const { status, body } = await getPrivateLink({
      albumId: albumId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setQRCodeTitle(strings.qr_code_private_title);
      setQRCodeMessage(strings.qr_code_private_message);
      processLink(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const processLink = (body: any) => {
    const shareLink = body.shareLink;
    copy(shareLink);
    setQRCodeData(shareLink);
    setIsImageDetailDialogOpen(true);
    toast.success(strings.share_link_notification);
  };
  const shareLinkComponents = () => (
    <>
      <ImageDetailDialog
        title={qrCodeTitle}
        message={qrCodeMessage}
        isOpen={isImageDetailDialogOpen}
        setIsOpen={setIsImageDetailDialogOpen}
        image={qrBase64}
        filename={`QR-${fileName}.png`}
        onStyleButtonClicked={() => setQRCodeWithoutStyle(state => !state)}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </>
  );
  return [shareLinkComponents, fetchShareLink, fetchPrivateLink, isLoading] as const;
};
