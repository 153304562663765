import React, { FunctionComponent } from "react";
import { strings } from "../../../localization/LocalizedStrings";
import { DocumentationOptions } from "../DocumentationOptions";
import { AboutPiper } from "../AboutPiper";

export const DocumentationHome: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_home.title}</h1>
      <DocumentationOptions defaultExpanded={[""]} defaultSelected={""} hidePreferences />
      <AboutPiper />
    </div>
  );
};
