import React, { FunctionComponent, useState } from "react";
import styles from "./styles.module.scss";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { AttributeUtilities } from "../../../models/AttributeUtilities";

export const AttributeList: FunctionComponent<IAttributeListProps> = ({ onAttributeSelected }) => {
  const theme = useTheme();
  const [selectedAttributeType, setSelectedAttributeType] = useState<AttributeType>();
  const attributes = [
    AttributeType.ARTISTS,
    AttributeType.LABELS,
    AttributeType.ALBUM_FORMATS,
    AttributeType.ALBUM_TYPES,
    AttributeType.TAGS,
    AttributeType.STORES,
    AttributeType.IMPACTS,
    AttributeType.SLEEVE_GRADES,
    AttributeType.MEDIA_GRADES,
    AttributeType.SELL_VALUES,
    AttributeType.EXCHANGE_RATES
  ];
  return (
    <List
      disablePadding
      className={styles.mainList}
      sx={{
        backgroundColor: "background.paper"
      }}
    >
      <ListItemText
        key={"title"}
        sx={{ textAlign: "center", paddingTop: 2 }}
        secondary={strings.attributes_title}
      />
      <Divider key={"mainDivider"} />
      {attributes.map(attributeType => (
        <div key={attributeType.valueOf()}>
          <ListItemButton
            selected={selectedAttributeType == attributeType}
            onClick={() => {
              setSelectedAttributeType(attributeType);
              onAttributeSelected(attributeType);
            }}
          >
            <ListItemIcon>
              <ResponsiveIcon icon={AttributeUtilities.getAttributeIcon(attributeType)} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={styles.title}
              primary={AttributeUtilities.getAttributeTitle(attributeType)}
              sx={{ color: theme.palette.primary.main }}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
        </div>
      ))}
    </List>
  );
};

export interface IAttributeListProps {
  onAttributeSelected: (attribute: AttributeType) => void;
}

export enum AttributeType {
  ARTISTS,
  STORES,
  LABELS,
  SLEEVE_GRADES,
  MEDIA_GRADES,
  SELL_VALUES,
  ALBUM_FORMATS,
  ALBUM_TYPES,
  IMPACTS,
  EXCHANGE_RATES,
  TAGS
}
