import { useBackend } from "../../services";

export const useDeleteArtist = () => {
  const { deleteRequest } = useBackend();
  const deleteArtist = (parameters: IDeleteArtistParameters) =>
    deleteRequest("artists", parameters);
  return { deleteArtist };
};

export interface IDeleteArtistParameters {
  uuid: string;
}
