import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import {
  ArrowOutwardRounded,
  ContentCopyRounded,
  DeleteForever,
  MoreVertRounded,
  PushPinOutlined,
  PushPinRounded,
  QrCode2Rounded,
  ShareRounded,
  YouTube
} from "@mui/icons-material";
import { IAlbum, SellStatus } from "../../../hooks/albums";
import { useContextMenu } from "../../../hooks/contextMenu";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { toast } from "react-toastify";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { useCollectionManager } from "../../../hooks/collections/useCollectionManager";
import { useGetVideoIds } from "../../../hooks/youTube/useGetVideoIds";
import { useGetPrivateLink } from "../../../hooks/share/useGetPrivateLink";
import { useUser } from "../../../hooks/session";
import { useShareLink } from "../../../hooks/share/useShareLink";
import { StringFormat } from "../../../models/StringFormat";
import { AlertDialog } from "../../AlertDialog";
import { useDeleteAlbum } from "../../../hooks/albums/useDeleteAlbum";
import { ToolbarRawButton } from "../../toolbar/ToolbarRawButton";
import styles from "./styles.module.scss";

export const AlbumOptionsButton: FunctionComponent<IAlbumOptionsButtonProps> = ({
  isPublic,
  className,
  buttonClassName,
  style,
  album,
  userEvent,
  onCopy,
  onPlayFromYouTube,
  onMarkAs,
  onDelete,
  setIsLoading,
  isForLayout
}) => {
  const theme = useTheme();
  const [currentUser] = useUser.useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getCurrentCollection, isCurrentCollectionShareable } = useCollectionManager();
  const [qrFilename, setQRFilename] = useState("");
  const { deleteAlbum } = useDeleteAlbum();
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
  const [shareLinkComponents, fetchShareLink, fetchPrivateLink, isShareLoading] =
    useShareLink(qrFilename);
  const getVideoIds = useGetVideoIds();
  const { getPrivateLink } = useGetPrivateLink();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    if (!userEvent) return;
    handleContextMenu(userEvent);
  }, [userEvent]);
  const setLoadingState = (isLoading: boolean) => {
    if (setIsLoading) {
      setIsLoading(isLoading);
    }
    setLoading(isLoading);
  };
  useEffect(() => setLoadingState(isShareLoading), [isShareLoading]);
  const {
    menuProps: reservedMenuProps,
    handleContextMenu: reservedHandleContextMenu,
    handleMenuClose: reservedHandleMenuClose
  } = useContextMenu();
  const shareAlbum = async () => {
    const collectionId = getCurrentCollection(isPublic)?.uuid;
    if (!collectionId) return;
    setQRFilename(album.name);
    await fetchShareLink(collectionId, album.uuid);
  };
  const playFromYouTube = async () => {
    if (!album.trackList) return;
    setLoadingState(true);
    const videoIds = await getVideoIds(album.trackList, album.name, album.artist.name);
    setLoadingState(false);
    if (videoIds.length > 0) {
      onPlayFromYouTube(videoIds);
    } else {
      toast.error(strings.player_unknown_error);
    }
  };
  const openOnDiscogs = () => {
    if (!album.discogsReleaseId) {
      return;
    }
    openInNewTab(`https://www.discogs.com/release/${album.discogsReleaseId}`);
  };
  const openOnPrivateCollection = async () => {
    setLoadingState(true);
    const { status, body } = await getPrivateLink({ albumId: album.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      openInNewTab(body.shareLink);
    } else {
      toast.error(body.message);
    }
    setLoadingState(false);
  };
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className={className} style={style}>
      <ToolbarRawButton
        tooltip={strings.options}
        icon={MoreVertRounded}
        onClick={handleContextMenu}
        loading={loading || isShareLoading}
        iconClassName={styles.icon}
        buttonClassName={buttonClassName}
      />
      <Menu {...menuProps}>
        {!isPublic && (
          <MenuItem
            key={"PrivateLink"}
            onClick={() => {
              handleMenuClose();
              setQRFilename(album.name);
              fetchPrivateLink(album.uuid).then();
            }}
            dense
          >
            <ListItemIcon>
              <QrCode2Rounded fontSize={"small"} />
            </ListItemIcon>
            {strings.get_private_link}
          </MenuItem>
        )}
        {isCurrentCollectionShareable(isPublic) && (
          <MenuItem
            key={"Share"}
            onClick={() => {
              handleMenuClose();
              shareAlbum().then();
            }}
            dense
          >
            <ListItemIcon>
              <ShareRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.share_album}
          </MenuItem>
        )}
        {!isForLayout && (
          <MenuItem
            key={"Copy"}
            onClick={async () => {
              handleMenuClose();
              onCopy();
            }}
            dense
          >
            <ListItemIcon>
              <ContentCopyRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.copy_album}
          </MenuItem>
        )}
        {!isPublic &&
          !isForLayout &&
          (album.sellStatus ? (
            <MenuItem
              key={"RemoveMark"}
              onClick={async () => {
                handleMenuClose();
                onMarkAs(null);
              }}
              dense
            >
              <ListItemIcon>
                <PushPinOutlined fontSize={"small"} />
              </ListItemIcon>
              {strings.remove_mark_title}
            </MenuItem>
          ) : (
            <MenuItem key={"AddMark"} onClick={reservedHandleContextMenu} dense>
              <ListItemIcon>
                <PushPinRounded fontSize={"small"} />
              </ListItemIcon>
              {strings.mark_as_title}
            </MenuItem>
          ))}
        {album.trackList && album.trackList.length > 0 && (
          <MenuItem
            key={"Play"}
            onClick={async () => {
              handleMenuClose();
              await playFromYouTube();
            }}
            dense
          >
            <ListItemIcon>
              <YouTube fontSize={"small"} />
            </ListItemIcon>
            {strings.play_from_youtube}
          </MenuItem>
        )}
        {album.discogsReleaseId && (
          <MenuItem
            key={"Discogs"}
            onClick={() => {
              handleMenuClose();
              openOnDiscogs();
            }}
            dense
          >
            <ListItemIcon>
              <ArrowOutwardRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.open_on_discogs}
          </MenuItem>
        )}
        {(isPublic || isForLayout) &&
          currentUser &&
          (AlbumUtilities.isOwner(album, currentUser) || isForLayout) && (
            <MenuItem
              key={"OpenOnPrivateCollection"}
              onClick={async () => {
                handleMenuClose();
                await openOnPrivateCollection();
              }}
              dense
            >
              <ListItemIcon>
                <ArrowOutwardRounded fontSize={"small"} />
              </ListItemIcon>
              {isForLayout ? strings.option_view_detail : strings.open_on_private_collection}
            </MenuItem>
          )}
        {!isPublic && !isForLayout && (
          <MenuItem
            key={"Remove"}
            onClick={() => {
              handleMenuClose();
              setIsOpenDeleteConfirmation(true);
            }}
            sx={{ color: theme.palette.colors?.remove }}
            dense
          >
            <ListItemIcon sx={{ color: theme.palette.colors?.remove }}>
              <DeleteForever fontSize={"small"} />
            </ListItemIcon>
            {strings.alert_delete}
          </MenuItem>
        )}
      </Menu>
      <Menu {...reservedMenuProps}>
        {AlbumUtilities.getAllSellStatuses().map(sellStatus => (
          <MenuItem
            key={`${sellStatus}`}
            onClick={() => {
              handleMenuClose();
              reservedHandleMenuClose();
              onMarkAs(sellStatus);
            }}
            dense
          >
            {AlbumUtilities.getSellStatusDescription(sellStatus)}
          </MenuItem>
        ))}
      </Menu>
      <AlertDialog
        message={StringFormat(strings.album_delete_confirmation, album.name)}
        open={isOpenDeleteConfirmation}
        setOpen={setIsOpenDeleteConfirmation}
        isConfirm
        onConfirm={async () => {
          setLoadingState(true);
          const { status, body } = await deleteAlbum({ uuid: album.uuid });
          if (!StatusCodesHelper.isSuccessful(status)) {
            setAlertMessage(body.message);
            setAlertIsOpen(true);
          } else {
            onDelete();
          }
          setLoadingState(false);
        }}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
      {shareLinkComponents()}
    </div>
  );
};

export interface IAlbumOptionsButtonProps {
  isPublic: boolean;
  className?: string;
  buttonClassName?: string;
  style?: CSSProperties;
  album: IAlbum;
  userEvent: React.MouseEvent | React.TouchEvent | undefined;
  onCopy: () => void;
  onPlayFromYouTube: (videoIds: string[]) => void;
  onMarkAs: (sellStatus: SellStatus | null) => void;
  onDelete: () => void;
  setIsLoading?: (loading: boolean) => void;
  isForLayout?: boolean;
}
