import { useBackend } from "../../services";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { ITag } from "./interfaces";
import {
  IGetPaginatedAttributeProps,
  IPublicGetPaginatedAttributeProps
} from "../attributes/interfaces";
import { useCollectionManager } from "../collections/useCollectionManager";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";

export const useGetTags = (isPublic: boolean, setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const { getCurrentCollection } = useCollectionManager();
  const [currentUser] = useUser.useState();
  const getTags = (parameters: IGetPaginatedAttributeProps) => get("tags", parameters);
  const getPublicTags = (parameters: IPublicGetPaginatedAttributeProps) =>
    get("tags/shared/public", parameters);
  return useGetPaginatedAttributes<ITag>(
    PageSizeHelper.tags(currentUser?.role ?? UserRole.common),
    isPublic ? getPublicTags : getTags,
    setLoading,
    true,
    getCurrentCollection(isPublic)?.uuid
  );
};
