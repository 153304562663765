import queryString from "query-string";
import { BackendConfig } from "../../config";
import { strings } from "../../localization/LocalizedStrings";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useSessionManager, useUser } from "../../hooks/session";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { useEffect } from "react";

const commonHeaders = (token: string) => {
  return {
    "Piper-JWT-Token": token,
    "Piper-Locale": strings.locale,
    "Piper-App": "Web"
  };
};

const socket = io(BackendConfig.url, {
  auth: {
    token: ""
  }
});

export const useBackend = () => {
  const navigate = useNavigate();
  const [currentUser] = useUser.useState();
  const { getToken, setToken, didUpdateToken, closeSession } = useSessionManager();
  const processResponse = async (response: Response) => {
    const body = await response.json();
    if (response.headers.has("Piper-Renewed-JWT-Token")) {
      const token = response.headers.get("Piper-Renewed-JWT-Token") ?? "";
      setToken({ token: token, expires: 0, issued: 0 });
    }
    if (StatusCodesHelper.isSessionExpired(response.status)) {
      toast.error(body.message, { toastId: "expiredSession" });
      closeSession();
      navigate(RoutesBuilder.signIn());
    } else if (StatusCodesHelper.isOnMaintenance(response.status)) {
      navigate(RoutesBuilder.maintenance());
    }
    return { body, status: response.status };
  };
  const get = async <TParams>(endpoint: string, query?: TParams) => {
    const stringifiedQuery = queryString.stringify(query || {}, { arrayFormat: "bracket" });
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}?${stringifiedQuery}`, {
      method: "GET",
      headers: new Headers(commonHeaders(token))
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };
  const post = async (endpoint: string, params: any, query?: any) => {
    const stringifiedQuery = queryString.stringify(query || {}, { arrayFormat: "bracket" });
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}?${stringifiedQuery}`, {
      method: "POST",
      headers: {
        ...commonHeaders(token),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };
  const put = async <TParams>(endpoint: string, params?: TParams) => {
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}`, {
      method: "PUT",
      headers: {
        ...commonHeaders(token),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };
  const patch = async <TParams>(endpoint: string, params?: TParams) => {
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}`, {
      method: "PATCH",
      headers: {
        ...commonHeaders(token),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };
  const deleteRequest = async <TParams>(endpoint: string, params?: TParams) => {
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}`, {
      method: "DELETE",
      headers: {
        ...commonHeaders(token),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };
  const upload = async (
    endpoint: string,
    filename: string,
    file: File,
    extraKey?: string,
    extraArguments?: string
  ) => {
    const form = new FormData();
    form.append("image", file, filename);
    if (extraKey) {
      form.append(extraKey, extraArguments ?? "");
    }
    const token = getToken();
    return fetch(`${BackendConfig.url}/${endpoint}`, {
      method: "POST",
      headers: commonHeaders(token),
      body: form
    })
      .then(async response => {
        return await processResponse(response);
      })
      .catch(error => {
        return { body: error, status: 500 };
      });
  };

  /* Socket */
  useEffect(() => {
    const token = getToken();
    if (token === null) {
      socket.disconnect();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      socket.auth.token = token;
      if (socket.disconnected && currentUser?.role != "guest") {
        socket.connect();
      }
    }
  }, [didUpdateToken]);

  const emitEvent = async (eventName: string, parameters: Record<any, any>) => {
    return await socket.emitWithAck(eventName, parameters);
  };

  return { get, post, put, patch, deleteRequest, upload, socket, emitEvent };
};
