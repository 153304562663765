import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  SvgIcon,
  useTheme
} from "@mui/material";
import { AlertDialog } from "../../AlertDialog";
import { ShelvingRepresentation } from "../ShelvingRepresentation";
import { IShelving } from "../../../hooks/shelvings";
import { strings } from "../../../localization/LocalizedStrings";
import { IShelf } from "../../../hooks/shelves";
import { IShelfSegment, IShelfSegmentAlbum } from "../../../hooks/shelfSegments";
import {
  AddCircleOutlineRounded,
  DashboardRounded,
  DriveFileRenameOutlineRounded,
  PlaylistAddRounded,
  ZoomInRounded
} from "@mui/icons-material";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { ModifyShelvingDialog } from "../ModifyShelvingDialog";
import { useDeleteShelving } from "../../../hooks/shelvings/useDeleteShelving";
import { StringFormat } from "../../../models/StringFormat";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { ModifyShelfDialog } from "../ModifyShelfDialog";
import { useDeleteShelf } from "../../../hooks/shelves/useDeleteShelf";
import { ModifyShelfSegmentDialog } from "../ModifyShelfSegmentDialog";
import { useDeleteShelfSegment } from "../../../hooks/shelfSegments/useDeleteShelfSegment";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import { ToolbarRawButton } from "../../toolbar/ToolbarRawButton";
import { ToolbarBox } from "../../toolbar/ToolbarBox";
import { LayoutStats } from "../LayoutStats";
import { useGetShelving } from "../../../hooks/shelvings/useGetShelving";

export const LayoutDetail: FunctionComponent<ILayoutDetailProps> = ({
  shelvings,
  setShelvings,
  selectedAlbumIds,
  isLoading,
  setIsLoading,
  onShelfSegmentAlbumSelected,
  onAssignAlbumsToShelfSegmentByFilter,
  onRemoveAlbumFromShelfSegment,
  onSelectedShelving
}) => {
  const theme = useTheme();
  const { getShelving } = useGetShelving();
  const { deleteShelving } = useDeleteShelving();
  const { deleteShelf } = useDeleteShelf();
  const { deleteShelfSegment } = useDeleteShelfSegment();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [isAddShelvingDialogOpen, setIsAddShelvingDialogOpen] = useState(false);
  const [isModifyShelvingDialogOpen, setIsModifyShelvingDialogOpen] = useState(false);
  const [isDeleteShelvingAlertOpen, setIsDeleteShelvingAlertOpen] = useState(false);
  const [isAddShelfDialogOpen, setIsAddShelfDialogOpen] = useState(false);
  const [isModifyShelfDialogOpen, setIsModifyShelfDialogOpen] = useState(false);
  const [isDeleteShelfAlertOpen, setIsDeleteShelfAlertOpen] = useState(false);
  const [isAddShelfSegmentDialogOpen, setIsAddShelfSegmentDialogOpen] = useState(false);
  const [isModifyShelfSegmentDialogOpen, setIsModifyShelfSegmentDialogOpen] = useState(false);
  const [isDeleteShelfSegmentAlertOpen, setIsDeleteShelfSegmentAlertOpen] = useState(false);
  const [selectedShelving, setSelectedShelving] = useState<IShelving>();
  const [selectedShelf, setSelectedShelf] = useState<IShelf>();
  const [selectedShelfSegment, setSelectedShelfSegment] = useState<IShelfSegment>();
  const [refreshSelects, setRefreshSelects] = useState(false);
  const [zoom, setZoom] = useState(0.5);
  useEffect(() => {
    if (!refreshSelects) return;
    setTimeout(() => setRefreshSelects(false), 100);
  }, [refreshSelects]);
  useEffect(() => {
    if (shelvings && shelvings.length > 0 && selectedShelving) {
      const newShelving = shelvings.filter(shelving => shelving.uuid == selectedShelving.uuid)[0];
      setSelectedShelving(newShelving);
      if (selectedShelf) {
        const newShelf = selectedShelving.shelves.filter(
          shelf => shelf.uuid == selectedShelf.uuid
        )[0];
        setSelectedShelf(newShelf);
        if (selectedShelfSegment) {
          const newSegment = selectedShelf.segments.filter(
            segment => segment.uuid == selectedShelfSegment.uuid
          )[0];
          setSelectedShelfSegment(newSegment);
        }
      }
    }
  }, [shelvings]);
  useEffect(() => {
    onSelectedShelving(selectedShelving);
  }, [selectedShelving]);
  const getShelvingDetail = async (uuid: string) => {
    setIsLoading(true);
    const { status, body } = await getShelving(uuid);
    if (StatusCodesHelper.isSuccessful(status)) {
      setShelvings(shelvings.map(shelving => (shelving.uuid == body.uuid ? body : shelving)));
      setSelectedShelving(body);
      setSelectedShelf(undefined);
      setSelectedShelfSegment(undefined);
      setRefreshSelects(true);
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  const removeShelving = async () => {
    setIsLoading(true);
    const { status, body } = await deleteShelving({ uuid: selectedShelving.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setShelvings(shelvings.filter(shelving => shelving.uuid != selectedShelving.uuid));
      setSelectedShelving(undefined);
      setSelectedShelf(undefined);
      setSelectedShelfSegment(undefined);
      setRefreshSelects(true);
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  const removeShelf = async () => {
    setIsLoading(true);
    const { status, body } = await deleteShelf({ uuid: selectedShelf.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setShelvings(
        shelvings.map(shelving => {
          if (shelving.uuid == selectedShelving.uuid) {
            shelving.shelves = shelving.shelves.filter(shelf => shelf.uuid != selectedShelf.uuid);
          }
          return shelving;
        })
      );
      setSelectedShelf(undefined);
      setSelectedShelfSegment(undefined);
      setRefreshSelects(true);
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  const removeShelfSegment = async () => {
    setIsLoading(true);
    const { status, body } = await deleteShelfSegment({ uuid: selectedShelfSegment.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setShelvings(
        shelvings.map(shelving => {
          if (shelving.uuid == selectedShelving.uuid) {
            shelving.shelves.map(shelf => {
              if (shelf.uuid == selectedShelf.uuid) {
                shelf.segments = shelf.segments.filter(
                  segment => segment.uuid != selectedShelfSegment.uuid
                );
              }
              return shelf;
            });
          }
          return shelving;
        })
      );
      setSelectedShelfSegment(undefined);
      setRefreshSelects(true);
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  return (
    <div className={styles.mainContainer}>
      <Card className={styles.breadcrumbDiv}>
        <Stack className={styles.breadcrumbStack} direction={"row"}>
          <Breadcrumbs className={styles.breadcrumb} separator={"•"}>
            {!refreshSelects && (
              <div className={styles.breadcrumbElement}>
                <FormControl sx={{ minWidth: "140px" }} disabled={isLoading}>
                  <InputLabel size={"small"} id={"shelving-label"} sx={{ pointerEvents: "none" }}>
                    {strings.shelving_title}
                  </InputLabel>
                  <Select
                    size={"small"}
                    labelId={"shelving-label"}
                    value={selectedShelving?.uuid ?? ""}
                    label={strings.shelving_title}
                    renderValue={() => <p>{selectedShelving?.name}</p>}
                    onChange={async event => {
                      const shelving = shelvings.filter(
                        shelving => shelving.uuid == event.target.value
                      )[0];
                      if (shelving.shelves !== undefined) {
                        setSelectedShelf(undefined);
                        setSelectedShelfSegment(undefined);
                        setSelectedShelving(shelving);
                        setRefreshSelects(true);
                      } else {
                        await getShelvingDetail(shelving.uuid);
                      }
                    }}
                  >
                    <MenuItem
                      key={"shelving_none"}
                      style={{ color: theme.palette.primary.subtitle }}
                    >
                      {strings.none}
                    </MenuItem>
                    {shelvings.map(shelving => (
                      <MenuItem key={shelving.uuid} value={shelving.uuid}>
                        {shelving.name}
                      </MenuItem>
                    ))}
                    <MenuItem
                      key={"shelving_new"}
                      onClickCapture={event => {
                        setIsAddShelvingDialogOpen(true);
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <SvgIcon className={styles.menuAddIcon} component={AddCircleOutlineRounded} />
                      {strings.new}
                    </MenuItem>
                  </Select>
                </FormControl>
                {selectedShelving && (
                  <ToolbarButton
                    onClick={() => setIsModifyShelvingDialogOpen(true)}
                    tooltip={strings.shelving_edit_title}
                    icon={DriveFileRenameOutlineRounded}
                  />
                )}
              </div>
            )}
            {selectedShelving && !refreshSelects && (
              <div className={styles.breadcrumbElement}>
                <FormControl sx={{ minWidth: "140px" }} disabled={isLoading}>
                  <InputLabel size={"small"} id={"shelf-label"} sx={{ pointerEvents: "none" }}>
                    {strings.shelf_title}
                  </InputLabel>
                  <Select
                    size={"small"}
                    labelId={"shelf-label"}
                    value={selectedShelf?.uuid ?? ""}
                    label={strings.shelf_title}
                    onChange={event => {
                      const shelf = selectedShelving.shelves?.filter(
                        shelf => shelf.uuid == event.target.value
                      )[0];
                      setSelectedShelfSegment(undefined);
                      setSelectedShelf(shelf);
                      setRefreshSelects(true);
                    }}
                  >
                    <MenuItem key={"shelf_none"} style={{ color: theme.palette.primary.subtitle }}>
                      {strings.none}
                    </MenuItem>
                    {selectedShelving.shelves
                      ?.sort((a, b) => a.order - b.order)
                      .map((shelf, index) => (
                        <MenuItem key={shelf.uuid} value={shelf.uuid}>
                          {index + 1}: {shelf.name}
                        </MenuItem>
                      ))}
                    <MenuItem
                      key={"shelf_new"}
                      onClickCapture={event => {
                        setIsAddShelfDialogOpen(true);
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <SvgIcon className={styles.menuAddIcon} component={AddCircleOutlineRounded} />
                      {strings.new}
                    </MenuItem>
                  </Select>
                </FormControl>
                {selectedShelf && (
                  <ToolbarButton
                    onClick={() => setIsModifyShelfDialogOpen(true)}
                    tooltip={strings.shelf_edit_title}
                    icon={DriveFileRenameOutlineRounded}
                  />
                )}
              </div>
            )}
            {selectedShelf && !refreshSelects && (
              <div className={styles.breadcrumbElement}>
                <FormControl sx={{ minWidth: "140px" }} disabled={isLoading}>
                  <InputLabel
                    size={"small"}
                    id={"shelf-segment-label"}
                    sx={{ pointerEvents: "none" }}
                  >
                    {strings.shelf_segment_title}
                  </InputLabel>
                  <Select
                    size={"small"}
                    labelId={"shelf-segment-label"}
                    value={selectedShelfSegment?.uuid ?? ""}
                    label={strings.shelf_segment_title}
                    onChange={event => {
                      const shelfSegment = selectedShelf.segments?.filter(
                        shelfSegment => shelfSegment.uuid == event.target.value
                      )[0];
                      setSelectedShelfSegment(shelfSegment);
                    }}
                  >
                    <MenuItem
                      key={"segment_none"}
                      style={{ color: theme.palette.primary.subtitle }}
                    >
                      {strings.none}
                    </MenuItem>
                    {selectedShelf.segments
                      ?.sort((a, b) => a.order - b.order)
                      .map((shelfSegment, index) => (
                        <MenuItem key={shelfSegment.uuid} value={shelfSegment.uuid}>
                          {index + 1}: {shelfSegment.name}
                        </MenuItem>
                      ))}
                    <MenuItem
                      key={"segment_new"}
                      onClickCapture={event => {
                        setIsAddShelfSegmentDialogOpen(true);
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <SvgIcon className={styles.menuAddIcon} component={AddCircleOutlineRounded} />
                      {strings.new}
                    </MenuItem>
                  </Select>
                </FormControl>
                {selectedShelfSegment && (
                  <ToolbarBox>
                    <ToolbarRawButton
                      onClick={() => setIsModifyShelfSegmentDialogOpen(true)}
                      tooltip={strings.shelf_segment_edit_title}
                      icon={DriveFileRenameOutlineRounded}
                    />
                    <Divider orientation="vertical" flexItem />
                    <ToolbarRawButton
                      onClick={() => onAssignAlbumsToShelfSegmentByFilter(selectedShelfSegment)}
                      tooltip={strings.shelf_segment_assign_filtered_albums}
                      icon={PlaylistAddRounded}
                    />
                  </ToolbarBox>
                )}
              </div>
            )}
          </Breadcrumbs>
          <Box flexGrow={1} />
          <Box className={styles.zoomSlider}>
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <ZoomInRounded />
              <Slider
                value={zoom}
                min={0.23}
                max={1.2}
                step={0.01}
                onChange={(event, newValue) => setZoom(newValue as number)}
              />
            </Stack>
          </Box>
        </Stack>
        {selectedShelving && (
          <Stack className={styles.statsStack} direction={"row"} spacing={1}>
            <p className={styles.tutorialText} style={{ color: theme.palette.primary.subtitle }}>
              {strings.shelving_tutorial}
            </p>
            <LayoutStats
              className={styles.layoutStats}
              shelving={selectedShelving}
              shelf={selectedShelf}
              shelfSegment={selectedShelfSegment}
            />
          </Stack>
        )}
      </Card>
      <Card className={styles.shelvingContainer}>
        {selectedShelving ? (
          <div className={styles.shelving}>
            <ShelvingRepresentation
              shelving={selectedShelving}
              selectedShelf={selectedShelf}
              selectedShelfSegment={selectedShelfSegment}
              selectedAlbumIds={selectedAlbumIds}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onShelfSegmentAlbumSelected={onShelfSegmentAlbumSelected}
              onRemoveAlbumFromShelfSegment={onRemoveAlbumFromShelfSegment}
              shelvingIndex={shelvings
                .map(shelving => shelving.uuid)
                .indexOf(selectedShelving.uuid)}
              zoom={zoom}
            />
          </div>
        ) : (
          <div className={styles.contentUnavailable}>
            <ContentUnavailableNotice
              isLoading={false}
              items={selectedShelving ? undefined : []}
              defaultVisible
              emptyIcon={DashboardRounded}
              emptyTitle={strings.empty_shelvings}
              emptyDescription={strings.empty_shelvings_subtitle}
              addButtonTitle={strings.add_attribute}
              searchText={undefined}
              onAddItem={() => setIsAddShelvingDialogOpen(true)}
            />
          </div>
        )}
      </Card>
      <ModifyShelvingDialog
        open={isAddShelvingDialogOpen}
        setOpen={setIsAddShelvingDialogOpen}
        callback={newShelving => {
          setShelvings([...shelvings, newShelving]);
          setSelectedShelving(newShelving);
          setSelectedShelf(undefined);
          setSelectedShelfSegment(undefined);
          setRefreshSelects(true);
        }}
      />
      <ModifyShelvingDialog
        open={isModifyShelvingDialogOpen}
        setOpen={setIsModifyShelvingDialogOpen}
        shelving={selectedShelving}
        callback={newShelving =>
          setShelvings(
            shelvings.map(shelving => (shelving.uuid == newShelving.uuid ? newShelving : shelving))
          )
        }
        onDelete={() => setIsDeleteShelvingAlertOpen(true)}
      />
      <ModifyShelfDialog
        open={isAddShelfDialogOpen}
        setOpen={setIsAddShelfDialogOpen}
        callback={newShelf =>
          setShelvings(
            shelvings.map(shelving => {
              if (shelving.uuid == selectedShelving.uuid) {
                shelving.shelves.push(newShelf);
              }
              return shelving;
            })
          )
        }
        shelving={selectedShelving}
      />
      <ModifyShelfDialog
        open={isModifyShelfDialogOpen}
        setOpen={setIsModifyShelfDialogOpen}
        shelf={selectedShelf}
        shelving={selectedShelving}
        callback={newShelf =>
          setShelvings(
            shelvings.map(shelving => {
              if (shelving.uuid == selectedShelving.uuid) {
                shelving.shelves = shelving.shelves.map(shelf =>
                  shelf.uuid == selectedShelf.uuid ? newShelf : shelf
                );
              }
              return shelving;
            })
          )
        }
        onDelete={() => setIsDeleteShelfAlertOpen(true)}
      />
      <ModifyShelfSegmentDialog
        open={isAddShelfSegmentDialogOpen}
        setOpen={setIsAddShelfSegmentDialogOpen}
        callback={newSegment =>
          setShelvings(
            shelvings.map(shelving => {
              if (shelving.uuid == selectedShelving.uuid) {
                shelving.shelves = shelving.shelves.map(shelf => {
                  if (shelf.uuid == selectedShelf.uuid) {
                    shelf.segments.push(newSegment);
                  }
                  return shelf;
                });
              }
              return shelving;
            })
          )
        }
        shelf={selectedShelf}
      />
      <ModifyShelfSegmentDialog
        open={isModifyShelfSegmentDialogOpen}
        setOpen={setIsModifyShelfSegmentDialogOpen}
        shelfSegment={selectedShelfSegment}
        shelf={selectedShelf}
        callback={newSegment =>
          setShelvings(
            shelvings.map(shelving => {
              if (shelving.uuid == selectedShelving.uuid) {
                shelving.shelves = shelving.shelves.map(shelf => {
                  if (shelf.uuid == selectedShelf.uuid) {
                    shelf.segments = shelf.segments.map(segment =>
                      segment.uuid == selectedShelfSegment.uuid ? newSegment : segment
                    );
                  }
                  return shelf;
                });
              }
              return shelving;
            })
          )
        }
        onDelete={() => setIsDeleteShelfSegmentAlertOpen(true)}
      />
      <AlertDialog
        isConfirm={true}
        message={StringFormat(strings.shelving_delete_confirmation, selectedShelving?.name ?? "")}
        open={isDeleteShelvingAlertOpen}
        setOpen={setIsDeleteShelvingAlertOpen}
        onConfirm={async () => {
          setIsModifyShelvingDialogOpen(false);
          await removeShelving();
        }}
      />
      <AlertDialog
        isConfirm={true}
        message={StringFormat(strings.shelf_delete_confirmation, selectedShelf?.name ?? "")}
        open={isDeleteShelfAlertOpen}
        setOpen={setIsDeleteShelfAlertOpen}
        onConfirm={async () => {
          setIsModifyShelfDialogOpen(false);
          await removeShelf();
        }}
      />
      <AlertDialog
        isConfirm={true}
        message={StringFormat(
          strings.shelf_segment_delete_confirmation,
          selectedShelfSegment?.name ?? ""
        )}
        open={isDeleteShelfSegmentAlertOpen}
        setOpen={setIsDeleteShelfSegmentAlertOpen}
        onConfirm={async () => {
          setIsModifyShelfSegmentDialogOpen(false);
          await removeShelfSegment();
        }}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </div>
  );
};

export interface ILayoutDetailProps {
  shelvings: IShelving[];
  setShelvings: (shelvings: IShelving[]) => void;
  selectedAlbumIds: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onShelfSegmentAlbumSelected: (shelfSegmentAlbum: IShelfSegmentAlbum) => void;
  onAssignAlbumsToShelfSegmentByFilter: (shelfSegment: IShelfSegment) => void;
  onRemoveAlbumFromShelfSegment: (
    shelfSegmentAlbum: IShelfSegmentAlbum,
    shelvingIndex: number,
    shelfIndex: number,
    shelfSegmentIndex: number,
    albumIndex: number
  ) => void;
  onSelectedShelving: (shelving: IShelving) => void;
}
