import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { ITag } from "../../../../hooks/tags";

export const TagCard: FunctionComponent<ITagCardProps> = ({
  tag,
  selected,
  onClick,
  onContextMenu
}) => {
  const theme = useTheme();
  return (
    <Card className={styles.container} sx={{ height: 60 }} elevation={5}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row"
        }}
        style={{
          borderColor: theme.palette.colors?.highlightBorder,
          borderWidth: 4,
          borderStyle: selected ? "double" : "none"
        }}
        onContextMenu={onContextMenu}
        onClick={onClick}
      >
        <Stack className={styles.textContainer}>
          <p className={styles.name}>{tag.name}</p>
          <p className={styles.description}>{tag.description}</p>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface ITagCardProps {
  tag: ITag;
  selected?: boolean;
  onClick: (event: React.MouseEvent) => void;
  onContextMenu: (event: React.MouseEvent) => void;
}
