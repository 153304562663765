import React, { FunctionComponent, useState } from "react";
import styles from "./styles.module.scss";
import { ITrack } from "../../../hooks/albums";
import { Button, Card, CardActionArea, ListItem, Stack, useTheme } from "@mui/material";
import { Delete, DragIndicator } from "@mui/icons-material";
import { TimeInterval } from "../../../models/TimeInterval";
import classNames from "classnames";
import { strings } from "../../../localization/LocalizedStrings";
import { ModifyGenericAttributeDialog } from "../ModifyGenericAttributeDialog";
import { ModifyLengthAttributeDialog } from "../ModifyLengthAttributeDialog";
import { ModifyFreeTextAttributeDialog } from "../ModifyFreeTextAttributeDialog";
import { StringFormat } from "../../../models/StringFormat";
import { ResponsiveIcon } from "../../ResponsiveIcon";

export const TrackItem: FunctionComponent<ITrackItemProps> = ({ track, onUpdate, onDelete }) => {
  const theme = useTheme();
  const [name, setName] = useState(track.name);
  const [isOpenTrackNameDialog, setIsOpenTrackNameDialog] = useState(false);
  const [position, setPosition] = useState(track.position);
  const [isOpenTrackPositionDialog, setIsOpenTrackPositionDialog] = useState(false);
  const [length, setLength] = useState(track.length);
  const [isOpenTrackLengthDialog, setIsOpenTrackLengthDialog] = useState(false);
  const [extraArtists, setExtraArtists] = useState(track.extraArtists);
  const [isOpenTrackExtraArtistsDialog, setIsOpenTrackExtraArtistsDialog] = useState(false);
  return (
    <ListItem>
      <Card className={styles.container}>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <ResponsiveIcon className={styles.dragIcon} icon={DragIndicator} />
          <Stack sx={{ width: "100%" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <CardActionArea
                className={classNames(styles.actionArea, styles.positionContainer)}
                onClick={() => {
                  setIsOpenTrackPositionDialog(true);
                }}
              >
                <p className={styles.position}>{position ?? "##"}</p>
              </CardActionArea>
              <CardActionArea
                className={styles.actionArea}
                onClick={() => {
                  setIsOpenTrackNameDialog(true);
                }}
              >
                <p className={styles.name}>{name}</p>
              </CardActionArea>
              <CardActionArea
                className={classNames(styles.actionArea, styles.lengthContainer)}
                onClick={() => {
                  setIsOpenTrackLengthDialog(true);
                }}
              >
                <p className={styles.length}>{TimeInterval.toMMSS(length ?? 0)}</p>
              </CardActionArea>
            </Stack>
            <Stack direction={"row"}>
              <CardActionArea
                className={styles.actionArea}
                onClick={() => {
                  setIsOpenTrackExtraArtistsDialog(true);
                }}
              >
                <p
                  className={styles.extraArtists}
                  style={{
                    color: extraArtists
                      ? theme.palette.primary.main
                      : theme.palette.primary.subtitle
                  }}
                >
                  {extraArtists?.join("\r\n") ?? strings.track_extra_artists_placeholder}
                </p>
              </CardActionArea>
              <Button className={styles.deleteButton} onClick={onDelete}>
                <ResponsiveIcon icon={Delete} />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <ModifyGenericAttributeDialog
        title={strings.track_name_title}
        description={strings.track_name_description}
        placeholder={strings.track_name_placeholder}
        missingText={strings.field_missing}
        value={name}
        canClear={false}
        open={isOpenTrackNameDialog}
        setOpen={setIsOpenTrackNameDialog}
        isNumber={false}
        onChange={newValue => {
          setName(newValue);
          track.name = newValue;
          onUpdate();
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.track_position_title}
        description={StringFormat(strings.track_position_description, name)}
        placeholder={strings.track_position_placeholder}
        missingText={strings.field_missing}
        value={position}
        canClear={true}
        open={isOpenTrackPositionDialog}
        setOpen={setIsOpenTrackPositionDialog}
        isNumber={false}
        onChange={newValue => {
          setPosition(newValue);
          track.position = newValue;
          onUpdate();
        }}
        onClearValue={() => {
          setPosition(undefined);
          track.position = undefined;
          onUpdate();
        }}
      />
      <ModifyLengthAttributeDialog
        title={strings.track_length_title}
        description={StringFormat(strings.track_length_description, name)}
        missingText={strings.field_missing}
        value={length}
        open={isOpenTrackLengthDialog}
        setOpen={setIsOpenTrackLengthDialog}
        onChange={newValue => {
          setLength(newValue);
          track.length = newValue;
          onUpdate();
        }}
        onClearValue={() => {
          setLength(undefined);
          track.length = undefined;
          onUpdate();
        }}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.track_extra_artists_title}
        description={StringFormat(strings.track_extra_artists_description, name)}
        placeholder={strings.track_extra_artists_placeholder}
        value={extraArtists?.join("\r\n")}
        open={isOpenTrackExtraArtistsDialog}
        setOpen={setIsOpenTrackExtraArtistsDialog}
        onChange={newValue => {
          const newExtraArtists = newValue.split("\r\n");
          setExtraArtists(newExtraArtists);
          track.extraArtists = newExtraArtists;
          onUpdate();
        }}
        onClearValue={() => {
          setExtraArtists(undefined);
          track.extraArtists = undefined;
          onUpdate();
        }}
      />
    </ListItem>
  );
};

export interface ITrackItemProps {
  track: ITrack;
  onUpdate: () => void;
  onDelete: () => void;
}
