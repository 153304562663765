import { useBackend } from "../../services";
import { AlbumContentType } from "./interfaces";

export const useAddAlbumContent = () => {
  const { post } = useBackend();
  const addAlbumContent = (parameters: IAddAlbumContentParameters) =>
    post("albumContents", parameters);
  return { addAlbumContent };
};

export interface IAddAlbumContentParameters {
  albumId: string;
  quantity?: number;
  albumFormatId?: string;
  name?: string;
  color?: string;
  description?: string;
  playbackSpeedId?: number;
  type: AlbumContentType;
}
