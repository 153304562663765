import { useBackend } from "../../services";

export const useAddShelf = () => {
  const { post } = useBackend();
  const addShelf = (parameters: IAddShelfParameters) => post("shelves", parameters);
  return { addShelf };
};

export interface IAddShelfParameters {
  name: string;
  description?: string;
  shelvingId: string;
}
