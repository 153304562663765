import React, { FunctionComponent } from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.scss";
import { ShortcutItem } from "../ShortcutItem";
import {
  AccountCircleRounded,
  AddCircleOutlineRounded,
  AssignmentOutlined,
  BorderInnerRounded,
  CasinoOutlined,
  CloudDownloadOutlined,
  CollectionsBookmarkRounded,
  DashboardRounded,
  DocumentScannerOutlined,
  FilterAltRounded,
  InventoryRounded,
  LayersRounded,
  PersonAddRounded,
  PublicRounded,
  QueryStatsRounded
} from "@mui/icons-material";
import { strings } from "../../localization/LocalizedStrings";
import { useNavigate } from "react-router-dom";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import classNames from "classnames";
import { useSessionManager, useUser } from "../../hooks/session";

export const ShortcutsGrid: FunctionComponent<IShortcutsGridProps> = ({
  className,
  isPublic,
  minimalist,
  showAttributesShortcut,
  onNewAlbum,
  onImportFromDiscogs,
  onImportFromExcel,
  onFilterAlbums,
  onRandomAlbums,
  onChangeCollection,
  onEditProfile,
  onScanBarcode
}) => {
  const [currentUser] = useUser.useState();
  const { closeSession } = useSessionManager();
  const navigate = useNavigate();
  return (
    <Grid className={classNames(styles.grid, className)} container spacing={2}>
      {(!minimalist || isPublic) && currentUser?.role == "guest" && (
        <ShortcutItem
          icon={PersonAddRounded}
          title={strings.shortcuts_create_user}
          onClick={() => {
            closeSession();
            navigate(RoutesBuilder.signIn());
          }}
        />
      )}
      {!isPublic && onNewAlbum && (
        <ShortcutItem
          icon={AddCircleOutlineRounded}
          title={strings.shortcuts_add_albums}
          onClick={onNewAlbum}
        />
      )}
      {!isPublic && onScanBarcode && (
        <ShortcutItem
          icon={DocumentScannerOutlined}
          title={strings.shortcuts_scan_barcode}
          onClick={onScanBarcode}
        />
      )}
      {!isPublic && (
        <ShortcutItem
          icon={CloudDownloadOutlined}
          title={strings.shortcuts_discogs_import}
          onClick={onImportFromDiscogs}
        />
      )}
      {!isPublic && (
        <ShortcutItem
          icon={BorderInnerRounded}
          title={strings.shortcuts_excel_import}
          onClick={onImportFromExcel}
        />
      )}
      {onFilterAlbums && (
        <ShortcutItem
          icon={FilterAltRounded}
          title={isPublic ? strings.shortcuts_filter_public : strings.shortcuts_filter}
          onClick={onFilterAlbums}
        />
      )}
      {!minimalist && onRandomAlbums && (
        <ShortcutItem
          icon={CasinoOutlined}
          title={strings.shortcuts_random_albums}
          onClick={onRandomAlbums}
        />
      )}
      {!isPublic && !minimalist && (
        <ShortcutItem
          icon={DashboardRounded}
          title={strings.shortcuts_layout}
          onClick={() => navigate(RoutesBuilder.layout())}
        />
      )}
      {!isPublic && !minimalist && currentUser?.role != "guest" && (
        <ShortcutItem
          icon={QueryStatsRounded}
          title={strings.shortcuts_stats}
          onClick={() => navigate(RoutesBuilder.stats())}
        />
      )}
      {!isPublic &&
        !minimalist &&
        (showAttributesShortcut ? (
          <ShortcutItem
            icon={AssignmentOutlined}
            title={strings.shortcuts_attributes}
            onClick={() => navigate(RoutesBuilder.attributes.manage())}
          />
        ) : (
          <ShortcutItem
            icon={CollectionsBookmarkRounded}
            title={strings.shortcuts_albums}
            onClick={() => navigate(RoutesBuilder.albums.list())}
          />
        ))}
      {!isPublic && onChangeCollection && (
        <ShortcutItem
          icon={LayersRounded}
          title={strings.shortcuts_change_collection}
          onClick={onChangeCollection}
        />
      )}
      {(!minimalist || isPublic) && (
        <ShortcutItem
          icon={PublicRounded}
          title={strings.shortcuts_marketplace}
          onClick={() => navigate(RoutesBuilder.marketplace())}
        />
      )}
      {currentUser?.role != "guest" && (
        <ShortcutItem
          icon={AccountCircleRounded}
          title={strings.shortcuts_edit_profile}
          onClick={onEditProfile}
        />
      )}
      <ShortcutItem
        icon={InventoryRounded}
        title={strings.shortcuts_documentation}
        onClick={() => navigate(RoutesBuilder.documentation.home())}
      />
    </Grid>
  );
};

export interface IShortcutsGridProps {
  className?: string;
  isPublic: boolean;
  minimalist?: boolean;
  showAttributesShortcut: boolean;
  onNewAlbum?: () => void;
  onImportFromDiscogs: () => void;
  onImportFromExcel: () => void;
  onFilterAlbums?: () => void;
  onRandomAlbums?: () => void;
  onChangeCollection?: () => void;
  onEditProfile: () => void;
  onScanBarcode?: () => void;
}
