export const doc_collections_public_collections_en = {
  title: "Public collections",
  description: `Public collections are the same as private collections except that they can be shared with other users using links and QR codes, and they can be found at the Exhibition.

  Users can star your public collection and each visit will increment the visits counter.  

  All albums inside a public collection can be accessed by any user and they will be able to see all its data except for the banned fields that you defined for the collection.

  Each day, the public collection and its albums have a chance to appear at the highlight lists on the Exhibition main page.  
  
  `
};
