import { enUS } from "date-fns/locale";
import { enUS as enUSDatePicker } from "@mui/x-date-pickers/locales";
import { doc_albums_en } from "./documentation/albums/Home/strings.en";
import { doc_import_from_discogs_en } from "./documentation/albums/ImportFromDiscogs/strings.en";
import { privacy_policy_en } from "./documentation/PrivacyPolicy/strings.en";
import { terms_en } from "./documentation/TermsOfService/strings.en";
import { doc_filter_albums_en } from "./documentation/albums/FilterAlbums/strings.en";
import { doc_order_albums_en } from "./documentation/albums/OrderAlbums/strings.en";
import { doc_home_en } from "./documentation/Home/strings.en";
import { doc_subscriptions_en } from "./documentation/subscriptions/Home/strings.en";
import { doc_mercado_pago_subscriptions_en } from "./documentation/subscriptions/MercadoPago/strings.en";
import { about_piper_en } from "./documentation/AboutPiper/strings.en";
import { doc_users_sign_up_en } from "./documentation/users/SignUp/strings.en";
import { doc_users_guest_en } from "./documentation/users/SignInAsGuest/strings.en";
import { doc_users_recover_password_en } from "./documentation/users/RecoverPassword/strings.en";
import { doc_albums_add_barcode_en } from "./documentation/albums/AddFromBarcode/strings.en";
import { doc_image_gallery_en } from "./documentation/albums/ImageGallery/strings.en";
import { doc_user_profile_en } from "./documentation/userProfile/Home/strings.en";
import { doc_import_from_excel_en } from "./documentation/albums/ImportFromExcel/strings.en";
import { doc_remove_filtered_albums_en } from "./documentation/albums/RemoveFilteredAlbums/strings.en";
import { doc_collections_en } from "./documentation/collections/Home/strings.en";
import { doc_collections_public_collections_en } from "./documentation/collections/PublicCollections/strings.en";
import { doc_collections_attributes_en } from "./documentation/collections/Attributes/strings.en";
import { doc_artists_en } from "./documentation/albumAttributes/Artists/strings.en";
import { doc_album_attributes_en } from "./documentation/albumAttributes/Home/strings.en";
import { doc_marking_albums_en } from "./documentation/albums/MarkingAlbums/strings.en";

export const strings_en = {
  locale: "en-US",
  currencyLocale: "en-US",
  fnsLocale: enUS,
  datePickerLocale: enUSDatePicker,
  app_name: "Piper",

  /* General */
  yes: "Yes",
  no: "No",
  none: "None",
  menu: "Menu",
  save: "Save",
  close: "Close",
  help: "Help",
  delete: "Delete",
  expand: "Expand",
  shrink: "Shrink",
  discount: "OFF",
  new: "New",
  horizontal: "Horizontal",
  vertical: "Vertical",
  refresh_list: "Refresh list",
  alert_accept: "OK",
  alert_cancel: "Cancel",
  alert_cancel_confirmation: "Are you sure you want to cancel this operation?",
  alert_confirm: "Confirm",
  alert_modify: "Modify",
  alert_delete: "Delete",
  alert_exit: "Exit",
  session_expired: "Your session has expired. Please sign in again.",
  album_singular: "album",
  albums_plural: "albums",
  disc_singular: "disc",
  plural_indicator: "s",
  search: "Search",
  unknown: "Unknown",
  mint: "Mint",
  missing_purchase_date: "Missing purchase date",
  date_missing: "Select a date",
  field_missing: "You have to complete this field",
  field_missing_short: "Complete this",
  field_invalid: "The field is invalid",
  not_number_error: "This field has to be a number",
  open_on_discogs: "View on Discogs",
  open_on_private_collection: "Open on my private collection",
  mark_as_title: "Mark as...",
  remove_mark_title: "Remove mark",
  refresh_image: "Refresh image",
  clear_value: "Clear value",
  clear_values: "Clear values",
  start_date: "Start date",
  end_date: "End date",
  generic_attribute_filter_description: "Enter the value you are looking for:",
  generic_track_name: "New track",
  no_value: "No value",
  currency_title: "Currency",
  share_link_notification: "Link copied to the clipboard!",
  image_upload_success: "Image updated successfully!",
  image_upload_error: "There was an error updating that image. Please try again later.",
  upload_multiple_files_error: "Please select only one file to upload.",
  upload_multiple_images_error: "Please select only one image to upload.",
  album_update_success: "Album updated successfully!",
  album_update_error: "There was an error updating the album. Please try again later.",
  album_created: "The album was added successfully!",
  album_deleted: "The album was removed successfully",
  generic_update_success: "The operation was successful",
  generic_update_error: "The operation could not be completed, please try again later.",
  clear_filter: "Clear filter",
  clear_search: "Clear search",
  order_warning: "⚠️ Unordered",
  back_to_app: "Go back to the app",
  general_queries: "If you have any questions please reach out:",
  network_online_message: "You are back online!",
  network_offline_message: "It seems you are not connected to the internet. Check your connection.",
  qr_code: "QR code",
  qr_code_public_title: "Public QR code",
  qr_code_public_message: "You can share this QR code with other people",
  qr_code_private_title: "Private QR code",
  qr_code_private_message: "This QR code will only work for you",
  download: "Download",
  toggle_style: "Toggle style",
  no_results: "The search didn't bring any results",
  edit_profile_description: "Enter the profile below:",
  edit_description_description: "Enter the description below:",
  about_app_title: "About",
  dropzone_placeholder: "Drop a file here, or tap to select one.",
  more_info: "More info",
  optional_attributes: "Optional attributes",
  push_notifications_permission_notice: "Would you like to receive notifications from Piper?",

  /* NotFoundPage */
  greetings: "Hey there!",
  not_found_subtitle: "We couldn't find\nwhat you are looking for",
  not_found_message: "Click the button below to go back to the app:",
  not_found_queries: "If you think this is a mistake or you have any questions, please reach out:",

  /* MaintenancePage */
  maintenance_message: "The app is under maintenance, it will be available again soon.",

  /* SignupViewController */
  signup_title: "New user",
  signup_subtitle: "Fill out this form",
  signup_description: "You will need to confirm your email address",
  signup_repeat_password_placeholder: "Repeat your password",
  signup_success_title: "Your user is ready!",
  signup_success_message:
    "Check your inbox and enter the code we sent you when you sign in to the app.\n\nWe sent a verification code to {0}",
  recover_password_form_title: "Enter your email and a new password",
  recover_password_form_subtitle: "We will send you a code you will need to enter in the next step",
  recover_password_email_not_found:
    "That email is not registered, you can create a new user with it.",

  /* CreateAlbumViewController */
  ca_title: "New album",
  ca_all_fields: "all required fields",
  ca_name: "the name",
  ca_artist: "the artist",
  ca_country: "the country",
  ca_catalogue_number: "the catalogue number",
  ca_color: "the color",
  ca_store: "the store",
  ca_label: "the label",
  ca_missing_message: "You have to complete {0} of the new album.",

  /* AlbumsTableViewController */
  add_album: "Add album",
  album_title: "Album",
  albums_title: "My albums",
  option_attributes: "Attributes",
  option_stats: "Statistics",
  option_marketplace: "Exhibition",
  option_close_session: "Close session",
  option_preferences: "Preferences",
  option_refresh_albums: "Refresh albums",
  option_fetch_all_albums: "Fetch all albums",
  option_reorder_albums: "Reorder albums",
  option_filter_albums: "Filter albums",
  option_show_index: "Show index",
  option_random_albums: "Random albums",
  option_reel: "Reel view",
  option_search_albums: "Search albums",
  option_view_detail: "See detail",
  option_discogs_import: "Import from Discogs",
  option_excel_import: "Import from spreadsheet",
  option_scan_barcode: "Add from barcode or search",
  random_albums_message: "Enter the number of albums:",
  play_from_youtube: "Play from YouTube",
  album_delete_all: "Remove all filtered albums",
  album_delete_all_confirmation_message:
    "You are about to remove ALL your albums on the currently selected collection. THIS CAN'T BE UNDONE! If you are really sure you want to do this, please write 'Remove all albums' below and confirm.",
  album_delete_all_filter_confirmation_message:
    "You are about to remove ALL albums that are currently filtered on the selected collection. THIS CAN'T BE UNDONE! The albums that fall out of the filter will be untouched. If you are really sure you want to do this, please write 'Remove all albums' below and confirm.",
  album_delete_all_placeholder: "Remove all albums",
  album_delete_confirmation:
    "Are you sure you want to remove the album {0}? This will remove it from ALL collections and you can't reverse it. If you want to remove this album from a specific collection you can do so by going to the album's detail and then to the collections list.",
  no_albums_error: "You can't use this functionality without albums.",
  options: "Options",
  album_options: "Album options",
  album_sleeve_grade: "Jacket:",
  album_media_grade: "Media:",
  open_drawer: "Open menu",
  open_attributes_list: "Open attributes list",
  open_albums_list: "Open albums list",
  add_attribute: "Add new",
  album_highlighted: "Highlight",
  album_sold: "Sold",
  album_upcoming: "Upcoming",
  album_new: "New",
  album_reserved: "Reserved",

  /* AlbumDetailViewController */
  exchange_rate_title: "Exchange rate",
  sell_value_title: "Selling value",
  purchase_price_title: "Purchase price",
  purchase_price_detail: "Price at the time of purchase: {0}",
  purchase_price_detail_dollars: "Price at the time of purchase: {0} ({1})",
  purchase_price_placeholder: "Price at the time of purchase",
  purchase_price_message: "Enter the price at the time of the purchase:",
  artist_title: "Artist",
  label_title: "Label",
  original_year_title: "Original year",
  original_year_message: "Enter the original year of the album:",
  release_year_title: "Release year",
  release_year_message: "Enter the year of this release:",
  country_title: "Country of origin",
  country_short_title: "Country",
  country_message: "Enter the country of origin for this release:",
  sleeve_grade_title: "Jacket grading",
  media_grade_plural_title: "Media gradings",
  media_grade_singular_title: "Media grading",
  revenue_title: "Revenue",
  purchase_date_title: "Purchase date",
  store_title: "Store",
  catalogue_number_title: "Catalogue number",
  catalogue_number_message: "Enter the catalogue number of this release:",
  disc_count_title: "Number of discs",
  disc_count_message: "Enter the number of discs:",
  disc_color_plural_title: "Discs color",
  disc_color_singular_title: "Disc color",
  disc_color_plural_message: "Enter the discs color:",
  disc_color_singular_message: "Enter the disc color:",
  album_format_title: "Format",
  album_type_title: "Album type",
  add_track: "Add track",
  track_count_title: "Number of tracks",
  track_count_title_big: "TRACKS",
  track_count_message: "Enter the number of tracks:",
  album_length_title: "Length",
  album_length_title_big: "LENGTH",
  album_length_message: "Enter the length of this album:",
  discogs_id_title: "Discogs ID",
  discogs_id_title_big: "DISCOGS ID",
  discogs_id_message: "Enter the release ID from Discogs:",
  impact_title: "Rating",
  random_title: "Random",
  notes_title: "Notes",
  notes_detail: "Enter notes for the album:",
  notes_placeholder: "Album notes",
  edit_album_name: "Edit name",
  change_artist: "Change artist",
  change_label: "Change label",
  name_title: "Name",
  name_detail: "Enter the name of the album:",
  name_placeholder: "Example: Slow turning",
  artist_placeholder: "Example: John Hiatt",
  country_placeholder: "Example: USA",
  original_year_placeholder: "Example: 1988",
  release_year_placeholder: "Example: 2018",
  catalogue_number_placeholder: "Example: B0027601-01",
  disc_color_placeholder: "Example: Black",
  impact_placeholder: "\u2606\u2606\u2606\u2606\u2606",
  sleeve_grade_placeholder: "Example: VG+",
  media_grade_placeholder: "Example: NM",
  no_album_selected: "Select an album to see its detail",
  album_no_collections: "This album doesn't have any particular collections associated to it yet",
  album_no_location: "This album doesn't have a location yet",
  share_album: "Share album",
  get_private_link: "Get private link",
  copy_album: "Copy album",
  album_shared_loading: "Loading content...",

  /* Image Gallery */
  image_gallery: "Image gallery",
  add_new_image: "Add new image",
  front_cover: "Front cover",
  back_cover: "Back cover",
  spine: "Spine",
  image_name: "Image name",
  image_name_message: "Enter a name for this image. It can only be 30 characters or less.",
  image_name_placeholder: "Example: Insert front",
  option_view_image: "View image detail",
  modify_image: "Edit image",
  move_left: "Move to the left",
  move_right: "Move to the right",
  move_up: "Move upwards",
  move_down: "Move downwards",
  edit_image_name: "Edit name",
  image_delete_confirmation: "Are you sure you want to remove this image?",

  /* TrackListDialog */
  track_list_title: "Tracklist",
  fetch_from_discogs: "Fetch from Discogs",
  fetch_from_discogs_disabled:
    "You need to set the Discogs ID on this album to use this functionality",
  track_extra_artists_placeholder: "Track credits",
  track_name_title: "Name",
  track_name_description: "Enter the track name:",
  track_name_placeholder: "Example: Telegraph Road",
  track_position_title: "Position",
  track_position_description: 'Enter the position for track "{0}":',
  track_position_placeholder: "Example: A1",
  track_length_title: "Length",
  track_length_description: 'Enter the length for track "{0}":',
  track_extra_artists_title: "Credits",
  track_extra_artists_description: 'Enter the credits for track "{0}":',
  fetch_tracks_from_discogs_confirmation:
    "Are you sure you want us to fetch the tracks for this album from Discogs? This will overwrite any custom changes you have made to the tracklist.",

  /* AlbumsFilterViewController */
  order_by_title: "Order by",
  order_direction_title: "Direction",
  order_desc_title: "Descendant",
  order_asc_title: "Ascendant",
  filter_description:
    "Select the filter elements you want to apply and send them to the right side panel. Click on confirm to apply the filter.",
  filter_album_format_title: "FORMAT",
  filter_album_type_title: "ALBUM TYPE",
  filter_tag_title: "TAG",
  filter_gatefold_title: "GATEFOLD",
  filter_insert_title: "INSERT",
  filter_lyrics_title: "LYRICS",
  filter_clean_title: "PLAYED",
  filter_artist_title: "ARTIST",
  filter_store_title: "STORE",
  filter_label_title: "LABEL",
  filter_impact_title: "RATING",
  filter_sleeve_grade_title: "JACKET GRADING",
  filter_media_grade_title: "MEDIA GRADING",
  filter_sell_value_title: "SELLING VALUE",
  filter_country_title: "COUNTRY OF ORIGIN",
  filter_color_title: "COLOR",
  filter_original_year_title: "ORIGINAL YEAR",
  filter_release_year_title: "RELEASE YEAR",
  filter_sell_status_title: "MARKED AS...",
  filter_confirm: "Apply filter",
  sort_by_sell_value: "Selling value (Name)",
  sort_by_sell_value_dollars: "Selling value (Value)",
  add_to_filter: "Add to filter",
  remove_from_filter: "Remove from filter",

  /* AttributeListCollectionViewController */
  attribute_artists_title: "Artists",
  attribute_stores_title: "Stores",
  attribute_labels_title: "Labels",
  attribute_exchange_rates_title: "Exchange rates",
  attribute_sell_values_title: "Selling values",
  attribute_impacts_title: "Ratings",
  attribute_sleeve_grades_title: "Jacket gradings",
  attribute_media_grades_title: "Media gradings",
  attribute_album_types_title: "Album types",
  attribute_album_formats_title: "Formats",
  artist_edit: "Edit artist",
  artist_view: "View artist",
  label_edit: "Edit label",
  label_view: "View label",
  empty_artists: "You don't have any artists yet",
  empty_stores: "You don't have any stores yet",
  empty_tags: "You don't have any tags yet",
  empty_labels: "You don't have any labels yet",
  empty_exchange_rates: "You don't have any exchange rates yet",
  empty_sell_values: "You don't have any selling values yet",
  empty_impacts: "Ratings couldn't be retrieved",
  empty_sleeve_grades: "Jacket gradings couldn't be retrieved",
  empty_media_grades: "Media gradings couldn't be retrieved",
  empty_album_types: "You don't have any album types yet",
  empty_album_formats: "You don't have any formats yet",
  empty_artists_public: "There are no artists to filter from",
  empty_stores_public: "There are no stores to filter from",
  empty_tags_public: "There are no tags to filter from",
  empty_labels_public: "There are no labels to filter from",
  empty_exchange_rates_public: "There are no exchange rates to filter from",
  empty_sell_values_public: "There are no selling values to filter from",
  empty_album_types_public: "There are no album types to filter from",
  empty_album_formats_public: "There are no album formats to filter from",
  empty_collections: "You don't have any collections yet",
  empty_user_notifications: "You don't have new notifications",
  empty_attribute_description: "Start by adding a new one!",
  empty_search_title: `No results for "{0}"`,
  empty_search_description: "Check the spelling or try a new search",
  add_exchange_rate_by_time_lapse: "Add by time lapse",
  add_exchange_rate_by_time_lapse_disabled:
    "To add exchange rates by a time lapse please upgrade your account subscription.",

  /* Email General */
  email_used: "This address is already in use ❌",
  email_valid: "This address is available ✔️",
  email_invalid: "This address doesn't look correct ❌",
  email_error_invalid: "The email address is invalid. Please verify it and try again.",
  email_error_duplicated: "The email address is already in use by another user.",

  /* Password General */
  password_very_weak: "The password is too weak ❌",
  password_weak: "The password is weak ❌",
  password_valid: "The password is acceptable ✔️",
  password_strong: "The password is strong ✔️",
  password_match: "The passwords match ✔️",
  password_mismatch: "The passwords don't match ❌",
  password_missing: "Enter your password.",
  password_repeat_missing: "Repeat the password you entered on the last field.",
  password_mismatch_error: "The passwords don't match. Please verify them and try again.",
  password_weak_error:
    "The password you entered doesn't meet the minimum security requirements. It must have 8 characters minimum and contain lowercase, uppercase, numbers, and/or special characters. Please verify it and try again.",

  /* LoginViewController */
  login_unknown_error: "We could't sign you in. Please try again later.",
  login_create_user: "Create a new user",
  login_create_user_or_login: "Create a new user or sign in",
  login_guest: "Sign in as guest",
  login_preferences: "Preferences",
  login_again: "Sign back in",
  login_again_subtitle: "We need you to enter your password again",
  login_again_message: "We need you to sign in again in order to perform this action",
  login_wrong_credentials:
    "The credentials seem to be incorrect. Please verify your inputs and try again.",
  login_title: "Welcome!",
  login_email: "Username or email",
  login_password: "Password",
  login_submit: "Sign in",
  login_subtitle: "Enter your credentials or",
  login_new_user_button: "create a new user",
  login_guest_info: "You can also sign in as a guest",
  login_recover_password: "Recover password",
  login_forgot_password: "Forgot your password?",
  guest_title: "Guest",
  signin_with_google: "Continue with Google",
  signin_with_apple: "Continue with Apple",
  signup_email: "Email",
  signup_oauth_intro: `You are one step away from creating your account! Please just confirm you agree to the statement below:
    `,
  signup_accept_first: "I accept the ",
  signup_accept_second: " and I agree to the ",
  signup_accept_third: ", and I am at least 13 years old.",

  /* SignupSuccessViewController */
  verification_code_sent: "We sent a code to\n{0}",

  /* ArtistDetailViewController */
  artist_death_date: "DEATH DATE",

  /* AVAudioPlayerViewController */
  player_unknown_error: "An unknown error occurred",
  player_autoplay_on: "Autoplay is now on",
  player_autoplay_off: "Autoplay is now off",

  /* SettingsTableViewController */
  settings_user_title: "User",
  settings_preferences_title: "Preferences",
  settings_advanced_title: "Application",
  settings_cache_confirmation:
    "You are about to delete all images and data stored in the cache memory.",
  settings_should_show_impact_descriptions: "Show rating descriptions",
  settings_should_show_album_id: "Show album ID",
  settings_order_new_albums: "Order new albums automatically",
  settings_show_decimals: "Show decimal places on certain financial amounts",
  settings_receive_email_notifications: "Receive notifications by email",
  my_external_systems_title: "Already associated apps",
  catering_external_systems_title: "Available apps",
  confirm_external_system_deletion: 'Are you sure you want to dissociate the app "{0}"?',

  /* EmailTableViewController */
  email_current_title: "Current email address",
  email_pending_title: "Confirmation pending",
  email_confirm_new: "Confirm new address",
  email_discard_new: "Discard new address",
  email_new_title: "New email address",
  email_confirmed: "Verified ✔️",
  email_pending: "Pending ⚠️",

  /* ChangePasswordViewController */
  password_new_mismatch: "The current password doesn't match, please check and try again.",
  password_update_success: "The password was updated successfully.",
  password_update_error_current: "Enter your current password.",
  password_update_error_new: "Enter a new password.",
  password_update_error_repeat: "Repeat the new password.",

  /* VerifyEmailCodeViewController */
  vec_title: "Verify email",
  vec_subtitle: "Check your inbox and enter the code we sent you",
  vec_code_placeholder: "Code",
  vec_resend_code: "Resend verification code",
  vec_confirmation:
    "We sent a new verification code and it should arrive in the next minutes. Please check your inbox.",
  vec_invalid_code: "The code you entered is incorrect. Verify it and try again.",
  vec_success: "Your new email address was confirmed successfully.",
  recover_password_success: "Your new password is set!",

  /* StatsViewController */
  stats_clean_title: "PLAYED",
  stats_gatefold_title: "GATEFOLD",
  stats_insert_title: "INSERT",
  stats_lyrics_title: "LYRICS",
  stats_impacts_title: "RATINGS",
  stats_sleeve_grades_title: "JACKET GRADINGS",
  stats_media_grades_title: "MEDIA GRADINGS",
  stats_pie_yes: "YES",
  stats_pie_no: "NO",
  stats_last_updated: "Last updated",
  stats_loading: "This can take some time for large collections, please be patient...",
  stats_error:
    "There was an error fetching your stats for this collection. Please try again later.",

  /* Attributes General */
  attributes_title: "Attributes",

  /* ModifyExchangeRateViewController */
  mer_new_title: "New exchange rate",
  mer_delete_confirmation: "Are you sure you want to delete this exchange rate?",
  mer_format_error: "Check that the exchange rate is a real number.",
  mer_rate_title: "EXCHANGE RATE (USD -> {0})",
  mer_rate_placeholder: "1 USD equals to # {0}",
  mer_date_title: "DATE",
  date_range_title: "Date range",
  mer_tl_title: "Fetch exchange rates automatically",
  mer_tl_description:
    "Enter below a range of dates and we will automatically fetch an exchange rate every 7 days. Make sure you don't pick a range of more than a year. If you want to load more than a year worth of exchange rates, please repeat this process as many times as you need.",

  /* ModifyLabelViewController */
  ml_new_title: "New label",
  ml_delete_confirmation: "Are you sure you want to delete this label?",
  ml_missing_error: "Enter the name of the new label.",
  ml_placeholder: "Example: Asylum Records",
  ml_field_title: "LABEL NAME",

  /* ModifyStoreViewController */
  ms_new_title: "New store",
  ms_delete_confirmation: "Are you sure you want to delete this store?",
  ms_missing_error: "Enter the name of the new store.",
  ms_placeholder: "Example: Ebay",
  ms_field_title: "STORE NAME",
  ms_address_title: "ADDRESS",
  ms_address_placeholder: "Address",

  /* ModifySellValueViewController */
  msv_new_title: "New selling value",
  msv_delete_confirmation: "Are you sure you want to delete this selling value?",
  msv_missing_error: "Enter all the required fields.",
  msv_group_title: "GROUP NAME",
  msv_group_placeholder: "Examples: A, 4, 3.2",
  msv_dollars_title: "VALUE [USD]",
  msv_dollars_placeholder: "Selling value in USD",
  msv_description_title: "GROUP DESCRIPTION",
  msv_description_placeholder: "Example: Rare albums in pretty good shape",
  msv_group: "Group",

  /* ModifyArtistViewController */
  ma_new_title: "New artist",
  ma_delete_confirmation: "Are you sure you want to delete this artist?",
  ma_missing_error: "Enter the name of the artist.",
  ma_name_title: "ARTISTIC NAME",
  ma_name_placeholder: "Example: Little Richard",
  ma_real_name_title: "REAL NAME",
  ma_real_name_placeholder: "Example: Richard Wayne Penniman",
  ma_website_title: "WEBSITE",
  ma_website_button_title: "Visit webpage",
  ma_website_placeholder: "Example: https://piper.ldelosheros.com",
  ma_discogs_id_title: "DISCOGS ID",
  ma_discogs_id_placeholder: "Example: 12345",
  ma_profile_title: "PROFILE",
  ma_profile_placeholder: "Profile",
  ma_birth_date_title: "BIRTH DATE",
  ma_death_date_title: "DEATH DATE",

  /* ModifyTimeLengthAlertController */
  mtl_title: "Album length",
  mtl_message: "Enter the album length:",
  mtl_missing_error: "Enter the new album length.",
  mtl_calculate: "Calculate from tracklist",

  /* ModifyAlbumTypeViewController */
  mat_new_title: "New album type",
  mat_delete_confirmation: "Are you sure you want to delete this album type?",
  mat_missing_error: "Enter the name of the new album type.",
  mat_field_title: "ALBUM TYPE NAME",
  mat_placeholder: "Examples: Studio, Compilation",
  mat_show_on_album_list_title: "SHOW ON ALBUM LIST",

  /* ModifyAlbumFormatViewController */
  maf_new_title: "New format",
  maf_delete_confirmation: "Are you sure you want to delete this format?",
  maf_missing_error: "Enter the name and short name of the new format.",
  maf_name_title: "NAME",
  maf_name_placeholder: "Example: Long Play",
  maf_short_name_title: "SHORT NAME",
  maf_short_name_placeholder: "Example: LP",

  /* Album contents */
  album_contents_title: "Content",
  add_content: "Add content",
  album_content_disc: "Disc / Media",
  album_content_other: "Other",
  playback_speed: "Playback speed",
  album_content_quantity: "Quantity",
  album_content_type: "Type of content",
  album_content_delete_confirmation: "Are you sure you want to remove this album content?",

  /* Tags */
  tag_title: "Tag",
  new_tag_title: "New tag",
  tags_title: "Tags",

  /* UpdateEmailDialog */
  update_email_title: "Email",
  new_email_title: "NEW EMAIL ADDRESS",
  new_email_placeholder: "New email address",
  email_pending_message: "Check your inbox, we sent a verification code to {0}",

  /* UpdatePasswordDialog */
  update_password_title: "Password",
  old_password_title: "CURRENT PASSWORD",
  old_password_placeholder: "Current password",
  new_password_title: "NEW PASSWORD",
  new_password_placeholder: "New password",
  repeat_password_placeholder: "Repeat the new password",

  /* Stats */
  stats_general_title: "General",
  stats_financials_title: "Finances",
  stats_album_total: "Albums total",
  stats_clean_total: "Played albums",
  stats_gatefold_total: "Gatefold albums",
  stats_insert_total: "Albums with insert",
  stats_lyrics_total: "Albums with lyrics",
  stats_colored_total: "Colored albums",
  stats_average_original_year: "Average original year",
  stats_average_release_year: "Average release year",

  stats_discs_total: "Number of discs",
  stats_length_total: "Total length",
  stats_average_album_length: "Average album length",
  stats_average_disc_length: "Average disc length",
  stats_average_track_length: "Average track length",
  stats_tracks_by_album: "Tracks by album",

  stats_artist_total: "Number of artists",
  stats_artist_most_albums: "Artist with most albums",
  stats_store_most_albums: "Store with most albums",
  stats_label_most_albums: "Label with most albums",

  stats_purchase_price_total: "Total purchase price",
  stats_sell_value_total: "Total selling value",
  stats_revenue_total: "Total revenue",

  /* DiscogsImport */
  import_from_discogs: "Import from Discogs",
  discogs_import_collection_folders_title: "Collection folders",
  discogs_import_collection_import: "Collection import",
  discogs_import_cancel_confirmation:
    "Are you sure you want to cancel the import process? You will only lose the albums pending to be imported.",
  discogs_import_subtitle: "Your collection is being imported!",
  discogs_import_description:
    "You can track the progress below. The import process will sleep for around one minute once in a while. This is due to Discogs' service restrictions and is completely normal.",
  discogs_import_info:
    'We are importing all albums from the folder named "{0}", with a total of {1} albums.',
  cancel_import: "Cancel import",
  discogs_folders_subtitle: "Select the folder from which to import the albums",
  discogs_folders_description:
    "After that you will be able to select the Piper collection you want to import them to\n\nYou can always cancel this process now and go to Discogs to manage your collection folders first",
  discogs_folders_confirmation:
    'You are about to import {0} albums from the folder "{1}" into the collection "{2}". We will try to complete as much information as possible but some fields will have to be completed or corrected manually.\n\nThis process will add new albums, artists, labels and formats as necessary, trying to match by name with the ones you already have. All your previous data will remain untouched.\n\nThe average duration of this process can be estimated to 2 minutes per 50 albums. You can cancel it at anytime and you will be able to keep the albums that were already imported.',
  discogs_import_cancelled: "You cancelled the import process.",
  discogs_import_errored: "An error occurred while importing your albums. You may try again later.",
  discogs_import_finished: "The process was completed successfully!",
  discogs_auth_message:
    "In order to import your collection from Discogs we need you to give permission to Piper on the Discogs web page. Please click the following button to open Discogs in a new tab and then come back here once the access is granted.",
  discogs_auth_button: "Go to Discogs",
  discogs_auth_retry: "Retry",
  discogs_should_skip_duplicates: "Skip albums with same release ID",
  discogs_should_upload_image:
    "Upload images from Discogs (This will increase the process duration)",
  discogs_should_upload_image_non_premium: "Upload images from Discogs (Only for premium users)",

  /* ExcelImport */
  excel_import_title: "Import from spreadsheet",
  excel_import_message: `Select a spreadsheet file with the 'xlsx' extension. The albums inside the spreadsheet have to comply to a specific format, select the help button on the top right corner of this window to know more.
  
After confirmation you will be able to select the Piper collection you want to import them to.

You can use the following template and fill it with your albums:
  `,
  excel_import_confirmation: `You are about to import all albums from the file "{0}" into the collection "{1}". If any row doesn't comply with the required format, the import process will skip that album.\n\nThis process will add new albums, artists, labels and other attributes as necessary, trying to match by name with the ones you already have. All your previous data will remain untouched.`,
  excel_import_download_template: "Download template",
  excel_import_template_url:
    "https://piper-public-images.s3.us-east-2.amazonaws.com/Piper-Template.xlsx",
  excel_import_problems: `We encountered some albums with problems, but the rest were successfully added to your collection. Find below a list of the albums that couldn't be imported:
  
{0}
  `,

  /* Collections */
  collection_title: "Collection",
  collection_new_title: "New collection",
  add_collection: "Add collection",
  share_collection: "Share collection",
  star_collection: "Star collection",
  unstar_collection: "Remove star",
  collections_title: "Collections",
  collections_tease: "Soon you'll be able to manage multiple collections!",
  collection_delete_confirmation:
    'Are you sure you want to remove the collection named "{0}"? All albums associated with this collection will still be available on the "All albums" collection.',
  private_collection: "Private",
  public_collection: "Public",
  collection_missing_field: "You have to complete {0} of the collection.",
  collection_assign_filtered_albums: "Assign filtered albums to this collection",
  collection_assign_filtered_albums_confirm:
    "Are you sure you want to assign the currently filtered albums to this collection? This will only add the albums that were previously not present on the selected collection.",
  collection_assign_filtered_albums_success: "The albums were assigned successfully!",
  collection_all_albums: "All albums",
  collection_associate: "Associate collection",
  collection_disassociate: "Disassociate",
  collection_disassociate_confirmation:
    'Are you sure you want to disassociate this album from the collection named "{0}"?',
  album_view_on_exhibition: "View at the exhibition",
  collection_description: "Description",
  collection_discount: "Discount",
  collection_create_visibility:
    "You will be able to convert this to a public collection once you associated some albums to it.",
  collection_visibility: "Visibility",
  collection_banned_fields: "Hidden fields",
  collection_banned_fields_description:
    "Select a field to hide when other people browse this collection (Only for public collections)",
  collection_banned_field_is_clean: "Played",
  collection_banned_field_is_gatefold: "Gatefold",
  collections_manage_title: "Collections",
  collection_modify: "Edit collection",
  collection_name_placeholder: "Example: Albums on sale",
  collection_image_info: "For best results, try to use images with an aspect ratio similar to 5:1.",

  /* PreferencesViewController */
  email_title: "Email",
  theme_option: "Interface style",
  dark_mode: "Dark",
  black_mode: "Black",
  light_mode: "Light",
  blue_mode: "Blue",
  orange_mode: "Orange",
  language_title: "Language (Idioma)",
  app_version_title: "App version",
  clear_cache_title: "Clear cache",
  about_title: "About this app",
  contact_us: "Contact",
  contact_support_title: "Contact support",
  support_mail: "piper.support@ldelosheros.com",
  reorder_tutorial: `To reorder your albums, drag and drop them to the desired position on the list. You can only reorder albums within the same artist.
  
Make sure you have the 'All albums' collection selected in order to reorder your album list. You also need that the list is ordered by artist with no filters, or only artists filtered.
  
Since this functionality brings performance issues in some devices, this is disabled on mobile. Please use the desktop app or the iOS native app to reorder your albums.
  `,
  credits: "© 2024 Lautaro de los Heros, Piper™",
  credits_short: "© 2024 Piper™",

  /* User Profile */
  user_profile_title: "User profile",
  username_title: "Username",
  modify_username_message:
    "Enter your username. It has to start with a letter and be 8-20 characters long. No special characters allowed except for underscores.",
  no_color_name_title: "New color",
  no_color_names_title: "Colors for non-colored discs",
  no_color_name_message: "Enter a color so it does not count as a colored disc in your statistics:",
  no_color_name_removal_confirmation: 'Are you sure you want to remove the color "{0}"?',
  countries_to_hide_title: "Countries to hide from album list",
  countries_to_hide_message: "Enter a country that you wish to hide from the album list:",
  countries_to_hide_removal_confirmation: 'Are you sure you want to remove the country "{0}"?',
  bio_title: "Bio",
  bio_message: "Enter your bio. It will be visible to other users.",
  public_collections_title: "Public collections",
  remove_account_title: "Remove my account",
  remove_account_confirmation_message:
    "You are about to remove your user account permanently. THIS CAN'T BE UNDONE! All your albums, attributes, images, and profile will be gone forever. If you are really sure you want to do this, please write 'Remove my account' below and confirm.",

  /* User Role */
  update_role_title: "Subscription plan",
  role_admin: "Administrator",
  role_guest: "No plan",
  role_common: "Free plan",
  role_collector: "Collector",
  role_premium: "Premium",
  subscription_free: "FREE",
  subscription_pay_info:
    "You will be charged on the first day of every month except for the first payment that will be done whenever you subscribe. The price of the first installment will be adjusted accordingly depending on the amount of days remaining.",
  subscription_pay_info_apple:
    "Pay using the Piper app available on the App Store. The subscription is managed by Apple.",
  total: "Total",
  subscription_pay_with_card: "Pay with credit card",
  subscription_pay_with_mercadopago: "Pay with Mercado Pago",
  subscription_pay_with_mercadopago_alert: `We are going to redirect you to Mercado Pago where you will be able to complete your payment.
    
IMPORTANT: It's important that you come back to Piper using the button they provide at the end of the process so we can associate your subscription to our app. If you fail to do so you won't see the subscription on Piper, but don't worry, just contact us in that case and we will fix it for you as soon as possible.`,
  subscription_pay_with_apple: "Pay with the App Store",
  subscription_payment_method_title: "Payment method",
  per_month: "per month",
  pay_option: "Option",
  pay_option_mercado_pago: "Mercado Pago",
  pay_option_apple: "Apple",
  subscription_success:
    "Your new subscription is ready! Please enter your credentials again to continue.",

  /* My Subscription */
  my_subscription_title: "My subscription",
  subscription_manage_in_mercadopago: "Manage in Mercado Pago",
  subscription_manage_in_apple: "Manage in App Store",
  subscription_status: "Subscription status",
  subscription_end_date: "Your subscription will end",
  subscription_change: "Change my subscription",
  subscription_renew: "Subscribe again",
  payment_last_status: "Last payment status",
  payment_next_date: "Next payment",
  payment_last_update: "Last updated",
  payment_approved: "Approved",
  payment_authorized: "Authorized",
  payment_cancelled: "Cancelled",
  payment_charged_back: "Charged back",
  payment_in_mediation: "In mediation",
  payment_in_process: "Processing",
  payment_pending: "Pending",
  payment_refunded: "Refunded",
  payment_rejected: "Rejected",
  payment_paused: "Paused",
  payment_is_processing_message:
    "Your payment is being processed. Check back in a few hours to see its status.",

  /* Marketplace */
  highlighted_collections_title: "Collections",
  highlighted_albums_title: "Albums",
  highlighted_users_title: "Users",
  public_username_prefix: "Collection from",
  marketplace_search_placeholder: "Albums, collections, users",
  no_public_collections: "No public collections to show",

  /* UserProfileDialog */
  edit_avatar: "Edit avatar",
  user_since_title: "User since",

  /* Shortcuts */
  shortcuts_title: "Shortcuts",
  shortcuts_add_albums: "Add a new album",
  shortcuts_scan_barcode: "Add an album from a barcode or search",
  shortcuts_discogs_import: "Import albums from my Discogs account",
  shortcuts_excel_import: "Import albums from a spreadsheet",
  shortcuts_stats: "See statistics of my albums",
  shortcuts_albums: "See my albums",
  shortcuts_attributes: "Add a new artist, label, exchange rate and more...",
  shortcuts_marketplace: "Explore collections from other users",
  shortcuts_filter: "Filter my albums on this collection",
  shortcuts_filter_public: "Filter the albums on this collection",
  shortcuts_random_albums: "Get a random album from this collection",
  shortcuts_change_collection: "View albums from a different collection",
  shortcuts_create_user: "Create a new user or sign in",
  shortcuts_search_title: "There are no albums to show",
  shortcuts_documentation: "Check the documentation for help or tips",
  shortcuts_edit_profile: "Customize my user profile",
  shortcuts_layout: "Organize the physical location of my albums",

  /* User Notifications */
  user_notifications_title: "Notifications",
  mark_as_read: "Mark as read",
  mark_as_unread: "Mark as unread",

  /* ScanBarcode */
  scan_barcode_title: "Scan the barcode present on the album",
  scan_barcode_description:
    "Alternatively, enter the barcode manually or any information about the album",
  camera_title: "Scan a barcode",
  flashlight: "Flashlight",
  open_camera: "Open camera",
  switch_camera: "Switch camera",

  /* Messages */
  messages_title: "Comments",
  messages_new_title: "New comment",
  messages_reply: "Reply",
  messages_more: "More comments",
  messages_replying_to: "You are replying to a comment from {0}",
  messages_reply_more: "1  reply",
  messages_replies_more: "{0}  replies",
  message_removed: "<<Message removed>>",
  album_no_messages: "This album doesn't have comments yet",
  view_message: "View comment",
  messages_private_notice: "If you want to reply to a comment visit this album at the exhibition.",
  messages_confirm_removal: `Are you sure you want to remove the message from {0} and all its replies? The message says:
  
  "{1}"`,
  messages_report: "Report message",

  /* Reports */
  report_new: "New report",
  report_description: "Tell us more about this report:",
  report_success: "Report sent successfully! Thanks for your support.",

  /* Chats */
  chats_title: "Conversations",
  chat_placeholder: "Type your message here...",
  chat_send: "Send message",
  chat_new: "Send a message",
  chat_select: "Select a conversation",
  chat_new_user: "Start a new conversation",
  chat_empty_title: "You don't have any conversations yet",
  chat_empty_description: "Start chatting with other users by using the button on the toolbar",
  chat_messages_empty_title: "There are no messages to display yet",
  chat_messages_empty_description:
    "You will see here your message history with this user once you start chatting",
  public_user_profiles_empty: "Type a username in the search bar to find users",
  chat_new_message_notification: "You have a new message",
  open_chats_list: "Open conversations list",

  /* Layout */
  layout_title: "Layout",
  shelving_title: "Shelving",
  shelving_new_title: "New shelving",
  shelving_tutorial:
    "Drag the spine of the album you see in the list to any of the segments to locate it. Drag the spines within the segment to rearrange them. Click on any album to select it and see its position on the shelving, or right click to access its detail.",
  shelf_title: "Shelf",
  shelf_new_title: "New shelf",
  shelf_segment_title: "Segment",
  shelf_segment_new_title: "New segment",
  shelf_segment_position_title: "Position",
  highlight_filtered_albums: "Highlight filtered albums",
  shelving_edit_title: "Edit shelving",
  shelf_edit_title: "Edit shelf",
  shelf_segment_edit_title: "Edit segment",
  shelving_orientation: "Shelving orientation",
  shelving_theme: "Shelving material",
  shelving_cedar: "Cedar",
  shelving_oak: "Oak",
  shelving_ebony: "Ebony",
  shelving_walnut: "Walnut",
  shelving_blue: "Blue",
  shelving_name_placeholder: "Example: Main vinyl storage",
  shelving_delete_confirmation: `Are you sure you want to remove the shelving named "{0}"? You won't lose any albums associated with this shelving.`,
  shelf_name_placeholder: "Example: Top shelf",
  shelf_delete_confirmation: `Are you sure you want to remove the shelf named "{0}"? You won't lose any albums associated with this shelf.`,
  shelf_segment_name_placeholder: "Example: First segment",
  shelf_segment_delete_confirmation: `Are you sure you want to remove the segment named "{0}"? You won't lose any albums associated with this segment.`,
  empty_shelvings: "Select a shelving to display it here",
  empty_shelvings_subtitle: "Or add a new one using the button below",
  shelf_album_added_message: "Album added to the shelf segment successfully",
  shelf_album_removed_message: "Album removed from the shelf segment successfully",
  shelf_album_duplicate_error: "You already have that album on that shelf segment!",
  shelf_remove_album: "Remove from shelf segment",
  shelf_segment_album_shape_title: "Album's shape",
  shelf_segment_album_shape_long_play: "12″",
  shelf_segment_album_shape_ten_inches: "10″",
  shelf_segment_album_shape_single: "7″",
  shelf_segment_album_shape_cd: "CD",
  shelf_segment_album_shape_box_set: "Box set",
  shelf_segment_assign_filtered_albums: "Assign filtered albums to this segment",
  shelf_segment_add_album_title: "Adding albums",
  album_location_title: "Location",
  album_position_title: "Position",
  layout_cannot_move_message: "To make modifications to this shelving, go to the Layout page.",

  /* Documentation */
  documentation: "Documentation",
  doc_home: doc_home_en,
  doc_users_sign_up: doc_users_sign_up_en,
  doc_users_guest: doc_users_guest_en,
  doc_users_recover_password: doc_users_recover_password_en,
  doc_albums: doc_albums_en,
  doc_filter_albums: doc_filter_albums_en,
  doc_order_albums: doc_order_albums_en,
  doc_import_from_discogs: doc_import_from_discogs_en,
  doc_import_from_excel: doc_import_from_excel_en,
  doc_image_gallery: doc_image_gallery_en,
  doc_marking_albums: doc_marking_albums_en,
  doc_remove_filtered_albums: doc_remove_filtered_albums_en,
  doc_albums_add_barcode: doc_albums_add_barcode_en,
  doc_album_attributes: doc_album_attributes_en,
  doc_artists: doc_artists_en,
  doc_collections: doc_collections_en,
  doc_collections_attributes: doc_collections_attributes_en,
  doc_collections_public_collections: doc_collections_public_collections_en,
  doc_user_profile: doc_user_profile_en,
  doc_subscriptions: doc_subscriptions_en,
  doc_mercado_pago_subscriptions: doc_mercado_pago_subscriptions_en,
  doc_more_options_title: "More things you can do",
  about_piper: about_piper_en,
  privacy_policy: privacy_policy_en,
  terms: terms_en,
  tutorial_video_id: "KYEkl_BWJ4I"
};
