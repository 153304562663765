import { useEffect, useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { isEqual } from "lodash";

export const useGetPaginatedAttributesByPage = <T, D>(
  pageSize: number,
  parameters: any,
  backendRequest: (parameters: any) => Promise<{ body: any; status: number }>,
  setLoading: (loading: boolean) => void,
  isEnabled: boolean,
  invertedOrder: boolean,
  dependencies: D[] = []
) => {
  const [items, setItems] = useState<T[]>([]);
  const [lastUuid, setLastUuid] = useState<string>();
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fetchItems = () => {
    let ignoreResponse = false;
    setLoading(true);
    setIsFetching(true);
    const oldParameters = parameters;
    backendRequest({ pageSize, lastUuid, ...parameters }).then(({ body, status }) => {
      if (ignoreResponse || !isEqual(oldParameters, parameters)) return;
      if (StatusCodesHelper.isSuccessful(status)) {
        setNoMoreItems(body.length < pageSize);
        if (body.length >= 0) {
          setItems(oldArray => (invertedOrder ? [...body, ...oldArray] : [...oldArray, ...body]));
          if (body.length > 0) {
            setLastUuid(invertedOrder ? body[0].uuid : body.slice(-1)[0].uuid);
          }
        }
      } else if (!StatusCodesHelper.isSessionExpired(status)) {
        setNoMoreItems(true);
        toast.error(body.message);
      }
      setLoading(false);
      setIsFetching(false);
    });
    return () => {
      ignoreResponse = true;
    };
  };
  useEffect(() => {
    if (isFetching || noMoreItems || !isEnabled) {
      return;
    }
    fetchItems();
  }, dependencies);
  const resetItems = () => {
    setItems([]);
    setNoMoreItems(false);
    setLastUuid(undefined);
  };
  return [items, setItems, resetItems, noMoreItems] as const;
};
