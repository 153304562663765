import React, { FunctionComponent, useState } from "react";
import { Box, Dialog, DialogContent, Divider, Grow, Skeleton, Toolbar } from "@mui/material";
import { CloseRounded, DownloadRounded, Style, StyleOutlined } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { LazyImageAspectFit } from "../LazyImageAspectFit";
import { strings } from "../../localization/LocalizedStrings";
import { saveAs } from "file-saver";
import { ToolbarBox } from "../toolbar/ToolbarBox";
import { ToolbarRawButton } from "../toolbar/ToolbarRawButton";
import { Buffer } from "buffer";

export const ImageDetailDialog: FunctionComponent<IImageDetailDialog> = ({
  title,
  message,
  isOpen,
  setIsOpen,
  image,
  filename,
  onStyleButtonClicked
}) => {
  const [styleToggle, setStyleToggle] = useState(true);
  const downloadImage = () => {
    if (!image) return;
    const buffer = Buffer.from(image, "base64");
    const blob = new Blob([buffer], { type: "image/png" });
    saveAs(blob, filename);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{title}</h3>
        <Box flexGrow={1} />
        <ToolbarBox>
          {onStyleButtonClicked && (
            <ToolbarRawButton
              tooltip={strings.toggle_style}
              icon={styleToggle ? Style : StyleOutlined}
              onClick={() => {
                setStyleToggle(state => !state);
                onStyleButtonClicked();
              }}
            />
          )}
          {onStyleButtonClicked && <Divider orientation="vertical" flexItem />}
          <ToolbarRawButton
            onClick={() => downloadImage()}
            tooltip={strings.download}
            icon={DownloadRounded}
          />
          <Divider orientation="vertical" flexItem />
          <ToolbarRawButton
            onClick={() => setIsOpen(false)}
            tooltip={strings.close}
            loading={!image}
            icon={CloseRounded}
          />
        </ToolbarBox>
      </Toolbar>
      <DialogContent className={styles.content}>
        {message && <p className={styles.message}>{message}</p>}
        {image ? (
          <LazyImageAspectFit alt={"image"} image={image} defaultImage={image} canMagnify />
        ) : (
          <Skeleton variant="rectangular" sx={{ aspectRatio: "1", height: "auto" }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export interface IImageDetailDialog {
  title: string;
  message?: string;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  image?: string;
  filename: string;
  onStyleButtonClicked?: () => void;
}
