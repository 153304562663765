import {
  AlbumOutlined,
  CheckBoxRounded,
  CheckCircleRounded,
  ControlCameraRounded,
  MicExternalOnRounded,
  MonetizationOnRounded,
  PriceChangeRounded,
  QueueMusicRounded,
  SellRounded,
  StarRounded,
  StoreRounded
} from "@mui/icons-material";
import { AttributeType } from "../components/attributes/AttributeList";
import { strings } from "../localization/LocalizedStrings";

export const AttributeUtilities = {
  getAttributeTitle: (attributeType: AttributeType) => {
    switch (attributeType) {
      case AttributeType.ARTISTS:
        return strings.attribute_artists_title;
      case AttributeType.STORES:
        return strings.attribute_stores_title;
      case AttributeType.LABELS:
        return strings.attribute_labels_title;
      case AttributeType.ALBUM_FORMATS:
        return strings.attribute_album_formats_title;
      case AttributeType.ALBUM_TYPES:
        return strings.attribute_album_types_title;
      case AttributeType.SLEEVE_GRADES:
        return strings.attribute_sleeve_grades_title;
      case AttributeType.MEDIA_GRADES:
        return strings.attribute_media_grades_title;
      case AttributeType.SELL_VALUES:
        return strings.attribute_sell_values_title;
      case AttributeType.IMPACTS:
        return strings.attribute_impacts_title;
      case AttributeType.EXCHANGE_RATES:
        return strings.attribute_exchange_rates_title;
      case AttributeType.TAGS:
        return strings.tags_title;
    }
  },
  getAttributeIcon: (attributeType: AttributeType) => {
    switch (attributeType) {
      case AttributeType.ARTISTS:
        return MicExternalOnRounded;
      case AttributeType.STORES:
        return StoreRounded;
      case AttributeType.LABELS:
        return QueueMusicRounded;
      case AttributeType.ALBUM_FORMATS:
        return ControlCameraRounded;
      case AttributeType.ALBUM_TYPES:
        return AlbumOutlined;
      case AttributeType.SLEEVE_GRADES:
        return CheckBoxRounded;
      case AttributeType.MEDIA_GRADES:
        return CheckCircleRounded;
      case AttributeType.SELL_VALUES:
        return PriceChangeRounded;
      case AttributeType.IMPACTS:
        return StarRounded;
      case AttributeType.EXCHANGE_RATES:
        return MonetizationOnRounded;
      case AttributeType.TAGS:
        return SellRounded;
    }
  }
};
