import { useBackend } from "../../services";

export const useUpdatePassword = () => {
  const { put } = useBackend();
  const updatePassword = (parameters: IUpdatePasswordParameters) =>
    put("user/password", parameters);
  return { updatePassword };
};

export interface IUpdatePasswordParameters {
  oldPassword: string;
  password: string;
}
