import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogContent, Grow, Stack, Toolbar } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { strings } from "../../localization/LocalizedStrings";
import { TextField } from "../TextField";
import { AlertDialog } from "../AlertDialog";
import { StatusCodes } from "http-status-codes";
import { useCheckEmail } from "../../hooks/checkEmail";
import { useTimer } from "react-timer-hook";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useUpdateEmail } from "../../hooks/users/useUpdateEmail";
import { useDeletePendingEmail } from "../../hooks/users/useDeletePendingEmail";
import { VerifyEmailCodeDialog } from "../VerifyEmailCodeDialog";
import { StringFormat } from "../../models/StringFormat";
import { FieldTitle } from "../Text/FieldTitle";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import {
  Check,
  CloseRounded,
  DeleteForever,
  HelpOutlineRounded,
  SaveRounded
} from "@mui/icons-material";
import { useUser } from "../../hooks/session";
import { LoginAgainDialog } from "../LoginAgainDialog";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { toast } from "react-toastify";

export const UpdateEmailDialog: FunctionComponent<IUpdateEmailDialogProps> = ({
  open,
  setOpen,
  onClose
}) => {
  const [currentUser, setCurrentUser] = useUser.useState();
  const { updateEmail } = useUpdateEmail();
  const { deletePendingEmail } = useDeletePendingEmail();
  const checkEmail = useCheckEmail();
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isVerifyCodeDialogOpen, setIsVerifyCodeDialogOpen] = useState(false);
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(strings.email_invalid)
      .required(strings.email_invalid)
      .test("isUsed", strings.email_error_duplicated, () => !isEmailUsed)
  });
  const initialValues: IFormProps = {
    email: ""
  };
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    onSubmit: async ({ email }) => {
      setIsLoading(true);
      const { body, status } = await updateEmail({ email: email });
      setIsLoading(false);
      if (StatusCodesHelper.isSuccessful(status)) {
        setCurrentUser(body);
        formik.resetForm();
        setAlertMessage(StringFormat(strings.email_pending_message, email));
        setIsAlertOpen(true);
      } else {
        if (status == StatusCodes.BAD_REQUEST) {
          setAlertMessage(strings.email_error_invalid);
          setIsAlertOpen(true);
        } else if (status == StatusCodes.FORBIDDEN) {
          setIsSignInAgainDialogOpen(true);
        } else if (status == 520) {
          setAlertMessage(strings.email_error_duplicated);
          setIsAlertOpen(true);
        } else {
          setAlertMessage(body.message);
          setIsAlertOpen(true);
        }
      }
    },
    validationSchema: validationSchema
  });
  const expiryTimestamp = () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 1);
    return date;
  };
  const { pause: stopEmailTimer, restart: restartEmailTimer } = useTimer({
    expiryTimestamp: expiryTimestamp(),
    onExpire: () => {
      checkEmail(formik.values.email).then(isEmailUsed => {
        setIsEmailUsed(isEmailUsed);
      });
    }
  });
  useEffect(() => {
    stopEmailTimer();
  }, []);
  useEffect(() => {
    setIsEmailUsed(false);
    if (formik.values.email.length < 5 || formik.errors.email != undefined) {
      stopEmailTimer();
      return;
    }
    restartEmailTimer(expiryTimestamp());
  }, [formik.values.email]);
  const onDelete = async () => {
    setIsLoading(true);
    const { body, status } = await deletePendingEmail();
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrentUser(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{strings.update_email_title}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.userProfile(
              "email"
            )}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.email_current_title.toUpperCase()}</FieldTitle>
          <div className={styles.textField} style={{ paddingBottom: 16 }}>
            <TextField
              name={"currentEmail"}
              readOnly={true}
              size="small"
              value={currentUser?.email}
              helperText={
                currentUser?.isEmailConfirmed ? strings.email_confirmed : strings.email_pending
              }
            />
          </div>
          {currentUser?.emailPending && (
            <div>
              <FieldTitle>{strings.email_pending_title.toUpperCase()}</FieldTitle>
              <div className={styles.textField} style={{ paddingBottom: 32 }}>
                <TextField
                  name={"pendingEmail"}
                  readOnly={true}
                  size="small"
                  value={currentUser?.emailPending}
                />
                <Stack direction={"row"} justifyContent={"end"}>
                  <ToolbarButton
                    tooltip={strings.email_discard_new}
                    disabled={isLoading}
                    icon={DeleteForever}
                    onClick={onDelete}
                  />
                  <ToolbarButton
                    tooltip={strings.email_confirm_new}
                    disabled={isLoading}
                    icon={Check}
                    onClick={() => setIsVerifyCodeDialogOpen(true)}
                  />
                </Stack>
              </div>
            </div>
          )}
          <FieldTitle>{strings.email_new_title.toUpperCase()}</FieldTitle>
          <div
            className={styles.textField}
            style={{ paddingBottom: 16, display: "flex", flexDirection: "row" }}
          >
            <TextField
              id="email"
              name="email"
              type="email"
              size="small"
              placeholder={strings.new_email_placeholder}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <ToolbarButton
              className={styles.saveButton}
              disabled={isLoading || !formik.isValid}
              onClick={() => {
                formik.handleSubmit();
              }}
              tooltip={strings.save}
              icon={SaveRounded}
            />
          </div>
        </DialogContent>
      </Dialog>
      <VerifyEmailCodeDialog
        email={currentUser?.email ?? ""}
        callback={() => {
          toast.success(strings.generic_update_success);
          handleClose();
        }}
        open={isVerifyCodeDialogOpen}
        setOpen={setIsVerifyCodeDialogOpen}
      />
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          await formik.submitForm();
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </FormikProvider>
  );
};

interface IFormProps {
  email: string;
}

export interface IUpdateEmailDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  onClose?: () => void;
}
