import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";

export const useGetVideoId = () => {
  const { get } = useBackend();
  return async (query: string) => {
    const curatedQuery = encodeURIComponent(query);
    const { status, body } = await get(`youtube/${curatedQuery}`);
    if (StatusCodesHelper.isSuccessful(status)) {
      return body.items[0]?.id.videoId;
    } else {
      return undefined;
    }
  };
};
