import styles from "./styles.module.scss";
import { useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { strings } from "../../localization/LocalizedStrings";
import { RoutesBuilder } from "../../models/RoutesBuilder";

export const AcceptanceText: FunctionComponent<{ withIntro?: boolean }> = ({ withIntro }) => {
  const theme = useTheme();
  return (
    <div className={styles.mainDiv}>
      {withIntro && <p>{strings.signup_oauth_intro}</p>}
      <p>
        {strings.signup_accept_first}
        {
          <a
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.policy()}`}
            target={"_blank"}
            style={{ color: theme.palette.primary.subtitle }}
          >
            {strings.privacy_policy.title}
          </a>
        }
        {strings.signup_accept_second}
        {
          <a
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.terms()}`}
            target={"_blank"}
            style={{ color: theme.palette.primary.subtitle }}
          >
            {strings.terms.title}
          </a>
        }
        {strings.signup_accept_third}
      </p>
    </div>
  );
};
