type Env = "production" | "development" | "test";

export const BackendConfig = {
  production: {
    url: "https://piper-cloud-run-xefokvu6ea-ue.a.run.app"
  },
  development: {
    url: "http://localhost:5001"
  },
  test: {
    url: "http://localhost:5001"
  }
}[(process.env.REACT_APP_STAGE || process.env.NODE_ENV) as Env];
