import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardActionArea,
  Dialog,
  DialogContent,
  Grid,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { CollectionBannedFieldsManager } from "../../../models/CollectionBannedFieldsManager";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { CloseRounded } from "@mui/icons-material";

export const CollectionBannedFieldsDialog: FunctionComponent<
  ICollectionBannedFieldsDialogProps
> = ({ open, setOpen, onSelect, alreadySelected }) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{strings.collection_banned_fields}</h3>
        <Box flexGrow={1} />
        <ToolbarButton tooltip={strings.close} icon={CloseRounded} onClick={() => setOpen(false)} />
      </Toolbar>
      <DialogContent sx={{ paddingTop: 0, paddingBottom: 0, overflow: "hidden" }}>
        <p className={styles.description}>{strings.collection_banned_fields_description}</p>
        <Grid container spacing={2} className={styles.grid}>
          {CollectionBannedFieldsManager.shared.bannedFields().map(bannedField => (
            <Grid item xs={6} key={bannedField.id}>
              <Card>
                <CardActionArea
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    ":disabled": {
                      color: theme.palette.colors?.disabled,
                      opacity: 0.8
                    }
                  }}
                  disabled={alreadySelected.includes(bannedField.id)}
                  onClick={() => {
                    onSelect(bannedField.id);
                  }}
                >
                  <p className={styles.fieldName}>{bannedField.name}</p>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export interface ICollectionBannedFieldsDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSelect: (value: string) => void;
  alreadySelected: string[];
}
