import { useBackend } from "../../services";
import { ICollection } from "./interfaces";
import { useEffect, useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";

export const useGetStandardCollections = <T>(
  setLoading: (loading: boolean) => void,
  isEnabled: boolean,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const [collections, setCollections] = useState<[ICollection]>();
  useEffect(() => {
    if (!isEnabled) return;
    setLoading(true);
    get("collections/standard").then(({ body, status }) => {
      if (StatusCodesHelper.isSuccessful(status)) {
        setCollections(body);
      }
      setLoading(false);
    });
  }, dependencies);
  return collections;
};
