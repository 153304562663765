import { strings } from "../localization/LocalizedStrings";
import { ICollection } from "../hooks/collections";
import { IAlbum } from "../hooks/albums";

export const CollectionHelper = {
  getVisibilityDescription: (collection: ICollection) => {
    switch (collection.visibility) {
      case "private":
        return strings.private_collection;
      case "public":
        return strings.public_collection;
    }
  },
  albumBelongsTo: (collection: ICollection | undefined, album: IAlbum) => {
    if (!collection) return false;
    const uuids = album.collections?.map(collection => collection.uuid);
    return uuids?.includes(collection.uuid);
  }
};
