import { useBackend } from "../../services";

export const useAddShelfSegment = () => {
  const { post } = useBackend();
  const addShelfSegment = (parameters: IAddShelfSegmentParameters) =>
    post("shelfSegments", parameters);
  return { addShelfSegment };
};

export interface IAddShelfSegmentParameters {
  name: string;
  description?: string;
  shelfId: string;
}
