import mainButtonsToolbarImage from "../../../../images/documentation/main-buttons-toolbar.png";

export const doc_albums_add_barcode_es = {
  title: "Escanear código de barras y buscar",
  home_title: "Agregar álbumes desde código de barras o búsqueda",
  description: `Piper tiene la opción de escanear el código de barras de un álbum directo de su sobre y buscarlo en Discogs. De la mism forma podés buscar en la base de datos de Discogs usando el campo de texto libre especificando parte de un título, nombre de artista, número de catálogo, etc.
  
  Usá el botón de "Agregar desde código de barras o buscar" en el menú desplegable o el botón de escáner en la barra de herramientas para abrir la ventana de búsqueda.  
  
`,
  main_buttons_toolbar_image: mainButtonsToolbarImage,
  scan_title: "Escanear un código de barras",
  scan_description: `Seleccioná el botón de la cámara para encenderla o apagarla. Poné el código de barras frente a la cámara hasta que sea reconocido y la ventana empiece a cargar.

  La aplicación va a buscar en Discogs el código de barras y mostrar los resultados.  

  Seleccioná el álbum que quieras y va a ser agregado a Piper automáticamente.  
  
`,
  search_title: "Buscar un álbum",
  search_description: `Podés especificar cualquier tipo de información sobre un álbum en el campo de búsqueda y vamos a intentar encontrar resultados en la base de datos de Discogs.
  
  Una vez que ingreses el texto deseado seleccioná el botón de 'iniciar búsqueda'. Los resultados, si es que hay, van a aparecer en una lista debajo.
  
  Seleccioná el álbum que quieras y va a ser agregado a Piper automáticamente. 
  
`
};
