import React, { FunctionComponent, ReactElement } from "react";
import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Toolbar
} from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { ContainedButton } from "../ContainedButton";
import styles from "./styles.module.scss";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { ToolbarButton } from "../toolbar/ToolbarButton";

export const AlertDialog: FunctionComponent<IAlertDialogProps> = ({
  title,
  message,
  isConfirm,
  open,
  setOpen,
  onConfirm,
  onCancel,
  confirmTitle,
  cancelTitle,
  helpLink,
  link,
  linkButtonText,
  hasCloseButton,
  maxWidth
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} maxWidth={maxWidth} TransitionComponent={Grow}>
      <Toolbar>
        <h3>{title ?? strings.app_name}</h3>
        <Box flexGrow={1} />
        {helpLink && (
          <ToolbarButton
            href={`${window.location.protocol}//${window.location.host}${helpLink}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
        )}
        {hasCloseButton && (
          <ToolbarButton onClick={handleClose} tooltip={strings.close} icon={CloseRounded} />
        )}
      </Toolbar>
      <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <p className={styles.message}>{message}</p>
        {link && (
          <ContainedButton className={styles.moreInfoButton} href={link}>
            {linkButtonText ?? strings.more_info}
          </ContainedButton>
        )}
      </DialogContent>
      <DialogActions>
        {isConfirm && (
          <ContainedButton
            className={styles.button}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              handleClose();
            }}
          >
            {cancelTitle ?? strings.alert_cancel}
          </ContainedButton>
        )}
        {!hasCloseButton && (
          <ContainedButton
            className={styles.button}
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
              handleClose();
            }}
          >
            {isConfirm ? confirmTitle ?? strings.alert_confirm : strings.alert_accept}
          </ContainedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export interface IAlertDialogProps {
  title?: string;
  message: string | ReactElement;
  isConfirm?: boolean;
  open: boolean;
  setOpen: (state: boolean) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmTitle?: string;
  cancelTitle?: string;
  helpLink?: string;
  link?: string;
  linkButtonText?: string;
  hasCloseButton?: boolean;
  maxWidth?: Breakpoint;
}
