import { useBackend } from "../../services";
import { ISellValue } from "./interfaces";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import {
  IGetPaginatedAttributeProps,
  IPublicGetPaginatedAttributeProps
} from "../attributes/interfaces";
import { useCollectionManager } from "../collections/useCollectionManager";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";

export const useGetSellValues = (isPublic: boolean, setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const { getCurrentCollection } = useCollectionManager();
  const [currentUser] = useUser.useState();
  const getSellValues = (parameters: IGetPaginatedAttributeProps) => get("sellValues", parameters);
  const getPublicSellValues = (parameters: IPublicGetPaginatedAttributeProps) =>
    get("sellValues/shared/public", parameters);
  return useGetPaginatedAttributes<ISellValue>(
    PageSizeHelper.sellValues(currentUser?.role ?? UserRole.common),
    isPublic ? getPublicSellValues : getSellValues,
    setLoading,
    true,
    getCurrentCollection(isPublic)?.uuid
  );
};
