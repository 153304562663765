import React, { FunctionComponent } from "react";
import { IShelf } from "../../../hooks/shelves";
import styles from "./styles.module.scss";
import { IShelfSegment } from "../../../hooks/shelfSegments";
import { IShelving } from "../../../hooks/shelvings";
import { useTheme } from "@mui/material";
import { ToolbarBox } from "../../toolbar/ToolbarBox";
import { useShelvingStats } from "../../../hooks/shelvings/useShelvingStats";
import { capitalizeFirstLetter } from "../../../models/StringFormat";
import { strings } from "../../../localization/LocalizedStrings";
import classNames from "classnames";

export const LayoutStats: FunctionComponent<ILayoutStatsProps> = ({
  className,
  shelving,
  shelf,
  shelfSegment
}) => {
  const theme = useTheme();
  const { shelvingAlbumCount, shelfAlbumCount, shelfSegmentAlbumCount } = useShelvingStats();
  return (
    <ToolbarBox
      className={classNames(className, styles.statsBox)}
      style={{
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
      }}
    >
      {shelving && (
        <p className={styles.statsLabel}>
          {capitalizeFirstLetter(strings.albums_plural)}:{" "}
          <b>
            {shelfSegment
              ? shelfSegmentAlbumCount(shelfSegment)
              : shelf
                ? shelfAlbumCount(shelf)
                : shelvingAlbumCount(shelving)}
          </b>
        </p>
      )}
    </ToolbarBox>
  );
};

export interface ILayoutStatsProps {
  className?: string;
  shelving: IShelving | undefined;
  shelf: IShelf | undefined;
  shelfSegment: IShelfSegment | undefined;
}
