import { useBackend } from "../../services";
import { ICurrency } from "../currencies";

export const useModifyUserProfile = () => {
  const { put } = useBackend();
  const modifyUserProfile = (parameters: IModifyUserProfileParameters) =>
    put("userProfiles", parameters);
  return { modifyUserProfile };
};

export interface IModifyUserProfileParameters {
  uuid?: string;
  username?: string;
  bio?: string;
  currency?: ICurrency;
  noColorNames?: string[];
  countriesToHide?: string[];
  shouldShowImpactDescriptions?: boolean;
  shouldShowAlbumId?: boolean;
  orderNewAlbums?: boolean;
  showDecimals?: boolean;
  receiveEmailNotifications?: boolean;
}
