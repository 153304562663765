export const StatusCodesHelper = {
  isSuccessful: (statusCode: number) => {
    return statusCode >= 200 && statusCode <= 299;
  },
  isSessionExpired: (statusCode: number) => {
    return statusCode == 444;
  },
  isOnMaintenance: (statusCode: number) => {
    return statusCode == 503;
  },
  isDuplicatedResource: (statusCode: number) => {
    return statusCode == 520;
  }
};
