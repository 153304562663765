import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";

export const DocumentationSignInAsGuest: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_users_guest.title}</h1>
      <p>{strings.doc_users_guest.description}</p>
      <img
        className={styles.imageFloatLeft}
        style={{ maxWidth: "16%" }}
        src={strings.doc_users_guest.options_image}
      />
      <img
        className={styles.imageFloatLeft}
        style={{ maxWidth: "32%" }}
        src={strings.doc_users_guest.drawer_image}
      />
    </div>
  );
};
