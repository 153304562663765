import { strings } from "../localization/LocalizedStrings";
import { ShelvingTheme } from "../hooks/shelvings";
import cedarImage from "../images/shelvings/shelving_cedar.jpg";
import oakImage from "../images/shelvings/shelving_oak.jpg";
import ebonyImage from "../images/shelvings/shelving_ebony.jpg";
import walnutImage from "../images/shelvings/shelving_walnut.jpg";
import blueImage from "../images/shelvings/shelving_blue.jpg";

export const ShelvingUtilities = {
  getThemeString: (theme: ShelvingTheme) => {
    switch (theme) {
      case "cedar":
        return strings.shelving_cedar;
      case "oak":
        return strings.shelving_oak;
      case "ebony":
        return strings.shelving_ebony;
      case "walnut":
        return strings.shelving_walnut;
      case "blue":
        return strings.shelving_blue;
    }
  },
  getThemeImage: (theme: ShelvingTheme) => {
    switch (theme) {
      case "cedar":
        return cedarImage;
      case "oak":
        return oakImage;
      case "ebony":
        return ebonyImage;
      case "walnut":
        return walnutImage;
      case "blue":
        return blueImage;
    }
  }
};
