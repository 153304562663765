import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Dialog, Divider, Grow, Stack, useTheme } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { StringFormat } from "../../../models/StringFormat";
import styles from "./styles.module.scss";
import { StoresDesktop } from "../../attributes/stores/StoresDesktop";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { SellValuesDesktop } from "../../attributes/sellValues/SellValuesDesktop";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import { format } from "date-fns";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../hooks/isMobile";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import {
  useCollectionManager,
  useCurrentPublicCollection
} from "../../../hooks/collections/useCollectionManager";
import { ISellValue } from "../../../hooks/sellValues";
import { IStore } from "../../../hooks/stores";
import { usePublicUserProfile, useUserProfile } from "../../../hooks/session";

export const AlbumFinancialsListSection: FunctionComponent<IAlbumFinancialsListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { updateAlbum } = useUpdateAlbum();
  const [currentUserProfile] = useUserProfile.useState();
  const [currentPublicUserProfile] = usePublicUserProfile.useState();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const collectionManager = useCollectionManager();
  const currencyFormatter = useCurrencyFormatter();
  const [isStoresDialogOpen, setIsStoresDialogOpen] = useState(false);
  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const [isPurchasePriceDialogOpen, setIsPurchasePriceDialogOpen] = useState(false);
  const [isLoadingPurchasePrice, setIsLoadingPurchasePrice] = useState(false);
  const [isSellValuesDialogOpen, setIsSellValuesDialogOpen] = useState(false);
  const [isLoadingSellValue, setIsLoadingSellValue] = useState(false);
  const [isPurchaseDatePickerOpen, setIsPurchaseDatePickerOpen] = useState(false);
  const [isLoadingPurchaseDate, setIsLoadingPurchaseDate] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  let isRevenueNegative = false;
  const userProfile = isPublic ? currentPublicUserProfile : currentUserProfile;
  const getRevenue = (purchasePrice: number, sellValue: number) => {
    const revenue = sellValue - purchasePrice;
    isRevenueNegative = revenue < 0;
    return currencyFormatter.format(revenue, userProfile, isPublic, 2);
  };
  const getPurchaseDate = () => {
    if (album.purchaseDate) {
      return format(album.purchaseDate * 1000, "d MMMM yyyy", { locale: strings.fnsLocale });
    }
    return strings.unknown;
  };
  const updatePurchasePrice = async (newValue: number | null) => {
    setIsLoadingPurchasePrice(true);
    const { body, status } = await updateAlbum({
      uuid: album.uuid,
      purchasePrice: newValue
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album = body;
      onAttributeUpdated(album);
    }
    setIsLoadingPurchasePrice(false);
  };
  const updateSellValue = async (newValue: ISellValue | undefined) => {
    setIsSellValuesDialogOpen(false);
    setIsLoadingSellValue(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      sellValueId: newValue?.uuid ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album = body;
      onAttributeUpdated(album);
    }
    setIsLoadingSellValue(false);
  };
  const updateStore = async (newValue: IStore | undefined) => {
    setIsStoresDialogOpen(false);
    setIsLoadingStore(true);
    const { status, body } = await updateAlbum({
      uuid: album.uuid,
      storeId: newValue?.uuid ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.storeId = newValue?.uuid;
      album.store = newValue;
      onAttributeUpdated(album);
    }
    setIsLoadingStore(false);
  };
  const sellValueDetail = () => {
    if (!album.sellValue?.pesos) return strings.unknown;
    const currentCollection = collectionManager.getCurrentCollection(isPublic);
    if (currentCollection && currentCollection.discount > 0) {
      return (
        <Stack>
          <p className={styles.oldPrice} style={{ color: theme.palette.secondary.main }}>
            {currencyFormatter.format(album.sellValue?.pesos, userProfile, isPublic, 2)}
          </p>
          <p className={styles.newPrice}>
            {currencyFormatter.format(
              (album.sellValue?.pesos ?? 0) * (1 - currentCollection.discount),
              userProfile,
              isPublic,
              2
            )}
          </p>
        </Stack>
      );
    } else {
      return currencyFormatter.format(album.sellValue?.pesos, userProfile, isPublic, 2);
    }
  };
  return (
    <div>
      {!(isPublic && album.purchasePrice === undefined) && (
        <div>
          <RightDetailListItem
            disabled={isPublic}
            title={strings.purchase_price_title}
            detail={
              album.purchasePriceToday != null
                ? currencyFormatter.format(album.purchasePriceToday, userProfile, isPublic, 2)
                : album.purchasePrice == null
                  ? strings.unknown
                  : album.purchaseDate
                    ? strings.unknown
                    : `${strings.unknown} (${strings.missing_purchase_date})`
            }
            subtitle={
              album.purchasePrice != null
                ? album.purchasePriceDollars != null
                  ? StringFormat(
                      strings.purchase_price_detail_dollars,
                      currencyFormatter.format(album.purchasePrice, userProfile, isPublic, 2),
                      currencyFormatter.format(
                        album.purchasePriceDollars,
                        userProfile,
                        isPublic,
                        2,
                        "USD"
                      )
                    )
                  : StringFormat(
                      strings.purchase_price_detail,
                      currencyFormatter.format(album.purchasePrice, userProfile, isPublic, 2)
                    )
                : undefined
            }
            isLoading={isLoadingPurchasePrice}
            onClick={() => {
              setIsPurchasePriceDialogOpen(true);
            }}
          />
          <Divider variant={"inset"} />
        </div>
      )}
      {!(isPublic && album.sellValue === undefined) && (
        <div>
          <RightDetailListItem
            disabled={isPublic}
            title={strings.sell_value_title}
            detail={sellValueDetail()}
            subtitle={(() => {
              if (!album.sellValue) return undefined;
              const currentCollection = collectionManager.getCurrentCollection(isPublic);
              const dollars = currencyFormatter.format(
                album.sellValue.dollars * (1 - (currentCollection?.discount ?? 0)),
                userProfile,
                isPublic,
                2,
                "USD"
              );
              return isPublic
                ? dollars
                : `${strings.msv_group} ${album.sellValue.group} (${dollars})`;
            })()}
            onClick={() => {
              setIsSellValuesDialogOpen(true);
            }}
            isLoading={isLoadingSellValue}
          />
          <Divider variant={"inset"} />
        </div>
      )}
      {!(isPublic && currentPublicCollection?.bannedFields?.includes("revenue")) && (
        <div>
          <RightDetailListItem
            disabled={isPublic}
            title={strings.revenue_title}
            detail={
              album.purchasePriceToday !== undefined && album.sellValue?.pesos !== undefined
                ? getRevenue(album.purchasePriceToday, album.sellValue?.pesos)
                : strings.unknown
            }
            textColor={
              isRevenueNegative ? theme.palette.colors?.remove : theme.palette.primary.main
            }
          />
          <Divider variant={"inset"} />
        </div>
      )}
      {!(isPublic && album.purchaseDate === undefined) && (
        <div>
          <LocalizationProvider
            localeText={
              strings.datePickerLocale.components.MuiLocalizationProvider.defaultProps.localeText
            }
            dateAdapter={DateAdapter}
            adapterLocale={strings.fnsLocale}
          >
            <MobileDatePicker
              slotProps={{
                actionBar: {
                  actions: ["clear", "cancel", "accept"]
                }
              }}
              format={"d MMM yyyy"}
              value={album.purchaseDate ? album.purchaseDate * 1000 : Date.now()}
              onAccept={async date => {
                const purchaseDate =
                  date == null ? null : Math.trunc((date as unknown as Date).getTime() / 1000);
                setIsLoadingPurchaseDate(true);
                const { body, status } = await updateAlbum({
                  uuid: album.uuid,
                  purchaseDate: purchaseDate
                });
                if (!StatusCodesHelper.isSuccessful(status)) {
                  setAlertMessage(body.message);
                  setAlertIsOpen(true);
                } else {
                  toast.success(strings.album_update_success);
                  album.purchaseDate = body.purchaseDate;
                  album.purchasePriceDollars = body.purchasePriceDollars;
                  album.purchasePriceToday = body.purchasePriceToday;
                  onAttributeUpdated(album);
                }
                setIsLoadingPurchaseDate(false);
              }}
              onClose={() => setIsPurchaseDatePickerOpen(false)}
              open={isPurchaseDatePickerOpen}
              slots={{
                field: () => (
                  <RightDetailListItem
                    disabled={isPublic}
                    title={strings.purchase_date_title}
                    detail={getPurchaseDate()}
                    onClick={() => setIsPurchaseDatePickerOpen(true)}
                    isLoading={isLoadingPurchaseDate}
                  />
                )
              }}
            />
          </LocalizationProvider>
          <Divider variant={"inset"} />
        </div>
      )}
      {!(isPublic && album.store === undefined) && (
        <RightDetailListItem
          disabled={isPublic}
          title={strings.store_title}
          detail={album.store?.name ?? strings.unknown}
          onClick={() => {
            setIsStoresDialogOpen(true);
          }}
          isLoading={isLoadingStore}
        />
      )}
      <ModifyGenericAttributeDialog
        title={strings.purchase_price_title}
        description={strings.purchase_price_message}
        placeholder={strings.purchase_price_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isPurchasePriceDialogOpen}
        setOpen={setIsPurchasePriceDialogOpen}
        isNumber
        value={album.purchasePrice?.toString()}
        onChange={async newValue => {
          await updatePurchasePrice(+newValue);
        }}
        onClearValue={async () => {
          await updatePurchasePrice(null);
        }}
      />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSellValuesDialogOpen}
        onClose={() => setIsSellValuesDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SellValuesDesktop
            handleClose={() => {
              setIsSellValuesDialogOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={album.sellValue?.uuid}
            onSellValueSelected={updateSellValue}
            onClearValue={() => updateSellValue(undefined)}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isStoresDialogOpen}
        onClose={() => setIsStoresDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <StoresDesktop
            handleClose={() => {
              setIsStoresDialogOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={album.store?.uuid}
            onStoreSelected={updateStore}
            onClearValue={() => updateStore(undefined)}
          />
        </div>
      </Dialog>
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </div>
  );
};

export interface IAlbumFinancialsListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
