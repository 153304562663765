import React, { FunctionComponent, useState } from "react";
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip
} from "@mui/material";
import styles from "./styles.module.scss";
import { useTheme } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { capitalizeFirstLetter } from "../../../../models/StringFormat";
import { ResponsiveIcon } from "../../../ResponsiveIcon";
import {
  MoreVertRounded,
  DriveFileRenameOutlineRounded,
  KeyboardDoubleArrowUpRounded,
  KeyboardDoubleArrowDownRounded,
  DeleteForever,
  CompressRounded,
  ExpandRounded
} from "@mui/icons-material";
import { useContextMenu } from "../../../../hooks/contextMenu";
import useLongPress from "../../../../hooks/longPress";
import { IAlbumContent } from "../../../../hooks/albumContents";

export const AlbumContentItem: FunctionComponent<IAlbumContentItemProps> = ({
  isPublic,
  albumContent,
  onModify,
  canMoveDown,
  canMoveUp,
  onMoveDown,
  onMoveUp,
  onDelete
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const { handleContextMenu, menuProps, handleMenuClose } = useContextMenu();
  const handleLongPress = useLongPress({ onLongPress: handleContextMenu });
  const albumContentName = () => {
    const defaultName = capitalizeFirstLetter(strings.disc_singular);
    switch (albumContent.type) {
      case "disc":
        if (albumContent.quantity > 1) {
          return (albumContent.albumFormat?.shortName ?? defaultName) + strings.plural_indicator;
        }
        return albumContent.albumFormat?.shortName ?? defaultName;
      case "other":
        return albumContent.name ?? defaultName;
    }
  };
  return (
    <div className={styles.container} onContextMenu={handleContextMenu} {...handleLongPress}>
      <ListItemButton disableGutters onClick={() => setIsExpanded(state => !state)}>
        <Stack direction={"row"} flexGrow={1} spacing={1} alignItems={"center"}>
          <p className={styles.quantity}>
            {albumContent.quantity ? `${albumContent.quantity}×` : ""}
          </p>
          <Stack>
            <p className={styles.name}>{albumContentName()}</p>
            <p
              className={styles.description}
              style={{
                color: theme.palette.secondary.main,
                WebkitLineClamp: isExpanded ? undefined : 1
              }}
            >
              {albumContent.description}
            </p>
          </Stack>
          <Box flexGrow={1} />
          {albumContent.type == "disc" && (
            <>
              <p className={styles.playbackSpeed}>{albumContent.playbackSpeed?.name}</p>
              <p className={styles.color}>{albumContent.color}</p>
            </>
          )}
        </Stack>
      </ListItemButton>
      <Stack className={styles.hoverButtonsStack} direction={"row"} spacing={1}>
        <Tooltip title={strings.options} arrow disableInteractive>
          <IconButton
            className={styles.hoverButton}
            style={{ backgroundColor: theme.palette.colors?.counterBackground }}
            onClick={event => {
              event.stopPropagation();
              handleContextMenu(event);
            }}
          >
            <ResponsiveIcon className={styles.hoverButtonIcon} icon={MoreVertRounded} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Menu {...menuProps}>
        {!isPublic && (
          <MenuItem
            onClick={async () => {
              handleMenuClose();
              onModify();
            }}
            dense
          >
            <ListItemIcon>
              <DriveFileRenameOutlineRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.alert_modify}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsExpanded(state => !state);
          }}
          dense
        >
          <ListItemIcon>
            {isExpanded ? (
              <CompressRounded fontSize={"small"} />
            ) : (
              <ExpandRounded fontSize={"small"} />
            )}
          </ListItemIcon>
          {isExpanded ? strings.shrink : strings.expand}
        </MenuItem>
        {!isPublic && canMoveUp && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              onMoveUp();
            }}
            dense
          >
            <ListItemIcon>
              <KeyboardDoubleArrowUpRounded />
            </ListItemIcon>
            {strings.move_up}
          </MenuItem>
        )}
        {!isPublic && canMoveDown && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              onMoveDown();
            }}
            dense
          >
            <ListItemIcon>
              <KeyboardDoubleArrowDownRounded />
            </ListItemIcon>
            {strings.move_down}
          </MenuItem>
        )}
        {!isPublic && (
          <MenuItem
            key={"Remove"}
            onClick={() => {
              handleMenuClose();
              onDelete();
            }}
            sx={{ color: theme.palette.colors?.remove }}
            dense
          >
            <ListItemIcon sx={{ color: theme.palette.colors?.remove }}>
              <DeleteForever fontSize={"small"} />
            </ListItemIcon>
            {strings.alert_delete}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export interface IAlbumContentItemProps {
  isPublic: boolean;
  albumContent: IAlbumContent;
  onModify: () => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onDelete: () => void;
}
