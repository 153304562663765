import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { Box, Card, CardActionArea, ListItem, Stack, useTheme } from "@mui/material";
import classNames from "classnames";
import { ISubscription } from "../../../hooks/subscriptions";
import { DiscountTag } from "../../DiscountTag";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { useUserProfile } from "../../../hooks/session";
import { strings } from "../../../localization/LocalizedStrings";

export const SubscriptionItem: FunctionComponent<ISubscriptionItemProps> = ({
  subscription,
  onSelection
}) => {
  const theme = useTheme();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  return (
    <ListItem className={styles.item}>
      <Card className={styles.container}>
        <CardActionArea
          className={classNames(styles.actionArea, styles.positionContainer)}
          onClick={onSelection}
        >
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Stack direction={"row"}>
              <p className={styles.name}>{subscription.name}</p>
              {subscription.discountARS > 0 && (
                <DiscountTag className={styles.discountBox} percentage={subscription.discountARS} />
              )}
              <Box flexGrow={1} />
              <div className={styles.price}>
                {subscription.priceARS > 0 && (
                  <div>
                    {subscription.discountARS > 0 && (
                      <p
                        className={styles.oldPrice}
                        style={{ color: theme.palette.secondary.main }}
                      >
                        {currencyFormatter.format(
                          strings.currencyLocale == "es-AR"
                            ? subscription.priceARS
                            : subscription.priceUSD,
                          currentUserProfile,
                          false,
                          2,
                          strings.currencyLocale == "es-AR" ? "ARS" : "USD",
                          strings.currencyLocale !== "es-AR"
                        )}
                      </p>
                    )}
                    <p className={styles.newPrice}>
                      {currencyFormatter.format(
                        strings.currencyLocale == "es-AR"
                          ? subscription.finalPriceARS
                          : subscription.finalPriceUSD,
                        currentUserProfile,
                        false,
                        2,
                        strings.currencyLocale == "es-AR" ? "ARS" : "USD",
                        strings.currencyLocale !== "es-AR"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </Stack>
            <p className={styles.description}>{subscription.description}</p>
          </Stack>
        </CardActionArea>
      </Card>
    </ListItem>
  );
};

export interface ISubscriptionItemProps {
  subscription: ISubscription;
  onSelection: () => void;
}
