import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  ErrorCorrectionLevel,
  Mode,
  TypeNumber
} from "qr-code-styling";
import pWhiteImage from "../../images/P-white.png";
import pBlackImage from "../../images/P-black.png";
import pBlackAndWhite from "../../images/P-black&white.png";

export const useQRCode = (data: string, noStyle: boolean) => {
  const theme = useTheme();
  const [qrBase64, setQRBase64] = useState("");
  const qrOptions = {
    typeNumber: 0 as TypeNumber,
    mode: "Byte" as Mode,
    errorCorrectionLevel: "M" as ErrorCorrectionLevel
  };
  const dotsOptions = {
    type: noStyle ? "square" : ("rounded" as DotType),
    color: noStyle ? "black" : theme.palette.primary.main
  };
  const backgroundOptions = {
    color: noStyle ? "white" : theme.palette.colors?.qrCodeBackground
  };
  const cornersSquareOptions = {
    type: noStyle ? "square" : ("extra-rounded" as CornerSquareType),
    color: noStyle ? "black" : theme.palette.primary.main
  };
  const cornersDotOptions = {
    type: noStyle ? "square" : ("dot" as CornerDotType),
    color: noStyle ? "black" : theme.palette.primary.main
  };
  const imageOptions = { hideBackgroundDots: true, imageSize: 0.5, margin: 0 };
  const blobToBase64 = async (blob: Blob) => {
    return new Promise<string | ArrayBuffer | null>(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    margin: 20,
    image: noStyle ? pBlackAndWhite : theme.palette.mode == "light" ? pWhiteImage : pBlackImage,
    qrOptions: qrOptions,
    imageOptions: imageOptions,
    dotsOptions: dotsOptions,
    backgroundOptions: backgroundOptions,
    cornersSquareOptions: cornersSquareOptions,
    cornersDotOptions: cornersDotOptions
  });
  useEffect(() => {
    setQRBase64("");
    if (data == "") return;
    qrCode.update({
      data: data
    });
    qrCode.getRawData("png").then(blob => {
      if (!blob) return;
      blobToBase64(blob).then(result => {
        if (!result || result instanceof ArrayBuffer) return;
        setQRBase64(result.split(",")[1]);
      });
    });
  }, [data, noStyle]);
  return qrBase64;
};
