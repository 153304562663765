import { useBackend } from "../../services";

export const useGetPrivateContent = () => {
  const { get } = useBackend();
  const getPrivateContent = (parameters: IGetPrivateContentParameters) =>
    get("share/privateContent", parameters);
  return { getPrivateContent };
};

export interface IGetPrivateContentParameters {
  privateLink: string | (string | null)[];
}
