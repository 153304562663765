import barImage from "../../../../images/documentation/filter-bar-image.png";
import orderImage from "../../../../images/documentation/filter-order-es.png";
import elementsImage from "../../../../images/documentation/filter-elements-es.png";

export const doc_filter_albums_es = {
  title: "Filtro de álbumes",
  home_title: "Filtrar tus álbumes",
  bar_image: barImage,
  order_image: orderImage,
  elements_image: elementsImage,
  description: `Piper ofrece un poderoso filtro para revisar y organizar tu colección usando distintas perspectivas, así podés analizar los datos desde distintos ángulos. Para abrir la ventana del filtro basta con hacer click en el botón de la barra de herramientas.
  
`,
  order_description: `El primer campo te permite elegir el órden de agrupamiento en el cual va a mostrarse la lista de álbumes. La opción por defecto es la de por artista, y los álbumes dentro de cada artista van a mostrarse ordenados por tu propio órden definido previamente (Ver la documentación de 'Ordenar tus álbumes'). Las demás opciones incluyen por sello, precio de compra, ganancia, duración, etc. En esos casos, los álbumes van a ser agrupados por la opción seleccionada y van a estar ordenados por año y nombre dentro de cada sección.
  
  El segundo campo va a permitirte decidir si la opción seleccionada va a ser aplicada en order ascendente o descendente.
  
`,
  elements_description: `La siguiente sección cuenta con dos tablas. La tabla de la izquierda muestra todos las opciones de filtro que pueden ser aplicadas. Éstas te permiten seleccionar un sello en específico, año, valor de venta, que querés restringir en tu lista de álbumes. Para hacer efectiva la selección, hacé click en el checkbox de todos los elementos que quieras aplicar y hacé click en la flecha que apunta a la derecha. De la misma forma, seleccioná los elementos que quieras remover del filtro de la tabla de la derecha y hacé click en la flecha que apunta a la izquierda. También podés ingresar el valor deseado en cualquier elemento de la tabla de la izquierda y va a ser aplicado automáticamente en la derecha.
  
  Cada elemento del filtro va a ser requerido en el criterio de búsqueda así que, por ejemplo, si seleccionaste el artista 'A' y el sello 'B' los álbumes mostrados van a ser los que pertenezcan al artista 'A' Y los que pertenezcan al sello 'B'.
  
  Esto no es cierto en el caso que selecciones múltiples elementos del mismo tipo, en ese caso el criterio de búsqueda va a incluir a todos los álbumes que cumplan con cualquiera de esos elementos. Así, por ejemplo, si seleccionaste el artista 'A' y el artista 'B' los álbumes mostrados van a ser los que pertenezcan al artista 'A' O los que pertenezcan al artista 'B'.
  
`
};
