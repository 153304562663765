import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { IAlbumFormat } from "../../../../hooks/albumFormats";

export const AlbumFormatCard: FunctionComponent<IAlbumFormatCardProps> = ({
  albumFormat,
  selected,
  onClick,
  onContextMenu
}) => {
  const theme = useTheme();
  return (
    <Card sx={{ height: 64 }} elevation={5}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
        style={{
          borderColor: theme.palette.colors?.highlightBorder,
          borderWidth: 4,
          borderStyle: selected ? "double" : "none"
        }}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <p className={styles.name}>{albumFormat.name}</p>
          <Card sx={{ padding: 0.5 }}>
            <p className={styles.shortName}>{albumFormat.shortName}</p>
          </Card>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface IAlbumFormatCardProps {
  albumFormat: IAlbumFormat;
  selected?: boolean;
  onClick: () => void;
  onContextMenu: (event: React.MouseEvent) => void;
}
