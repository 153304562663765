import { useBackend } from "../../services";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useGetPaginatedAttributesByPage } from "../attributes/useGetPaginatedAttributesByPage";
import { IMessage } from "./interfaces";

export const useGetMessagesForAlbum = <T>(
  albumId: string,
  replyToMessageId: string | undefined,
  setLoading: (loading: boolean) => void,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const getMessages = (parameters: any) => get("messages/album", parameters);
  return useGetPaginatedAttributesByPage<IMessage, T>(
    PageSizeHelper.messages(),
    { albumId: albumId, replyToMessageId: replyToMessageId },
    getMessages,
    setLoading,
    true,
    false,
    dependencies
  );
};
