import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, List, Grow, Toolbar } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { SubscriptionItem } from "../SubscriptionItem";
import { useGetSubscriptions, ISubscription } from "../../../hooks/subscriptions";
import { useIsMounted } from "../../../hooks/isMounted";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { AlertDialog } from "../../AlertDialog";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { SubscriptionDialog } from "../SubscriptionDialog";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { LoginAgainDialog } from "../../LoginAgainDialog";
import { StatusCodes } from "http-status-codes";

export const SubscriptionsDialog: FunctionComponent<ISubscriptionsDialogProps> = ({
  isOpen,
  setIsOpen
}) => {
  const isMounted = useIsMounted();
  const { getSubscriptions } = useGetSubscriptions();
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<ISubscription>();
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState(false);
  const [isSignInAgainDialogOpen, setIsSignInAgainDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!isOpen || !isMounted.current) return;
    fetchSubscriptions().then();
  }, [isOpen]);
  const fetchSubscriptions = async () => {
    setSubscriptions([]);
    setLoading(true);
    const { status, body } = await getSubscriptions();
    if (StatusCodesHelper.isSuccessful(status)) {
      setSubscriptions(body);
    } else if (status == StatusCodes.FORBIDDEN) {
      setIsSignInAgainDialogOpen(true);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setLoading(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3>{strings.update_role_title}</h3>
        <Box flexGrow={1} />
        <ToolbarButton
          href={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.subscriptions.home()}`}
          target={"_blank"}
          tooltip={strings.help}
          icon={HelpOutlineRounded}
        />
        <ToolbarButton
          loading={loading}
          onClick={() => {
            setIsOpen(false);
          }}
          tooltip={strings.close}
          icon={CloseRounded}
        />
      </Toolbar>
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
        <List>
          {subscriptions.map(subscription => (
            <SubscriptionItem
              key={String(subscription.id)}
              subscription={subscription}
              onSelection={async () => {
                // Ignore click on free plan
                if (subscription.id == 0) return;
                setSelectedSubscription(subscription);
                setIsSubscriptionDialogOpen(true);
              }}
            />
          ))}
        </List>
      </DialogContent>
      {selectedSubscription && (
        <SubscriptionDialog
          subscription={selectedSubscription}
          isOpen={isSubscriptionDialogOpen}
          setIsOpen={setIsSubscriptionDialogOpen}
        />
      )}
      <LoginAgainDialog
        isOpen={isSignInAgainDialogOpen}
        setIsOpen={setIsSignInAgainDialogOpen}
        loginCallback={async () => {
          setIsSignInAgainDialogOpen(false);
          await fetchSubscriptions();
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface ISubscriptionsDialogProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
}
