export const doc_users_recover_password_es = {
  title: "Recuperar contraseña",
  description: `Si en algún momento te olvidás tu constraseña podés reiniciarla usando el botón de "¿Olvidaste tu contraseña?" en la pantalla de inicio, o la opción de "Recuperar contraseña" en el menú desplegable.
  
  Una nueva ventana te va a pedir que ingreses tu dirección de correo electrónico y una contraseña nueva. Después de confirmar esa información se va a enviar un código de verificación a tu correo que vas a tener que ingresar en el próximo paso.  

  Una vez que el código fue validado vas a poder ingresar normalmente usando tu nueva contraseña.  

  Acordate que siempre podés optar por ingresar con tu cuenta de Google o Apple sin tener que usar otra contraseña.  
  
`
};
