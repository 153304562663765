import React, { FunctionComponent } from "react";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "../subscriptions/DocumentationSubscriptions/styles.module.scss";

export const TermsOfService: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.terms.title}</h1>
      <p>{strings.terms.block1}</p>
      <section id={"consent"} />
      <h2>{strings.terms.consent_title}</h2>
      <p>{strings.terms.consent}</p>
      <section id={"service"} />
      <h2>{strings.terms.service_title}</h2>
      <p>{strings.terms.service}</p>
      <section id={"data"} />
      <h2>{strings.terms.data_title}</h2>
      <p>{strings.terms.data}</p>
      <section id={"subscriptions"} />
      <h2>{strings.terms.subscriptions_title}</h2>
      <p>{strings.terms.subscriptions}</p>
      <section id={"users"} />
      <h2>{strings.terms.users_title}</h2>
      <p>{strings.terms.users}</p>
      <ul className={styles.indentedText}>
        <li>{strings.terms.users_1}</li>
        <li>{strings.terms.users_2}</li>
        <li>{strings.terms.users_3}</li>
        <li>{strings.terms.users_4}</li>
        <li>{strings.terms.users_5}</li>
        <li>{strings.terms.users_6}</li>
        <li>{strings.terms.users_7}</li>
        <li>{strings.terms.users_8}</li>
        <li>{strings.terms.users_9}</li>
        <li>{strings.terms.users_10}</li>
        <li>{strings.terms.users_11}</li>
      </ul>
      <section id={"warranties"} />
      <h2>{strings.terms.warranties_title}</h2>
      <p>{strings.terms.warranties}</p>
      <ul className={styles.indentedText}>
        <li>{strings.terms.warranties_1}</li>
        <li>{strings.terms.warranties_2}</li>
        <li>{strings.terms.warranties_3}</li>
        <li>{strings.terms.warranties_4}</li>
        <li>{strings.terms.warranties_5}</li>
      </ul>
      <section id={"rights"} />
      <h2>{strings.terms.rights_title}</h2>
      <p>{strings.terms.rights}</p>
      <section id={"changes"} />
      <h2>{strings.terms.changes_title}</h2>
      <p>{strings.terms.changes}</p>
      <section id={"contact"} />
      <h2>{strings.terms.contact_title}</h2>
      <p>{strings.terms.contact}</p>
      <p style={{ fontWeight: "bold" }}>{strings.terms.last_update}</p>
    </div>
  );
};
