import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { strings } from "../../localization/LocalizedStrings";
import { TextField } from "../TextField";
import { AlertDialog } from "../AlertDialog";
import { useVerifyEmailCode } from "../../hooks/users/useVerifyEmailCode";
import { useResendVerificationCode } from "../../hooks/users/useResendVerificationCode";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { ContainedButton } from "../ContainedButton";
import { TimeInterval } from "../../models/TimeInterval";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { CloseRounded, RefreshRounded } from "@mui/icons-material";
import { useUser } from "../../hooks/session";

export const VerifyEmailCodeDialog: FunctionComponent<IVerifyEmailCodeDialogProps> = ({
  email,
  password,
  open,
  setOpen,
  callback,
  onClose
}) => {
  const theme = useTheme();
  const [, setCurrentUser] = useUser.useState();
  const { verifyEmailCode } = useVerifyEmailCode();
  const { resendVerificationCode } = useResendVerificationCode();
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleClose = () => {
    if (isLoading) return;
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  const initialValues: IFormProps = {
    code: ""
  };
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    onSubmit: async ({ code }) => {
      setIsLoading(true);
      const { body, status } = await verifyEmailCode({
        email: email,
        password: password,
        code: code.toUpperCase()
      });
      setIsLoading(false);
      if (StatusCodesHelper.isSuccessful(status)) {
        if (callback != undefined) {
          callback();
        }
        setCurrentUser(body);
        handleClose();
      } else {
        if (status == 510) {
          setAlertMessage(strings.vec_invalid_code);
          setIsAlertOpen(true);
        } else {
          setAlertMessage(body.message);
          setIsAlertOpen(true);
        }
      }
    }
  });
  const onResendVerificationCode = async () => {
    setIsLoading(true);
    const { body, status } = await resendVerificationCode({
      email: email
    });
    if (status == 511) {
      // Leeway time not yet elapsed
      const { body: errorBody } = body;
      const { timeElapsed, leeway } = errorBody;
      setCountdown(leeway - timeElapsed);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (countdown <= 0) {
      return;
    }
    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);
  return (
    <FormikProvider value={formik}>
      <Dialog maxWidth={"sm"} open={open} onClose={handleClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{strings.vec_title}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
            {strings.vec_subtitle}
          </p>
          <Stack spacing={2} direction={"row"} sx={{ alignItems: "center", marginBottom: 2 }}>
            <TextField
              id="code"
              name="code"
              size="small"
              placeholder={strings.vec_code_placeholder}
              value={formik.values.code.toUpperCase()}
              onChange={formik.handleChange}
            />
            <ToolbarButton
              tooltip={strings.vec_resend_code}
              icon={RefreshRounded}
              disabled={isLoading || countdown > 0}
              onClick={onResendVerificationCode}
            />
          </Stack>
          <p className={styles.countdown} hidden={countdown <= 0}>
            {TimeInterval.toMMSS(countdown)}
          </p>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </FormikProvider>
  );
};

interface IFormProps {
  code: string;
}

export interface IVerifyEmailCodeDialogProps {
  email: string;
  password?: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  callback?: () => void;
  onClose?: () => void;
}
