import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Card, Dialog, DialogContent, Grow, Slider, Stack, Toolbar } from "@mui/material";
import { IAlbum } from "../../../../hooks/albums";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { strings } from "../../../../localization/LocalizedStrings";
import { CloseRounded, ZoomInRounded } from "@mui/icons-material";
import { AlertDialog } from "../../../AlertDialog";
import { useGetShelving } from "../../../../hooks/shelvings/useGetShelving";
import { IShelfSegmentAlbum } from "../../../../hooks/shelfSegments";
import { IShelving } from "../../../../hooks/shelvings";
import { ShelvingRepresentation } from "../../../layout/ShelvingRepresentation";
import { DragDropContext } from "@hello-pangea/dnd";
import styles from "./styles.module.scss";

export const AlbumLocationDialog: FunctionComponent<IAlbumLocationDialogProps> = ({
  album,
  shelfSegmentAlbum,
  isOpen,
  setIsOpen
}) => {
  const { getShelving } = useGetShelving();
  const [shelving, setShelving] = useState<IShelving>();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [didMakeMove, setDidMakeMove] = useState(false);
  const [zoom, setZoom] = useState(0.5);
  useEffect(() => {
    if (!shelfSegmentAlbum) return;
    setShelving(undefined);
    fetchShelving().then();
  }, [shelfSegmentAlbum]);
  const fetchShelving = async () => {
    setIsLoading(true);
    const { status, body } = await getShelving(shelfSegmentAlbum.shelfSegment.shelf.shelving.uuid);
    if (StatusCodesHelper.isSuccessful(status)) {
      setShelving(body);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3 style={{ marginRight: 8 }}>{strings.album_location_title}</h3>
        <Box flexGrow={1} />
        <Box className={styles.zoomSlider}>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <ZoomInRounded />
            <Slider
              value={zoom}
              min={0.23}
              max={1.2}
              step={0.01}
              onChange={(event, newValue) => setZoom(newValue as number)}
            />
          </Stack>
        </Box>
        <ToolbarButton
          onClick={() => setIsOpen(false)}
          tooltip={strings.close}
          icon={CloseRounded}
          loading={isLoading}
        />
      </Toolbar>
      <DragDropContext
        onDragEnd={() => {
          if (didMakeMove) return;
          setDidMakeMove(true);
          setAlertMessage(strings.layout_cannot_move_message);
          setIsAlertOpen(true);
        }}
      >
        <DialogContent className={styles.dialog}>
          <Card elevation={15} className={styles.shelving}>
            <div className={styles.shelvingDiv}>
              {shelving && (
                <ShelvingRepresentation
                  shelving={shelving}
                  selectedShelf={undefined}
                  selectedShelfSegment={undefined}
                  selectedAlbumIds={[album.uuid]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onShelfSegmentAlbumSelected={() => {
                    //
                  }}
                  shelvingIndex={0}
                  zoom={zoom}
                />
              )}
            </div>
          </Card>
        </DialogContent>
      </DragDropContext>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface IAlbumLocationDialogProps {
  album: IAlbum;
  shelfSegmentAlbum?: IShelfSegmentAlbum;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
