import { blueTheme, darkTheme, blackTheme, lightTheme, orangeTheme } from "../theme";
import { strings } from "../localization/LocalizedStrings";

export const ThemeManager = {
  currentTheme: () => {
    switch (localStorage.getItem("themeMode")) {
      case "light":
        return lightTheme;
      case "dark":
        return darkTheme;
      case "black":
        return blackTheme;
      case "blue":
        return blueTheme;
      case "orange":
        return orangeTheme;
      default:
        return darkTheme;
    }
  },
  currentThemeMode: () => {
    return localStorage.getItem("themeMode") ?? "dark";
  },
  setTheme: (themeMode: ThemeMode) => {
    localStorage.setItem("themeMode", themeMode);
  },
  isCurrentThemeLight: () => {
    return localStorage.getItem("themeMode") == "light";
  },
  listOfThemeNames: () => {
    return [
      strings.light_mode,
      strings.dark_mode,
      strings.black_mode,
      strings.blue_mode,
      strings.orange_mode
    ];
  },
  listOfThemeModes: () => {
    return ["light", "dark", "black", "blue", "orange"];
  }
};

export type ThemeMode = "light" | "dark" | "black" | "blue" | "orange";
