import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, Dialog, DialogContent, Grid, Grow, Stack, Toolbar } from "@mui/material";
import styles from "./styles.module.scss";
import { IArtist } from "../../../../hooks/artists";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { useGetImage } from "../../../../hooks/images";
import { ImageCache } from "../../../../models/ImageCache";
import genericImage from "../../../../images/generic_artist.png";
import { strings } from "../../../../localization/LocalizedStrings";
import { ArtistUtilities } from "../../../../models/ArtistUtilities";
import { ModifyArtistDialog } from "../ModifyArtistDialog";
import { ContainedButton } from "../../../ContainedButton";
import { CloseRounded, DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { LazyImageAspectFit } from "../../../LazyImageAspectFit";

export const ArtistDialog: FunctionComponent<IArtistDialogProps> = ({
  artist,
  isOpen,
  setIsOpen,
  canEdit,
  onModifiedItem,
  onDeletedItem
}) => {
  const isMobile = useIsMobile();
  const { getImage } = useGetImage();
  const [myArtist, setMyArtist] = useState(artist);
  const [image, setImage] = useState();
  const [thumbnail, setThumbnail] = useState(artist.thumbnail);
  const [artistToModify, setArtistToModify] = useState<IArtist>();
  const [isModifyArtistDialogOpen, setIsModifyArtistDialogOpen] = useState(false);
  useEffect(() => {
    setThumbnail(myArtist.thumbnail);
    fetchImage().then();
  }, [myArtist.uuid, myArtist.hasImage, myArtist.thumbnail]);
  useEffect(() => {
    setMyArtist(artist);
  }, [artist]);
  const currentArtistUuid = useRef("");
  const fetchImage = async () => {
    currentArtistUuid.current = myArtist.uuid;
    setImage(undefined);
    if (!myArtist.hasImage) return;
    const response = await getImage({
      name: myArtist.uuid,
      width: ImageCache.bigArtistImageSize,
      height: ImageCache.bigArtistImageSize
    });
    // Discard image if artist changed
    if (currentArtistUuid.current != myArtist.uuid) return;
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Grow}
    >
      <div className={styles.dialog}>
        <Toolbar>
          <h3>{myArtist.name}</h3>
          <Box flexGrow={1} />
          {canEdit && (
            <ToolbarButton
              onClick={() => {
                setArtistToModify(myArtist);
                setIsModifyArtistDialogOpen(true);
              }}
              tooltip={strings.artist_edit}
              icon={DriveFileRenameOutlineRounded}
            />
          )}
          <ToolbarButton
            onClick={() => setIsOpen(false)}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
      </div>
      <DialogContent dividers>
        {myArtist ? (
          <Stack spacing={2}>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              <LazyImageAspectFit
                className={styles.image}
                style={{ width: isMobile ? "100%" : "50%" }}
                image={image}
                imageStyle={{ borderRadius: "6%" }}
                thumbnail={thumbnail}
                defaultImage={genericImage}
                alt={myArtist.name}
              />
              <Stack style={{ width: isMobile ? "100%" : "50%" }}>
                <div className={styles.redirectGridItem}>
                  {myArtist.realName && (
                    <Stack spacing={0}>
                      <p className={styles.title}>{strings.ma_real_name_title}</p>
                      <h3 className={styles.realName}>{myArtist.realName}</h3>
                    </Stack>
                  )}
                  <p className={styles.artistDates}>
                    {ArtistUtilities.getDatesString(myArtist, "long")}
                  </p>
                </div>
                <Grid container>
                  <Grid item xs className={styles.redirectGridItem}>
                    <ContainedButton
                      className={styles.redirectButton}
                      disabled={myArtist.discogsArtistId?.length === 0}
                      href={`https://www.discogs.com/artist/${myArtist.discogsArtistId}`}
                      target={"_blank"}
                      component={"a"}
                    >
                      {strings.open_on_discogs}
                    </ContainedButton>
                  </Grid>
                  <Grid item xs className={styles.redirectGridItem}>
                    <ContainedButton
                      className={styles.redirectButton}
                      disabled={!myArtist.website || myArtist.website.length === 0}
                      href={myArtist.website}
                      target={"_blank"}
                    >
                      {strings.ma_website_button_title}
                    </ContainedButton>
                  </Grid>
                </Grid>
                <Box flexGrow={1} />
              </Stack>
            </Stack>
            <p className={styles.profile}>{myArtist.profile}</p>
          </Stack>
        ) : (
          <Box sx={{ height: 200 }} />
        )}
      </DialogContent>
      <ModifyArtistDialog
        open={isModifyArtistDialogOpen}
        setOpen={setIsModifyArtistDialogOpen}
        artist={artistToModify}
        originalImageData={image}
        onModifiedItem={newItem => {
          if (onModifiedItem) {
            onModifiedItem(newItem);
          }
          setMyArtist(newItem);
        }}
        onDeletedItem={onDeletedItem}
      />
    </Dialog>
  );
};

export interface IArtistDialogProps {
  artist: IArtist;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  canEdit: boolean;
  onModifiedItem?: (artist: IArtist) => void;
  onDeletedItem?: (artist: IArtist) => void;
}
