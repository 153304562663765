import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#303030",
      paper: "#424242"
    },
    primary: {
      main: "#ffffff",
      subtitle: "#d3d3d3"
    },
    secondary: {
      main: "#d3d3d3"
    },
    action: {
      disabledBackground: "#d3d3d3",
      disabled: "#808080"
    },
    colors: {
      main: "#40403f",
      appBar: "#484848",
      highlight: "#1c1c1c",
      disabled: "#808080",
      gradeNumberBackground: "#40403f",
      buttonColor: "#fff",
      buttonBackground: "#707070",
      buttonHover: "#909090",
      counterBackground: "#707070",
      warning: "#ffbf00",
      remove: "#f35f5f",
      discount: "#1788ce",
      mercadoPagoBackgroundColor: "#171E2E",
      qrCodeBackground: "#000000",
      goldmineGray: "#696969",
      goldmineRed: "#c55006",
      goldmineGreen: "#1aa626",
      goldmineBlue: "#2a9bd7",
      goldmineMint: "#2d51e0",
      highlightBorder: "#2a9bd7",
      highlightButton: "#808080",
      highlightSpine: "#ece81f",
      breadcrumb: "#d3d3d3",
      pricingNotification: "rgba(255,251,19,1)",
      criticalNotification: "rgba(255,19,19,1)",
      messageNotification: "rgba(19,165,255,1)",
      border: "#626262",
      ownChat: "#2c376e",
      otherChat: "#1a1c1c",
      chatSubheader: "#444444",
      toastBackground: "#000",
      drawerBackgroundColor: "#1e1e1e",
      divider: "#6a6a6a"
    }
  },
  typography: {
    fontFamily: `"SF Pro Text", sans-serif`,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000
  }
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#f2f2f2",
      paper: "#ffffff"
    },
    primary: {
      main: "#000",
      subtitle: "#424242"
    },
    secondary: {
      main: "#424242"
    },
    action: {
      disabledBackground: "#d3d3d3",
      disabled: "#808080"
    },
    colors: {
      main: "#f2f2f2",
      appBar: "#ffffff",
      highlight: "#d0d0d0",
      disabled: "#d4d4d4",
      gradeNumberBackground: "#c7c7c7",
      buttonColor: "#000",
      buttonBackground: "#e3e3e3",
      buttonHover: "#d4d4d4",
      counterBackground: "#e3e3e3",
      warning: "#daa520",
      remove: "#e63946",
      discount: "#a4dcff",
      mercadoPagoBackgroundColor: "#ffffff",
      qrCodeBackground: "#ffffff",
      goldmineGray: "#afafaf",
      goldmineRed: "#f65a5a",
      goldmineGreen: "#2cde3c",
      goldmineBlue: "#7bcbff",
      goldmineMint: "#7a7ff3",
      highlightBorder: "#54bdff",
      highlightButton: "#d4d4d4",
      highlightSpine: "#ece81f",
      breadcrumb: "#d3d3d3",
      pricingNotification: "rgb(222,166,0)",
      criticalNotification: "rgba(255,19,19,1)",
      messageNotification: "rgba(19,165,255,1)",
      border: "#e0e0e0",
      ownChat: "#10e7e5",
      otherChat: "#ffffff",
      chatSubheader: "#dadada",
      toastBackground: "#fff",
      drawerBackgroundColor: "#c4c4c4",
      divider: "#e0e0e0"
    }
  },
  typography: {
    fontFamily: `"SF Pro Text", sans-serif`,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000
  }
});

export const blackTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
      paper: "#000"
    },
    primary: {
      main: "#dbdbdb",
      subtitle: "#a2a2a2"
    },
    secondary: {
      main: "#939393"
    },
    action: {
      disabledBackground: "#b2b2b2",
      disabled: "#808080"
    },
    colors: {
      main: "#000000",
      appBar: "#000000",
      highlight: "#2f2f2f",
      disabled: "#808080",
      gradeNumberBackground: "#2b2b2b",
      buttonColor: "#fff",
      buttonBackground: "#1a1a1a",
      buttonHover: "#909090",
      counterBackground: "#505050",
      warning: "#ffbf00",
      remove: "#e63946",
      discount: "#0c88e0",
      mercadoPagoBackgroundColor: "#171E2E",
      qrCodeBackground: "#000000",
      goldmineGray: "#696969",
      goldmineRed: "#9a4005",
      goldmineGreen: "#0f8516",
      goldmineBlue: "#0776a4",
      goldmineMint: "#2d51e0",
      highlightBorder: "#0776a4",
      highlightButton: "#808080",
      highlightSpine: "#ece81f",
      breadcrumb: "#d3d3d3",
      pricingNotification: "rgba(255,251,19,1)",
      criticalNotification: "rgba(255,19,19,1)",
      messageNotification: "rgba(19,165,255,1)",
      border: "#2a2a2a",
      ownChat: "#001952",
      otherChat: "#000000",
      chatSubheader: "#000000",
      toastBackground: "#000",
      drawerBackgroundColor: "#151515",
      divider: "#363636"
    }
  },
  typography: {
    fontFamily: `"SF Pro Text", sans-serif`,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000
  }
});

export const blueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#00104f",
      paper: "#01184f"
    },
    primary: {
      main: "#ffffff",
      subtitle: "#d3d3d3"
    },
    secondary: {
      main: "#d3d3d3"
    },
    action: {
      disabledBackground: "#d3d3d3 !important",
      disabled: "#808080 !important"
    },
    colors: {
      main: "#021030",
      appBar: "#01184f",
      highlight: "#253f7a",
      disabled: "#808080",
      gradeNumberBackground: "#001a54",
      buttonColor: "#fff",
      buttonBackground: "#253f7a",
      buttonHover: "#2666fc",
      counterBackground: "#455a8a",
      warning: "#ffbf00",
      remove: "#e63946",
      discount: "#3498db",
      mercadoPagoBackgroundColor: "#171E2E",
      qrCodeBackground: "#000000",
      goldmineGray: "#696969",
      goldmineRed: "#c55006",
      goldmineGreen: "#1aa626",
      goldmineBlue: "#2a9bd7",
      goldmineMint: "#2d51e0",
      highlightBorder: "#2a9bd7",
      highlightButton: "#808080",
      highlightSpine: "#ece81f",
      breadcrumb: "#d3d3d3",
      pricingNotification: "rgba(255,251,19,1)",
      criticalNotification: "rgba(255,19,19,1)",
      messageNotification: "rgba(19,165,255,1)",
      border: "#2b3f6c",
      ownChat: "#040a67",
      otherChat: "#000000",
      chatSubheader: "#08102d",
      toastBackground: "#000",
      drawerBackgroundColor: "#040523",
      divider: "#3a4971"
    }
  },
  typography: {
    fontFamily: `"SF Pro Text", sans-serif`,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000
  }
});

export const orangeTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
      paper: "#a65a04"
    },
    primary: {
      main: "#ffffff",
      subtitle: "#f2f2f2"
    },
    secondary: {
      main: "#f2f2f2"
    },
    action: {
      disabledBackground: "#f2f2f2 !important",
      disabled: "#c2c2c2 !important"
    },
    colors: {
      main: "#9a5a13",
      appBar: "#884707",
      highlight: "#b37202",
      disabled: "#ffb05c",
      gradeNumberBackground: "#8a5700",
      buttonColor: "#fff",
      buttonBackground: "#e07800",
      buttonHover: "#b37202",
      counterBackground: "#ff9900",
      warning: "#f7fd08",
      remove: "#7a0404",
      discount: "#1085d2",
      mercadoPagoBackgroundColor: "#171E2E",
      qrCodeBackground: "#000000",
      goldmineGray: "#d7d7d7",
      goldmineRed: "#ef0808",
      goldmineGreen: "#419d4b",
      goldmineBlue: "#2ba6ea",
      goldmineMint: "#2d51e0",
      highlightBorder: "#2ba6ea",
      highlightButton: "#f4b46b",
      highlightSpine: "#ece81f",
      breadcrumb: "#d3d3d3",
      pricingNotification: "rgb(172,31,192)",
      criticalNotification: "rgb(187,16,16)",
      messageNotification: "rgba(0,70,112,1)",
      border: "#f7972a",
      ownChat: "#040a67",
      otherChat: "#000000",
      chatSubheader: "#5b1c1c",
      toastBackground: "#000",
      drawerBackgroundColor: "#702e09",
      divider: "#eaa14d"
    }
  },
  typography: {
    fontFamily: `"SF Pro Text", sans-serif`,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000
  }
});

declare module "@mui/material/styles" {
  interface Palette {
    colors: PaletteOptions["primary"];
  }
  interface PaletteColor {
    subtitle?: string;
  }
  interface PaletteOptions {
    colors: PaletteOptions["primary"];
  }
  interface PaletteColorOptions {
    main: string;
    appBar?: string;
    highlight?: string;
    disabled?: string;
    subtitle?: string;
    gradeNumberBackground?: string;
    buttonColor?: string;
    buttonBackground?: string;
    buttonHover?: string;
    counterBackground?: string;
    warning?: string;
    remove?: string;
    discount?: string;
    mercadoPagoBackgroundColor?: string;
    qrCodeBackground?: string;
    goldmineGray?: string;
    goldmineRed?: string;
    goldmineGreen?: string;
    goldmineBlue?: string;
    goldmineMint?: string;
    highlightBorder?: string;
    highlightButton?: string;
    highlightSpine?: string;
    breadcrumb?: string;
    pricingNotification?: string;
    criticalNotification?: string;
    messageNotification?: string;
    border?: string;
    ownChat?: string;
    otherChat?: string;
    chatSubheader?: string;
    toastBackground?: string;
    drawerBackgroundColor?: string;
    divider?: string;
  }
}
