import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useUserProfile } from "../session";

export const useGetUserProfile = () => {
  const { get } = useBackend();
  const [, setUserProfile] = useUserProfile.useState();
  const getUserProfile = async () => {
    const { status, body } = await get("userProfiles");
    if (StatusCodesHelper.isSuccessful(status)) {
      setUserProfile(body);
    }
  };
  return { getUserProfile };
};
