import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { AppBar, Badge, Box, Dialog, Grow, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { MainDrawer } from "../../components/MainDrawer";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { useIsMobile } from "../../hooks/isMobile";
import { useUser, useUserProfile } from "../../hooks/session";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { useNavigate } from "react-router-dom";
import { ChatsDetail } from "../../components/chats/ChatsDetail";
import { MapsUgcRounded, ListAltRounded } from "@mui/icons-material";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { SearchBar } from "../../components/SearchBar";
import { UserProfilesDesktop } from "../../components/userProfiles/UserProfilesDesktop";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";
import { useAddChat } from "../../hooks/chats/useAddChat";
import { useGetChats } from "../../hooks/chats/useGetChats";
import queryString from "query-string";
import { IChat } from "../../hooks/chats";
import { useBackend } from "../../services";
import { useIsScreenWide } from "../../hooks/isScreenWide";

export const Chats: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.chats_title}`;
  }, []);
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const { socket } = useBackend();
  const { addChat } = useAddChat();
  const [searchText, setSearchText] = useState<string>();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [chats, , setChats, noMoreChats] = useGetChats(setIsLoading);
  const [selectedChat, setSelectedChat] = useState<IChat>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const [isUserProfilesDesktopOpen, setIsUserProfilesDesktopOpen] = useState(false);
  const [shouldOpenDrawer, setShouldOpenDrawer] = useState(false);
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (typeof queryParams.userId == "string") {
      createChat(queryParams.userId).then();
    }
  }, []);
  useEffect(() => {
    const onNewChat = (data: { chat: IChat }) => {
      const { chat } = data;
      socket.emit("join_chat", { chatId: chat.uuid });
    };
    const onNewMessage = (data: { chat: IChat }) => {
      const { chat } = data;
      const chatIds = chats.map(chat => chat.uuid);
      if (!chatIds.includes(chat.uuid)) {
        setChats(chats => [chat, ...chats]);
      }
    };
    socket.on("new_chat", onNewChat);
    socket.on("new_message", onNewMessage);
    return () => {
      socket.off("new_chat", onNewChat);
      socket.off("new_message", onNewMessage);
    };
  });
  const createChat = async (userId: string) => {
    if (userId == currentUser?.uuid) return;
    setIsLoading(true);
    const { status, body } = await addChat({ userIds: [userId] });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (body.lastMessage === undefined) {
        socket.emit("new_chat", { chatId: body.uuid });
        socket.emit("join_chat", { chatId: body.uuid });
        setChats(chats => [body, ...chats]);
      }
      setSelectedChat(body);
    } else {
      toast.error(body.message);
    }
    setIsLoading(false);
  };
  const toolbarItems = () => (
    <>
      <SearchBar
        onSearchRequested={searchText => setSearchText(searchText)}
        onSearchDismissed={() => setSearchText(undefined)}
      />
      <ToolbarButton
        onClick={() => setIsUserProfilesDesktopOpen(true)}
        loading={isLoading}
        tooltip={strings.chat_new_user}
        icon={MapsUgcRounded}
      />
    </>
  );
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              style={{ marginRight: isMobile ? 0 : 8 }}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={"chats"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
          />
          {isMobile && (
            <ToolbarButton
              onClick={() => setShouldOpenDrawer(state => !state)}
              tooltip={strings.open_chats_list}
              icon={ListAltRounded}
            />
          )}
          <Badge
            overlap={"circular"}
            badgeContent={
              <Box
                className={styles.betaTag}
                style={{ backgroundColor: theme.palette.colors?.discount }}
              >
                <p>BETA</p>
              </Box>
            }
          >
            <h3 className={styles.title}>{strings.chats_title}</h3>
          </Badge>
          {!isMobile && toolbarItems()}
          <Box flexGrow={1} />
          <UserProfileCard
            isPublic={false}
            userProfile={currentUserProfile}
            onClick={() => {
              if (!currentUserProfile || currentUser?.role == "guest") return;
              navigate(RoutesBuilder.userProfile());
            }}
          />
        </Toolbar>
      </AppBar>
      <div className={styles.mainBody}>
        <div
          className={styles.detailBody}
          style={{ backgroundColor: theme.palette.background.default }}
        >
          <ChatsDetail
            chats={chats}
            noMoreChats={noMoreChats}
            setChats={setChats}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            searchText={searchText}
            isLoading={isLoading}
            toolbarItems={toolbarItems()}
            shouldOpenDrawer={shouldOpenDrawer}
          />
          <AppCredits />
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isUserProfilesDesktopOpen}
        onClose={() => setIsUserProfilesDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <UserProfilesDesktop
            handleClose={() => setIsUserProfilesDesktopOpen(false)}
            columnWidth={isMobile ? 12 : 4}
            onUserProfileSelected={async userProfile => {
              setIsUserProfilesDesktopOpen(false);
              await createChat(userProfile.userId);
            }}
          />
        </div>
      </Dialog>
    </MainContent>
  );
};
