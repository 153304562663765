import React, { FunctionComponent, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import { AppCredits } from "../../components/AppCredits";
import piperTextImage from "../../images/piper-text.png";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { DocumentationOptions } from "../../components/documentation/DocumentationOptions";
import { DocumentationBreadcrumb } from "../../components/documentation/DocumentationBreadcrumb";
import { useNavigate } from "react-router-dom";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useIsMobile } from "../../hooks/isMobile";

export const DocumentationPage: FunctionComponent<IDocumentationPageProps> = ({
  defaultExpanded,
  defaultSelected,
  children
}) => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.documentation}`;
  }, []);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isIosApp = window.navigator.userAgent.includes("Piper-iOS/ldelosheros");
  const topReference = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    topReference.current?.scrollIntoView({ behavior: "smooth" });
  }, [children]);
  return (
    <div className={styles.mainDiv}>
      {!isIosApp && (
        <div className={styles.header} style={{ height: "10%" }}>
          <IconButton
            className={styles.logo}
            disableRipple
            onClick={() => navigate(RoutesBuilder.albums.list())}
          >
            <img
              className={styles.logoImage}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={piperTextImage}
              alt={"Piper"}
            />
          </IconButton>
          <DocumentationBreadcrumb />
        </div>
      )}
      <div
        className={styles.container}
        style={{
          height: isIosApp ? "100vh" : "90%",
          backgroundColor: theme.palette.background.default
        }}
      >
        {!isMobile && (
          <DocumentationOptions
            className={styles.options}
            defaultExpanded={defaultExpanded}
            defaultSelected={defaultSelected}
          />
        )}
        <div
          className={styles.mainContent}
          style={{
            width: isMobile ? "100%" : "78%",
            color: theme.palette.primary.main
          }}
        >
          <div ref={topReference} className={styles.scrollReference} />
          <div className={styles.text}>{children}</div>
          <Box flexGrow={1} />
          <Stack className={styles.footer}>
            <p>{strings.general_queries}</p>
            <a
              href={`mailto: ${strings.support_mail}`}
              style={{ color: theme.palette.secondary.main }}
            >
              {strings.support_mail}
            </a>
          </Stack>
          <AppCredits className={isMobile ? styles.creditsMobile : undefined} />
        </div>
      </div>
    </div>
  );
};

export interface IDocumentationPageProps {
  children: React.ReactNode;
  defaultExpanded: string[];
  defaultSelected: string;
}
