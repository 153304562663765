import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { Card, List, ListItemButton, ListItemText } from "@mui/material";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const DocumentationSubscriptions: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_subscriptions.title}</h1>
      <p>{strings.doc_subscriptions.description}</p>
      <ul className={styles.indentedText}>
        <li>
          <p>
            <b>{strings.doc_subscriptions.free_plan}</b>
            {strings.doc_subscriptions.free_plan_description}
          </p>
        </li>
        <ul className={styles.indentedText}>
          <li>{strings.doc_subscriptions.free_plan_body_1}</li>
          <li>{strings.doc_subscriptions.free_plan_body_2}</li>
          <li>{strings.doc_subscriptions.free_plan_body_3}</li>
          <li>{strings.doc_subscriptions.free_plan_body_4}</li>
          <li>{strings.doc_subscriptions.free_plan_body_5}</li>
          <li>{strings.doc_subscriptions.free_plan_body_6}</li>
          <li>{strings.doc_subscriptions.free_plan_body_7}</li>
          <li>{strings.doc_subscriptions.free_plan_body_8}</li>
          <li>{strings.doc_subscriptions.free_plan_body_9}</li>
          <li>{strings.doc_subscriptions.free_plan_body_10}</li>
        </ul>
        <p>{strings.doc_subscriptions.free_plan_end}</p>
      </ul>
      <ul className={styles.indentedText}>
        <li>
          <p>
            <b>{strings.doc_subscriptions.collector_plan}</b>
            {strings.doc_subscriptions.collector_plan_description}
          </p>
        </li>
      </ul>
      <ul className={styles.indentedText}>
        <li>
          <p>
            <b>{strings.doc_subscriptions.premium_plan}</b>
            {strings.doc_subscriptions.premium_plan_description}
          </p>
        </li>
        <ul className={styles.indentedText}>
          <li>{strings.doc_subscriptions.premium_plan_body_1}</li>
          <li>{strings.doc_subscriptions.premium_plan_body_2}</li>
        </ul>
      </ul>
      <p>{strings.doc_subscriptions.access}</p>
      <section id={"more"} />
      <h2>{strings.doc_more_options_title}</h2>
      <List className={styles.list}>
        <Card sx={{ margin: 2 }}>
          <ListItemButton
            key={"MercadoPago"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.subscriptions.mercadoPago()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_mercado_pago_subscriptions.title}
            />
          </ListItemButton>
        </Card>
      </List>
    </div>
  );
};
