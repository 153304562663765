import image from "../../../../images/documentation/mark-albums-es.png";

export const doc_marking_albums_es = {
  title: "Marcando álbumes",
  image: image,
  description: `Podés marcar tus álbumes para indicar de una forma visual su estado en la lista de álbumes. Cada tipo de marca va a tener un aspecto distinto en la lista así que probalos todos y usalos según tu conveniencia.

  Las marcas son especialmente útiles para las colecciones públicas que uses para vender tus álbumes, ya que de esta menera podés comunicar de una forma sencilla si un álbum ya está reservado, si fue vendido, o si está disponible para una pre venta.
  
  `
};
