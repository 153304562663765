import { useBackend } from "../../services";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IExchangeRate } from "./interfaces";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";

export const useGetExchangeRates = (setLoading: (loading: boolean) => void) => {
  const { get } = useBackend();
  const [currentUser] = useUser.useState();
  const getExchangeRates = (parameters: IGetPaginatedAttributeProps) =>
    get("exchangeRates", parameters);
  return useGetPaginatedAttributes<IExchangeRate>(
    PageSizeHelper.exchangeRates(currentUser?.role ?? UserRole.common),
    getExchangeRates,
    setLoading,
    true
  );
};
