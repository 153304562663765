import { useBackend } from "../../services";

export const useDeleteCollection = () => {
  const { deleteRequest } = useBackend();
  const deleteCollection = (parameters: IDeleteCollectionParameters) =>
    deleteRequest("collections", parameters);
  return { deleteCollection };
};

export interface IDeleteCollectionParameters {
  uuid: string;
}
