export const doc_collections_attributes_es = {
  title: "Atributos de una colección",
  home_title: "Atributos",
  description: `Abajo vas a encontrar una list de los atributos que pertenecen a una colección y una breve descripción de cada uno.
  
  `,
  name_title: "Nombre",
  name_description: `Cada colección necesita un nombre. Usá un nombre que represente a los álbumes dentro de ella. Tené en cuenta que este nombre va a ser visible por otros usuarios si la colección es pública.
  
  `,
  image_title: "Imagen",
  image_description: `Hay una imagen por defecto asignada a la colección, pero podés cambiarla por una tuya. La imagen de las colecciones es mostrada en un tamaño dinámico dependiendo de la resolución de la pantalla, así que es mejor usar imágenes que no sufran mucho al ser deformadas. La relación de aspecto promedio que va mejor con esta imagen es 5:1.
  
  `,
  visibility_title: "Visibilidad",
  visibility_description: `Este atributo no está disponible la primera vez que creás una colección, ya que todas las colecciones son privadas en un principio. Vas a poder ver este atributo cuando vayas a editar la colección.

  Hay dos opciones posibles para la visibilidad: privada o pública.

  Las colecciones privadas son pueden ser accedidas por vos, nadie más va a poder ver esta colección.  

  Las colecciones públicas van a estar públicamente disponibles a todos los usuarios, ya que van a poder buscarlas en la Exhibición, e incluso vas a poder compartir links públicos y códigos QR. Para que una colección pueda ser pública debe tener algunos álbumes asignados.  
  
  `,
  banned_fields_title: "Campos ocultos",
  banned_fields_description: `Este atributo no está disponible la primera vez que creás una colección, ya que todas las colecciones son privadas en un principio. Vas a poder ver este atributo cuando vayas a editar la colección.
  
  Ingresá acá todos los atributos del álbum que quieras ocultar de los demás usuarios cuando vean esta colección en la Exhibición, o desde links públicos y códigos QR.
  
  `,
  discount_title: "Descuento",
  discount_description: `Podés asignar un porcentaje de descuento que va a afectar a todos los álbumes en esta colección.

  Por cada álbum, el valor de venta va a ser mostrado ajustado por ese porcentaje de descuento.  

  Si elegís ocultar el valor de venta para una colección pública, entonces solamente va a ser visible el porcentaje de descuento.  
  
  `,
  description_title: "Descripción",
  description_description: `Usá este campo para describir tu colección, explicar de qué se trata, mostrar tus apreciaciones, términos de venta, o cualquier cosa que quieras expresar sobre ella.
  
  `
};
