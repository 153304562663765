import { useBackend } from "../../services";

export const useSignInWithGoogleCode = () => {
  const { post } = useBackend();
  const signInWithGoogleCode = (parameters: ISignInWithGoogleCodeParameters) =>
    post("login/googleCode", parameters);
  return { signInWithGoogleCode };
};

export const useSignUpWithGoogle = () => {
  const { post } = useBackend();
  const signUpWithGoogle = (parameters: ISignInWithGoogleParameters) =>
    post("signup/google", parameters);
  return { signUpWithGoogle };
};

export interface ISignInWithGoogleParameters {
  token: string;
  validateEmail?: string;
}

export interface ISignInWithGoogleCodeParameters {
  code: string;
  validateEmail?: string;
}
