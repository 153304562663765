import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { IExchangeRate, useGetExchangeRates } from "../../../../hooks/exchangeRates";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyExchangeRateDialog } from "../ModifyExchangeRateDialog";
import { ExchangeRateCard } from "../ExchangeRateCard";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { useContextMenu } from "../../../../hooks/contextMenu";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const ExchangeRatesDesktop: FunctionComponent<IExchangeRatesDesktopProps> = ({
  isPublic,
  handleClose,
  columnWidth,
  onExchangeRateSelected
}) => {
  const [loading, setLoading] = useState(true);
  const [exchangeRates, resetExchangeRates, setExchangeRates, noMoreExchangeRates] =
    useGetExchangeRates(setLoading);
  const [filteredExchangeRates, setFilteredExchangeRates] = useState<IExchangeRate[]>();
  const [isAddExchangeRateDialogOpen, setIsAddExchangeRateDialogOpen] = useState(false);
  const [isModifyExchangeRateDialogOpen, setIsModifyExchangeRateDialogOpen] = useState(false);
  const [selectedExchangeRate, setSelectedExchangeRate] = useState<IExchangeRate>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredExchangeRates();
  }, [exchangeRates]);
  const refreshFilteredExchangeRates = () => {
    if (searchText == "") {
      setFilteredExchangeRates(exchangeRates);
      return;
    }
    setFilteredExchangeRates(
      exchangeRates?.filter(exchangeRate => {
        return exchangeRate.rate.toString().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredExchangeRates();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={false}
      items={filteredExchangeRates}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.EXCHANGE_RATES)}
      emptyString={isPublic ? strings.empty_exchange_rates_public : strings.empty_exchange_rates}
      addItemString={strings.add_attribute}
      title={strings.attribute_exchange_rates_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddExchangeRateDialogOpen(true)}
      refreshAction={resetExchangeRates}
    >
      {filteredExchangeRates?.map(exchangeRate => (
        <Grid item key={exchangeRate.uuid} xs={columnWidth}>
          <ExchangeRateCard
            exchangeRate={exchangeRate}
            onClick={() => {
              if (onExchangeRateSelected) {
                onExchangeRateSelected(exchangeRate);
              } else {
                setSelectedExchangeRate(exchangeRate);
                setIsModifyExchangeRateDialogOpen(true);
              }
            }}
            onContextMenu={event => {
              setSelectedExchangeRate(exchangeRate);
              handleContextMenu(event);
            }}
          />
        </Grid>
      ))}
      {(loading || !noMoreExchangeRates) &&
        Array.from({ length: 40 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 60 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyExchangeRateDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifyExchangeRateDialog
        open={isAddExchangeRateDialogOpen}
        setOpen={setIsAddExchangeRateDialogOpen}
        onNewItem={newExchangeRate => {
          const newExchangeRates = exchangeRates;
          newExchangeRates.push(newExchangeRate);
          newExchangeRates.sort((a, b) => b.unixTimestamp - a.unixTimestamp);
          setExchangeRates(newExchangeRates);
          refreshFilteredExchangeRates();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyExchangeRateDialog
        open={isModifyExchangeRateDialogOpen}
        setOpen={setIsModifyExchangeRateDialogOpen}
        exchangeRate={selectedExchangeRate}
        onModifiedItem={newExchangeRate => {
          const newExchangeRates = exchangeRates.map(exchangeRate =>
            exchangeRate.uuid == newExchangeRate.uuid ? newExchangeRate : exchangeRate
          );
          newExchangeRates.sort((a, b) => b.unixTimestamp - a.unixTimestamp);
          setExchangeRates(newExchangeRates);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedExchangeRate => {
          const newExchangeRates = exchangeRates.filter(
            exchangeRate => exchangeRate.uuid != deletedExchangeRate.uuid
          );
          setExchangeRates(newExchangeRates);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface IExchangeRatesDesktopProps {
  isPublic: boolean;
  handleClose?: () => void;
  columnWidth: GridSize;
  onExchangeRateSelected?: (exchangeRate: IExchangeRate) => void;
}
