export const doc_collections_public_collections_es = {
  title: "Colecciones públicas",
  description: `Las colecciones públicas son iguales que las privadas excepto que pueden ser compartidas con otros usuarios usando links y códigos QR, y además pueden ser encontradas en la Exhibición.
  
  Los usuarios pueden dar estrellas a tu colección pública y cada visita va a incrementar el contador de visitas.
  
  Todos los álbumes dentro de una colección pública pueden ser accedidos por cualquier usuario y van a poder ver toda la información cargada excepto por los campos ocultos que hayas definido para la colección.
  
  Cada día, tanto la colección pública como sus álbumes tienen probabilidad de ser destacadas en la página principal de la Exhibición.
  
  `
};
