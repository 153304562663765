import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "@mui/lab";
import { useGetTheme } from "./hooks/theme/useGetTheme";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const theme = useGetTheme();
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
          <Router>
            <Routes />
          </Router>
        </GoogleOAuthProvider>
      </LocalizationProvider>
      <ToastContainer
        theme={theme.palette.mode}
        position={"bottom-right"}
        limit={3}
        newestOnTop
        draggablePercent={20}
      />
    </ThemeProvider>
  );
}

export default App;
