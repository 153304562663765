import { ITrack } from "../albums";
import { useGetVideoId } from "./useGetVideoId";

export const useGetVideoIds = () => {
  const getVideoId = useGetVideoId();
  return async (trackList: ITrack[], albumName: string, artistName: string) => {
    const videoIds: string[] = [];
    for (const track of trackList) {
      const videoId = await getVideoId(`${artistName} ${albumName} ${track.name}`);
      if (videoId) {
        videoIds.push(videoId);
      }
    }
    return videoIds;
  };
};
