export const privacy_policy_es = {
  title: "Política de Privacidad",
  title_short: "P. de Privacidad",
  block1: `Una de nuestras principales prioridades en Piper es la privacidad de nuestros usuarios. Este documento de Política de Privacidad contiene tipos de información que Piper recopila y registra y cómo usamos esa información.
  
  Si tenés preguntas adicionales o requerís más información sobre nuestra Política de Privacidad, no dudes en contactarnos.
  
  Esta Política de Privacidad se aplica sólo a nuestras actividades en línea y es válida para los usuarios de nuestra aplicación con respecto a la información que compartieron y/o recopilaron en Piper. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales que no sean esta aplicación.
  
  `,
  consent_title: "Consentimiento",
  consent: `Al usar nuestra aplicación aceptás nuestra Política de Privacidad y aceptás sus términos.
  
  `,
  info_collect_title: "Información que recopilamos",
  info_collect: `La información personal que te solicitamos proporcionar, y las razones por las que se te la solicita, se te aclararán en el momento en que te solicitemos que proporciones tu información personal.
  Si te comunicás con nosotros directamente, podemos recibir información adicional sobre tu persona, como tu nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/o los archivos adjuntos que nos envíes, y cualquier otra información que elijas proporcionar.
  Cuando te registres para una Cuenta, sólo te vamos a pedir tu dirección de correo electrónico y una contraseña.
  
  `,
  sensitive_info_title: "Información sensible",
  sensitive_info_description: `Consideramos información sensible a tu contraseña, tokens de acceso, y mensajes privados que envíes a otros usuarios. Todos esos campos se guardan encriptados en nuestra base de datos, de forma que nadie pueda leer esos datos sin la llave de encriptación.
  
  `,
  info_use_title: "Cómo usamos tu información",
  info_use: `Usamos la información que recopilamos de varias maneras, incluso para:
  `,
  info_use_1: "Proporcionar, operar y mantener nuestra aplicación.",
  info_use_2: "Mejorar, personalizar y ampliar nuestra aplicación.",
  info_use_3: "Comprender y analizar cómo usás nuestra aplicación.",
  info_use_4: "Desarrollar nuevos productos, servicios, características y funcionalidades.",
  info_use_5:
    "Comunicarnos con vos, ya sea directamente o a través de uno de nuestros socios, incluido el servicio de atención al cliente, para brindarte actualizaciones y otra información relacionada con la aplicación, y con fines promocionales y de marketing.",
  info_use_6: "Enviarte correos electrónicos.",
  info_use_7: `Encontrar y prevenir el fraude.
  
  `,
  log_files_title: "Archivos de registro",
  log_files: `Piper sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan y usan nuestra aplicación. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), marca de fecha y hora, páginas de referencia/salida y, posiblemente, la cantidad de clics. Estos no están vinculados a ninguna información que sea personalmente identificable. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica.
  
  `,
  cookies_title: "Cookies",
  cookies: `Como cualquier otro sitio web, Piper utiliza "cookies". Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes y las páginas del sitio web a las que accedió o visitó el visitante. La información se utiliza para optimizar la experiencia de los usuarios al personalizar el contenido de nuestra página web según el tipo de navegador de los visitantes y/u otra información.
  
  `,
  third_parties_title: "Políticas de Privacidad de terceros",
  third_parties: `La Política de privacidad de Piper no se aplica a otros anunciantes o sitios web. Por lo tanto, te recomendamos que consultes las Políticas de Privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo excluir ciertas opciones.

  Podés optar por deshabilitar las cookies a través de las opciones de tu navegador. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, podés consultar los sitios web respectivos de esos navegadores.
  
  `,
  ccpa_rights_title: "Derechos de privacidad de CCPA (No vender mi información personal)",
  ccpa_rights: `Según la CCPA, entre otros derechos, los consumidores de California tienen derecho a:
  `,
  ccpa_rights_1:
    "Solicitar que una empresa que recopila datos personales de un consumidor divulgue las categorías y piezas específicas de datos personales que una empresa ha recopilado sobre los consumidores.",
  ccpa_rights_2:
    "Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que haya recopilado una empresa.",
  ccpa_rights_3:
    "Solicitar que una empresa que vende los datos personales de un consumidor, no venda los datos personales del consumidor.",
  ccpa_rights_end: `
  Si realizás una solicitud, tenemos un mes para responderte. Si deseás ejercer alguno de estos derechos, ponete en contacto con nosotros.

  `,
  gdpr_rights_title: "Derechos de protección de datos RGPD",
  gdpr_rights: `Nos gustaría asegurarnos de que conocés todos tus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente:
  `,
  gdpr_rights_1:
    "El derecho de acceso: tenés derecho a solicitar copias de tus datos personales. Es posible que te cobremos una tarifa por este servicio.",
  gdpr_rights_2:
    "El derecho a la rectificación: tenés derecho a solicitar que corrijamos cualquier información que creas que es inexacta. También tenés derecho a solicitar que completemos la información que creas que está incompleta.",
  gdpr_rights_3:
    "El derecho a borrar: tenés derecho a solicitar que borremos tus datos personales, bajo ciertas condiciones.",
  gdpr_rights_4:
    "El derecho a restringir el procesamiento: tenés derecho a solicitar que restrinjamos el procesamiento de tus datos personales, bajo ciertas condiciones.",
  gdpr_rights_5:
    "El derecho a oponerse al procesamiento: tenés derecho a oponerte a que procesemos tus datos personales, bajo ciertas condiciones.",
  gdpr_rights_6:
    "El derecho a la portabilidad de datos: tenés derecho a solicitar que transfiramos los datos que hayamos recopilado a otra organización, o directamente a vos, bajo ciertas condiciones.",
  gdpr_rights_end: `
  Si realizás una solicitud, tenemos un mes para responderte. Si deseás ejercer alguno de estos derechos, ponete en contacto con nosotros.
  
  `,
  children_title: "Información de los niños",
  children: `Otra de nuestras prioridades es agregar protección para los niños mientras usan Internet. Alentamos a los padres y tutores a observar, participar y/o monitorear y guiar su actividad en línea.
  
  Piper no recopila a sabiendas ninguna Información de identificación personal de niños menores de 13 años. Si creés que tu hijo proporcionó este tipo de información en nuestro sitio web, te recomendamos encarecidamente que te comuniques con nosotros de inmediato y haremos todo lo posible para eliminar dicha información de nuestros registros.
  
  `,
  changes_title: "Cambios en esta Política de Privacidad",
  changes: `Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Por lo tanto, te recomendamos que revises esta página periódicamente por cualquier cambio. Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. Estos cambios entran en vigencia inmediatamente después de que se publiquen en esta página.
  
  `,
  contact_title: "Contactanos",
  contact: `Si tenés alguna pregunta o sugerencia sobre nuestra Política de Privacidad, no dudes en ponerte en contacto con nosotros.
  
  
  `,
  last_update: "Última actualización: 4 de enero, 2024"
};
