import { useBackend } from "../../services";

export const useDeleteAlbumImage = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbumImage = (parameters: IDeleteAlbumImageParameters) =>
    deleteRequest("albumImages", parameters);
  return { deleteAlbumImage };
};

export interface IDeleteAlbumImageParameters {
  uuid: string;
}
