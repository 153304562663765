import React, { FunctionComponent } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import styles from "./styles.module.scss";
import { SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";

export const SelectListItem: FunctionComponent<ISelectListItemProps> = ({
  icon,
  title,
  labels,
  values,
  value,
  handleChange,
  disabled
}) => {
  return (
    <ListItem>
      <div className={styles.itemDiv}>
        <ListItemIcon>
          <ResponsiveIcon icon={icon} />
        </ListItemIcon>
        <ListItemText disableTypography className={styles.title} secondary={title} />
        <Select
          value={value}
          disabled={disabled}
          className={styles.item}
          onChange={handleChange}
          sx={{ height: "30px" }}
        >
          {labels.map((label, index) => (
            <MenuItem key={`item_${index}`} value={values[index]}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </ListItem>
  );
};

export interface ISelectListItemProps {
  icon: SvgIconComponent;
  title: string;
  labels: string[];
  values: any[];
  value: any;
  handleChange: (event: SelectChangeEvent) => void;
  disabled?: boolean;
}
