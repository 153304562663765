import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { strings } from "../../localization/LocalizedStrings";
import { TextField } from "../TextField";
import { AlertDialog } from "../AlertDialog";
import { useTimer } from "react-timer-hook";
import { useCheckPassword } from "../../hooks/checkPassword";
import { useUpdatePassword } from "../../hooks/users";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { FieldTitle } from "../Text/FieldTitle";
import { ContainedButton } from "../ContainedButton";
import { ToolbarButton } from "../toolbar/ToolbarButton";
import { CloseRounded } from "@mui/icons-material";
import { useUser } from "../../hooks/session";

export const UpdatePasswordDialog: FunctionComponent<IUpdatePasswordDialogProps> = ({
  open,
  setOpen
}) => {
  const [currentUser] = useUser.useState();
  const { updatePassword } = useUpdatePassword();
  const checkPassword = useCheckPassword();
  const [isPasswordWeak, setIsPasswordWeak] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };
  const validationSchema = Yup.object({
    oldPassword: currentUser?.isPasswordSet
      ? Yup.string().required(strings.password_update_error_current)
      : Yup.string().notRequired(),
    newPassword: Yup.string()
      .required(strings.password_update_error_new)
      .test("isPasswordWeak", strings.password_weak, () => !isPasswordWeak),
    repeatPassword: Yup.string()
      .required(strings.password_update_error_repeat)
      .oneOf([Yup.ref("newPassword"), ""], strings.password_mismatch)
  });
  const initialValues: IFormProps = {
    oldPassword: "",
    newPassword: "",
    repeatPassword: ""
  };
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    onSubmit: async ({ oldPassword, newPassword }) => {
      setIsLoading(true);
      const { status, body } = await updatePassword({
        oldPassword: oldPassword,
        password: newPassword
      });
      setIsLoading(false);
      if (StatusCodesHelper.isSuccessful(status)) {
        setIsSuccessAlertOpen(true);
        if (currentUser) {
          currentUser.isPasswordSet = true;
        }
      } else {
        setAlertMessage(body.message);
        setIsAlertOpen(true);
      }
    },
    validationSchema: validationSchema
  });
  const expiryTimestamp = () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 1);
    return date;
  };
  const { pause: stopPasswordTimer, restart: restartPasswordTimer } = useTimer({
    expiryTimestamp: expiryTimestamp(),
    onExpire: () => {
      checkPassword(formik.values.newPassword).then(result => {
        setIsPasswordWeak([0, 1].includes(result?.id ?? -1));
      });
    }
  });
  useEffect(() => {
    stopPasswordTimer();
  }, []);
  useEffect(() => {
    setIsPasswordWeak(false);
    if (formik.values.newPassword == "" || formik.errors.newPassword != undefined) {
      stopPasswordTimer();
      return;
    }
    restartPasswordTimer(expiryTimestamp());
  }, [formik.values.newPassword]);
  useEffect(() => {
    if (!open) return;
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{strings.update_password_title}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {currentUser?.isPasswordSet && (
            <div>
              <FieldTitle>{strings.old_password_title}</FieldTitle>
              <input type="text" style={{ display: "none" }} />
              <input type="password" style={{ display: "none" }} />
              <div className={styles.textField}>
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  size="small"
                  placeholder={strings.old_password_placeholder}
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          )}
          <FieldTitle>{strings.new_password_title}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="newPassword"
              name="newPassword"
              type="password"
              size="small"
              placeholder={strings.new_password_placeholder}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.textField}>
            <TextField
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              size="small"
              placeholder={strings.repeat_password_placeholder}
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
      <AlertDialog
        message={strings.password_update_success}
        open={isSuccessAlertOpen}
        setOpen={setIsSuccessAlertOpen}
        onConfirm={() => {
          handleClose();
        }}
      />
    </FormikProvider>
  );
};

interface IFormProps {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export interface IUpdatePasswordDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}
