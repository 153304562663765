import optionImage from "../../../../images/documentation/album-remove-filtered-en.png";

export const doc_remove_filtered_albums_en = {
  title: "Remove multiple albums at once",
  option_image: optionImage,
  description: `If you right click on the 'clear filter' button on the toolbar you will get the option to remove all filtered albums.
  
  This process will check for the current filter and, after confirmation, it will remove all albums that fall under that filter.
  
  If no filter is currently applied, this will remove ALL YOUR ALBUMS.
  
  If you did this by mistake, contact support. We will look into your case and see if we can restore some of the removed albums.
  
  `
};
