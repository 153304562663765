import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { AlbumsFilter } from "./Component";
import { strings } from "../../../localization/LocalizedStrings";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../../models/FilterElement";
import { CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { ContainedButton } from "../../ContainedButton";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { AlertDialog } from "../../AlertDialog";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { useAlbumFilterManager } from "../../../hooks/albums/useAlbumFilterManager";

export const AlbumsFilterDialog: FunctionComponent<IAlbumsFilterDialogProps> = ({
  isPublic,
  isOpen,
  setIsOpen,
  isLayout,
  onFilterApplied
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const { getCurrentFilterElements, getCurrentFilterSortOption, getCurrentFilterSortOrder } =
    useAlbumFilterManager(isLayout);
  const [filterElements, setFilterElements] = useState<FilterElement[]>(
    getCurrentFilterElements(isPublic)
  );
  const [filterElementsToApply, setFilterElementsToApply] = useState<FilterElement[]>(
    getCurrentFilterElements(isPublic)
  );
  const [sortOption, setSortOption] = useState(getCurrentFilterSortOption(isPublic));
  const [sortOrder, setSortOrder] = useState(getCurrentFilterSortOrder(isPublic));
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [loading] = useState(false);
  useEffect(() => {
    setFilterElementsToApply(filterElements);
  }, [filterElements]);
  useEffect(() => {
    setFilterElements(getCurrentFilterElements(isPublic));
    setSortOption(getCurrentFilterSortOption(isPublic));
    setSortOrder(getCurrentFilterSortOrder(isPublic));
  }, [isOpen, isPublic]);
  const handleClose = () => {
    if (filterElements != filterElementsToApply) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    setIsOpen(false);
    setFilterElementsToApply(filterElements);
  };
  return (
    <Dialog maxWidth={"sm"} open={isOpen} onClose={handleClose} TransitionComponent={Grow}>
      <div className={styles.dialog} style={{ backgroundColor: theme.palette.background.default }}>
        <div className={styles.container}>
          <Toolbar className={styles.toolbar} sx={{ marginBottom: "0 !important" }}>
            <h3 style={{ color: theme.palette.primary.main }}>{strings.option_filter_albums}</h3>
            <Box flexGrow={1} />
            <ToolbarButton
              href={`${window.location.protocol}//${
                window.location.host
              }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.filterAlbums()}`}
              target={"_blank"}
              tooltip={strings.help}
              icon={HelpOutlineRounded}
            />
            <ToolbarButton
              loading={loading}
              onClick={handleClose}
              tooltip={strings.close}
              icon={CloseRounded}
            />
          </Toolbar>
          <Stack direction={"row"} sx={{ px: 4, paddingBottom: 2 }} spacing={2}>
            <Stack sx={{ width: "50%" }}>
              <p className={styles.orderTitle}>{strings.order_by_title}</p>
              <Select
                value={sortOption}
                className={styles.select}
                size={"small"}
                onChange={event => setSortOption(event.target.value as FilterSortOption)}
              >
                <MenuItem value={FilterSortOption.ARTIST} divider dense>
                  {strings.artist_title}
                </MenuItem>
                {(!isPublic || !currentPublicCollection?.bannedFields?.includes("label")) && (
                  <MenuItem value={FilterSortOption.LABEL} divider dense>
                    {strings.label_title}
                  </MenuItem>
                )}
                <MenuItem value={FilterSortOption.ORIGINAL_YEAR} divider dense>
                  {strings.original_year_title}
                </MenuItem>
                <MenuItem value={FilterSortOption.RELEASE_YEAR} divider dense>
                  {strings.release_year_title}
                </MenuItem>
                {(!isPublic || !currentPublicCollection?.bannedFields?.includes("store")) && (
                  <MenuItem value={FilterSortOption.STORE} divider dense>
                    {strings.store_title}
                  </MenuItem>
                )}
                <MenuItem value={FilterSortOption.COUNTRY} divider dense>
                  {strings.country_title}
                </MenuItem>
                {(!isPublic ||
                  !currentPublicCollection?.bannedFields?.includes("purchasePrice")) && (
                  <MenuItem value={FilterSortOption.PURCHASE_PRICE} divider dense>
                    {strings.purchase_price_title}
                  </MenuItem>
                )}
                {(!isPublic || !currentPublicCollection?.bannedFields?.includes("sellValue")) && (
                  <MenuItem value={FilterSortOption.SELL_VALUE} divider dense>
                    {strings.sort_by_sell_value}
                  </MenuItem>
                )}
                {(!isPublic || !currentPublicCollection?.bannedFields?.includes("sellValue")) && (
                  <MenuItem value={FilterSortOption.SELL_VALUE_DOLLARS} divider dense>
                    {strings.sort_by_sell_value_dollars}
                  </MenuItem>
                )}
                {(!isPublic || !currentPublicCollection?.bannedFields?.includes("revenue")) && (
                  <MenuItem value={FilterSortOption.REVENUE} divider dense>
                    {strings.revenue_title}
                  </MenuItem>
                )}
                {(!isPublic ||
                  !currentPublicCollection?.bannedFields?.includes("purchaseDate")) && (
                  <MenuItem value={FilterSortOption.PURCHASE_DATE} divider dense>
                    {strings.purchase_date_title}
                  </MenuItem>
                )}
                <MenuItem value={FilterSortOption.TRACK_COUNT} divider dense>
                  {strings.track_count_title}
                </MenuItem>
                <MenuItem value={FilterSortOption.LENGTH} divider dense>
                  {strings.album_length_title}
                </MenuItem>
                <MenuItem value={FilterSortOption.RANDOM} dense>
                  {strings.random_title}
                </MenuItem>
              </Select>
            </Stack>
            <Stack sx={{ width: "50%" }}>
              <p className={styles.orderTitle}>{strings.order_direction_title}</p>
              <Select
                value={sortOrder}
                className={styles.select}
                size={"small"}
                disabled={sortOption == FilterSortOption.RANDOM}
                onChange={event => setSortOrder(event.target.value as FilterSortOrder)}
              >
                <MenuItem value={FilterSortOrder.ASCENDANT} divider dense>
                  {strings.order_asc_title}
                </MenuItem>
                <MenuItem value={FilterSortOrder.DESCENDANT} dense>
                  {strings.order_desc_title}
                </MenuItem>
              </Select>
            </Stack>
          </Stack>
          <p className={styles.description}>{strings.filter_description}</p>
          <DialogContent className={isMobile ? styles.dialogContentMobile : styles.dialogContent}>
            <AlbumsFilter
              isPublic={isPublic}
              isLayout={isLayout}
              onFilterChanged={setFilterElementsToApply}
            />
          </DialogContent>
          <DialogActions>
            <ContainedButton
              onClick={() => {
                setIsOpen(false);
                setFilterElements(filterElementsToApply);
                onFilterApplied(filterElementsToApply, sortOption, sortOrder);
              }}
            >
              {strings.filter_confirm}
            </ContainedButton>
          </DialogActions>
        </div>
      </div>
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={() => {
          setIsOpen(false);
          setFilterElementsToApply(filterElements);
        }}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
    </Dialog>
  );
};

export interface IAlbumsFilterDialogProps {
  isPublic: boolean;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  isLayout: boolean;
  onFilterApplied: (
    filterElements: FilterElement[],
    sortOption: FilterSortOption,
    sortOrder: FilterSortOrder
  ) => void;
}
