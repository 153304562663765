import { useBackend } from "../../services";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useGetPaginatedAttributesByPage } from "../attributes/useGetPaginatedAttributesByPage";
import { IMessage } from "./interfaces";

export const useGetMessagesForChat = <T>(
  chatId: string,
  setLoading: (loading: boolean) => void,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const getMessages = (parameters: any) => get("messages/chat", parameters);
  return useGetPaginatedAttributesByPage<IMessage, T>(
    PageSizeHelper.chatMessages(),
    { chatId: chatId },
    getMessages,
    setLoading,
    true,
    true,
    dependencies
  );
};
