import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Card } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";

export const PaymentStatusCard: FunctionComponent<IPaymentStatusCardProps> = ({ status }) => {
  const redColor = "#ff2f00";
  const greenColor = "#10b61c";
  const yellowColor = "#fa8802";
  const [text, setText] = useState("");
  const [color, setColor] = useState(yellowColor);
  useEffect(() => {
    switch (status) {
      case "authorized":
        setText(strings.payment_authorized);
        setColor(greenColor);
        break;
      case "cancelled":
        setText(strings.payment_cancelled);
        setColor(redColor);
        break;
      case "pending":
        setText(strings.payment_pending);
        setColor(yellowColor);
        break;
      case "paused":
        setText(strings.payment_paused);
        setColor(redColor);
        break;
    }
  }, [status]);
  return (
    <Card
      className={styles.card}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color
      }}
    >
      <p className={styles.description}>{text}</p>
    </Card>
  );
};

export interface IPaymentStatusCardProps {
  status: PaymentStatus;
}

export type PaymentStatus = "pending" | "authorized" | "paused" | "cancelled";
