import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import { useAddAlbumFormat } from "../../../../hooks/albumFormats/useAddAlbumFormat";
import { useModifyAlbumFormat } from "../../../../hooks/albumFormats/useModifyAlbumFormat";
import { useDeleteAlbumFormat } from "../../../../hooks/albumFormats/useDeleteAlbumFormat";
import { IAlbumFormat } from "../../../../hooks/albumFormats";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { FieldTitle } from "../../../Text/FieldTitle";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { getAnalytics, logEvent } from "firebase/analytics";

export const ModifyAlbumFormatDialog: FunctionComponent<IModifyAlbumFormatDialogProps> = ({
  open,
  setOpen,
  albumFormat,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [dialogTitle, setDialogTitle] = useState(strings.maf_new_title);
  const { addAlbumFormat } = useAddAlbumFormat();
  const { modifyAlbumFormat } = useModifyAlbumFormat();
  const { deleteAlbumFormat } = useDeleteAlbumFormat();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.field_missing_short),
    shortName: Yup.string().required(strings.field_missing_short)
  });
  const formik = useFormik({
    initialValues: {
      name: albumFormat?.name ?? "",
      shortName: albumFormat?.shortName ?? ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ name, shortName }) => {
      setIsLoading(true);
      if (albumFormat) {
        const { status, body } = await modifyAlbumFormat({
          uuid: albumFormat.uuid,
          name: name,
          shortName: shortName
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_format_action", { isNew: true });
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addAlbumFormat({
          name: name,
          shortName: shortName
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_format_action", { isNew: false });
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!albumFormat) return;
    setIsLoading(true);
    const { status, body } = await deleteAlbumFormat({ uuid: albumFormat.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(albumFormat);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(albumFormat ? strings.album_format_title : strings.maf_new_title);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {albumFormat && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.maf_name_title}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="name"
              name="name"
              size="small"
              autoFocus={!isMobile}
              placeholder={strings.maf_name_placeholder}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <FieldTitle>{strings.maf_short_name_title}</FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="shortName"
              name="shortName"
              size="small"
              placeholder={strings.maf_short_name_placeholder}
              value={formik.values.shortName}
              onChange={formik.handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyAlbumFormatDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  albumFormat?: IAlbumFormat;
  onNewItem?: (item: IAlbumFormat) => void;
  onModifiedItem?: (item: IAlbumFormat) => void;
  onDeletedItem?: (item: IAlbumFormat) => void;
}
