import { useEffect, useState } from "react";
import { ThemeManager } from "../../models/ThemeManager";

export const useGetTheme = () => {
  const initialTheme = ThemeManager.currentTheme();

  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTheme = ThemeManager.currentTheme();
      if (theme !== newTheme) {
        setTheme(newTheme);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [theme]);

  return theme;
};
