import { useBackend } from "../../services";

export const useDeleteAlbumContent = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbumContent = (parameters: IDeleteAlbumContentParameters) =>
    deleteRequest("albumContents", parameters);
  return { deleteAlbumContent };
};

export interface IDeleteAlbumContentParameters {
  uuid: string;
}
