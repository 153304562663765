import { Level } from "level";
import { DateHelper } from "./DateHelper";

export class ImageCache {
  private db;
  static shared = new ImageCache();
  private constructor() {
    try {
      this.db = new Level("images-db");
    } catch {
      return;
    }
  }
  async getCachedImage(name: string) {
    try {
      return await this.db?.get(name);
    } catch {
      return undefined;
    }
  }
  async saveImage(name: string, image: string) {
    try {
      await this.db?.put(name, image);
      return true;
    } catch {
      return false;
    }
  }
  async removeImage(name: string) {
    try {
      await this.db?.del(name);
      return true;
    } catch {
      return false;
    }
  }
  async clear() {
    try {
      await this.db?.clear();
      localStorage.setItem("lastImageCacheClearance", String(DateHelper.dateNowInSeconds()));
      return true;
    } catch {
      return false;
    }
  }
  isCacheOlderThan(unixTimestamp: number) {
    const lastImageCacheClearance = localStorage.getItem("lastImageCacheClearance") ?? "0";
    return +lastImageCacheClearance < unixTimestamp;
  }
  static smallAlbumImageSize = 120;
  static bigAlbumImageSize = 1000;
  static smallArtistImageSize = 160;
  static bigArtistImageSize = 600;
  static smallLabelImageSize = 60;
  static bigLabelImageSize = 300;
  static smallAvatarImageSize = 160;
  static bigAvatarImageSize = 320;
  static smallCollectionImageWidth = 600;
  static smallCollectionImageHeight = 240;
  static bigCollectionImageWidth = 1000;
  static bigCollectionImageHeight = 400;
}
