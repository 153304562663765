import { useBackend } from "../../services";

export const useAddExchangeRate = () => {
  const { post } = useBackend();
  const addExchangeRate = (parameters: IAddExchangeRateParameters) =>
    post("exchangeRates", parameters);
  return { addExchangeRate };
};

export interface IAddExchangeRateParameters {
  unixTimestamp: number;
  rate: number;
}
