import deleteImage from "../../../../images/documentation/delete-album-es.png";
import createImage from "../../../../images/documentation/create-album-es.png";

export const doc_albums_es = {
  title: "Álbumes",
  home_title: "Manejar tus álbumes",
  description: `Llamamos álbum al conjunto de grabaciones musicales que puede estar representado en un disco de vinilo, disco compacto, cassette, etc. En Piper los álbumes cuentan con distintos atributos totalmente personalizables para que puedas administrar tu colección de la manera que prefieras. También tenés varias opciones para ordenar y organizar tus álbumes por artista, sello discográfico y mucho más.
  
  Existe también la posibilidad de organizar los álbumes en distintas colecciones y revisar estadísticas independientemente para cada una de ellas.

  ¡Pensá en grande! Agregá a tu colección todos tus vinilos, CD's, o incluso álbumes que tengas en formato digital. Personalizá tu colección de manera que cumpla con tus necesidades.

`,
  create_title: "Agregar un álbum",
  create_image: createImage,
  create_description: `
  Para agregar un nuevo álbum basta con hacer click en el botón de '+' en la barra de herramientas de la página de 'Mis álbumes'.

  Un nuevo diálogo se va a abrir donde se pueden ingresar todos los atributos del nuevo álbum. Se puede navegar haciendo click en cada campo o presionando la tecla TAB y presionando ENTER en los campos donde se requiere selección.
  
  Para modificar la imagen basta con arrastrar la nueva imagen sobre la vieja, o hacer click sobre la imagen y seleccionar una nueva.
  
  Una vez confirmes, un texto rojo va a aparecer debajo de los campos obligatorios que no estén llenados. Si todos los campos mandatorios están completos entonces el álbum va a ser creado.

`,
  delete_image: deleteImage,
  delete_title: "Eliminar un álbum",
  delete_description: `Para eliminar un álbum, hacé click derecho sobre él en la lista de álbumes y seleccioná la opción de eliminar. Un diálogo de confirmación va a aparecer para que puedas chequear el nombre del álbum antes de eliminarlo.
  
  Esta operación es irreversible, así que asegurate de estar eliminando el álbum correcto. El álbum eliminado va a ser desasignado automáticamente de todas las colecciones a las que estaba asignado. Si alguien accede a un link compartido del álbum eliminado, van a poder seguir viendo la colección pública y simplemente el álbum no va a ser cargado.
  
`
};
