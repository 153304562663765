import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea } from "@mui/material";
import { IExchangeRate } from "../../../../hooks/exchangeRates";
import { format } from "date-fns";
import { strings } from "../../../../localization/LocalizedStrings";
import { useCurrencyFormatter } from "../../../../hooks/currencyFormatter";
import { useUserProfile } from "../../../../hooks/session";

export const ExchangeRateCard: FunctionComponent<IExchangeRateCardProps> = ({
  exchangeRate,
  onClick,
  onContextMenu
}) => {
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  const getDate = () => {
    const date = new Date(exchangeRate.unixTimestamp * 1000);
    return format(date, "d MMM yyyy", { locale: strings.fnsLocale });
  };
  return (
    <Card sx={{ height: 60 }} elevation={5}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <p className={styles.rate}>
          {currencyFormatter.format(exchangeRate.rate, currentUserProfile, false, 2)}
        </p>
        <p className={styles.date}>{getDate()}</p>
      </CardActionArea>
    </Card>
  );
};

export interface IExchangeRateCardProps {
  exchangeRate: IExchangeRate;
  onClick: () => void;
  onContextMenu: (event: React.MouseEvent) => void;
}
