import { useBackend } from "../../services";

export const useStarCollection = () => {
  const { post } = useBackend();
  const starCollection = (parameters: IStarCollectionParameters) =>
    post("user/starCollection", parameters);
  return { starCollection };
};

export interface IStarCollectionParameters {
  collectionId: string;
}
