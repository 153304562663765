import signUpThirdPartyImage from "../../../../images/documentation/sign-up-third-party-en.png";
import newUserImage from "../../../../images/documentation/new-user-en.png";

export const doc_users_sign_up_en = {
  title: "Create a new user",
  title_users: "Users",
  home_title: "Sign up",
  description: `To use Piper you first need a user. You have three options for this:
  `,
  description_1: "Create a user using Piper.",
  description_2: "Sign up with Google.",
  description_3: "Sign up with Apple.",
  sign_up_third_party_title: "Sign up with Google/Apple",
  sign_up_third_party_image: signUpThirdPartyImage,
  sign_up_third_party_description: `The easiest way to sign up is to use one of the buttons to continue with Google or Apple. You will just have to log in using your existing credentials for any of those services and you will automatically get signed into Piper. It's that simple!
  
  The created user will have your email as a username but you can change it right away through your user profile. Any time you have to sign in again you can use the same button you used before, or you can create a password and sign in using it as well, all authentication methods are interchangeable.
  
`,
  sign_up_piper_title: "Sign up with Piper",
  sign_up_piper_image: newUserImage,
  sign_up_piper_description: `To sign up with Piper select the "create a new user" button, or select the same option on the drawer menu.
  
  A new window will open where you can enter your email address and a password. Once you enter those fields and accept the terms of service, a verification code will be sent to your email.
  
  When you try to sign in for the first time, the system will ask you for the verification code. Once you enter it you will be good to go!
  
  If you didn't get the code or you lost it you can ask for a new one using the resend button.
  
`
};
