import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Card,
  CardActionArea,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  useTheme
} from "@mui/material";
import { IAlbumType, useGetAlbumTypes } from "../../../../hooks/albumTypes";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyAlbumTypeDialog } from "../ModifyAlbumTypeDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const AlbumTypesDesktop: FunctionComponent<IAlbumTypesDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onAlbumTypeSelected,
  onClearValue
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [albumTypes, resetAlbumTypes, setAlbumTypes, noMoreAlbumTypes] = useGetAlbumTypes(
    isPublic,
    setLoading
  );
  const [filteredAlbumTypes, setFilteredAlbumTypes] = useState<IAlbumType[]>();
  const [isAddAlbumTypeDialogOpen, setIsAddAlbumTypeDialogOpen] = useState(false);
  const [isModifyAlbumTypeDialogOpen, setIsModifyAlbumTypeDialogOpen] = useState(false);
  const [selectedAlbumType, setSelectedAlbumType] = useState<IAlbumType>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredAlbumTypes();
  }, [albumTypes]);
  const refreshFilteredAlbumTypes = () => {
    if (searchText == "") {
      setFilteredAlbumTypes(albumTypes);
      return;
    }
    setFilteredAlbumTypes(
      albumTypes?.filter(albumType => {
        return albumType.name.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredAlbumTypes();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredAlbumTypes}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.ALBUM_TYPES)}
      emptyString={isPublic ? strings.empty_album_types_public : strings.empty_album_types}
      addItemString={strings.add_attribute}
      title={strings.attribute_album_types_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddAlbumTypeDialogOpen(true)}
      refreshAction={resetAlbumTypes}
      onClearValue={onClearValue}
    >
      {filteredAlbumTypes?.map(albumType => (
        <Grid item key={albumType.uuid} xs={columnWidth}>
          <Card sx={{ height: 64 }} elevation={5}>
            <CardActionArea
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
              style={{
                borderColor: theme.palette.colors?.highlightBorder,
                borderWidth: 4,
                borderStyle: preselectedId == albumType.uuid ? "double" : "none"
              }}
              onClick={() => {
                if (onAlbumTypeSelected) {
                  onAlbumTypeSelected(albumType);
                } else {
                  setSelectedAlbumType(albumType);
                  setIsModifyAlbumTypeDialogOpen(true);
                }
              }}
              onContextMenu={event => {
                setSelectedAlbumType(albumType);
                handleContextMenu(event);
              }}
            >
              <h6 className={styles.name}>{albumType.name}</h6>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {(loading || !noMoreAlbumTypes) &&
        Array.from({ length: 4 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyAlbumTypeDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifyAlbumTypeDialog
        open={isAddAlbumTypeDialogOpen}
        setOpen={setIsAddAlbumTypeDialogOpen}
        onNewItem={newAlbumType => {
          const newAlbumTypes = albumTypes;
          newAlbumTypes.push(newAlbumType);
          newAlbumTypes.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setAlbumTypes(newAlbumTypes);
          refreshFilteredAlbumTypes();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyAlbumTypeDialog
        open={isModifyAlbumTypeDialogOpen}
        setOpen={setIsModifyAlbumTypeDialogOpen}
        albumType={selectedAlbumType}
        onModifiedItem={newAlbumType => {
          const newAlbumTypes = albumTypes.map(albumType =>
            albumType.uuid == newAlbumType.uuid ? newAlbumType : albumType
          );
          newAlbumTypes.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setAlbumTypes(newAlbumTypes);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedAlbumType => {
          const newAlbumTypes = albumTypes.filter(
            albumType => albumType.uuid != deletedAlbumType.uuid
          );
          setAlbumTypes(newAlbumTypes);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface IAlbumTypesDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: number;
  preselectedId?: string;
  onAlbumTypeSelected?: (albumType: IAlbumType) => void;
  onClearValue?: () => void;
}
