import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { ISellValue } from "../../../../hooks/sellValues";
import { useCurrencyFormatter } from "../../../../hooks/currencyFormatter";
import { useUserProfile } from "../../../../hooks/session";

export const SellValueCard: FunctionComponent<ISellValueCardProps> = ({
  sellValue,
  selected,
  onClick,
  onContextMenu
}) => {
  const theme = useTheme();
  const currencyFormatter = useCurrencyFormatter();
  const [currentUserProfile] = useUserProfile.useState();
  return (
    <Card className={styles.container} sx={{ height: 120 }} elevation={5}>
      <CardActionArea
        className={styles.cardArea}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row"
        }}
        style={{
          borderColor: theme.palette.colors?.highlightBorder,
          borderWidth: 4,
          borderStyle: selected ? "double" : "none"
        }}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <Stack className={styles.mainStack} spacing={1}>
          <div className={styles.groupDiv}>
            <Card sx={{ padding: 0.5, textAlign: "center" }}>
              <p className={styles.group}>{sellValue.group}</p>
            </Card>
          </div>
          <p className={styles.dollars}>{`${currencyFormatter.format(
            sellValue.dollars,
            currentUserProfile,
            false,
            2,
            "USD"
          )}`}</p>
          <p className={styles.description}>{sellValue.description}</p>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface ISellValueCardProps {
  sellValue: ISellValue;
  selected?: boolean;
  onClick: () => void;
  onContextMenu: (event: React.MouseEvent) => void;
}
