export const doc_mercado_pago_subscriptions_es = {
  title: "Suscribirse usando Mercado Pago",
  description: `Piper está integrado con Mercado Pago para que puedas pagar tu suscripción usando la plataforma. Este tipo de pago está disponible solamente en algunos países de Sudamérica.

  Si ya tenés una cuenta de Mercado Pago vas a poder elegir pagar con tu cuenta existente y vas a tener disponibles todas tus tarjetas, así como también los fondos en tu cuenta. Es importante que una vez que se realizó el pago uses el botón que se muestra para volver al sitio para que podamos registrar la suscripción en nuestra base de datos. Si no hacés este paso no vas a ver la suscripción en Piper, pero no te preocupes, en ese caso contactanos y vamos a solucionar el inconveniente lo antes posible.  
  
  La suscripción se puede administrar en su totalidad en la plataforma de Mercado Pago. Podés revisar tus pagos, cuándo va a ser el próximo cobro, cancelar la suscripción y más.

  Las suscripciones son cobradas el primer día de cada mes. Cuando hacés el primer pago, el monto va a ser proporcional a la cantidad de días que restan al primer día del próximo mes.  

  Si cancelás tu suscripción en cualquier momento vas a poder seguir disfrutando de tus privilegios hasta el primer día del mes siguiente.  
  
`
};
