import { IShelving } from "./interfaces";
import { IShelfSegment } from "../shelfSegments";
import { IShelf } from "../shelves";

export const useShelvingStats = () => {
  const shelvingAlbumCount = (shelving: IShelving) =>
    shelving.shelves.reduce((value, shelf) => value + shelfAlbumCount(shelf), 0);
  const shelfAlbumCount = (shelf: IShelf) =>
    shelf.segments.reduce((value, segment) => value + shelfSegmentAlbumCount(segment), 0);
  const shelfSegmentAlbumCount = (shelfSegment: IShelfSegment) => shelfSegment.albums?.length ?? 0;
  return { shelvingAlbumCount, shelfAlbumCount, shelfSegmentAlbumCount };
};
