import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { AppBar, Box, Drawer, Stack, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { MainDrawer } from "../../components/MainDrawer";
import { AttributeList, AttributeType } from "../../components/attributes/AttributeList";
import { LabelsDesktop } from "../../components/attributes/labels/LabelsDesktop";
import { StoresDesktop } from "../../components/attributes/stores/StoresDesktop";
import { ArtistsDesktop } from "../../components/attributes/artists/ArtistsDesktop";
import { SleeveGradesDesktop } from "../../components/attributes/sleeveGrades/SleeveGradesDesktop";
import { MediaGradesDesktop } from "../../components/attributes/mediaGrades/MediaGradesDesktop";
import { SellValuesDesktop } from "../../components/attributes/sellValues/SellValuesDesktop";
import { AlbumFormatsDesktop } from "../../components/attributes/albumFormats/AlbumFormatsDesktop";
import { AlbumTypesDesktop } from "../../components/attributes/albumTypes/AlbumTypesDesktop";
import { ImpactsDesktop } from "../../components/attributes/impacts/ImpactsDesktop";
import { ExchangeRatesDesktop } from "../../components/attributes/exchangeRates/ExchangeRatesDesktop";
import { CloseRounded, ListAltRounded } from "@mui/icons-material";
import { UserProfileCard } from "../../components/userProfiles/UserProfileCard";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { useIsMobile } from "../../hooks/isMobile";
import { ShortcutsGrid } from "../../components/ShortcutsGrid";
import { useUser, useUserProfile } from "../../hooks/session";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { useIsScreenWide } from "../../hooks/isScreenWide";
import { TagsDesktop } from "../../components/attributes/tags/TagsDesktop";

export const Attributes: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.attributes_title}`;
  }, []);
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [selectedAttribute, setSelectedAttribute] = useState<AttributeType>();
  const [isAttributeListDrawerOpen, setIsAttributeListDrawerOpen] = useState(isMobile);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  const getAttributeDesktop = () => {
    switch (selectedAttribute) {
      case AttributeType.ARTISTS:
        return <ArtistsDesktop isPublic={false} columnWidth={isMobile ? 12 : 3} />;
      case AttributeType.STORES:
        return <StoresDesktop isPublic={false} columnWidth={isMobile ? 6 : 3} />;
      case AttributeType.LABELS:
        return <LabelsDesktop isPublic={false} columnWidth={isMobile ? 12 : 4} />;
      case AttributeType.ALBUM_FORMATS:
        return <AlbumFormatsDesktop isPublic={false} columnWidth={isMobile ? 6 : 3} />;
      case AttributeType.ALBUM_TYPES:
        return <AlbumTypesDesktop isPublic={false} columnWidth={isMobile ? 6 : 3} />;
      case AttributeType.SLEEVE_GRADES:
        return <SleeveGradesDesktop columnWidth={isMobile ? 12 : 4} />;
      case AttributeType.MEDIA_GRADES:
        return <MediaGradesDesktop columnWidth={isMobile ? 12 : 4} />;
      case AttributeType.SELL_VALUES:
        return <SellValuesDesktop isPublic={false} columnWidth={isMobile ? 6 : 3} />;
      case AttributeType.IMPACTS:
        return <ImpactsDesktop columnWidth={isMobile ? 4 : 2} />;
      case AttributeType.EXCHANGE_RATES:
        return <ExchangeRatesDesktop isPublic={false} columnWidth={isMobile ? 4 : 2} />;
      case AttributeType.TAGS:
        return <TagsDesktop isPublic={false} columnWidth={isMobile ? 6 : 3} />;
      default:
        return (
          currentUser && (
            <Stack className={styles.noSelectionDiv}>
              <p className={styles.shortcutsTitle} style={{ color: theme.palette.primary.main }}>
                {strings.shortcuts_title}
              </p>
              <ShortcutsGrid
                className={styles.shortcutsGrid}
                isPublic={false}
                showAttributesShortcut={false}
                onImportFromDiscogs={() => setIsOpenDiscogsImport(true)}
                onImportFromExcel={() => setIsOpenExcelImport(true)}
                onEditProfile={goToUserProfile}
              />
            </Stack>
          )
        );
    }
  };
  const attributeList = () => (
    <div
      className={styles.attributeListContainer}
      style={{ backgroundColor: theme.palette.background.default }}
    >
      <AttributeList
        onAttributeSelected={attributeType => {
          setIsAttributeListDrawerOpen(false);
          setSelectedAttribute(attributeType);
        }}
      />
    </div>
  );
  const goToUserProfile = () => navigate(RoutesBuilder.userProfile());
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              style={{ marginRight: isMobile ? 0 : 8 }}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={"attributes"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
          />
          {isMobile && (
            <ToolbarButton
              onClick={() => {
                setIsAttributeListDrawerOpen(true);
              }}
              tooltip={strings.open_attributes_list}
              icon={ListAltRounded}
            />
          )}
          <h3 className={styles.title}>{strings.option_attributes}</h3>
          <Box flexGrow={1} />
          <UserProfileCard
            isPublic={false}
            userProfile={currentUserProfile}
            onClick={() => {
              if (!currentUserProfile || currentUser?.role == "guest") return;
              goToUserProfile();
            }}
          />
        </Toolbar>
      </AppBar>
      <div className={styles.mainBody}>
        {!isMobile && attributeList()}
        <div
          className={styles.attributeDetail}
          style={{ backgroundColor: theme.palette.colors?.main }}
        >
          {getAttributeDesktop()}
          <Box flexGrow={1} />
          <AppCredits className={styles.credits} />
        </div>
      </div>
      {isMobile && (
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default
            }
          }}
          anchor={"left"}
          open={isAttributeListDrawerOpen}
          onClose={() => setIsAttributeListDrawerOpen(false)}
        >
          <div>
            <AppBar position={"sticky"}>
              <Toolbar>
                <Box flexGrow={1} />
                <ToolbarButton
                  onClick={() => setIsAttributeListDrawerOpen(false)}
                  tooltip={strings.close}
                  icon={CloseRounded}
                />
              </Toolbar>
            </AppBar>
            {attributeList()}
          </div>
        </Drawer>
      )}
    </MainContent>
  );
};
