import { useBackend } from "../../services";

export const useAddLabel = () => {
  const { post } = useBackend();
  const addLabel = (parameters: IAddLabelParameters) => post("labels", parameters);
  return { addLabel };
};

export interface IAddLabelParameters {
  name: string;
  discogsId?: string;
  profile?: string;
  website?: string;
}
