import React, { FunctionComponent } from "react";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "../subscriptions/DocumentationSubscriptions/styles.module.scss";

export const PrivacyPolicy: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.privacy_policy.title}</h1>
      <p>{strings.privacy_policy.block1}</p>
      <section id={"consent"} />
      <h2>{strings.privacy_policy.consent_title}</h2>
      <p>{strings.privacy_policy.consent}</p>
      <section id={"info-collect"} />
      <h2>{strings.privacy_policy.info_collect_title}</h2>
      <p>{strings.privacy_policy.info_collect}</p>
      <section id={"info-sensitive"} />
      <h2>{strings.privacy_policy.sensitive_info_title}</h2>
      <p>{strings.privacy_policy.sensitive_info_description}</p>
      <section id={"info-use"} />
      <h2>{strings.privacy_policy.info_use_title}</h2>
      <p>{strings.privacy_policy.info_use}</p>
      <ul className={styles.indentedText}>
        <li>{strings.privacy_policy.info_use_1}</li>
        <li>{strings.privacy_policy.info_use_2}</li>
        <li>{strings.privacy_policy.info_use_3}</li>
        <li>{strings.privacy_policy.info_use_4}</li>
        <li>{strings.privacy_policy.info_use_5}</li>
        <li>{strings.privacy_policy.info_use_6}</li>
        <li>{strings.privacy_policy.info_use_7}</li>
      </ul>
      <section id={"log-files"} />
      <h2>{strings.privacy_policy.log_files_title}</h2>
      <p>{strings.privacy_policy.log_files}</p>
      <section id={"cookies"} />
      <h2>{strings.privacy_policy.cookies_title}</h2>
      <p>{strings.privacy_policy.cookies}</p>
      <section id={"third-parties"} />
      <h2>{strings.privacy_policy.third_parties_title}</h2>
      <p>{strings.privacy_policy.third_parties}</p>
      <section id={"ccpa-rights"} />
      <h2>{strings.privacy_policy.ccpa_rights_title}</h2>
      <p>{strings.privacy_policy.ccpa_rights}</p>
      <ul className={styles.indentedText}>
        <li>{strings.privacy_policy.ccpa_rights_1}</li>
        <li>{strings.privacy_policy.ccpa_rights_2}</li>
        <li>{strings.privacy_policy.ccpa_rights_3}</li>
      </ul>
      <p>{strings.privacy_policy.ccpa_rights_end}</p>
      <section id={"gdpr-rights"} />
      <h2>{strings.privacy_policy.gdpr_rights_title}</h2>
      <p>{strings.privacy_policy.gdpr_rights}</p>
      <ul className={styles.indentedText}>
        <li>{strings.privacy_policy.gdpr_rights_1}</li>
        <li>{strings.privacy_policy.gdpr_rights_2}</li>
        <li>{strings.privacy_policy.gdpr_rights_3}</li>
        <li>{strings.privacy_policy.gdpr_rights_4}</li>
        <li>{strings.privacy_policy.gdpr_rights_5}</li>
        <li>{strings.privacy_policy.gdpr_rights_6}</li>
      </ul>
      <p>{strings.privacy_policy.gdpr_rights_end}</p>
      <section id={"changes"} />
      <h2>{strings.privacy_policy.changes_title}</h2>
      <p>{strings.privacy_policy.changes}</p>
      <section id={"contact"} />
      <h2>{strings.privacy_policy.contact_title}</h2>
      <p>{strings.privacy_policy.contact}</p>
      <p style={{ fontWeight: "bold" }}>{strings.privacy_policy.last_update}</p>
    </div>
  );
};
