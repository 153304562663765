import styles from "./styles.module.scss";
import { useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { strings } from "../../localization/LocalizedStrings";
import classNames from "classnames";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { useIsMobile } from "../../hooks/isMobile";

export const AppCredits: FunctionComponent<IAppCreditsProps> = ({ className, textClassName }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <p className={classNames(styles.credits, className)}>
      <a
        href={`${window.location.protocol}//${
          window.location.host
        }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.about()}`}
        target={"_blank"}
        className={textClassName}
        style={{ color: theme.palette.primary.subtitle }}
      >
        {strings.about_app_title}
      </a>
      {"   "}
      <a
        href={`${window.location.protocol}//${
          window.location.host
        }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.policy()}`}
        target={"_blank"}
        className={textClassName}
        style={{ color: theme.palette.primary.subtitle }}
      >
        {isMobile ? strings.privacy_policy.title_short : strings.privacy_policy.title}
      </a>
      {"   "}
      <a
        href={`${window.location.protocol}//${
          window.location.host
        }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.terms()}`}
        target={"_blank"}
        className={textClassName}
        style={{ color: theme.palette.primary.subtitle }}
      >
        {isMobile ? strings.terms.title_short : strings.terms.title}
      </a>
      {"   "}
      <a
        href={`mailto: ${strings.support_mail}`}
        className={textClassName}
        style={{ color: theme.palette.primary.subtitle }}
      >
        {strings.contact_us}
      </a>
      {"   "}
      <span className={textClassName} style={{ color: theme.palette.primary.subtitle }}>
        {isMobile ? strings.credits_short : strings.credits}
      </span>
    </p>
  );
};

export interface IAppCreditsProps {
  className?: string;
  textClassName?: string;
}
