import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ITrack } from "../../../hooks/albums";
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useTheme
} from "@mui/material";
import { TimeInterval } from "../../../models/TimeInterval";
import { strings } from "../../../localization/LocalizedStrings";
import { useGetVideoId } from "../../../hooks/youTube";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { CompressRounded, ExpandRounded, MoreVertRounded, YouTube } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useContextMenu } from "../../../hooks/contextMenu";
import useLongPress from "../../../hooks/longPress";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import classNames from "classnames";

export const TrackListItem: FunctionComponent<ITrackListItemProps> = ({
  track,
  artistName,
  albumName,
  onPlayFromYouTube
}) => {
  const theme = useTheme();
  const getVideoId = useGetVideoId();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const handleLongPress = useLongPress({ onLongPress: handleContextMenu });
  const playFromYouTube = async () => {
    setIsLoading(true);
    const videoId = await getVideoId(`${artistName} ${albumName} ${track.name}`);
    if (videoId) {
      onPlayFromYouTube(videoId);
    } else {
      toast.error(strings.player_unknown_error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsExpanded(false);
  }, [track]);
  return (
    <div className={styles.container} onContextMenu={handleContextMenu} {...handleLongPress}>
      <ListItemButton disableGutters onClick={() => setIsExpanded(state => !state)}>
        <Stack direction={"row"} flexGrow={1} spacing={1} alignItems={"center"}>
          <p className={styles.position}>{track.position}</p>
          <Stack>
            <p className={styles.name}>{track.name}</p>
            <p
              className={styles.extraArtists}
              style={{
                color: theme.palette.secondary.main,
                WebkitLineClamp: isExpanded ? undefined : 1
              }}
            >
              {track.extraArtists?.join("\r\n")}
            </p>
          </Stack>
          <Box flexGrow={1} />
          {isLoading ? (
            <CustomLoadingIndicator className={styles.loading} />
          ) : (
            <p className={classNames(styles.length, styles.hideOnHover)}>
              {track.length ? TimeInterval.toMMSS(track.length) : undefined}
            </p>
          )}
        </Stack>
      </ListItemButton>
      <Stack className={styles.hoverButtonsStack} direction={"row"} spacing={1}>
        <Tooltip title={strings.options} arrow disableInteractive>
          <IconButton
            className={styles.hoverButton}
            style={{ backgroundColor: theme.palette.colors?.counterBackground }}
            onClick={event => {
              event.stopPropagation();
              handleContextMenu(event);
            }}
          >
            <ResponsiveIcon className={styles.hoverButtonIcon} icon={MoreVertRounded} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Menu {...menuProps}>
        <MenuItem
          onClick={async () => {
            handleMenuClose();
            await playFromYouTube();
          }}
          dense
        >
          <ListItemIcon>
            <YouTube fontSize={"small"} />
          </ListItemIcon>
          {strings.play_from_youtube}
        </MenuItem>
        {track.extraArtists && track.extraArtists.length > 0 && track.extraArtists[0] != "" && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsExpanded(state => !state);
            }}
            dense
          >
            <ListItemIcon>
              {isExpanded ? (
                <CompressRounded fontSize={"small"} />
              ) : (
                <ExpandRounded fontSize={"small"} />
              )}
            </ListItemIcon>
            {isExpanded ? strings.shrink : strings.expand}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export interface ITrackListItemProps {
  track: ITrack;
  artistName: string;
  albumName: string;
  onPlayFromYouTube: (videoId: string) => void;
}
