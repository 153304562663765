import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationPublicCollections: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_collections_public_collections.title}</h1>
      <p>{strings.doc_collections_public_collections.description}</p>
    </div>
  );
};
