import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { omit } from "lodash";
import { Box, Button } from "@mui/material";
import { ToolbarBox } from "../toolbar/ToolbarBox";
import classNames from "classnames";

export const ContainedButton: FunctionComponent<any> = props => {
  return (
    <ToolbarBox className={classNames(styles.box, props.className)}>
      <Button sx={{ width: "100%", height: "100%" }} {...omit(props, "className", "children")}>
        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <p className={styles.title}>{props.children}</p>
        </Box>
      </Button>
    </ToolbarBox>
  );
};
