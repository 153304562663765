import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { IPublicCollection } from "../../../hooks/collections";
import genericImage from "../../../images/collection.jpg";
import { UserAvatar } from "../../UserAvatar";
import { useGetImage } from "../../../hooks/images";
import { ImageCache } from "../../../models/ImageCache";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import { CollectionStats } from "../../collections/CollectionStats";
import { DiscountTag } from "../../DiscountTag";

export const PublicCollectionCard: FunctionComponent<IPublicCollectionCardProps> = ({
  publicCollection,
  onClick,
  showUser
}) => {
  const theme = useTheme();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [imageUpdateCount, setImageUpdateCount] = useState(0);
  const fetchImage = async () => {
    if (!publicCollection.collection.hasImage) return;
    const response = await getImage({
      name: publicCollection.collection.uuid,
      width: ImageCache.smallCollectionImageWidth,
      height: ImageCache.smallCollectionImageHeight
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    if (imageUpdateCount > 0) {
      setImage(undefined);
      fetchImage().then();
    } else {
      setImageUpdateCount(state => state + 1);
    }
  }, [publicCollection]);
  return (
    <Card
      className={styles.card}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CardActionArea
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }}
        onClick={onClick}
      >
        <LazyImageAspectFit
          className={styles.image}
          image={image}
          thumbnail={publicCollection.collection.thumbnail}
          defaultImage={genericImage}
          beforeLoad={fetchImage}
          alt={publicCollection.collection.name}
        />
        <div className={styles.nameContainer} />
        <Stack direction={"row"} className={styles.nameStack}>
          <p className={styles.name}>{publicCollection.collection.name}</p>
          <Box flexGrow={1} />
          {publicCollection.collection.discount > 0 && (
            <DiscountTag
              className={styles.discountBox}
              percentage={publicCollection.collection.discount}
            />
          )}
        </Stack>
        <p className={styles.description} style={{ color: theme.palette.secondary.main }}>
          {publicCollection.collection.description}
        </p>
        <Box flexGrow={1} />
        <Stack direction={"row"} className={styles.bottomStack}>
          {showUser && (
            <div style={{ display: "flex", alignSelf: "end", justifyContent: "center" }}>
              <UserAvatar className={styles.avatar} userProfile={publicCollection.userProfile} />
              <p className={styles.username}>{publicCollection.userProfile.username}</p>
            </div>
          )}
          <Box flexGrow={1} />
          <CollectionStats
            className={styles.collectionStats}
            collection={publicCollection.collection}
          />
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface IPublicCollectionCardProps {
  publicCollection: IPublicCollection;
  onClick: () => void;
  showUser: boolean;
}
