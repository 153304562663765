import { useBackend } from "../../services";

export const useUnassignAlbumFromCollection = () => {
  const { deleteRequest } = useBackend();
  const unassignAlbumFromCollection = (parameters: IUnassignAlbumFromCollectionParameters) =>
    deleteRequest("albums/assignToCollection", parameters);
  return { unassignAlbumFromCollection };
};

export interface IUnassignAlbumFromCollectionParameters {
  uuid: string;
  collectionId: string;
}
