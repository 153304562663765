import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Button, Tooltip, useTheme } from "@mui/material";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { SvgIconComponent } from "@mui/icons-material";
import { useIsMobile } from "../../../hooks/isMobile";

export const ToolbarRawButton: FunctionComponent<IToolbarRawButtonProps> = ({
  loading,
  disabled,
  color,
  highlighted,
  href,
  target,
  tooltip,
  onClick,
  onContextMenu,
  icon,
  iconClassName,
  buttonClassName
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <Tooltip title={isMobile ? "" : tooltip} onContextMenu={onContextMenu} arrow disableInteractive>
      <span className={styles.span}>
        <Button
          className={buttonClassName}
          sx={{
            minWidth: 0,
            color: color,
            backgroundColor: highlighted ? theme.palette.colors?.highlightButton : undefined,
            borderRadius: 0,
            height: "100% !important",
            width: "100% !important"
          }}
          disabled={disabled}
          onClick={onClick}
          href={href ?? ""}
          target={target}
        >
          {loading ? (
            <CustomLoadingIndicator className={styles.loadingIndicator} />
          ) : (
            <ResponsiveIcon className={iconClassName} icon={icon} />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export interface IToolbarRawButtonProps {
  loading?: boolean;
  disabled?: boolean;
  color?: string;
  highlighted?: boolean;
  href?: string;
  target?: string;
  tooltip: string;
  onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
  onContextMenu?: (event: React.MouseEvent, imageData?: string) => void;
  icon: SvgIconComponent;
  iconClassName?: string;
  buttonClassName?: string;
}
