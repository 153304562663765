import React, { FunctionComponent, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Card,
  Grid,
  List,
  Skeleton,
  SvgIcon,
  SwipeableDrawer,
  Toolbar,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../hooks/users";
import { useUser, useUserProfile } from "../../../hooks/session";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useIsMobile } from "../../../hooks/isMobile";
import {
  CloseRounded,
  CommentRounded,
  CommentsDisabledRounded,
  DragIndicator
} from "@mui/icons-material";
import { ChatBox } from "../ChatBox";
import { ChatListItem } from "../ChatListItem";
import { IChat } from "../../../hooks/chats";
import { strings } from "../../../localization/LocalizedStrings";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import { useBackend } from "../../../services";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { IMessage } from "../../../hooks/messages";
import { PageSizeHelper } from "../../../models/PageSizeHelper";

export const ChatsDetail: FunctionComponent<IChatsDetailProps> = ({
  chats,
  noMoreChats,
  setChats,
  selectedChat,
  setSelectedChat,
  searchText,
  toolbarItems,
  shouldOpenDrawer,
  isLoading
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { socket } = useBackend();
  const [filteredChats, setFilteredChats] = useState<IChat[]>([]);
  const [currentUser] = useUser.useState();
  const [currentUserProfile] = useUserProfile.useState();
  const [isChatsListDrawerOpen, setIsChatsListDrawerOpen] = useState(isMobile);
  const [isChatLoading, setIsChatLoading] = useState(false);
  useEffect(() => {
    if (searchText) {
      setFilteredChats(
        chats.filter(chat => {
          const otherUser = chat.chatUsers.filter(
            chatUser => chatUser.userProfile.userId != currentUserProfile?.userId
          )[0];
          return otherUser.userProfile.username.toLowerCase().includes(searchText.toLowerCase());
        })
      );
    } else {
      setFilteredChats(chats);
    }
  }, [searchText, chats]);
  useEffect(() => {
    const onNewMessage = (data: { chat: IChat; message: IMessage }) => {
      const newChat = data.chat;
      const message = data.message;
      setChats(chats => {
        const newChats = chats.map(chat => (chat.uuid == newChat.uuid ? newChat : chat));
        return newChats.sort((a, b) => b.lastUpdateUnixTimestamp - a.lastUpdateUnixTimestamp);
      });
      if (message.authorUserProfile.userId != currentUser?.uuid) {
        document.title = `${strings.app_name} - ${message.authorUserProfile.username}: ${message.body}`;
      }
    };
    socket.on("new_message", onNewMessage);
    return () => {
      socket.off("new_message", onNewMessage);
    };
  });
  useEffect(() => {
    if (currentUser?.role == UserRole.guest) {
      navigate(RoutesBuilder.home());
    }
  }, [currentUser]);
  useEffect(() => setIsChatsListDrawerOpen(true), [shouldOpenDrawer]);
  const chatsList = () => (
    <>
      {filteredChats.length > 0 ? (
        <List className={styles.chatList}>
          {filteredChats.map(chat => (
            <ChatListItem
              key={chat.uuid}
              chat={chat}
              selected={chat.uuid == selectedChat?.uuid}
              disabled={isChatLoading}
              onClick={() => {
                setSelectedChat(chat);
                setIsChatsListDrawerOpen(false);
              }}
            />
          ))}
          {(isLoading || !noMoreChats) &&
            Array.from({ length: PageSizeHelper.chats() }, (value, index) => (
              <Grid key={index} item xs={12}>
                <Skeleton
                  variant={"rectangular"}
                  sx={{ height: 56, marginBottom: 2 }}
                  className={styles.skeleton}
                />
              </Grid>
            ))}
        </List>
      ) : (
        <ContentUnavailableNotice
          isLoading={isLoading}
          items={filteredChats}
          emptyIcon={CommentsDisabledRounded}
          emptyTitle={strings.chat_empty_title}
          emptyDescription={strings.chat_empty_description}
          searchText={searchText}
        />
      )}
    </>
  );
  return (
    <Card className={styles.mainContainer}>
      <PanelGroup className={styles.mainDiv} direction={"horizontal"}>
        {!isMobile && (
          <Panel
            minSize={30}
            defaultSize={40}
            maxSize={50}
            style={{
              display: "flex",
              position: "relative",
              overflow: "auto",
              paddingBottom: 15,
              paddingTop: 15,
              paddingRight: 8,
              paddingLeft: 4
            }}
          >
            <Card sx={{ position: "relative", width: "100%" }} elevation={5}>
              {chatsList()}
            </Card>
          </Panel>
        )}
        {!isMobile && (
          <PanelResizeHandle className={styles.resizeHandle}>
            <SvgIcon
              component={DragIndicator}
              sx={{ alignSelf: "center", color: theme.palette.secondary.main, fontSize: 14 }}
            />
          </PanelResizeHandle>
        )}
        <Panel className={isMobile ? styles.graphsDivMobile : styles.graphsDiv}>
          {selectedChat ? (
            <ChatBox chat={selectedChat} setIsChatLoading={setIsChatLoading} />
          ) : (
            <Card sx={{ position: "relative", width: "100%", height: "100%" }} elevation={5}>
              <ContentUnavailableNotice
                isLoading={false}
                defaultVisible={true}
                items={[]}
                emptyIcon={CommentRounded}
                emptyTitle={strings.chat_select}
                emptyDescription={""}
                searchText={undefined}
              />
            </Card>
          )}
        </Panel>
      </PanelGroup>
      {isMobile && (
        <SwipeableDrawer
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default
            }
          }}
          ModalProps={{ keepMounted: true }}
          anchor={"left"}
          open={isChatsListDrawerOpen}
          onOpen={() => setIsChatsListDrawerOpen(true)}
          onClose={() => setIsChatsListDrawerOpen(false)}
        >
          <div
            style={{ width: "80vw", overflow: "hidden", display: "flex", flexDirection: "column" }}
          >
            <AppBar position={"sticky"}>
              <Toolbar className={styles.toolbar} disableGutters>
                {toolbarItems}
                <Box flexGrow={1} />
                <ToolbarButton
                  loading={isLoading}
                  onClick={() => setIsChatsListDrawerOpen(false)}
                  tooltip={strings.close}
                  icon={CloseRounded}
                />
              </Toolbar>
            </AppBar>
            {chatsList()}
          </div>
        </SwipeableDrawer>
      )}
    </Card>
  );
};

export interface IChatsDetailProps {
  chats: IChat[];
  noMoreChats: boolean;
  setChats: React.Dispatch<React.SetStateAction<IChat[]>>;
  selectedChat: IChat | undefined;
  setSelectedChat: (chat: IChat) => void;
  searchText?: string;
  toolbarItems: any;
  shouldOpenDrawer: boolean;
  isLoading: boolean;
}
