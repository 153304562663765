import React, { FunctionComponent, useState } from "react";
import { Box, Button, Dialog, Paper, PaperProps, Toolbar } from "@mui/material";
import YouTube from "react-youtube";
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material";
import Draggable from "react-draggable";
import styles from "./styles.module.scss";
import { ResponsiveIcon } from "../ResponsiveIcon";
import NewWindow from "react-new-window";

function PaperComponent(props: PaperProps) {
  return (
    <Paper
      {...props}
      style={{ margin: 0, maxHeight: "100%", backgroundColor: "black", backgroundImage: "none" }}
    />
  );
}

export const YouTubePlayer: FunctionComponent<IYouTubePlayerProps> = ({
  title,
  videoIds,
  open,
  setOpen
}) => {
  const [inNewWindow, setInNewWindow] = useState(false);
  const opts = {
    height: "308",
    width: "526",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1 as const,
      playlist: videoIds?.join(",")
    }
  };
  const newWindowOpts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1 as const,
      playlist: videoIds?.join(",")
    }
  };
  return (
    <div>
      {inNewWindow ? (
        <div>
          {open && (
            <NewWindow
              copyStyles
              title={title}
              center={"parent"}
              onUnload={() => {
                setOpen(false);
                setInNewWindow(false);
              }}
            >
              <YouTube opts={newWindowOpts} />
            </NewWindow>
          )}
        </div>
      ) : (
        <Draggable>
          <Dialog
            open={open}
            hideBackdrop
            disableEnforceFocus
            disableScrollLock
            PaperComponent={PaperComponent}
            sx={{ width: "fit-content", height: "fit-content" }}
          >
            <Toolbar disableGutters className={styles.toolbar} style={{ cursor: "move" }}>
              <h4 className={styles.title}>{title}</h4>
              <Box flexGrow={1} />
              <Button
                onClick={() => {
                  setInNewWindow(true);
                }}
              >
                <ResponsiveIcon icon={OpenInNewRounded} />
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                <ResponsiveIcon icon={CloseRounded} />
              </Button>
            </Toolbar>
            <YouTube className={styles.player} opts={opts} />
          </Dialog>
        </Draggable>
      )}
    </div>
  );
};

export interface IYouTubePlayerProps {
  title: string;
  videoIds?: string[];
  open: boolean;
  setOpen: (newValue: boolean) => void;
}
