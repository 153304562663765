import { useBackend } from "../../services";
import { ICollection } from "./interfaces";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { useUser } from "../session";
import { UserRole } from "../users";

export const useGetCollections = (setLoading: (loading: boolean) => void, isEnabled: boolean) => {
  const { get } = useBackend();
  const [currentUser] = useUser.useState();
  const getCollections = (parameters: IGetPaginatedAttributeProps) =>
    get("collections", parameters);
  return useGetPaginatedAttributes<ICollection>(
    PageSizeHelper.collections(currentUser?.role ?? UserRole.common),
    getCollections,
    setLoading,
    isEnabled,
    undefined
  );
};
