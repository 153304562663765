import React, { FunctionComponent } from "react";
import { IShelf } from "../../../hooks/shelves";
import { ShelfSegmentRepresentation } from "../ShelfSegmentRepresentation";
import styles from "./styles.module.scss";
import { IShelfSegment, IShelfSegmentAlbum } from "../../../hooks/shelfSegments";
import { IShelving } from "../../../hooks/shelvings";

export const ShelfRepresentation: FunctionComponent<IShelfRepresentationProps> = ({
  shelving,
  shelf,
  selectedShelfSegment,
  selectedAlbumIds,
  isLoading,
  setIsLoading,
  onShelfSegmentAlbumSelected,
  onRemoveAlbumFromShelfSegment,
  shelvingIndex,
  shelfIndex,
  zoom,
  canRemove
}) => {
  const shelfSegmentRepresentation = (segment: IShelfSegment, index: number) => (
    <ShelfSegmentRepresentation
      key={segment.uuid}
      shelving={shelving}
      shelfSegment={segment}
      selectedAlbumIds={selectedAlbumIds}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      onShelfSegmentAlbumSelected={onShelfSegmentAlbumSelected}
      onRemoveAlbumFromShelfSegment={(album, segmentIndex, albumIndex) =>
        onRemoveAlbumFromShelfSegment(album, shelfIndex, segmentIndex, albumIndex)
      }
      shelvingIndex={shelvingIndex}
      shelfIndex={shelfIndex}
      segmentIndex={index}
      canRemove={canRemove}
    />
  );
  return (
    <div
      key={shelf.uuid}
      className={styles.shelf}
      style={{
        width: shelving.orientation == "horizontal" ? "100%" : `${540 * zoom}px`,
        height: shelving.orientation == "horizontal" ? `${540 * zoom}px` : "100%",
        flexDirection: shelving.orientation == "horizontal" ? "row" : "column"
      }}
    >
      {selectedShelfSegment
        ? shelfSegmentRepresentation(
            selectedShelfSegment,
            shelf.segments.map(segment => segment.uuid).indexOf(selectedShelfSegment.uuid)
          )
        : shelf.segments
            ?.sort((a, b) => a.order - b.order)
            .map((segment, index) => shelfSegmentRepresentation(segment, index))}
    </div>
  );
};

export interface IShelfRepresentationProps {
  shelving: IShelving;
  shelf: IShelf;
  selectedShelfSegment?: IShelfSegment;
  selectedAlbumIds: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onShelfSegmentAlbumSelected: (shelfSegmentAlbum: IShelfSegmentAlbum) => void;
  onRemoveAlbumFromShelfSegment: (
    shelfSegmentAlbum: IShelfSegmentAlbum,
    shelfIndex: number,
    shelfSegmentIndex: number,
    albumIndex: number
  ) => void;
  shelvingIndex: number;
  shelfIndex: number;
  zoom: number;
  canRemove: boolean;
}
