import { useBackend } from "../../services";

export const useShareSearchByText = () => {
  const { get } = useBackend();
  const shareSearchByText = (parameters: IShareSearchByTextParameters) =>
    get("share/query", parameters);
  return { shareSearchByText };
};

export interface IShareSearchByTextParameters {
  query: string;
}
