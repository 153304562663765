import mainButtonsToolbarImage from "../../../../images/documentation/main-buttons-toolbar.png";

export const doc_albums_add_barcode_en = {
  title: "Scan barcode and search",
  home_title: "Add albums from a barcode or search",
  description: `Piper has the option to scan an album barcode directly from the jacket and search for it on Discogs. In the same manner you can search on the Discogs database using the free text search field by specifying part of a title, artist name, catalogue number, etc.
  
  Use the "Add from barcode or search" option on the drawer menu o the scan button on the main toolbar to open the search window.
  
`,
  main_buttons_toolbar_image: mainButtonsToolbarImage,
  scan_title: "Scan a barcode",
  scan_description: `Select the camera button to toggle the camera on and off. Put the barcode in front of the camera until it is recognized and the window starts loading.
  
  The app will search on Discogs for the barcode and show any results.
  
  Select the desired album to automatically create it on Piper.
  
`,
  search_title: "Search for an album",
  search_description: `You can specify any kind of information about an album on the search bar and we will attempt to find any matches on the Discogs database.
  
  Once you entered the desired text use the 'start search' button to begin searching. The results, if any, will appear on a list below.
  
  Select the desired album to automatically create it on Piper.
  
`
};
