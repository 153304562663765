import React, { FunctionComponent, useEffect, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { strings } from "../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { Badge, Box, CardActionArea, Collapse, ListItemText, Stack } from "@mui/material";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { AddCommentRounded, ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { AlbumMessagesList } from "../messages/AlbumMessagesList";
import { ModifyFreeTextAttributeDialog } from "../../attributes/ModifyFreeTextAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { useAddMessageForAlbum } from "../../../hooks/messages/useAddMessageForAlbum";
import { IMessage } from "../../../hooks/messages";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { ToolbarButton } from "../../toolbar/ToolbarButton";

export const AlbumMessagesListSection: FunctionComponent<IAlbumMessagesListSectionProps> = ({
  isPublic,
  album
}) => {
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [wasExpanded, setWasExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addMessageForAlbum } = useAddMessageForAlbum();
  const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);
  const [newMessage, setNewMessage] = useState<IMessage>();
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onNewMessage = async (messageBody: string) => {
    setIsLoading(true);
    const { status, body } = await addMessageForAlbum({
      albumId: album.uuid,
      collectionId: currentPublicCollection?.uuid,
      body: messageBody
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (wasExpanded) {
        setNewMessage(body);
      } else {
        setIsExpanded(true);
        setWasExpanded(true);
      }
      album.messagesCount = album.messagesCount ? album.messagesCount + 1 : 1;
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsExpanded(true);
    setWasExpanded(true);
  }, [newMessage]);
  useEffect(() => {
    setIsExpanded(false);
    setWasExpanded(false);
    setNewMessage(undefined);
  }, [album.uuid]);
  useEffect(() => {
    setIsLoading(album.messagesCount === undefined);
  }, [album.messagesCount]);
  return (
    <div className={styles.mainDiv}>
      <CardActionArea
        className={styles.container}
        onClick={() => {
          setIsExpanded(state => !state);
          setWasExpanded(true);
        }}
      >
        <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
          <ListItemText
            disableTypography
            className={styles.title}
            secondary={strings.messages_title}
            sx={{ flexGrow: 0 }}
          />
          <Badge className={styles.badge} badgeContent={album.messagesCount} color={"primary"} />
          <Box className={styles.loadingDiv}>
            <ResponsiveIcon icon={isExpanded ? ExpandLessRounded : ExpandMoreRounded} />
          </Box>
        </Stack>
      </CardActionArea>
      <ToolbarButton
        className={styles.addButton}
        tooltip={strings.messages_new_title}
        icon={AddCommentRounded}
        loading={isLoading}
        onClick={() => setIsNewMessageDialogOpen(true)}
      />
      <Collapse in={isExpanded} className={styles.collapse}>
        {(isExpanded || wasExpanded) && (
          <AlbumMessagesList
            isPublic={isPublic}
            album={album}
            style={{
              width: "100%",
              boxSizing: "border-box",
              paddingRight: 12
            }}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            newMessage={newMessage}
          />
        )}
      </Collapse>
      <ModifyFreeTextAttributeDialog
        title={strings.messages_new_title}
        description={""}
        placeholder={strings.messages_new_title}
        open={isNewMessageDialogOpen}
        setOpen={setIsNewMessageDialogOpen}
        onChange={onNewMessage}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumMessagesListSectionProps {
  isPublic: boolean;
  album: IAlbum;
}
