import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "../../albums/DocumentationAlbums/styles.module.scss";
import { Card, List, ListItemButton, ListItemText } from "@mui/material";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const DocumentationAlbumAttributesHome: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_album_attributes.title}</h1>
      <p>{strings.doc_album_attributes.description}</p>
      <section id={"name"} />
      <h2>{strings.doc_album_attributes.name_title}</h2>
      <p>{strings.doc_album_attributes.name_description}</p>
      <section id={"image"} />
      <h2>{strings.doc_album_attributes.image_title}</h2>
      <p>{strings.doc_album_attributes.image_description}</p>
      <section id={"country"} />
      <h2>{strings.doc_album_attributes.country_title}</h2>
      <p>{strings.doc_album_attributes.country_description}</p>
      <section id={"originalYear"} />
      <h2>{strings.doc_album_attributes.original_year_title}</h2>
      <p>{strings.doc_album_attributes.original_year_description}</p>
      <section id={"releaseYear"} />
      <h2>{strings.doc_album_attributes.release_year_title}</h2>
      <p>{strings.doc_album_attributes.release_year_description}</p>
      <section id={"catalogueNumber"} />
      <h2>{strings.doc_album_attributes.catalogue_number_title}</h2>
      <p>{strings.doc_album_attributes.catalogue_number_description}</p>
      <section id={"discogsId"} />
      <h2>{strings.doc_album_attributes.discogs_id_title}</h2>
      <p>{strings.doc_album_attributes.discogs_id_description}</p>
      <section id={"purchaseDate"} />
      <h2>{strings.doc_album_attributes.purchase_date_title}</h2>
      <p>{strings.doc_album_attributes.purchase_date_description}</p>
      <section id={"purchasePrice"} />
      <h2>{strings.doc_album_attributes.purchase_price_title}</h2>
      <p>{strings.doc_album_attributes.purchase_price_description}</p>
      <section id={"trackCount"} />
      <h2>{strings.doc_album_attributes.track_count_title}</h2>
      <p>{strings.doc_album_attributes.track_count_description}</p>
      <section id={"length"} />
      <h2>{strings.doc_album_attributes.length_title}</h2>
      <p>{strings.doc_album_attributes.length_description}</p>
      <section id={"collections"} />
      <h2>{strings.doc_album_attributes.collections_title}</h2>
      <p>{strings.doc_album_attributes.collections_description}</p>
      <section id={"played"} />
      <h2>{strings.doc_album_attributes.played_title}</h2>
      <p>{strings.doc_album_attributes.played_description}</p>
      <section id={"insert"} />
      <h2>{strings.doc_album_attributes.has_insert_title}</h2>
      <p>{strings.doc_album_attributes.has_insert_description}</p>
      <section id={"lyrics"} />
      <h2>{strings.doc_album_attributes.has_lyrics_title}</h2>
      <p>{strings.doc_album_attributes.has_lyrics_description}</p>
      <section id={"gatefold"} />
      <h2>{strings.doc_album_attributes.gatefold_title}</h2>
      <p>{strings.doc_album_attributes.gatefold_description}</p>
      <section id={"jacketGrade"} />
      <h2>{strings.doc_album_attributes.sleeve_grade_title}</h2>
      <p>{strings.doc_album_attributes.sleeve_grade_description}</p>
      <section id={"mediaGrade"} />
      <h2>{strings.doc_album_attributes.media_grade_title}</h2>
      <p>{strings.doc_album_attributes.media_grade_description}</p>
      <section id={"rating"} />
      <h2>{strings.doc_album_attributes.impact_title}</h2>
      <p>{strings.doc_album_attributes.impact_description}</p>
      <section id={"notes"} />
      <h2>{strings.doc_album_attributes.notes_title}</h2>
      <p>{strings.doc_album_attributes.notes_description}</p>
      <section id={"more"} />
      <h2>{strings.doc_album_attributes.more_attributes}</h2>
      <List>
        <Card sx={{ margin: 2 }}>
          <ListItemButton
            key={"Artists"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.albumAttributes.artists()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_artists.title}
            />
          </ListItemButton>
        </Card>
      </List>
    </div>
  );
};
