import { IAlbum, SellStatus } from "../hooks/albums";
import { capitalizeFirstLetter } from "./StringFormat";
import { strings } from "../localization/LocalizedStrings";
import { IUserProfile } from "../hooks/userProfiles";
import { useTheme } from "@mui/material";
import { IUser } from "../hooks/users";
import _ from "lodash";
import { ShelfSegmentAlbumShape } from "../hooks/shelfSegments";

export const AlbumUtilities = {
  getRevenue: (album: IAlbum) => {
    return (album.sellValue?.pesos ?? 0) - (album.purchasePriceToday ?? 0);
  },
  getSubtitle: (album: IAlbum, userProfile: IUserProfile | undefined) => {
    const quantitiesSubtitle = AlbumUtilities.getQuantitiesSubtitle(album);
    let yearSubtitle = album.originalYear ?? undefined;
    if (album.releaseYear && album.releaseYear != album.originalYear) {
      if (yearSubtitle) {
        yearSubtitle += ` (${album.releaseYear})`;
      } else {
        yearSubtitle = `(${album.releaseYear})`;
      }
    }
    let albumTypeSubtitle: string = undefined;
    if (album.albumType && album.albumType.showOnAlbumList) {
      albumTypeSubtitle = album.albumType.name;
    }
    let countrySubtitle: string = undefined;
    if (userProfile && album.country && !userProfile.countriesToHide.includes(album.country)) {
      countrySubtitle = album.country;
    }
    const subtitles = [quantitiesSubtitle, yearSubtitle, albumTypeSubtitle, countrySubtitle].filter(
      element => element !== undefined
    );
    return subtitles.join(" • ");
  },
  getQuantitiesSubtitle: (album: IAlbum) => {
    const discs = album.albumContents?.filter(albumContent => albumContent.type == "disc");
    const elements = _.groupBy(discs, albumContent => albumContent.albumFormatId);
    let result = "";
    for (const key in elements) {
      const discs = elements[key];
      const quantity = discs.reduce((a, b) => a + b.quantity, 0);
      const albumFormat = discs[0].albumFormat;
      let albumFormatString =
        albumFormat?.shortName ?? capitalizeFirstLetter(strings.disc_singular);
      if (quantity > 1) {
        albumFormatString += strings.plural_indicator;
      }
      const quantityText = quantity > 0 ? `${quantity}×` : "";
      result += `${quantityText}${albumFormatString} + `;
    }
    if (result == "") {
      return undefined;
    }
    return result.slice(0, -3);
  },
  getAllSellStatuses: () => {
    return ["reserved", "sold", "upcoming", "new", "highlighted"] as SellStatus[];
  },
  getSellStatusDescription: (sellStatus: SellStatus) => {
    switch (sellStatus) {
      case "highlighted":
        return strings.album_highlighted;
      case "new":
        return strings.album_new;
      case "reserved":
        return strings.album_reserved;
      case "sold":
        return strings.album_sold;
      case "upcoming":
        return strings.album_upcoming;
    }
  },
  getSellStatusBackgroundColor: (sellStatus: SellStatus) => {
    const theme = useTheme();
    switch (sellStatus) {
      case "highlighted":
        return theme.palette.colors?.discount;
      case "new":
        return theme.palette.colors?.discount;
      case "reserved":
        return theme.palette.colors?.disabled;
      case "sold":
        return theme.palette.colors?.remove;
      case "upcoming":
        return theme.palette.colors?.discount;
    }
  },
  isOwner: (album: IAlbum, user: IUser) => {
    return (user.role == "admin" && album.userId == null) || album.userId == user.uuid;
  },
  getShapeRelativeSize: (shape: ShelfSegmentAlbumShape) => {
    switch (shape) {
      case "longPlay":
        return "96%";
      case "tenInches":
        return "80%";
      case "boxSet":
        return "98%";
      case "single":
        return "56%";
      case "compactDisc":
        return "40%";
    }
  }
};
