import { useBackend } from "../../services";
import { IPublicCollection } from "./interfaces";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";

export const useGetPublicCollections = (
  userId: string,
  setLoading: (loading: boolean) => void,
  isEnabled: boolean
) => {
  const { get } = useBackend();
  const getPublicCollections = (parameters: IGetPaginatedAttributeProps) =>
    get("collections/public", { userId, ...parameters });
  return useGetPaginatedAttributes<IPublicCollection>(
    20,
    getPublicCollections,
    setLoading,
    isEnabled,
    undefined
  );
};
