import { useBackend } from "../../services";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";

export const useCheckEmail = () => {
  const { post } = useBackend();
  return async (email: string) => {
    const { status, body } = await post("checkEmail", { email: email });
    if (StatusCodesHelper.isSuccessful(status)) {
      return body.isUsed;
    } else {
      return undefined;
    }
  };
};
