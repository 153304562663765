import { Box, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { strings } from "../../localization/LocalizedStrings";
import classNames from "classnames";

export const DiscountTag: FunctionComponent<IDiscountTagProps> = ({ className, percentage }) => {
  const theme = useTheme();
  return (
    <Box
      className={classNames(styles.discountBox, className)}
      style={{ backgroundColor: theme.palette.colors?.discount }}
    >
      <p>
        {percentage * 100}% {strings.discount}
      </p>
    </Box>
  );
};

export interface IDiscountTagProps {
  className?: string;
  percentage: number;
}
