import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { ILabel, useGetLabels } from "../../../../hooks/labels";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyLabelDialog } from "../ModifyLabelDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { LabelCard } from "../LabelCard";
import { LabelDialog } from "../LabelDialog";
import { MusicVideoRounded, DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { useContextMenu } from "../../../../hooks/contextMenu";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";
import { useAsyncState } from "../../../../hooks/useAsyncState/useAsyncState";

export const LabelsDesktop: FunctionComponent<ILabelsDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onLabelSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const [labels, resetLabels, setLabels, noMoreLabels] = useGetLabels(isPublic, setLoading);
  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>();
  const [imageData, setImageData] = useState<string>();
  const [isLabelDialogOpen, setIsLabelDialogOpen] = useState(false);
  const [isAddLabelDialogOpen, setIsAddLabelDialogOpen] = useState(false);
  const [isModifyLabelDialogOpen, setIsModifyLabelDialogOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useAsyncState(undefined);
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredLabels();
  }, [labels]);
  const refreshFilteredLabels = () => {
    if (searchText == "") {
      setFilteredLabels(labels);
      return;
    }
    setFilteredLabels(
      labels?.filter(label => {
        return label.name.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredLabels();
  }, [searchText]);
  const onModifiedLabel = (newLabel: ILabel) => {
    const newLabels = labels.map(label => (label.uuid == newLabel.uuid ? newLabel : label));
    newLabels.sort((a, b) => a.name.localeCompare(b.name, undefined, { ignorePunctuation: true }));
    setLabels(newLabels);
    toast.success(strings.generic_update_success);
  };
  const onDeletedLabel = (deletedLabel: ILabel) => {
    const newLabels = labels.filter(label => label.uuid != deletedLabel.uuid);
    setLabels(newLabels);
    toast.success(strings.generic_update_success);
  };
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredLabels}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.LABELS)}
      emptyString={isPublic ? strings.empty_labels_public : strings.empty_labels}
      addItemString={strings.add_attribute}
      title={strings.attribute_labels_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddLabelDialogOpen(true)}
      refreshAction={resetLabels}
      onClearValue={onClearValue}
    >
      {filteredLabels?.map(label => (
        <Grid item key={label.uuid} xs={columnWidth}>
          <LabelCard
            label={label}
            selected={preselectedId == label.uuid}
            onClick={async (event, imageData) => {
              setImageData(imageData);
              if (onLabelSelected) {
                onLabelSelected(label);
              } else {
                await setSelectedLabel(label);
                setIsLabelDialogOpen(true);
              }
            }}
            onContextMenu={async (event, imageData) => {
              setImageData(imageData);
              handleContextMenu(event);
              await setSelectedLabel(label);
            }}
          />
        </Grid>
      ))}
      {(loading || !noMoreLabels) &&
        Array.from({ length: 40 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 60 }} className={styles.skeleton} />
          </Grid>
        ))}
      <ModifyLabelDialog
        open={isAddLabelDialogOpen}
        setOpen={setIsAddLabelDialogOpen}
        onNewItem={newLabel => {
          const newLabels = labels;
          newLabels.push(newLabel);
          newLabels.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setLabels(newLabels);
          refreshFilteredLabels();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyLabelDialog
        open={isModifyLabelDialogOpen}
        setOpen={setIsModifyLabelDialogOpen}
        label={selectedLabel}
        originalImageData={imageData}
        onModifiedItem={onModifiedLabel}
        onDeletedItem={onDeletedLabel}
      />
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsLabelDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <MusicVideoRounded />
          </ListItemIcon>
          {strings.label_view}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyLabelDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.label_edit}
        </MenuItem>
      </Menu>
      {selectedLabel && (
        <LabelDialog
          label={selectedLabel}
          isOpen={isLabelDialogOpen}
          setIsOpen={setIsLabelDialogOpen}
          canEdit={true}
          onModifiedItem={onModifiedLabel}
          onDeletedItem={deletedLabel => {
            setIsLabelDialogOpen(false);
            onDeletedLabel(deletedLabel);
          }}
        />
      )}
    </AttributeDesktop>
  );
};

export interface ILabelsDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: number;
  preselectedId?: string;
  onLabelSelected?: (label: ILabel) => void;
  onClearValue?: () => void;
}
