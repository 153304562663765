import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import { CustomLoadingIndicator } from "../CustomLoadingIndicator";
import { SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";
import { LazyImageAspectFit } from "../LazyImageAspectFit";

export const RightDetailListItem: FunctionComponent<IRightDetailListItemProps> = ({
  title,
  detail,
  subtitle,
  leftSubtitle,
  titleColor,
  textColor,
  isLoading,
  onClick,
  icon,
  theNewIcon,
  rightIcon,
  detailImage,
  disabled,
  boldTitle,
  children
}) => {
  const theme = useTheme();
  return (
    <ListItemButton
      className={detailImage ? styles.itemWithDetailImage : styles.itemWithoutDetailImage}
      sx={{ opacity: "1 !important" }}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
      }}
      disabled={disabled}
    >
      <div className={styles.itemDiv}>
        {icon && (
          <ListItemIcon>
            <ResponsiveIcon icon={icon} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              className={styles.titlePrimary}
              style={{
                color: titleColor ?? theme.palette.primary.main,
                fontWeight: boldTitle ? "bold" : "normal"
              }}
            >
              {title}
            </Typography>
          }
          secondary={
            <Typography
              className={styles.titleSecondary}
              style={{ color: theme.palette.primary.subtitle }}
            >
              {leftSubtitle}
            </Typography>
          }
        />
        <Box className={styles.loadingDiv}>
          {isLoading ? (
            <CustomLoadingIndicator />
          ) : (
            theNewIcon && (
              <ListItemIcon>
                <ResponsiveIcon icon={theNewIcon} />
              </ListItemIcon>
            )
          )}
        </Box>
        {children}
        <ListItemText
          className={styles.listItemText}
          primary={
            <Typography
              className={styles.detailPrimary}
              style={{ color: textColor ?? theme.palette.primary.main }}
            >
              {detail}
            </Typography>
          }
          secondary={
            <Typography
              className={styles.detailSecondary}
              style={{ color: theme.palette.primary.subtitle }}
            >
              {subtitle}
            </Typography>
          }
          sx={{ textAlign: "right" }}
        />
        {rightIcon && (
          <ListItemIcon>
            <ResponsiveIcon icon={rightIcon} />
          </ListItemIcon>
        )}
      </div>
      {detailImage && (
        <LazyImageAspectFit
          className={styles.detailImageContainer}
          image={detailImage}
          defaultImage={detailImage}
          alt={"Image"}
        />
      )}
    </ListItemButton>
  );
};

export interface IRightDetailListItemProps {
  children?: React.ReactNode;
  title: string;
  detail?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  leftSubtitle?: string;
  titleColor?: string;
  textColor?: string;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  icon?: SvgIconComponent;
  theNewIcon?: SvgIconComponent;
  rightIcon?: SvgIconComponent;
  detailImage?: string;
  disabled?: boolean;
  boldTitle?: boolean;
}
