import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { Box, ListItemButton, ListItemIcon, ListItemText, Stack, useTheme } from "@mui/material";
import { CustomLoadingIndicator } from "../CustomLoadingIndicator";
import { SvgIconComponent } from "@mui/icons-material";
import { ResponsiveIcon } from "../ResponsiveIcon";

export const FreeTextListItem: FunctionComponent<IFreeTextListItemProps> = ({
  title,
  detail,
  isLoading,
  onClick,
  icon,
  disabled,
  maxLines,
  hideTitle
}) => {
  const theme = useTheme();
  return (
    <ListItemButton sx={{ opacity: "1 !important" }} onClick={onClick} disabled={disabled}>
      <div className={title ? styles.itemDiv : styles.itemDivWithoutTitle}>
        <Stack direction={"row"}>
          {icon && (
            <ListItemIcon className={styles.icon}>
              <ResponsiveIcon icon={icon} />
            </ListItemIcon>
          )}
          <Stack direction={"column"}>
            {!hideTitle && (
              <Stack direction={"row"} spacing={1}>
                <ListItemText disableTypography className={styles.title} secondary={title} />
                <Box className={styles.loadingDiv}>{isLoading && <CustomLoadingIndicator />}</Box>
              </Stack>
            )}
            <ListItemText
              disableTypography
              className={styles.detail}
              style={{ color: theme.palette.primary.subtitle, WebkitLineClamp: maxLines }}
              secondary={detail == "" ? "--" : detail}
            />
          </Stack>
        </Stack>
      </div>
    </ListItemButton>
  );
};

export interface IFreeTextListItemProps {
  title?: string;
  detail: string;
  isLoading?: boolean;
  onClick?: () => void;
  icon?: SvgIconComponent;
  disabled?: boolean;
  maxLines?: number;
  hideTitle?: boolean;
}
