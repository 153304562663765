import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardActionArea, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import genericImage from "../../../../images/generic_label.png";
import { ILabel } from "../../../../hooks/labels";
import { useGetImage } from "../../../../hooks/images";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { ImageCache } from "../../../../models/ImageCache";
import { LazyImageAspectFit } from "../../../LazyImageAspectFit";
import { strings } from "../../../../localization/LocalizedStrings";
import { ResponsiveIcon } from "../../../ResponsiveIcon";
import { MoreVertRounded } from "@mui/icons-material";

export const LabelCard: FunctionComponent<ILabelCardProps> = ({
  label,
  selected,
  onClick,
  onContextMenu
}) => {
  const theme = useTheme();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [imageUpdateCount, setImageUpdateCount] = useState(0);
  const fetchImage = async () => {
    if (!label.hasImage) return;
    const response = await getImage({
      name: label.uuid,
      width: ImageCache.bigLabelImageSize,
      height: ImageCache.bigLabelImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    if (imageUpdateCount > 0) {
      setImage(undefined);
      fetchImage().then();
    } else {
      setImageUpdateCount(state => state + 1);
    }
  }, [label]);
  return (
    <Card className={styles.container} sx={{ height: 60 }} elevation={5}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row"
        }}
        style={{
          borderColor: theme.palette.colors?.highlightBorder,
          borderWidth: 4,
          borderStyle: selected ? "double" : "none"
        }}
        onContextMenu={event => onContextMenu(event, image)}
        onClick={event => onClick(event, image)}
      >
        <LazyImageAspectFit
          className={styles.image}
          imageStyle={{ borderRadius: "6%" }}
          image={image}
          thumbnail={label.thumbnail}
          defaultImage={genericImage}
          beforeLoad={fetchImage}
          alt={label.name}
        />
        <Stack className={styles.rightContainer}>
          <p className={styles.name}>{label.name}</p>
          <p className={styles.profile}>{label.profile}</p>
        </Stack>
      </CardActionArea>
      <Stack className={styles.hoverButtonsStack} direction={"row"} spacing={1}>
        <Tooltip title={strings.options} arrow disableInteractive>
          <IconButton
            className={styles.hoverButton}
            style={{ backgroundColor: theme.palette.colors?.counterBackground }}
            onClick={event => onContextMenu(event, image)}
          >
            <ResponsiveIcon className={styles.hoverButtonIcon} icon={MoreVertRounded} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Card>
  );
};

export interface ILabelCardProps {
  label: ILabel;
  selected?: boolean;
  onClick: (event: React.MouseEvent, imageData?: string) => void;
  onContextMenu: (event: React.MouseEvent, imageData?: string) => void;
}
