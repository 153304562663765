import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationCollectionsAttributes: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_collections_attributes.title}</h1>
      <p>{strings.doc_collections_attributes.description}</p>
      <section id={"name"} />
      <h2>{strings.doc_collections_attributes.name_title}</h2>
      <p>{strings.doc_collections_attributes.name_description}</p>
      <section id={"image"} />
      <h2>{strings.doc_collections_attributes.image_title}</h2>
      <p>{strings.doc_collections_attributes.image_description}</p>
      <section id={"visibility"} />
      <h2>{strings.doc_collections_attributes.visibility_title}</h2>
      <p>{strings.doc_collections_attributes.visibility_description}</p>
      <section id={"bannedFields"} />
      <h2>{strings.doc_collections_attributes.banned_fields_title}</h2>
      <p>{strings.doc_collections_attributes.banned_fields_description}</p>
      <section id={"discount"} />
      <h2>{strings.doc_collections_attributes.discount_title}</h2>
      <p>{strings.doc_collections_attributes.discount_description}</p>
      <section id={"description"} />
      <h2>{strings.doc_collections_attributes.description_title}</h2>
      <p>{strings.doc_collections_attributes.description_description}</p>
    </div>
  );
};
