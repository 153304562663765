import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogContent, Grow, Toolbar, DialogActions, useTheme } from "@mui/material";
import { Counter } from "../../Counter";
import { CloseRounded, CleaningServicesRounded } from "@mui/icons-material";
import { ContainedButton } from "../../ContainedButton";
import { strings } from "../../../localization/LocalizedStrings";
import { ToolbarButton } from "../../toolbar/ToolbarButton";

export const ModifyIncrementalAttributeDialog: FunctionComponent<
  IModifyIncrementalAttributeDialogProps
> = ({ title, description, canClear, open, setOpen, value, maxValue, onChange }) => {
  const theme = useTheme();
  const [incrementalNumber, setIncrementalNumber] = useState<number | null>(value ?? 1);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setIncrementalNumber(value ?? 1);
  }, [value]);
  return (
    <Dialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose} TransitionComponent={Grow}>
      <Toolbar>
        <h3>{title}</h3>
        <Box flexGrow={1} />
        {canClear && (
          <ToolbarButton
            onClick={() => {
              onChange(null);
              handleClose();
            }}
            tooltip={strings.clear_value}
            icon={CleaningServicesRounded}
            color={theme.palette.colors?.remove}
          />
        )}
        <ToolbarButton onClick={handleClose} tooltip={strings.close} icon={CloseRounded} />
      </Toolbar>
      <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <p className={styles.description}>{description}</p>
        <Counter
          className={styles.counter}
          initialValue={value ?? 1}
          minimumValue={1}
          maximumValue={maxValue}
          nullable={canClear}
          onValueChanged={newValue => {
            setIncrementalNumber(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <ContainedButton
          type={"submit"}
          onClick={() => {
            onChange(incrementalNumber);
            handleClose();
          }}
        >
          {strings.alert_confirm}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export interface IModifyIncrementalAttributeDialogProps {
  title: string;
  description: string;
  canClear: boolean;
  open: boolean;
  setOpen: (state: boolean) => void;
  value: number | undefined;
  maxValue?: number;
  onChange: (newValue: number | null) => void;
}
