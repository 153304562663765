import { useBackend } from "../../services";

export const useModifyExchangeRate = () => {
  const { put } = useBackend();
  const modifyExchangeRate = (parameters: IModifyExchangeRateParameters) =>
    put("exchangeRates", parameters);
  return { modifyExchangeRate };
};

export interface IModifyExchangeRateParameters {
  uuid: string;
  unixTimestamp: number;
  rate: number;
}
