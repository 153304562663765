import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { omit } from "lodash";
import { useTheme } from "@mui/material";
import { TextField } from "../TextField";

export const FreeTextField: FunctionComponent<any> = props => {
  const theme = useTheme();
  return (
    <TextField
      component="textarea"
      className={classNames(styles.textArea, props.className)}
      style={{
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.subtitle
      }}
      {...omit(props, "className")}
    />
  );
};
