export const doc_users_recover_password_en = {
  title: "Recover password",
  description: `If you ever forget your password you can reset it using the "Forgot your password?" button in the sign in page, or the "Recover password" option on the drawer menu.
  
  A new window will ask you to enter your email address and a new password. After confirming that a verification code will be sent to your email that you will have to enter in the next step.
  
  Once the code is validated you will be able to sign in normally sing the new password.
  
  You can always opt to sign in using Google or Apple using your existing account for either one.
  
`
};
