import React, { FunctionComponent, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Card,
  CardActionArea,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  useTheme
} from "@mui/material";
import classNames from "classnames";
import { IUserNotification } from "../../../hooks/userNotifications";
import { format } from "date-fns";
import { strings } from "../../../localization/LocalizedStrings";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { DraftsRounded, MailRounded, MarkAsUnreadRounded } from "@mui/icons-material";
import { useMarkReadUserNotification } from "../../../hooks/userNotifications/useMarkReadUserNotification";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { useContextMenu } from "../../../hooks/contextMenu";

export const UserNotificationItem: FunctionComponent<IUserNotificationItemProps> = ({
  userNotification,
  onClick,
  onUpdatedUserNotification
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { markReadUserNotification } = useMarkReadUserNotification();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const backgroundColor = () => {
    switch (userNotification.severity) {
      case "info":
        return undefined;
      case "pricing":
        return theme.palette.colors?.pricingNotification;
      case "critical":
        return theme.palette.colors?.criticalNotification;
      case "message":
        return theme.palette.colors?.messageNotification;
    }
  };
  const toggleIsReadUserNotification = async () => {
    setIsLoading(true);
    const { status, body } = await markReadUserNotification({
      uuid: userNotification.uuid,
      isRead: !userNotification.isRead
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      onUpdatedUserNotification(body);
    }
    setIsLoading(false);
  };
  return (
    <ListItem className={styles.item}>
      <Card className={styles.container} elevation={userNotification.isRead ? 2 : 5}>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <CardActionArea
            className={classNames(styles.actionArea, styles.positionContainer)}
            onContextMenu={handleContextMenu}
            onClick={() => {
              if (!userNotification.isRead) {
                toggleIsReadUserNotification().then();
              }
              onClick();
            }}
          >
            <div className={styles.severityMark} style={{ backgroundColor: backgroundColor() }} />
            <Stack sx={{ width: "100%", alignItems: "center" }} direction={"row"} spacing={2}>
              {isLoading ? (
                <CustomLoadingIndicator />
              ) : (
                <ResponsiveIcon icon={userNotification.isRead ? DraftsRounded : MailRounded} />
              )}
              <Stack sx={{ width: "100%" }} spacing={1}>
                <Stack direction={"row"}>
                  <p
                    className={styles.name}
                    style={{
                      color: userNotification.isRead
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }}
                  >
                    {userNotification.title}
                  </p>
                  <Box flexGrow={1} />
                  <p className={styles.visibility}>
                    {format(userNotification.createdUnixTimestamp * 1000, "d MMMM yyyy HH:mm", {
                      locale: strings.fnsLocale
                    })}
                  </p>
                </Stack>
                <p
                  className={classNames(
                    styles.description,
                    userNotification.isRead ? styles.readDescription : styles.nonReadDescription
                  )}
                  style={{
                    color: userNotification.isRead
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main
                  }}
                >
                  {userNotification.message}
                </p>
              </Stack>
            </Stack>
          </CardActionArea>
        </Stack>
      </Card>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            toggleIsReadUserNotification().then();
          }}
          dense
        >
          <ListItemIcon>
            {userNotification.isRead ? (
              <MarkAsUnreadRounded fontSize={"small"} />
            ) : (
              <DraftsRounded fontSize={"small"} />
            )}
          </ListItemIcon>
          {userNotification.isRead ? strings.mark_as_unread : strings.mark_as_read}
        </MenuItem>
      </Menu>
    </ListItem>
  );
};

export interface IUserNotificationItemProps {
  userNotification: IUserNotification;
  onClick: () => void;
  onUpdatedUserNotification: (newUserNotification: IUserNotification) => void;
}
