import { TreeItem, treeItemClasses, TreeItemProps } from "@mui/x-tree-view";
import { styled } from "@mui/material";

export const CustomTreeItem = styled(TreeItem)<TreeItemProps>(() => ({
  [`& .${treeItemClasses.label}`]: {
    marginTop: 1,
    marginBottom: 1,
    marginRight: 12,
    padding: 4,
    maxWidth: "fit-content",
    fontWeight: "normal !important",
    "&.parent": {
      fontWeight: "bold !important"
    }
  },
  [`& .${treeItemClasses.content}`]: {
    borderRadius: 10
  }
}));
