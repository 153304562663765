import { useBackend } from "../../services";
import { ISleeveGrade } from "./interfaces";
import { useEffect, useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";

export const useGetSleeveGrades = <T>(
  setLoading: (loading: boolean) => void,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const [sleeveGrades, setSleeveGrades] = useState<ISleeveGrade[]>([]);
  useEffect(() => {
    setLoading(true);
    get("sleeveGrades").then(({ body, status }) => {
      if (StatusCodesHelper.isSuccessful(status)) {
        setSleeveGrades(body);
      } else if (!StatusCodesHelper.isSessionExpired(status)) {
        toast.error(body.message);
      }
      setLoading(false);
    });
  }, dependencies);
  return sleeveGrades;
};
