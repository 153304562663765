import { useBackend } from "../../services";

export const useGetAlbumImages = () => {
  const { get } = useBackend();
  const getAlbumImages = (parameters: IGetAlbumImagesProps) => get("albumImages", parameters);
  return { getAlbumImages };
};

export interface IGetAlbumImagesProps {
  albumId: string;
}
