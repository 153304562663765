import React, { FunctionComponent } from "react";
import { TimeInterval } from "../../models/TimeInterval";
import { TextField } from "../TextField";
import { useTheme } from "@mui/material";

export const LengthTextField: FunctionComponent<ILengthTextFieldProps> = ({
  id,
  name,
  defaultValue,
  size,
  autoFocus,
  onChange,
  enterAction
}) => {
  const theme = useTheme();
  return (
    <TextField
      id={id}
      name={name}
      defaultValue={defaultValue ? TimeInterval.toHHMMSS(defaultValue) : undefined}
      size={size}
      mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
      placeholder={"HH:mm:ss"}
      autoFocus={autoFocus}
      onChange={(event: { target: { value: string } }) => {
        onChange(TimeInterval.getSeconds(event.target.value));
      }}
      enterAction={enterAction}
      style={{
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.subtitle,
        width: "100%",
        minHeight: "36px"
      }}
    />
  );
};

export interface ILengthTextFieldProps {
  id: string;
  name: string;
  defaultValue: number | undefined;
  size: string;
  autoFocus?: boolean;
  onChange: (newValue: number) => void;
  enterAction?: () => void;
}
