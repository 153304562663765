import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { MainContent } from "../../components/MainContent";
import { AppBar, Box, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../localization/LocalizedStrings";
import { MainDrawer } from "../../components/MainDrawer";
import { CustomLoadingIndicator } from "../../components/CustomLoadingIndicator";
import { PiperLogoButton } from "../../components/PiperLogoButton";
import { AppCredits } from "../../components/AppCredits";
import { useIsMobile } from "../../hooks/isMobile";
import { useUserProfile } from "../../hooks/session";
import { ToolbarButton } from "../../components/toolbar/ToolbarButton";
import { HelpOutlineRounded, InboxRounded } from "@mui/icons-material";
import { BadgeWithOffset } from "../../components/BadgeWithOffset";
import { UserNotificationsDialog } from "../../components/userNotifications/UserNotificationsDialog";
import { RoutesBuilder } from "../../models/RoutesBuilder";
import { UserProfileDetail } from "../../components/userProfiles/UserProfileDetail";
import { useIsScreenWide } from "../../hooks/isScreenWide";

export const UserProfile: FunctionComponent = () => {
  useEffect(() => {
    document.title = `${strings.app_name} - ${strings.user_profile_title}`;
  }, []);
  const theme = useTheme();
  const isScreenWide = useIsScreenWide();
  const isMobile = useIsMobile();
  const [currentUserProfile] = useUserProfile.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenWide);
  const [isUserNotificationsDialogOpen, setIsUserNotificationsDialogOpen] = useState(false);
  const [isOpenDiscogsImport, setIsOpenDiscogsImport] = useState(false);
  const [isOpenExcelImport, setIsOpenExcelImport] = useState(false);
  return (
    <MainContent showingPersistentDrawer={isScreenWide && isDrawerOpen}>
      <AppBar
        position="sticky"
        sx={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.colors?.appBar
        }}
      >
        <Toolbar className={styles.toolbar} disableGutters>
          {(!isScreenWide || !isDrawerOpen) && (
            <PiperLogoButton
              style={{ marginRight: isMobile ? 0 : 8 }}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          )}
          <MainDrawer
            currentPage={"profile"}
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            isOpenDiscogsImport={isOpenDiscogsImport}
            setIsOpenDiscogsImport={setIsOpenDiscogsImport}
            isOpenExcelImport={isOpenExcelImport}
            setIsOpenExcelImport={setIsOpenExcelImport}
          />
          <h3 className={styles.title}>{strings.user_profile_title}</h3>
          <Box sx={{ paddingLeft: 1, width: theme.spacing(4) }}>
            {isLoading && <CustomLoadingIndicator />}
          </Box>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.userProfile()}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <BadgeWithOffset
            overlap={"circular"}
            badgeContent={currentUserProfile?.unreadNotifications}
            color={"error"}
          >
            <ToolbarButton
              tooltip={strings.user_notifications_title}
              icon={InboxRounded}
              onClick={() => setIsUserNotificationsDialogOpen(true)}
            />
          </BadgeWithOffset>
        </Toolbar>
      </AppBar>
      <div className={styles.mainBody}>
        <div
          className={styles.detailBody}
          style={{ backgroundColor: theme.palette.background.default }}
        >
          <UserProfileDetail setIsLoading={setIsLoading} />
          <AppCredits />
        </div>
      </div>
      <UserNotificationsDialog
        open={isUserNotificationsDialogOpen}
        setOpen={setIsUserNotificationsDialogOpen}
      />
    </MainContent>
  );
};
