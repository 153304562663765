import optionsImage from "../../../../images/documentation/options-en.png";
import drawerImage from "../../../../images/documentation/sign-in-drawer-en.png";

export const doc_users_guest_en = {
  title: "Sign in as guest",
  options_image: optionsImage,
  drawer_image: drawerImage,
  description: `You can sign in as a guest using the option inside the menu drawer. You won't need a password, you will get right into Piper with a pre-created collection full of albums, artists and more.
  
  While being a guest you won't be able to make any changes, but feel free to explore through the collection and check all the things you could do with your own collection. The only blocked section will be Statistics.
  
  Whenever you want to sign in with your own account, just close the guest session and you will be presented with the sign in page once again.
  
`
};
