import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { Dialog, Grow } from "@mui/material";
import styles from "./styles.module.scss";
import { AlertDialog } from "../../AlertDialog";
import { ImpactsDesktop } from "../../attributes/impacts/ImpactsDesktop";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { ImpactHelper } from "../../../models/ImpactHelper";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../hooks/isMobile";
import { useUserProfile } from "../../../hooks/session";
import { IImpact } from "../../../hooks/impacts";

export const AlbumImpactListSection: FunctionComponent<IAlbumImpactListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const isMobile = useIsMobile();
  const [currentUserProfile] = useUserProfile.useState();
  const { updateAlbum } = useUpdateAlbum();
  const [isImpactsDialogOpen, setIsImpactsDialogOpen] = useState(false);
  const [isLoadingImpact, setIsLoadingImpact] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const updateImpact = async (newValue: IImpact | undefined) => {
    setIsLoadingImpact(true);
    setIsImpactsDialogOpen(false);
    const { body, status } = await updateAlbum({
      uuid: album.uuid,
      impactId: newValue?.id ?? null
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.impactId = newValue?.id;
      album.impact = newValue;
      onAttributeUpdated(album);
    }
    setIsLoadingImpact(false);
  };
  return (
    <div>
      <RightDetailListItem
        disabled={isPublic}
        title={strings.impact_title}
        detail={
          album.impact
            ? (currentUserProfile?.shouldShowImpactDescriptions
                ? `${album.impact.description} - `
                : "") + ImpactHelper.starsString(album.impact)
            : strings.unknown
        }
        onClick={() => setIsImpactsDialogOpen(true)}
        isLoading={isLoadingImpact}
      />
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isImpactsDialogOpen}
        onClose={() => setIsImpactsDialogOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ImpactsDesktop
            handleClose={() => {
              setIsImpactsDialogOpen(false);
            }}
            columnWidth={isMobile ? 6 : 3}
            preselectedId={album.impact?.id}
            onImpactSelected={updateImpact}
            onClearValue={() => updateImpact(undefined)}
          />
        </div>
      </Dialog>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumImpactListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
