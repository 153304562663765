import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";

export const DocumentationArtists: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_artists.title}</h1>
      <p>{strings.doc_artists.description}</p>
    </div>
  );
};
