import { useBackend } from "../../services";

export const useDeleteShelfSegment = () => {
  const { deleteRequest } = useBackend();
  const deleteShelfSegment = (parameters: IDeleteShelfSegmentParameters) =>
    deleteRequest("shelfSegments", parameters);
  return { deleteShelfSegment };
};

export interface IDeleteShelfSegmentParameters {
  uuid: string;
}
