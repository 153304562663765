import deleteImage from "../../../../images/documentation/delete-album-en.png";
import createImage from "../../../../images/documentation/create-album-en.png";

export const doc_albums_en = {
  title: "Albums",
  home_title: "Manage your albums",
  description: `We call an album to the group of audio recordings issued on vinyl discs, compact discs, cassettes, etc. Albums on Piper have different attributes fully customizable so you can manage your collection the way it best suits you. You count with a lot of sorting and filtering options for your albums so you can view them by artist, label and more.
  
  You can also organize your albums by multiple collections and review statistics independently for each one.
  
  Think big! Add to your collection all your vinyl, CD's, or even albums you own on digital format. Personalize your collection to suit your needs.

`,
  create_title: "Add an album",
  create_image: createImage,
  create_description: `
  In order to add a new album you just need to click on the '+' button on the toolbar in the 'My albums' page.
  
  A new dialog will appear where you can enter all the attributes for the new album. You can navigate through the fields by clicking them or using the TAB key and the ENTER key when a selection is required.
  
  To modify the image you can drag and drop the new one, or just click on the old image to select the new one.
  
  Once you confirm, a red text will appear below any missing required field. If all the mandatory fields are completed, then the album will be created.
  
`,
  delete_image: deleteImage,
  delete_title: "Delete an album",
  delete_description: `To delete an album, right click on it from the album list and select the option to delete it. A confirmation dialog will appear so you can double check the album name before removing it.
  
  This process is irreversible, so make sure you are removing the right album. The deleted album will be unassigned from all the collections it was assigned to. If someone had access to a shared link of the removed album, they will still be able to see the public collection and the album will simply not load.

`
};
