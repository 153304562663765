import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { SvgIcon, useTheme } from "@mui/material";
import { StarOutlineRounded, VisibilityOutlined } from "@mui/icons-material";
import { ICollection } from "../../../hooks/collections";
import { strings } from "../../../localization/LocalizedStrings";
import classNames from "classnames";

export const CollectionStats: FunctionComponent<ICollectionStatsProps> = ({
  className,
  collection
}) => {
  const theme = useTheme();
  return (
    <div className={classNames(styles.mainDiv, className)}>
      {collection.starCount !== undefined && collection.starCount > 0 && (
        <>
          <SvgIcon
            component={StarOutlineRounded}
            style={{ color: theme.palette.secondary.main }}
            sx={{ fontSize: 16 }}
          />
          <p
            className={styles.visits}
            style={{ color: theme.palette.secondary.main, marginRight: 4 }}
          >
            {Intl.NumberFormat(strings.currencyLocale, {
              notation: "compact",
              maximumFractionDigits: 1
            }).format(collection.starCount)}
          </p>
        </>
      )}
      {collection.visits > 0 && (
        <>
          <SvgIcon
            component={VisibilityOutlined}
            style={{ color: theme.palette.secondary.main }}
            sx={{ fontSize: 16 }}
          />
          <p className={styles.visits} style={{ color: theme.palette.secondary.main }}>
            {Intl.NumberFormat(strings.currencyLocale, {
              notation: "compact",
              maximumFractionDigits: 1
            }).format(collection.visits)}
          </p>
        </>
      )}
    </div>
  );
};

export interface ICollectionStatsProps {
  className?: string;
  collection: ICollection;
}
