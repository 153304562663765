import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "../DocumentationOrderAlbums/styles.module.scss";

export const DocumentationRemoveFilteredAlbums: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_remove_filtered_albums.title}</h1>
      <img
        alt={"Remove filtered albums"}
        className={styles.image}
        src={strings.doc_remove_filtered_albums.option_image}
      />
      <p>{strings.doc_remove_filtered_albums.description}</p>
    </div>
  );
};
