import prefsImage from "../../../../images/documentation/order-album-prefs-en.png";
import albumImage from "../../../../images/documentation/unordered-album-en.png";

export const doc_order_albums_en = {
  title: "Ordering albums",
  home_title: "Order your albums",
  prefs_image: prefsImage,
  album_image: albumImage,
  description: `Once you create a new album, the album list will get updated showing the new album inside the artist section sorted at the bottom by default. This behavior can be customized on your user profile with the 'Order new albums automatically' preference. If you set this to 'NO', then when you create a new album it will be displayed at the top of the artist list with a warning. This means that album has yet to be ordered, since in the background it was assigned with an order equal to -1. This is helpful if you care about keeping your albums sorted by your preferred criteria, since this warning will only disappear when you manually give an order to the album.
  
`,
  drag_description: `To manually give order to your albums, simply drag and drop them on the albums list. This task can only be performed when you don't have any filters applied, or if the only filter elements at play are artist's ones.
  
`
};
