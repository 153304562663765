import styles from "./styles.module.scss";
import React, { FunctionComponent } from "react";
import { Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { GoldmineRatingCard } from "../GoldmineRatingCard";

export const GradeCard: FunctionComponent<IGradeCardProps> = ({
  points,
  goldmineGrading,
  description,
  selected,
  onClick
}) => {
  const theme = useTheme();
  return (
    <Card sx={{ height: 132 }} elevation={5} onClick={onClick}>
      <CardActionArea
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start"
        }}
        style={{
          borderColor: theme.palette.colors?.highlightBorder,
          borderWidth: 4,
          borderStyle: selected ? "double" : "none"
        }}
      >
        <Stack
          sx={{
            display: "flex",
            padding: 2,
            alignItems: "center"
          }}
          spacing={2}
        >
          <div
            className={styles.points}
            style={{ backgroundColor: theme.palette.colors?.gradeNumberBackground }}
          >
            <p>{points <= 10 ? points : "M"}</p>
          </div>
          <GoldmineRatingCard points={points} goldmineGrading={goldmineGrading} />
        </Stack>
        <p className={styles.description}>{description}</p>
      </CardActionArea>
    </Card>
  );
};

export interface IGradeCardProps {
  points: number;
  goldmineGrading: string;
  description: string;
  selected?: boolean;
  onClick: () => void;
}
