import React, { CSSProperties, FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { SvgIcon } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import classNames from "classnames";

export const ResponsiveIcon: FunctionComponent<IResponsiveIconProps> = ({
  icon,
  className,
  style
}) => {
  return <SvgIcon className={classNames(styles.icon, className)} style={style} component={icon} />;
};

export interface IResponsiveIconProps {
  icon: SvgIconComponent;
  className?: string;
  style?: CSSProperties;
}
