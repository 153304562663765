import { useBackend } from "../../services";

export const useDeleteExchangeRate = () => {
  const { deleteRequest } = useBackend();
  const deleteExchangeRate = (parameters: IDeleteExchangeRateParameters) =>
    deleteRequest("exchangeRates", parameters);
  return { deleteExchangeRate };
};

export interface IDeleteExchangeRateParameters {
  uuid: string;
}
