import albumListImage from "../../../images/documentation/album-list-en.png";
import appStoreButton from "../../../images/AppStoreButton_EN.svg";

export const about_piper_en = {
  title: "What is Piper?",
  block1: `Thanks for using Piper! Here you will find a quick summary of what you can do with this app, how you can manage your music collection, make it your own, and maybe even share it with the world! Remember that you can always sign in as a guest and see for yourself an example of what a complete collection looks like on Piper.
  
  `,
  album_list_image: albumListImage,
  block2: `With Piper you will be able to add one by one all your albums in your collection. Or if you have your collection on Discogs already, you can import it in just a few steps.
  
  For each album track its artist, label, track list, jacket and media conditions, notes and more!
  
  Use the powerful Layout page to add different shelving units and place your albums just as you have them organized in your room. You can choose the number of shelves and segments for each one of your shelving units, creating an exact replica of your real furniture.
  
  If you use a currency other than USD, you will also be able to input purchase prices, selling values in your own currency and then calculate all your financial information adjusted by inflation using exchange rates. Piper let's you group albums in selling value categories, so you just need to update the price in one place and all your albums in that group will get updated, all defined in dollars so it will get automatically updated using the current exchange rate. 
  
  Use the powerful filter offered by Piper to search among your albums combining attributes, or even changing the grouping and order criterias. You will be able to order your collection by year, purchase price, artist, length, and more!
  
  Organize your albums by different collections, and define them as private or public so other users can see a part of your collection.
  
  Have a look at the statistics section to get to know better your collection, from totals and averages to complete graphs.
  
  Scan an album's barcode and add it to your Piper collection automatically! You can also search by text directly on the Discogs database and add albums that way.
  
  Generate private or public QR codes (and links) to quickly access to an album or collection.
  
  Play any song or album directly through Piper using YouTube.
  
  Explore albums and collections from other users using the exhibition section.
  
  
  And it doesn't end there, continue exploring the rest of the documentation (still in progress) to see all you can do or, even better, start using Piper to discover all it has to offer.
  
  `,
  mobile_title: "Use Piper on your phone!",
  mobile_description: `You can use Piper on mobile as well! See below how you can access the application depending on your platform of choice:
  
  `,
  android_title: "Android",
  android_description_1: `In order to use Piper on Android open your web browser and navigate to `,
  android_description_2: `
  Once the page loaded, search on your settings for an option to save the app icon on your desktop.
  
  Close the web browser and open Piper using the newly added app icon.
  
  `,
  ios_title: "iOS",
  ios_description: `Piper is available on the App Store!
  
  Scan this code and download the app or use the button:
`,
  app_store_button: appStoreButton
};
