import { useBackend } from "../../services";
import { IAlbum } from "./interfaces";

export const useReorderAlbums = () => {
  const { post } = useBackend();
  const reorderAlbums = (albums: IAlbum[]) => {
    const sortedUuids = albums.map(album => album.uuid);
    return post("albums/reorder", { sortedUuids });
  };
  return { reorderAlbums };
};

export interface IReorderAlbumsParameters {
  sortedUuids: string[];
}
