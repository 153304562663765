export interface IToken {
  token: string;
  issued: number;
  expires: number;
}

export interface IUser {
  uuid: string;
  dateCreated?: number;
  email: string;
  isEmailConfirmed?: boolean;
  emailPending: string | null;
  role?: UserRole;
  lastImageUpdateUnixTimestamp: number;
  isPasswordSet: boolean;
}

export const enum UserRole {
  admin = "admin",
  common = "common",
  collector = "collector",
  premium = "premium",
  guest = "guest"
}
