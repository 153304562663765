import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, ListItem, Stack, useTheme } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { strings } from "../../../localization/LocalizedStrings";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";

export const BooleanAttributesListItem: FunctionComponent<IBooleanAttributesListItemProps> = ({
  isPublic,
  className,
  hideIsGatefold,
  hideIsClean,
  isCleanLoading,
  isGatefoldLoading,
  hasLyricsLoading,
  hasInsertLoading,
  originalIsGatefold,
  originalHasInsert,
  originalHasLyrics,
  originalIsClean,
  onIsGatefoldChanged,
  onHasInsertChanged,
  onHasLyricsChanged,
  onIsCleanChanged
}) => {
  const theme = useTheme();
  const [isClean, setIsClean] = useState(originalIsClean);
  const handleIsClean = (event: React.MouseEvent<HTMLElement>, newIsClean: boolean) => {
    if (newIsClean !== null) {
      setIsClean(newIsClean);
      onIsCleanChanged(newIsClean);
    }
  };
  const [isGatefold, setIsGatefold] = useState(originalIsGatefold);
  const handleIsGatefold = (event: React.MouseEvent<HTMLElement>, newIsGatefold: boolean) => {
    if (newIsGatefold !== null) {
      setIsGatefold(newIsGatefold);
      onIsGatefoldChanged(newIsGatefold);
    }
  };
  const [hasInsert, setHasInsert] = useState(originalHasInsert);
  const handleHasInsert = (event: React.MouseEvent<HTMLElement>, newHasInsert: boolean) => {
    if (newHasInsert !== null) {
      setHasInsert(newHasInsert);
      onHasInsertChanged(newHasInsert);
    }
  };
  const [hasLyrics, setHasLyrics] = useState(originalHasLyrics);
  const handleHasLyrics = (event: React.MouseEvent<HTMLElement>, newHasLyrics: boolean) => {
    if (newHasLyrics !== null) {
      setHasLyrics(newHasLyrics);
      onHasLyricsChanged(newHasLyrics);
    }
  };
  useEffect(() => {
    setIsGatefold(originalIsGatefold);
    setIsClean(originalIsClean);
    setHasInsert(originalHasInsert);
    setHasLyrics(originalHasLyrics);
  }, [originalHasInsert, originalHasLyrics, originalIsClean, originalIsGatefold]);
  return (
    <ListItem className={className}>
      <div className={styles.itemDiv}>
        <Grid container flexGrow={1} spacing={2}>
          {!hideIsClean && (
            <Grid item xs>
              <Stack spacing={1} alignItems={"center"}>
                <p className={styles.title}>{strings.filter_clean_title}</p>
                {isPublic ? (
                  <p className={styles.value}>
                    {isClean ? strings.stats_pie_yes : strings.stats_pie_no}
                  </p>
                ) : (
                  <ToggleButtonGroup
                    value={isClean}
                    exclusive
                    onChange={handleIsClean}
                    sx={{ height: "30px" }}
                  >
                    <ToggleButton
                      className={styles.toggleButton}
                      sx={{ color: theme.palette.colors?.disabled }}
                      value={true}
                    >
                      {isClean && isCleanLoading ? (
                        <CustomLoadingIndicator className={styles.loadingIndicator} />
                      ) : (
                        strings.stats_pie_yes
                      )}
                    </ToggleButton>
                    <ToggleButton
                      className={styles.toggleButton}
                      sx={{ color: theme.palette.colors?.disabled }}
                      value={false}
                    >
                      {!isClean && isCleanLoading ? (
                        <CustomLoadingIndicator className={styles.loadingIndicator} />
                      ) : (
                        strings.stats_pie_no
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
            </Grid>
          )}
          <Grid item xs>
            <Stack spacing={1} alignItems={"center"}>
              <p className={styles.title}>{strings.filter_insert_title}</p>
              {isPublic ? (
                <p className={styles.value}>
                  {hasInsert ? strings.stats_pie_yes : strings.stats_pie_no}
                </p>
              ) : (
                <ToggleButtonGroup
                  value={hasInsert}
                  exclusive
                  onChange={handleHasInsert}
                  sx={{ height: "30px" }}
                >
                  <ToggleButton
                    className={styles.toggleButton}
                    sx={{ color: theme.palette.colors?.disabled }}
                    value={true}
                  >
                    {hasInsert && hasInsertLoading ? (
                      <CustomLoadingIndicator className={styles.loadingIndicator} />
                    ) : (
                      strings.stats_pie_yes
                    )}
                  </ToggleButton>
                  <ToggleButton
                    className={styles.toggleButton}
                    sx={{ color: theme.palette.colors?.disabled }}
                    value={false}
                  >
                    {!hasInsert && hasInsertLoading ? (
                      <CustomLoadingIndicator className={styles.loadingIndicator} />
                    ) : (
                      strings.stats_pie_no
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Stack>
          </Grid>
          <Grid item xs>
            <Stack spacing={1} alignItems={"center"}>
              <p className={styles.title}>{strings.filter_lyrics_title}</p>
              {isPublic ? (
                <p className={styles.value}>
                  {hasLyrics ? strings.stats_pie_yes : strings.stats_pie_no}
                </p>
              ) : (
                <ToggleButtonGroup
                  value={hasLyrics}
                  exclusive
                  onChange={handleHasLyrics}
                  sx={{ height: "30px" }}
                >
                  <ToggleButton
                    className={styles.toggleButton}
                    sx={{ color: theme.palette.colors?.disabled }}
                    value={true}
                  >
                    {hasLyrics && hasLyricsLoading ? (
                      <CustomLoadingIndicator className={styles.loadingIndicator} />
                    ) : (
                      strings.stats_pie_yes
                    )}
                  </ToggleButton>
                  <ToggleButton
                    className={styles.toggleButton}
                    sx={{ color: theme.palette.colors?.disabled }}
                    value={false}
                  >
                    {!hasLyrics && hasLyricsLoading ? (
                      <CustomLoadingIndicator className={styles.loadingIndicator} />
                    ) : (
                      strings.stats_pie_no
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Stack>
          </Grid>
          {!hideIsGatefold && (
            <Grid item xs>
              <Stack spacing={1} alignItems={"center"}>
                <p className={styles.title}>{strings.filter_gatefold_title}</p>
                {isPublic ? (
                  <p className={styles.value}>
                    {isGatefold ? strings.stats_pie_yes : strings.stats_pie_no}
                  </p>
                ) : (
                  <ToggleButtonGroup
                    value={isGatefold}
                    exclusive
                    onChange={handleIsGatefold}
                    sx={{ height: "30px" }}
                  >
                    <ToggleButton
                      className={styles.toggleButton}
                      sx={{ color: theme.palette.colors?.disabled }}
                      value={true}
                    >
                      {isGatefold && isGatefoldLoading ? (
                        <CustomLoadingIndicator className={styles.loadingIndicator} />
                      ) : (
                        strings.stats_pie_yes
                      )}
                    </ToggleButton>
                    <ToggleButton
                      className={styles.toggleButton}
                      sx={{ color: theme.palette.colors?.disabled }}
                      value={false}
                    >
                      {!isGatefold && isGatefoldLoading ? (
                        <CustomLoadingIndicator className={styles.loadingIndicator} />
                      ) : (
                        strings.stats_pie_no
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </div>
    </ListItem>
  );
};

export interface IBooleanAttributesListItemProps {
  isPublic: boolean;
  className?: string;
  hideIsGatefold: boolean;
  hideIsClean: boolean;
  isCleanLoading: boolean;
  isGatefoldLoading: boolean;
  hasLyricsLoading: boolean;
  hasInsertLoading: boolean;
  originalIsGatefold: boolean;
  originalHasInsert: boolean;
  originalHasLyrics: boolean;
  originalIsClean: boolean;
  onIsGatefoldChanged: (newValue: boolean) => void;
  onHasInsertChanged: (newValue: boolean) => void;
  onHasLyricsChanged: (newValue: boolean) => void;
  onIsCleanChanged: (newValue: boolean) => void;
}
