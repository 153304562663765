import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { IArtist, useGetArtists } from "../../../../hooks/artists";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyArtistDialog } from "../ModifyArtistDialog";
import { ArtistCard } from "../ArtistCard";
import { ArtistDialog } from "../ArtistDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { AccountBoxRounded, DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useContextMenu } from "../../../../hooks/contextMenu";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";
import { useAsyncState } from "../../../../hooks/useAsyncState/useAsyncState";

export const ArtistsDesktop: FunctionComponent<IArtistsDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onArtistSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const [artists, resetArtists, setArtists, noMoreArtists] = useGetArtists(isPublic, setLoading);
  const [filteredArtists, setFilteredArtists] = useState<IArtist[]>();
  const [isAddArtistDialogOpen, setIsAddArtistDialogOpen] = useState(false);
  const [isModifyArtistDialogOpen, setIsModifyArtistDialogOpen] = useState(false);
  const [isArtistDialogOpen, setIsArtistDialogOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useAsyncState(undefined);
  const [searchText, setSearchText] = useState("");
  const [artistImageData, setArtistImageData] = useState<string>();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredArtists();
  }, [artists]);
  const refreshFilteredArtists = () => {
    if (searchText == "") {
      setFilteredArtists(artists);
      return;
    }
    setFilteredArtists(
      artists?.filter(artist => {
        return artist.name.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredArtists();
  }, [searchText]);
  const onModifiedArtist = (newArtist: IArtist) => {
    const newArtists = artists.map(artist => (artist.uuid == newArtist.uuid ? newArtist : artist));
    newArtists.sort((a, b) => a.name.localeCompare(b.name, undefined, { ignorePunctuation: true }));
    setArtists(newArtists);
    toast.success(strings.generic_update_success);
  };
  const onDeletedArtist = (deletedArtist: IArtist) => {
    const newArtists = artists.filter(artist => artist.uuid != deletedArtist.uuid);
    setArtists(newArtists);
    toast.success(strings.generic_update_success);
  };
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredArtists}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.ARTISTS)}
      emptyString={isPublic ? strings.empty_artists_public : strings.empty_artists}
      addItemString={strings.add_attribute}
      title={strings.attribute_artists_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddArtistDialogOpen(true)}
      refreshAction={resetArtists}
      onClearValue={onClearValue}
    >
      {filteredArtists?.map(artist => (
        <Grid item key={artist.uuid} xs={columnWidth}>
          <ArtistCard
            artist={artist}
            selected={preselectedId == artist.uuid}
            onClick={async (event, imageData) => {
              setArtistImageData(imageData);
              if (onArtistSelected) {
                onArtistSelected(artist);
              } else {
                await setSelectedArtist(artist);
                setIsArtistDialogOpen(true);
              }
            }}
            onContextMenu={async (event, imageData) => {
              setArtistImageData(imageData);
              handleContextMenu(event);
              await setSelectedArtist(artist);
            }}
          />
        </Grid>
      ))}
      {(loading || !noMoreArtists) &&
        Array.from({ length: 20 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 90 }} className={styles.skeleton} />
          </Grid>
        ))}
      <ModifyArtistDialog
        open={isAddArtistDialogOpen}
        setOpen={setIsAddArtistDialogOpen}
        onNewItem={newArtist => {
          const newArtists = artists;
          newArtists.push(newArtist);
          newArtists.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setArtists(newArtists);
          refreshFilteredArtists();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyArtistDialog
        open={isModifyArtistDialogOpen}
        setOpen={setIsModifyArtistDialogOpen}
        artist={selectedArtist}
        originalImageData={artistImageData}
        onModifiedItem={onModifiedArtist}
        onDeletedItem={onDeletedArtist}
      />
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsArtistDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <AccountBoxRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.artist_view}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyArtistDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.artist_edit}
        </MenuItem>
      </Menu>
      {selectedArtist && (
        <ArtistDialog
          artist={selectedArtist}
          isOpen={isArtistDialogOpen}
          setIsOpen={setIsArtistDialogOpen}
          canEdit={true}
          onModifiedItem={onModifiedArtist}
          onDeletedItem={deletedArtist => {
            setIsArtistDialogOpen(false);
            onDeletedArtist(deletedArtist);
          }}
        />
      )}
    </AttributeDesktop>
  );
};

export interface IArtistsDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: GridSize;
  preselectedId?: string;
  onArtistSelected?: (artist: IArtist) => void;
  onClearValue?: () => void;
}
