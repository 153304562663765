import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { AlertDialog } from "../../AlertDialog";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { FreeTextListItem } from "../../FreeTextListItem";
import { ModifyFreeTextAttributeDialog } from "../../attributes/ModifyFreeTextAttributeDialog";
import { toast } from "react-toastify";

export const AlbumNotesListSection: FunctionComponent<IAlbumNotesListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const { updateAlbum } = useUpdateAlbum();
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const updateNotes = async (newValue: string) => {
    setIsLoading(true);
    const { body, status } = await updateAlbum({
      uuid: album.uuid,
      notes: newValue
    });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.notes = newValue;
      onAttributeUpdated(album);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <FreeTextListItem
        disabled={isPublic}
        title={strings.notes_title}
        detail={album.notes ? album.notes : "--"}
        onClick={() => setIsNotesDialogOpen(true)}
        isLoading={isLoading}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.notes_title}
        description={strings.notes_detail}
        placeholder={strings.notes_placeholder}
        open={isNotesDialogOpen}
        setOpen={setIsNotesDialogOpen}
        value={album.notes}
        onChange={updateNotes}
        onClearValue={() => updateNotes("")}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumNotesListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
