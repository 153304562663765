import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { CloseRounded } from "@mui/icons-material";
import { strings } from "../../../localization/LocalizedStrings";
import { AlertDialog } from "../../AlertDialog";
import { SwitchListItem } from "../../SwitchListItem";
import { FieldTitle } from "../../Text/FieldTitle";
import { ContainedButton } from "../../ContainedButton";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { ShelfSegmentAlbumShape } from "../../../hooks/shelfSegments";

export const ModifyShelfSegmentAlbumDialog: FunctionComponent<
  IModifyShelfSegmentAlbumDialogProps
> = ({ open, setOpen, onSuccess }) => {
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const tryToClose = () => {
    setIsConfirmCloseAlertOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      shape: "longPlay" as ShelfSegmentAlbumShape
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ shape }) => {
      handleClose();
      onSuccess(shape);
    }
  });
  useEffect(() => {
    if (!open) return;
    formik.resetForm();
  }, [open]);
  const handleShapeChange = (
    event: React.MouseEvent<HTMLElement>,
    shape: ShelfSegmentAlbumShape
  ) => {
    if (shape !== null) {
      formik.setFieldValue("shape", shape);
    }
  };
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={tryToClose} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{strings.shelf_segment_add_album_title}</h3>
          <Box sx={{ flexGrow: 1 }} />
          <ToolbarButton onClick={tryToClose} tooltip={strings.close} icon={CloseRounded} />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>{strings.shelf_segment_album_shape_title.toUpperCase()}</FieldTitle>
          <SwitchListItem
            className={styles.visibility}
            labels={[
              strings.shelf_segment_album_shape_long_play,
              strings.shelf_segment_album_shape_ten_inches,
              strings.shelf_segment_album_shape_single,
              strings.shelf_segment_album_shape_cd,
              strings.shelf_segment_album_shape_box_set
            ]}
            values={["longPlay", "tenInches", "single", "compactDisc", "boxSet"]}
            value={formik.values.shape}
            fullWidth={true}
            disableGutters
            handleChange={handleShapeChange}
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
    </FormikProvider>
  );
};

export interface IModifyShelfSegmentAlbumDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSuccess: (shape: ShelfSegmentAlbumShape) => void;
}
