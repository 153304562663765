export const doc_import_from_excel_es = {
  title: "Importar álbumes desde una planilla de cálculo",
  description_1: `Podés importar tus álbumes a Piper desde una planilla de cálculo. Seleccioná esta opción desde el menú desplehable y va a aparecer una nueva ventana.

  Dentro de la ventana de importación, seleccioná un archivo con la extensión 'xlsx'. La mayoría de los programas pueden importar planillas de cálculo en este formato.

  El archivo tiene que tener un álbum por fila, y sus atributos tienen que estar posicionados en cada columna en un órden específico. Cada columna puede ser mandatoria (esta columna tiene que estar completa para cada fila), u opcional (esta columna puede estar vacía para alguna o todas las filas, pero tiene que estar presente, así que dejá una columna en blanco si no usás ese atributo para tus álbumes).

  IMPORTANTE: La primera fila del archivo es ignorada por el proceso, así que usala como encabezado para ponerle títulos a las columnas. Ubicá todos los álbumes en una tabla única. Evitá tener filas en blanco al final de la tabla.  

  Todos los atributos que apliquen van a ser comparados por nombre con los que ya tengas en Piper. Si alguno ya existe, ése va a ser usado. De otro modo, se va a crear uno nuevo.  

  Podés usar la siguiente plantilla para llenarla con tus álbumes:
`,
  description_2: `Las columnas dentro del archivo se explican abajo. Solamente las dos primeras son mandatorias.
  `,
  column_1: `Nombre del álbum (Mandatoria)
El nombre del álbum. No puede estar vacío.
  `,
  column_2: `Nombre del artista (Mandatoria)
El nombre del artista del álbum. No puede estar vacío.
  `,
  column_3: `Nombre del sello (Opcional)
El nombre del sello discográfico del álbum.
  `,
  column_4: `Número de catálogo (Opcional)
El número de catálogo del álbum.
  `,
  column_5: `Año original (Opcional)
El año de la primera edición del álbum.
  `,
  column_6: `Año de edición (Opcional)
El año específico de la edición del álbum.
  `,
  column_7: `País de origen (Opcional)
El país de origen del álbum.
  `,
  column_8: `Graduación del sobre (Opcional)
La graduación del sobre del álbum en puntos numéricos. La nota se basa en el estándar de graduación Goldmine y sigue el siguiente criterio, ingresá alguno de los siguientes números o dejá el campo vacío:
    
    (1) = P-
    (2) = P
    (3) = G-
    (4) = G
    (5) = G+
    (6) = VG
    (7) = VG+
    (8) = VG++
    (9) = NM-
    (10) = NM
    (11) = M
  `,
  column_9: `Graduación del disco (Opcional)
La graduación del disco(s) del álbum en puntos numéricos. La nota se basa en el estándar de graduación Goldmine y sigue el siguiente criterio, ingresá alguno de los siguientes números o dejá el campo vacío:
    
    (1) = P-
    (2) = P
    (3) = G-
    (4) = G
    (5) = G+
    (6) = VG
    (7) = VG+
    (8) = VG++
    (9) = NM-
    (10) = NM
    (11) = M
  `,
  column_10: `Escuchado (Opcional)
Indica si el álbum fue escuchado o no. Llená el campo con cualquier contenido para SÍ, o dejalo vacío para NO.
  `,
  column_11: `Insert (Opcional)
Indica si el álbum tiene insert o no. Llená el campo con cualquier contenido para SÍ, o dejalo vacío para NO.
  `,
  column_12: `Letras (Opcional)
Indica si el álbum tiene letras de las canciones o no. Llená el campo con cualquier contenido para SÍ, o dejalo vacío para NO.
  `,
  column_13: `Gatefold (Opcional)
Indica si el álbum es gatefold o no. Llená el campo con cualquier contenido para SÍ, o dejalo vacío para NO.
  `,
  column_14: `Precio de compra (Opcional)
El precio de compra del álbum al momento de la compra. Ingresá el número en tu moneda local sin el símbolo de la moneda.
  `,
  column_15: `Valor de venta (Opcional)
El nombre del grupo del valor de venta del álbum.
  `,
  column_16: `Fecha de compra (Opcional)
La fecha de compra del álbum. Asegurate que la celda tenga el formato de fecha.
  `,
  column_17: `Tienda (Opcional)
La tienda donde se compró el álbum.
  `,
  column_18: `Número de discos (Opcional)
El número de discos que tiene el álbum.
  `,
  column_19: `Color del disco (Opcional)
El color del disco del álbum.
  `,
  column_20: `Formato (Opcional)
El nombre completo del formato del álbum. Por ejemplo: 'Long Play'.
  `,
  column_21: `Tipo de álbum (Opcional)
El nombre del tipo de álbum. Por ejemplo: 'Demo'.
  `,
  column_22: `Número de pistas (Opcional)
El número de pistas en el álbum.
  `,
  column_23: `Duración (Opcional)
La duración del álbum en segundos.
  `,
  column_24: `Calificación (Opcional)
La calificación para el álbum. Ingresá el número de estrellas como se ve abajo:

    (1) = ☆☆☆☆★
    (2) = ☆☆☆★★
    (3) = ☆☆★★★
    (4) = ☆★★★★
    (5) = ★★★★★
  `,
  column_25: `Notas (Opcional)
Ingresá las notas que quieras para el álbum.
  `
};
