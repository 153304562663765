import { useBackend } from "../../services";

export const useDeleteAlbumType = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbumType = (parameters: IDeleteAlbumTypeParameters) =>
    deleteRequest("albumTypes", parameters);
  return { deleteAlbumType };
};

export interface IDeleteAlbumTypeParameters {
  uuid: string;
}
