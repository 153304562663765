import { useBackend } from "../../services";

export const useGetPrivateLink = () => {
  const { get } = useBackend();
  const getPrivateLink = (parameters: IGetPrivateLinkParameters) =>
    get("share/privateLink", parameters);
  return { getPrivateLink };
};

export interface IGetPrivateLinkParameters {
  albumId: string;
}
