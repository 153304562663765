import React, { FunctionComponent } from "react";
import { FieldAttributes } from "formik";
import { Box, CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";

export const CustomLoadingIndicator: FunctionComponent<FieldAttributes<any>> = props => {
  return (
    <Box style={props.style} sx={{ display: "flex" }}>
      <CircularProgress className={styles.circularProgress} {...props} color={"primary"} />
    </Box>
  );
};
