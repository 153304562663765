import { useBackend } from "../../services";
import { ShelvingOrientation, ShelvingTheme } from "./interfaces";

export const useModifyShelving = () => {
  const { put } = useBackend();
  const modifyShelving = (parameters: IModifyShelvingParameters) => put("shelvings", parameters);
  return { modifyShelving };
};

export interface IModifyShelvingParameters {
  uuid: string;
  name: string;
  description?: string;
  orientation: ShelvingOrientation;
  theme: ShelvingTheme;
}
