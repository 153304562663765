import { useBackend } from "../../services";

export const useModifyAlbumType = () => {
  const { put } = useBackend();
  const modifyAlbumType = (parameters: IModifyAlbumTypeParameters) => put("albumTypes", parameters);
  return { modifyAlbumType };
};

export interface IModifyAlbumTypeParameters {
  uuid: string;
  name: string;
  showOnAlbumList: boolean;
}
