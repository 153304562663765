import React, { FunctionComponent, useEffect, useState } from "react";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import { RoutesBuilder } from "./models/RoutesBuilder";
import { Login } from "./pages/Login";
import { Albums } from "./pages/Albums";
import { Attributes } from "./pages/Attributes";
import { LanguageManager } from "./models/LanguageManager";
import { strings } from "./localization/LocalizedStrings";
import { Stats } from "./pages/Stats";
import { MaintenancePage } from "./pages/Maintenance";
import { MarketplacePage } from "./pages/Marketplace";
import { YouTubePlayer } from "./components/YouTubePlayer";
import { NotFoundPage } from "./pages/NotFound";
import { DocumentationRoutes } from "./components/documentation/DocumentationRoutes";
import { PaymentPage } from "./pages/Payment";
import { useSessionManager, useUser } from "./hooks/session";
import { AdminConsole } from "./pages/AdminConsole";
import { UserProfile } from "./pages/UserProfile";
import { Chats } from "./pages/Chats";
import { useBackend } from "./services";
import { toast } from "react-toastify";
import { ChatListItem } from "./components/chats/ChatListItem";
import { IChat } from "./hooks/chats";
import { IMessage } from "./hooks/messages";
import { Firebase } from "./services/Firebase";
import { useSavePushNotificationToken } from "./hooks/pushNotificationTokens";
import { onMessage } from "firebase/messaging";
import { Layout } from "./pages/Layout";

export const Routes: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getToken } = useSessionManager();
  const { savePushNotificationToken } = useSavePushNotificationToken();
  const { socket } = useBackend();
  const [currentUser] = useUser.useState();
  const [videoIds, setVideoIds] = useState<string[]>();
  const [playerTitle, setPlayerTitle] = useState("");
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  useEffect(() => {
    const onNewMessage = (data: { chat: IChat; message: IMessage }) => {
      if (
        window.location.pathname.includes(RoutesBuilder.chats()) ||
        !currentUser ||
        currentUser.role == "guest"
      )
        return;
      const { chat, message } = data;
      toast(
        <div>
          <p style={{ marginBottom: 3 }}>{strings.chat_new_message_notification}</p>
          <ChatListItem chat={chat} selected={false} disabled hideReadMark />
        </div>,
        {
          onClick: () => {
            navigate(`${RoutesBuilder.chats()}?userId=${message.authorUserProfile.userId}`, {
              replace: true
            });
            window.location.reload();
          }
        }
      );
    };
    socket.on("new_message", onNewMessage);
    return () => {
      socket.off("new_message", onNewMessage);
    };
  });

  if (Firebase.shared.messaging) {
    onMessage(Firebase.shared.messaging, payload => {
      toast(payload.notification?.body);
    });
  }

  if (process.env.REACT_APP_MAINTENANCE == "true") {
    return <MaintenancePage />;
  }

  const language = LanguageManager.getLanguage();
  if (language) {
    strings.setLanguage(language);
  }

  const nonAuthenticatedPath = RoutesBuilder.documentation.home();
  if (!window.location.pathname.includes(nonAuthenticatedPath)) {
    const token = getToken();
    if (!token) {
      return <Login isMinimalist={false} />;
    } else {
      Firebase.shared.getFCMToken(fcmToken => {
        if (fcmToken) {
          if (currentUser?.role != "guest") {
            window.navigator.mediaDevices.enumerateDevices().then(devices => {
              savePushNotificationToken({ token: fcmToken, deviceId: devices[0].deviceId }).then();
            });
          }
        } else {
          toast(strings.push_notifications_permission_notice, {
            autoClose: false,
            onClick: Firebase.shared.requestPermission,
            onClose: Firebase.shared.requestPermission,
            toastId: "firebase-messaging",
            closeOnClick: true,
            delay: 50000
          });
        }
      });
    }
  }

  const onPlayFromYouTube = (title: string, videoIds: string[]) => {
    setPlayerTitle(title);
    setVideoIds(videoIds);
    setIsPlayerOpen(true);
  };

  return (
    <>
      <Switch>
        <Route
          path={RoutesBuilder.home()}
          element={<Albums isPublic={false} onPlayFromYouTube={onPlayFromYouTube} />}
        />
        <Route path={RoutesBuilder.signIn()} element={<Login isMinimalist={false} />} />
        <Route
          path={RoutesBuilder.albums.list()}
          element={<Albums isPublic={false} onPlayFromYouTube={onPlayFromYouTube} />}
        />
        <Route path={RoutesBuilder.attributes.manage()} element={<Attributes />} />
        <Route path={RoutesBuilder.chats()} element={<Chats />} />
        <Route path={RoutesBuilder.userProfile()} element={<UserProfile />} />
        <Route path={RoutesBuilder.stats()} element={<Stats />} />
        <Route path={RoutesBuilder.marketplace()} element={<MarketplacePage />} />
        <Route
          path={RoutesBuilder.layout()}
          element={<Layout onPlayFromYouTube={onPlayFromYouTube} />}
        />
        <Route path={RoutesBuilder.admin()} element={<AdminConsole />} />
        <Route
          key={"public_albums"}
          path={RoutesBuilder.share()}
          element={<Albums isPublic={true} onPlayFromYouTube={onPlayFromYouTube} />}
        />
        <Route
          key={"private_albums"}
          path={RoutesBuilder.private()}
          element={<Albums isPublic={false} onPlayFromYouTube={onPlayFromYouTube} />}
        />
        <Route path={RoutesBuilder.payment()} element={<PaymentPage />} />
        <Route path={`${RoutesBuilder.documentation.home()}/*`} element={<DocumentationRoutes />} />
        <Route
          key={"maintenance"}
          path={RoutesBuilder.maintenance()}
          element={<MaintenancePage />}
        />
        <Route key={"not_found"} path={"*"} element={<NotFoundPage />} />
      </Switch>
      <YouTubePlayer
        title={playerTitle}
        videoIds={videoIds}
        open={isPlayerOpen}
        setOpen={setIsPlayerOpen}
      />
    </>
  );
};
