import { useBackend } from "../../services";
import { IMediaGrade } from "./interfaces";
import { useEffect, useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";

export const useGetMediaGrades = <T>(
  setLoading: (loading: boolean) => void,
  dependencies: T[] = []
) => {
  const { get } = useBackend();
  const [mediaGrades, setMediaGrades] = useState<IMediaGrade[]>([]);
  useEffect(() => {
    setLoading(true);
    get("mediaGrades").then(({ body, status }) => {
      if (StatusCodesHelper.isSuccessful(status)) {
        setMediaGrades(body);
      } else if (!StatusCodesHelper.isSessionExpired(status)) {
        toast.error(body.message);
      }
      setLoading(false);
    });
  }, dependencies);
  return mediaGrades;
};
