import { useBackend } from "../../services";
import { ShelvingOrientation, ShelvingTheme } from "./interfaces";

export const useAddShelving = () => {
  const { post } = useBackend();
  const addShelving = (parameters: IAddShelvingParameters) => post("shelvings", parameters);
  return { addShelving };
};

export interface IAddShelvingParameters {
  name: string;
  description?: string;
  orientation: ShelvingOrientation;
  theme: ShelvingTheme;
}
