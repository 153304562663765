import importDiscogsImage from "../../../../images/documentation/import-discogs-en.png";

export const doc_import_from_discogs_en = {
  title: "Import albums from Discogs",
  import_image: importDiscogsImage,
  description: `If you have a Discogs account you can import your albums to Piper. Just select the option from the menu drawer and a dialog will open.
  
  If it's your first time doing it, the browser will redirect to Discogs so you can sign in and grant permission to Piper to see your collection.
  
  After giving permission you will be able to select which Discogs folder will be imported. This is especially useful if you want to import only some of your albums. If you already have a part of your collection imported you can check the option to skip albums with same release ID. This will make sure that those albums are not duplicated during this process
  
  If you have a premium subscription plan, you can also check the option to retrieve the album images directly from Discogs (only the front cover). This comes with the drawback of making the process last longer.
  
  Once a Discogs folder is selected you can optionally select a collection to which these albums will be assigned. Either way, you will always find the albums on the 'All albums' collection.
  
  Take into account that the import process can take several minutes depending on the number of albums that have to be imported. The process will be executed in our servers so you can close the window and keep using the app while the import runs. If you go back to the import dialog you can check the progress or cancel.
  
  The imported albums will have some missing parameters, so you will have to review them and make any modifications you feel necessary.

`
};
