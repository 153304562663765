import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { Divider } from "@mui/material";
import { RightDetailListItem } from "../../RightDetailListItem";
import { strings } from "../../../localization/LocalizedStrings";
import { useUpdateAlbum } from "../../../hooks/albums/useUpdateAlbum";
import { ModifyGenericAttributeDialog } from "../../attributes/ModifyGenericAttributeDialog";
import { AlertDialog } from "../../AlertDialog";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { toast } from "react-toastify";

export const AlbumYearListSection: FunctionComponent<IAlbumYearListSectionProps> = ({
  isPublic,
  album,
  onAttributeUpdated
}) => {
  const [isLoadingOriginalYear, setIsLoadingOriginalYear] = useState(false);
  const [isDialogOpenOriginalYear, setIsDialogOpenOriginalYear] = useState(false);
  const [isLoadingReleaseYear, setIsLoadingReleaseYear] = useState(false);
  const [isDialogOpenReleaseYear, setIsDialogOpenReleaseYear] = useState(false);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [isDialogOpenCountry, setIsDialogOpenCountry] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const { updateAlbum } = useUpdateAlbum();

  const updateOriginalYear = async (newValue: string | null) => {
    setIsLoadingOriginalYear(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, originalYear: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.originalYear = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingOriginalYear(false);
  };

  const updateReleaseYear = async (newValue: string | null) => {
    setIsLoadingReleaseYear(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, releaseYear: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.releaseYear = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingReleaseYear(false);
  };

  const updateCountry = async (newValue: string | null) => {
    setIsLoadingCountry(true);
    const { status, body } = await updateAlbum({ uuid: album.uuid, country: newValue });
    if (!StatusCodesHelper.isSuccessful(status)) {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    } else {
      toast.success(strings.album_update_success);
      album.country = newValue ?? undefined;
      onAttributeUpdated(album);
    }
    setIsLoadingCountry(false);
  };

  return (
    <div>
      <RightDetailListItem
        disabled={isPublic}
        title={strings.original_year_title}
        detail={album.originalYear ?? strings.unknown}
        isLoading={isLoadingOriginalYear}
        onClick={() => setIsDialogOpenOriginalYear(true)}
      />
      <Divider variant={"inset"} />
      <RightDetailListItem
        disabled={isPublic}
        title={strings.release_year_title}
        detail={album.releaseYear ?? strings.unknown}
        isLoading={isLoadingReleaseYear}
        onClick={() => setIsDialogOpenReleaseYear(true)}
      />
      <Divider variant={"inset"} />
      <RightDetailListItem
        disabled={isPublic}
        title={strings.country_title}
        detail={album.country && album.country != "" ? album.country : strings.unknown}
        isLoading={isLoadingCountry}
        onClick={() => setIsDialogOpenCountry(true)}
      />
      <ModifyGenericAttributeDialog
        title={strings.original_year_title}
        description={strings.original_year_message}
        placeholder={strings.original_year_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isDialogOpenOriginalYear}
        setOpen={setIsDialogOpenOriginalYear}
        isNumber={true}
        value={album.originalYear}
        onChange={async newValue => {
          await updateOriginalYear(newValue);
        }}
        onClearValue={async () => {
          await updateOriginalYear(null);
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.release_year_title}
        description={strings.release_year_message}
        placeholder={strings.release_year_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isDialogOpenReleaseYear}
        setOpen={setIsDialogOpenReleaseYear}
        isNumber={true}
        value={album.releaseYear}
        onChange={async newValue => {
          await updateReleaseYear(newValue);
        }}
        onClearValue={async () => {
          await updateReleaseYear(null);
        }}
      />
      <ModifyGenericAttributeDialog
        title={strings.country_title}
        description={strings.country_message}
        placeholder={strings.country_placeholder}
        missingText={strings.field_missing}
        canClear={true}
        open={isDialogOpenCountry}
        setOpen={setIsDialogOpenCountry}
        isNumber={false}
        value={album.country}
        onChange={async newValue => {
          await updateCountry(newValue);
        }}
        onClearValue={async () => {
          await updateCountry(null);
        }}
      />
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </div>
  );
};

export interface IAlbumYearListSectionProps {
  isPublic: boolean;
  album: IAlbum;
  onAttributeUpdated: (album: IAlbum) => void;
}
