import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  useTheme
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IMessage } from "../../../../hooks/messages";
import { UserAvatar } from "../../../UserAvatar";
import styles from "./styles.module.scss";
import { strings } from "../../../../localization/LocalizedStrings";
import {
  CampaignRounded,
  ForumRounded,
  DeleteForever,
  ExpandLessRounded,
  ExpandMoreRounded,
  MoreVertRounded,
  Reply
} from "@mui/icons-material";
import { AlbumMessagesList } from "../AlbumMessagesList";
import { IAlbum } from "../../../../hooks/albums";
import { StringFormat } from "../../../../models/StringFormat";
import { ModifyFreeTextAttributeDialog } from "../../../attributes/ModifyFreeTextAttributeDialog";
import { useAddMessageForAlbum } from "../../../../hooks/messages/useAddMessageForAlbum";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { AlertDialog } from "../../../AlertDialog";
import { PublicUserProfileDialog } from "../../../userProfiles/PublicUserProfileDialog";
import { useIsMobile } from "../../../../hooks/isMobile";
import { useCurrentPublicCollection } from "../../../../hooks/collections/useCollectionManager";
import TimeAgo from "react-timeago";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import englishStrings from "react-timeago/lib/language-strings/en";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import spanishStrings from "react-timeago/lib/language-strings/es";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { ResponsiveIcon } from "../../../ResponsiveIcon";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { useUser } from "../../../../hooks/session";
import { useDeleteMessage } from "../../../../hooks/messages/useDeleteMessage";
import { useAddReportForMessage } from "../../../../hooks/reports/useAddReportForMessage";
import { toast } from "react-toastify";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const AlbumMessageListItem: FunctionComponent<IAlbumMessageListItemProps> = ({
  isPublic,
  album,
  message,
  isLoading,
  setIsLoading,
  onMessageRemoved
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userProfile = message.authorUserProfile;
  const [createdDate, setCreatedDate] = useState(new Date(message.createdUnixTimestamp * 1000));
  const formatter = buildFormatter(strings.locale == "en-US" ? englishStrings : spanishStrings);
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const [currentUser] = useUser.useState();
  const [isRepliesExpanded, setIsRepliesExpanded] = useState(false);
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);
  const { addMessageForAlbum } = useAddMessageForAlbum();
  const { deleteMessage } = useDeleteMessage();
  const { addReportForMessage } = useAddReportForMessage();
  const [isReportMessageAlertOpen, setIsReportMessageAlertOpen] = useState(false);
  const [isRemoveMessageAlertOpen, setIsRemoveMessageAlertOpen] = useState(false);
  const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);
  const [newMessage, setNewMessage] = useState<IMessage>();
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [numberOfReplies, setNumberOfReplies] = useState(message.numberOfReplies);
  const { handleContextMenu, handleMenuClose, menuProps } = useContextMenu();
  const onNewMessage = async (messageBody: string) => {
    setIsLoading(true);
    const { status, body } = await addMessageForAlbum({
      albumId: album.uuid,
      collectionId: isPublic ? currentPublicCollection?.uuid : undefined,
      body: messageBody,
      replyToMessageId: message.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setNewMessage(body);
      message.numberOfReplies = numberOfReplies + 1;
      setNumberOfReplies(numberOfReplies => numberOfReplies + 1);
      setIsRepliesExpanded(true);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const onReportMessage = async (reportBody: string) => {
    setIsLoading(true);
    const { status, body } = await addReportForMessage({
      messageId: message.uuid,
      body: reportBody
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.report_success);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  const onRemoveMessage = async () => {
    setIsLoading(true);
    const { status, body } = await deleteMessage({ uuid: message.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      onMessageRemoved();
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setNumberOfReplies(message.numberOfReplies);
    setCreatedDate(new Date(message.createdUnixTimestamp * 1000));
    setIsRepliesExpanded(false);
  }, [message.uuid]);
  return (
    <ListItem className={styles.listItem}>
      {message.replyToMessageId && (
        <Box
          className={styles.partialBorderBox}
          style={{ borderColor: theme.palette.colors?.disabled }}
        />
      )}
      <Box className={styles.borderBox} style={{ borderColor: theme.palette.colors?.disabled }} />
      <Stack sx={{ width: "100%" }}>
        <Stack className={styles.userStack} direction={"row"}>
          <UserAvatar
            className={styles.avatar}
            style={{ borderColor: theme.palette.colors?.disabled }}
            userProfile={userProfile}
            onClick={() => setIsUserProfileDialogOpen(true)}
          />
          <p className={styles.username} onClick={() => setIsUserProfileDialogOpen(true)}>
            {userProfile.username}
          </p>
          <p className={styles.date} style={{ color: theme.palette.primary.subtitle }}>
            {" •"}
          </p>
          <TimeAgo
            className={styles.date}
            style={{ color: theme.palette.primary.subtitle }}
            date={createdDate}
            formatter={formatter}
          />
        </Stack>
        <p
          className={styles.body}
          style={{
            fontStyle: message.isRemoved ? "italic" : "normal",
            color: message.isRemoved ? theme.palette.secondary.main : theme.palette.primary.main
          }}
        >
          {message.body}
        </p>
        <Stack className={styles.buttonsStack} direction={"row"} spacing={isMobile ? 1 : 2}>
          <Box className={styles.spaceBox} />
          {numberOfReplies > 0 && (
            <Button
              disabled={isLoading}
              startIcon={isRepliesExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              size={"small"}
              className={styles.button}
              onClick={() => setIsRepliesExpanded(state => !state)}
            >
              {numberOfReplies > 1
                ? StringFormat(strings.messages_replies_more, String(numberOfReplies))
                : strings.messages_reply_more}
            </Button>
          )}
          {!message.isRemoved && (
            <Button
              disabled={isLoading}
              startIcon={<Reply />}
              size={"small"}
              className={styles.button}
              onClick={() => setIsNewMessageDialogOpen(true)}
            >
              {strings.messages_reply}
            </Button>
          )}
          <Box flexGrow={1} />
          {!message.isRemoved && (
            <IconButton
              disabled={isLoading}
              className={styles.moreButton}
              style={{ backgroundColor: theme.palette.colors?.buttonBackground }}
              onClick={handleContextMenu}
            >
              <ResponsiveIcon className={styles.moreButtonIcon} icon={MoreVertRounded} />
            </IconButton>
          )}
        </Stack>
        <Collapse in={isRepliesExpanded} timeout={"auto"}>
          <AlbumMessagesList
            isPublic={isPublic}
            className={styles.repliesList}
            album={album}
            parentMessage={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            newMessage={newMessage}
          />
        </Collapse>
      </Stack>
      <Menu {...menuProps}>
        {currentUser?.role != "guest" && message.authorUserProfile.userId != currentUser?.uuid && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate(`${RoutesBuilder.chats()}?userId=${message.authorUserProfile.userId}`);
            }}
            dense
          >
            <ListItemIcon>
              <ForumRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.chat_send}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsReportMessageAlertOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <CampaignRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.messages_report}
        </MenuItem>
        {(currentUser?.role == "admin" ||
          album.userId == currentUser?.uuid ||
          message.authorUserProfile.userId == currentUser?.uuid) && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsRemoveMessageAlertOpen(true);
            }}
            style={{ color: theme.palette.colors?.remove }}
            dense
          >
            <ListItemIcon>
              <DeleteForever style={{ color: theme.palette.colors?.remove }} fontSize={"small"} />
            </ListItemIcon>
            {strings.delete}
          </MenuItem>
        )}
      </Menu>
      <PublicUserProfileDialog
        userProfile={message.authorUserProfile}
        isOpen={isUserProfileDialogOpen}
        setIsOpen={setIsUserProfileDialogOpen}
        showCollectionInfo={false}
        showSendMessageButton
      />
      <ModifyFreeTextAttributeDialog
        title={strings.messages_new_title}
        description={StringFormat(strings.messages_replying_to, message.authorUserProfile.username)}
        placeholder={strings.messages_new_title}
        open={isNewMessageDialogOpen}
        setOpen={setIsNewMessageDialogOpen}
        onChange={onNewMessage}
      />
      <ModifyFreeTextAttributeDialog
        title={strings.report_new}
        description={strings.report_description}
        placeholder={strings.report_new}
        open={isReportMessageAlertOpen}
        setOpen={setIsReportMessageAlertOpen}
        onChange={onReportMessage}
      />
      <AlertDialog
        isConfirm
        message={StringFormat(
          strings.messages_confirm_removal,
          message.authorUserProfile.username,
          message.body
        )}
        open={isRemoveMessageAlertOpen}
        setOpen={setIsRemoveMessageAlertOpen}
        onConfirm={onRemoveMessage}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </ListItem>
  );
};

export interface IAlbumMessageListItemProps {
  isPublic: boolean;
  album: IAlbum;
  message: IMessage;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onMessageRemoved: () => void;
}
