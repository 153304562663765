import styles from "./styles.module.scss";
import React, { CSSProperties, FunctionComponent } from "react";
import { ToolbarBox } from "../ToolbarBox";
import { SvgIconComponent } from "@mui/icons-material";
import classNames from "classnames";
import { ToolbarRawButton } from "../ToolbarRawButton";

export const ToolbarButton: FunctionComponent<IToolbarButtonProps> = ({
  className,
  style,
  ...props
}) => {
  return (
    <ToolbarBox className={classNames(styles.toolbarButton, className)} style={style}>
      <ToolbarRawButton {...props} />
    </ToolbarBox>
  );
};

export interface IToolbarButtonProps {
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  color?: string;
  href?: string;
  target?: string;
  tooltip: string;
  onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
  onContextMenu?: (event: React.MouseEvent, imageData?: string) => void;
  icon: SvgIconComponent;
}
