import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, ListItemIcon, Menu, MenuItem, Skeleton } from "@mui/material";
import { IAlbumFormat, useGetAlbumFormats } from "../../../../hooks/albumFormats";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyAlbumFormatDialog } from "../ModifyAlbumFormatDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { AlbumFormatCard } from "../AlbumFormatCard";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const AlbumFormatsDesktop: FunctionComponent<IAlbumFormatsDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onAlbumFormatSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const [albumFormats, resetAlbumFormats, setAlbumFormats, noMoreAlbumFormats] = useGetAlbumFormats(
    isPublic,
    setLoading
  );
  const [filteredAlbumFormats, setFilteredAlbumFormats] = useState<IAlbumFormat[]>();
  const [isAddAlbumFormatDialogOpen, setIsAddAlbumFormatDialogOpen] = useState(false);
  const [isModifyAlbumFormatDialogOpen, setIsModifyAlbumFormatDialogOpen] = useState(false);
  const [selectedAlbumFormat, setSelectedAlbumFormat] = useState<IAlbumFormat>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredAlbumFormats();
  }, [albumFormats]);
  const refreshFilteredAlbumFormats = () => {
    if (searchText == "") {
      setFilteredAlbumFormats(albumFormats);
      return;
    }
    setFilteredAlbumFormats(
      albumFormats?.filter(albumFormat => {
        return (
          albumFormat.name.toLowerCase().includes(searchText.toLowerCase()) ||
          albumFormat.shortName.toString().toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredAlbumFormats();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredAlbumFormats}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.ALBUM_FORMATS)}
      emptyString={isPublic ? strings.empty_album_formats_public : strings.empty_album_formats}
      addItemString={strings.add_attribute}
      title={strings.attribute_album_formats_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddAlbumFormatDialogOpen(true)}
      refreshAction={resetAlbumFormats}
      onClearValue={onClearValue}
    >
      {filteredAlbumFormats?.map(albumFormat => (
        <Grid item key={albumFormat.uuid} xs={columnWidth}>
          <AlbumFormatCard
            albumFormat={albumFormat}
            onClick={() => {
              if (onAlbumFormatSelected) {
                onAlbumFormatSelected(albumFormat);
              } else {
                setSelectedAlbumFormat(albumFormat);
                setIsModifyAlbumFormatDialogOpen(true);
              }
            }}
            onContextMenu={event => {
              setSelectedAlbumFormat(albumFormat);
              handleContextMenu(event);
            }}
            selected={preselectedId == albumFormat.uuid}
          />
        </Grid>
      ))}
      {(loading || !noMoreAlbumFormats) &&
        Array.from({ length: 4 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyAlbumFormatDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifyAlbumFormatDialog
        open={isAddAlbumFormatDialogOpen}
        setOpen={setIsAddAlbumFormatDialogOpen}
        onNewItem={newAlbumFormat => {
          const newAlbumFormats = albumFormats;
          newAlbumFormats.push(newAlbumFormat);
          newAlbumFormats.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setAlbumFormats(newAlbumFormats);
          refreshFilteredAlbumFormats();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyAlbumFormatDialog
        open={isModifyAlbumFormatDialogOpen}
        setOpen={setIsModifyAlbumFormatDialogOpen}
        albumFormat={selectedAlbumFormat}
        onModifiedItem={newAlbumFormat => {
          const newAlbumFormats = albumFormats.map(albumFormat =>
            albumFormat.uuid == newAlbumFormat.uuid ? newAlbumFormat : albumFormat
          );
          newAlbumFormats.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setAlbumFormats(newAlbumFormats);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedAlbumFormat => {
          const newAlbumFormats = albumFormats.filter(
            albumFormat => albumFormat.uuid != deletedAlbumFormat.uuid
          );
          setAlbumFormats(newAlbumFormats);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface IAlbumFormatsDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: GridSize;
  preselectedId?: string;
  onAlbumFormatSelected?: (albumFormat: IAlbumFormat) => void;
  onClearValue?: () => void;
}
