import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./styles.module.scss";
import { CustomLoadingIndicator } from "../CustomLoadingIndicator";
import classNames from "classnames";
import { CardActionArea } from "@mui/material";
import { useFirstRender } from "../../hooks/firstRender/useFirstRender";
import { toast } from "react-toastify";
import { strings } from "../../localization/LocalizedStrings";

export const ImageDropzone: FunctionComponent<IImageDropzoneProps> = ({
  disabled,
  className,
  style,
  onImageChanged,
  initialImage,
  placeholderImage,
  isLoading,
  shouldOpenFileDialog,
  onClick
}) => {
  const firstRender = useFirstRender();
  const [imageFile, setImageFile] = useState<File & { preview: string }>();
  const [isSpine, setIsSpine] = useState(false);
  useEffect(() => {
    setImageFile(undefined);
  }, [isLoading]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { "image/*": [] },
    disabled: disabled,
    noClick: true,
    maxFiles: 1,
    onDrop: (acceptedFiles, filesRejection) => {
      if (filesRejection.length > 0) {
        toast.error(strings.upload_multiple_images_error);
        return;
      }
      const file = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      });
      setImageFile(file);
      onImageChanged(file);
    }
  });
  useEffect(() => {
    if (firstRender) return;
    open();
  }, [shouldOpenFileDialog]);
  useEffect(() => {
    if (imageFile) {
      return () => URL.revokeObjectURL(imageFile.preview);
    }
  }, [imageFile]);
  const updateDimensions = (source: string) => {
    const image = new Image();
    image.src = source;
    image.onload = function () {
      setIsSpine(image.height > image.width);
    };
  };
  useEffect(() => {
    updateDimensions(imageFile?.preview ?? initialImage ?? placeholderImage);
  }, [imageFile, initialImage, placeholderImage]);
  return (
    <CardActionArea
      className={classNames(className, styles.container)}
      {...getRootProps()}
      style={{
        ...style,
        backgroundColor: "transparent"
      }}
      onClick={onClick ?? open}
    >
      {isLoading && (
        <div className={styles.blurDiv}>
          <CustomLoadingIndicator />
        </div>
      )}
      <input {...getInputProps()} />
      <img
        className={isSpine ? styles.spineImage : styles.image}
        src={imageFile?.preview ?? initialImage ?? placeholderImage}
        alt={"Image"}
      />
    </CardActionArea>
  );
};

export interface IImageDropzoneProps {
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onImageChanged: (imageFile: File & { preview: string }) => void;
  initialImage?: string;
  placeholderImage: string;
  isLoading: boolean;
  shouldOpenFileDialog?: boolean;
  onClick?: () => void;
}
