import { useBackend } from "../../services";
import { IUserNotification } from "./interfaces";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";

export const useGetUserNotifications = (
  setLoading: (loading: boolean) => void,
  isEnabled: boolean
) => {
  const { get } = useBackend();
  const getUserNotifications = (parameters: IGetPaginatedAttributeProps) =>
    get("userNotifications", parameters);
  return useGetPaginatedAttributes<IUserNotification>(
    PageSizeHelper.userNotifications(),
    getUserNotifications,
    setLoading,
    isEnabled,
    undefined
  );
};
