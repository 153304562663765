import { useBackend } from "../../services";

export const useRecoverPassword = () => {
  const { post } = useBackend();
  const recoverPassword = (parameters: IRecoverPasswordParameters) =>
    post("recoverPassword", parameters);
  return { recoverPassword };
};

export interface IRecoverPasswordParameters {
  email: string;
}
