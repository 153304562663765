import {
  IGetPaginatedAttributeProps,
  IGetPaginatedByTextAttributeProps,
  IPublicGetPaginatedAttributeProps
} from "./interfaces";
import { useEffect, useState } from "react";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { toast } from "react-toastify";

export const useGetPaginatedAttributes = <T>(
  pageSize: number,
  backendRequest: (
    parameters:
      | IGetPaginatedAttributeProps
      | IPublicGetPaginatedAttributeProps
      | IGetPaginatedByTextAttributeProps
  ) => Promise<{ body: any; status: number }>,
  setLoading: (loading: boolean) => void,
  isEnabled: boolean,
  collectionId?: string,
  query?: string
) => {
  const [items, setItems] = useState<T[]>([]);
  const [lastUuid, setLastUuid] = useState<string>();
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  useEffect(() => {
    if (noMoreItems || !isEnabled) return;
    let ignoreResponse = false;
    setLoading(true);
    backendRequest({ pageSize, lastUuid, collectionId, query }).then(({ body, status }) => {
      if (ignoreResponse) return;
      if (StatusCodesHelper.isSuccessful(status)) {
        setNoMoreItems(body.length < pageSize);
        if (body.length >= 0) {
          setItems(oldArray => [...oldArray, ...body]);
          if (body.length > 0) {
            setLastUuid(body.slice(-1)[0].uuid);
          }
        }
      } else if (!StatusCodesHelper.isSessionExpired(status)) {
        setNoMoreItems(true);
        toast.error(body.message);
      }
      setLoading(false);
    });
    return () => {
      ignoreResponse = true;
    };
  }, [lastUuid, forceFetch]);
  const resetItems = () => {
    setItems([]);
    setNoMoreItems(false);
    if (lastUuid === undefined) {
      setForceFetch(state => !state);
    } else {
      setLastUuid(undefined);
    }
  };
  return [items, resetItems, setItems, noMoreItems] as const;
};
