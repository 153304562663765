export const ImageUtilities = {
  rotate: (image: HTMLImageElement, angle: number) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = angle % 180 === 0 ? image.width : image.height;
    canvas.height = angle % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    return canvas.toDataURL();
  }
};
