import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, GridSize, Skeleton } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { ISleeveGrade, useGetSleeveGrades } from "../../../../hooks/sleeveGrades";
import { GradeCard } from "../../GradeCard";
import { AttributeDesktop } from "../../AttributeDesktop";
import styles from "./styles.module.scss";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const SleeveGradesDesktop: FunctionComponent<ISleeveGradesDesktopProps> = ({
  handleClose,
  columnWidth,
  preselectedId,
  onSleeveGradeSelected,
  onClearValue
}) => {
  const [loading, setLoading] = useState(true);
  const sleeveGrades = useGetSleeveGrades(setLoading, []);
  const [filteredSleeveGrades, setFilteredSleeveGrades] = useState<ISleeveGrade[]>();
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    refreshFilteredSleeveGrades();
  }, [sleeveGrades]);
  const refreshFilteredSleeveGrades = () => {
    if (searchText == "") {
      setFilteredSleeveGrades(sleeveGrades);
      return;
    }
    setFilteredSleeveGrades(
      sleeveGrades?.filter(sleeveGrade => {
        return (
          sleeveGrade.description.toLowerCase().includes(searchText.toLowerCase()) ||
          +searchText == sleeveGrade.points ||
          sleeveGrade.goldmineGrade.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    refreshFilteredSleeveGrades();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={false}
      items={filteredSleeveGrades}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.SLEEVE_GRADES)}
      emptyString={strings.empty_sleeve_grades}
      title={strings.attribute_sleeve_grades_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      onClearValue={onClearValue}
    >
      {filteredSleeveGrades?.map(sleeveGrade => (
        <Grid item key={sleeveGrade.id} xs={columnWidth}>
          <GradeCard
            points={sleeveGrade.points}
            goldmineGrading={sleeveGrade.goldmineGrade}
            description={sleeveGrade.description}
            selected={preselectedId == sleeveGrade.id}
            onClick={() => {
              if (onSleeveGradeSelected) {
                onSleeveGradeSelected(sleeveGrade);
              }
            }}
          />
        </Grid>
      ))}
      {loading &&
        Array.from({ length: 11 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 132 }} className={styles.skeleton} />
          </Grid>
        ))}
    </AttributeDesktop>
  );
};

export interface ISleeveGradesDesktopProps {
  handleClose?: () => void;
  columnWidth: GridSize;
  preselectedId?: number;
  onSleeveGradeSelected?: (sleeveGrade: ISleeveGrade) => void;
  onClearValue?: () => void;
}
