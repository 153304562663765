import { useBackend } from "../../services";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";

export const useDeleteAlbumsByFilter = () => {
  const { deleteRequest } = useBackend();
  const deleteAlbumsByFilter = (
    collectionId: string | undefined,
    sortOption: FilterSortOption,
    sortOrder: FilterSortOrder,
    filterElements?: FilterElement[]
  ) => {
    const filters = FilterElement.queryItems(filterElements);
    return deleteRequest("albums/all", {
      ...filters,
      collectionId,
      sortOption,
      sortOrder
    });
  };
  return { deleteAlbumsByFilter };
};
