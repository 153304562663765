import { useBackend } from "../../services";
import { useGetPaginatedAttributes } from "../attributes/useGetPaginatedAttributes";
import { IGetPaginatedByTextAttributeProps } from "../attributes/interfaces";
import { PageSizeHelper } from "../../models/PageSizeHelper";
import { IUserProfile } from "./interfaces";

export const useGetUserProfilesByText = (
  searchText: string,
  setLoading: (loading: boolean) => void
) => {
  const { get } = useBackend();
  const getUserProfiles = (parameters: IGetPaginatedByTextAttributeProps) =>
    get("userProfiles/shared/public", parameters);
  return useGetPaginatedAttributes<IUserProfile>(
    PageSizeHelper.userProfiles(),
    getUserProfiles,
    setLoading,
    true,
    undefined,
    searchText
  );
};
