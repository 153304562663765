import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { Card, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";
import { useNavigate } from "react-router-dom";

export const DocumentationAlbums: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_albums.title}</h1>
      <p>{strings.doc_albums.description}</p>
      <section id={"create"} />
      <h2>{strings.doc_albums.create_title}</h2>
      <img className={styles.imageCreate} src={strings.doc_albums.create_image} />
      <p>{strings.doc_albums.create_description}</p>
      <section id={"delete"} />
      <h2>{strings.doc_albums.delete_title}</h2>
      <img className={styles.imageFloatRight} src={strings.doc_albums.delete_image} />
      <p>{strings.doc_albums.delete_description}</p>
      <section id={"more"} />
      <h2>{strings.doc_more_options_title}</h2>
      <List className={styles.list}>
        <Card sx={{ margin: 2 }}>
          <ListItemButton
            key={"FilterAlbums"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.filterAlbums()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_filter_albums.home_title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"OrderAlbums"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.orderAlbums()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_order_albums.home_title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"MarkingAlbums"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.markingAlbums()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_marking_albums.title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"ScanBarcode"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.scanBarcode()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_albums_add_barcode.home_title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"ImportFromDiscogs"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromDiscogs()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_import_from_discogs.title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"ImportFromExcel"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.importFromExcel()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_import_from_excel.title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"ImageGallery"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.imageGallery()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_image_gallery.title}
            />
          </ListItemButton>
          <Divider variant={"inset"} />
          <ListItemButton
            key={"RemoveFiltered"}
            className={styles.listItem}
            onClick={() =>
              navigate(
                `${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.removeFilteredAlbums()}`
              )
            }
          >
            <ListItemText
              disableTypography
              className={styles.itemTitle}
              primary={strings.doc_remove_filtered_albums.title}
            />
          </ListItemButton>
        </Card>
      </List>
    </div>
  );
};
