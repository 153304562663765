import React, { FunctionComponent } from "react";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { ContainedButton } from "../../../ContainedButton";

export const DocumentationImportFromExcel: FunctionComponent = () => {
  return (
    <div>
      <section id={"title"} />
      <h1>{strings.doc_import_from_excel.title}</h1>
      <p>{strings.doc_import_from_excel.description_1}</p>
      <ContainedButton className={styles.templateButton} href={strings.excel_import_template_url}>
        {strings.excel_import_download_template}
      </ContainedButton>
      <p>{strings.doc_import_from_excel.description_2}</p>
      <ol>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_1}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_2}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_3}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_4}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_5}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_6}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_7}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_8}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_9}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_10}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_11}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_12}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_13}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_14}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_15}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_16}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_17}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_18}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_19}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_20}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_21}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_22}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_23}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_24}</span>
        </li>
        <li className={styles.listItem}>
          <span className={styles.normalText}>{strings.doc_import_from_excel.column_25}</span>
        </li>
      </ol>
    </div>
  );
};
