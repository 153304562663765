import { useBackend } from "../../services";
import { IAlbumContent } from "./interfaces";

export const useReorderAlbumContents = () => {
  const { post } = useBackend();
  const reorderAlbumContents = (albumContents: IAlbumContent[]) => {
    const sortedUuids = albumContents.map(albumContent => albumContent.uuid);
    return post("albumContents/reorder", { sortedUuids });
  };
  return { reorderAlbumContents };
};
