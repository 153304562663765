import { useBackend } from "../../services";

export const useGetSharedContent = () => {
  const { get } = useBackend();
  const getSharedContent = (parameters: IGetSharedContentParameters) =>
    get("share/sharedContent", parameters);
  return { getSharedContent };
};

export interface IGetSharedContentParameters {
  shareLink: string | (string | null)[];
}
