import { useBackend } from "../../services";

export const useModifySellValue = () => {
  const { put } = useBackend();
  const modifySellValue = (parameters: IModifySellValueParameters) => put("sellValues", parameters);
  return { modifySellValue };
};

export interface IModifySellValueParameters {
  uuid: string;
  group: string;
  dollars: number;
  description: string;
}
