import deleteImage from "../../../../images/documentation/collection-remove-en.png";
import image from "../../../../images/documentation/collection-button-en.png";

export const doc_collections_en = {
  title: "Collections",
  home_title: "What are collections?",
  image: image,
  description: `You can divide your main collection into different collections. Each collection can show a different aspect of your albums, you will have separate statistics for each one and you can also filter them individually.
  
  An album can be part of none, one or many collections, but the album's instance is unique. In other words, assigning an album to multiple collections won't duplicate the album.
  
  The 'All albums' collection will always contain all the albums you have, so if you remove or change collections you will never lose the reference to an album.
  
  Create collections by physical location, genre, personal taste, you name it. Think of collections as different windows to your main collection, each window showing a different view.
  
`,
  create_title: "Add a collection",
  create_description: `In order to add a new collection you just need to click on the collection button on the toolbar in the 'My albums' page.
  
  A new dialog will appear where you can see all collections you have, and at the toolbar you will have the plus button to add a new one.
  
  When you are adding a new collection, you can modify its image, name, and description.
  
  Once you confirm, a red text will appear below any missing required field. If all the mandatory fields are completed, then the collection will be created.
  
  When you are updating an existing collection, a new set of attributes will be available. You will be able to set the collection as 'public' and make it available at the Exhibition. And if you do so, make sure to check the banned fields attribute, and select any attributes you want to hide to the rest of the users.
  
`,
  delete_image: deleteImage,
  delete_title: "Delete a collection",
  delete_description: `To delete an collection, right click on it from the collections list and select the option to delete it. A confirmation dialog will appear so you can double check the collection name before removing it.
  
  This process is irreversible, so make sure you are removing the right collection. All albums assigned to that collection will be unassigned automatically. If someone had access to a shared link of the removed collection, they won't be able to access it anymore.

`
};
