export const doc_subscriptions_es = {
  title: "Suscripciones",
  home_title: "Cómo funcionan las suscripciones",
  description: `Para mantener Piper actualizado y libre de publicidad dependemos de nuestro sistema de suscripciones. Todos los usuarios pueden usar nuestra aplicación de forma gratuita con ciertas limitaciones y pueden optar por uno de nuestros planes disponibles para no tener límites y acceder a nuevas funcionalidades.
  
  Podés elegir entre estos 3 planes que tenemos disponibles:
  
  `,
  free_plan: "Plan gratuito: ",
  free_plan_description: `Todos los usuarios comienzan con este plan cuando se registran. No te vamos a cobrar nada mientras te mantengas en este plan pero vas a tener las siguientes limitaciones:
  
  `,
  free_plan_body_1: "300 álbumes.",
  free_plan_body_2: "100 artistas.",
  free_plan_body_3: "100 sellos.",
  free_plan_body_4: "100 tiendas.",
  free_plan_body_5: "20 formatos.",
  free_plan_body_6: "20 tipos de álbum.",
  free_plan_body_7: "50 valores de venta.",
  free_plan_body_8: "100 cambios de moneda.",
  free_plan_body_9: "3 colecciones.",
  free_plan_body_10: "5 muebles.",
  free_plan_end: `
  
  En el momento que llegues a cualquiera de estos límites el sistema te va a avisar que llegaste al máximo para ese recurso cuando quieras agregar un nuevo ítem. Siempre tenés la opción de eliminar un ítem para agregar uno distinto en cualquier momento.
  
  Si en cierto punto tuviste una suscripción paga activa y tuviste más álbumes/atributos que los límites del plan gratuito y luego cancelaste tu suscripción, vas a notar que la aplicación no va a dejarte actualizar ninguna instancia de ese recurso, así que no vas a poder modificar ninguno hasta que regularices la situación. En este escenario no vas a poder buscar álbumes, filtrarlos, ni tampoco obtener álbumes de forma aleatoria. No te preocupes, ya que todos tus datos están todavía guardados. Cuando elimines suficientes recursos o renueves tu suscripción vas a poder seguir usando Piper como antes.
      
  `,
  collector_plan: "Plan Coleccionista: ",
  collector_plan_description: `Con esta suscripción no vas a tener ningún límite en la cantidad de álbumes y atributos, pero no cuenta con los beneficios del plan 'Premium'.
  
  `,
  premium_plan: "Plan Premium: ",
  premium_plan_description: `La suscripción 'Premium' incluye todos los beneficios del plan 'Coleccionista', y además podés hacer las siguientes cosas:
  
  `,
  premium_plan_body_1:
    "Cargar imágenes adicionales para tus álbumes usando la galería de imágenes.",
  premium_plan_body_2: `Usar imágenes de Discogs al importar álbumes.
  
  `,
  access: `Para acceder al estado de tu suscripción actual o comprar otra suscripción podés ir a tu perfil de usuario.
  
`
};
