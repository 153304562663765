import React, { FunctionComponent } from "react";
import { IShelving } from "../../../hooks/shelvings";
import { ShelfRepresentation } from "../ShelfRepresentation";
import styles from "./styles.module.scss";
import { IShelf } from "../../../hooks/shelves";
import { IShelfSegment, IShelfSegmentAlbum } from "../../../hooks/shelfSegments";
import { ShelvingUtilities } from "../../../models/ShelvingUtilities";
import piperLogo from "../../../images/piper-text.png";

export const ShelvingRepresentation: FunctionComponent<IShelvingRepresentationProps> = ({
  shelving,
  selectedShelf,
  selectedShelfSegment,
  selectedAlbumIds,
  isLoading,
  setIsLoading,
  onShelfSegmentAlbumSelected,
  onRemoveAlbumFromShelfSegment,
  shelvingIndex,
  zoom
}) => {
  const shelfRepresentation = (shelf: IShelf, index: number) => (
    <ShelfRepresentation
      key={shelf.uuid}
      shelving={shelving}
      shelf={shelf}
      selectedShelfSegment={selectedShelfSegment}
      selectedAlbumIds={selectedAlbumIds}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      onShelfSegmentAlbumSelected={onShelfSegmentAlbumSelected}
      onRemoveAlbumFromShelfSegment={(album, shelfIndex, segmentIndex, albumIndex) =>
        onRemoveAlbumFromShelfSegment(album, shelvingIndex, shelfIndex, segmentIndex, albumIndex)
      }
      shelvingIndex={shelvingIndex}
      shelfIndex={index}
      zoom={zoom}
      canRemove={onRemoveAlbumFromShelfSegment !== undefined}
    />
  );
  return (
    <div
      className={styles.cabinet}
      style={{
        borderImage: `url(${ShelvingUtilities.getThemeImage(shelving.theme)}) 100`,
        flexDirection: shelving.orientation == "horizontal" ? "column" : "row",
        height: shelving.orientation == "horizontal" ? "fit-content" : "100%",
        width: shelving.orientation == "horizontal" ? "100%" : "fit-content"
      }}
    >
      {selectedShelf
        ? shelfRepresentation(
            selectedShelf,
            shelving.shelves.map(shelf => shelf.uuid).indexOf(selectedShelf.uuid)
          )
        : shelving.shelves
            ?.sort((a, b) => a.order - b.order)
            .map((shelf, index) => shelfRepresentation(shelf, index))}
      <img className={styles.piperLogo} src={piperLogo} />
    </div>
  );
};

export interface IShelvingRepresentationProps {
  shelving: IShelving;
  selectedShelf: IShelf;
  selectedShelfSegment: IShelfSegment;
  selectedAlbumIds: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onShelfSegmentAlbumSelected: (shelfSegmentAlbum: IShelfSegmentAlbum) => void;
  onRemoveAlbumFromShelfSegment?: (
    shelfSegmentAlbum: IShelfSegmentAlbum,
    shelvingIndex: number,
    shelfIndex: number,
    shelfSegmentIndex: number,
    albumIndex: number
  ) => void;
  shelvingIndex: number;
  zoom: number;
}
