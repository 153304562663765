export const doc_collections_attributes_en = {
  title: "Collection attributes",
  home_title: "Attributes",
  description: `Find below the list of attributes that belong to a collection and a short description for each one.
  
  `,
  name_title: "Name",
  name_description: `Each collection needs a name. Use a name that represents the albums inside it. Take into account that this name will be visible for other users if the collection is public.
  
  `,
  image_title: "Image",
  image_description: `There's an image by default assign to collections, but you can change it for one of your own. The collection image is displayed in a dynamic size that is dependant on the screen resolution, so it's best to use images that don't suffer from stretches. The approximate aspect ratio that goes best with the image is 5:1.
  
  `,
  visibility_title: "Visibility",
  visibility_description: `This attribute is not available the first time you create a collection, since all collections are private at first. You will be able to see this attribute only when you go to edit a collection.
  
  There are two possible options for visibility: private or public.
  
  Private collections can only be accessed by you, no one will be able to see that collection.
  
  Public collections are publicly available to all users, since they can be searched for at the Exhibition, and you can even share public links and QR codes. For a collection to be public, it has to have some albums assigned to it.
  
  `,
  banned_fields_title: "Hidden fields",
  banned_fields_description: `This attribute is not available the first time you create a collection, since all collections are private at first. You will be able to see this attribute only when you go to edit a collection.
  
  Enter here any album attributes that you want to hide from other users when they see this collection at the Exhibition, or through public links and QR codes.
  
  `,
  discount_title: "Discount",
  discount_description: `You can set a discount percentage that will affect all albums on this collection.
  
  For each album, the selling value will be displayed adjusted by this discounted percentage.
  
  If you chose to hide the selling value for a public collection, then only the discount percentage will be visible.
  
  `,
  description_title: "Description",
  description_description: `Use this field to describe the collection, explain what is it about, your appreciations, selling terms or anything you want to express about it.
  
  `
};
