import React, { FunctionComponent } from "react";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { Route, Routes as Switch } from "react-router-dom";
import { DocumentationPage } from "../../../pages/Documentation";
import { DocumentationHome } from "../DocumentationHome";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { TermsOfService } from "../TermsOfService";
import { DocumentationImportFromDiscogs } from "../albums/DocumentationImportFromDiscogs";
import { DocumentationAlbums } from "../albums/DocumentationAlbums";
import { NotFoundPage } from "../../../pages/NotFound";
import { DocumentationFilterAlbums } from "../albums/DocumentationFilterAlbums";
import { DocumentationOrderAlbums } from "../albums/DocumentationOrderAlbums";
import { DocumentationSubscriptions } from "../subscriptions/DocumentationSubscriptions";
import { DocumentationSubscriptionWithMercadoPago } from "../subscriptions/DocumentationSubscriptionWithMercadoPago";
import { AboutPiper } from "../AboutPiper";
import { DocumentationSignUp } from "../users/DocumentationSignUp";
import { DocumentationSignInAsGuest } from "../users/DocumentationSignInAsGuest";
import { DocumentationRecoverPassword } from "../users/DocumentationRecoverPassword";
import { DocumentationAlbumsAddBarcode } from "../albums/DocumentationAlbumsAddBarcode";
import { DocumentationImageGallery } from "../albums/DocumentationImageGallery";
import { DocumentationUserProfile } from "../userProfile/DocumentationUserProfile";
import { DocumentationImportFromExcel } from "../albums/DocumentationImportFromExcel";
import { DocumentationRemoveFilteredAlbums } from "../albums/DocumentationRemoveFilteredAlbums";
import { DocumentationCollections } from "../collections/DocumentationCollections";
import { DocumentationPublicCollections } from "../collections/DocumentationPublicCollections";
import { DocumentationCollectionsAttributes } from "../collections/DocumentationCollectionsAttributes";
import { DocumentationArtists } from "../albumAttributes/DocumentationArtists";
import { DocumentationAlbumAttributesHome } from "../albumAttributes/DocumentationAlbumAttributesHome";
import { DocumentationMarkingAlbums } from "../albums/DocumentationMarkingAlbums";

export const DocumentationRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <Route
        path={"/"}
        element={
          <DocumentationPage defaultExpanded={[""]} defaultSelected={""}>
            <DocumentationHome />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.about()}
        element={
          <DocumentationPage defaultExpanded={["about"]} defaultSelected={"about"}>
            <AboutPiper />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.users.home()}
        element={
          <DocumentationPage defaultExpanded={["users_parent"]} defaultSelected={"users_sign_up"}>
            <DocumentationSignUp />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.users.signUp()}
        element={
          <DocumentationPage defaultExpanded={["users_parent"]} defaultSelected={"users_sign_up"}>
            <DocumentationSignUp />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.users.guest()}
        element={
          <DocumentationPage defaultExpanded={["users_parent"]} defaultSelected={"users_guest"}>
            <DocumentationSignInAsGuest />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.users.recoverPassword()}
        element={
          <DocumentationPage
            defaultExpanded={["users_parent"]}
            defaultSelected={"users_recover_password"}
          >
            <DocumentationRecoverPassword />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.home()}
        element={
          <DocumentationPage defaultExpanded={["album_parent"]} defaultSelected={"album_home"}>
            <DocumentationAlbums />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.filterAlbums()}
        element={
          <DocumentationPage defaultExpanded={["album_parent"]} defaultSelected={"album_filter"}>
            <DocumentationFilterAlbums />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.orderAlbums()}
        element={
          <DocumentationPage defaultExpanded={["album_parent"]} defaultSelected={"album_order"}>
            <DocumentationOrderAlbums />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.markingAlbums()}
        element={
          <DocumentationPage defaultExpanded={["album_parent"]} defaultSelected={"album_marks"}>
            <DocumentationMarkingAlbums />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.scanBarcode()}
        element={
          <DocumentationPage
            defaultExpanded={["album_parent"]}
            defaultSelected={"album_scan_barcode"}
          >
            <DocumentationAlbumsAddBarcode />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.importFromDiscogs()}
        element={
          <DocumentationPage
            defaultExpanded={["album_parent"]}
            defaultSelected={"album_import_discogs"}
          >
            <DocumentationImportFromDiscogs />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.importFromExcel()}
        element={
          <DocumentationPage
            defaultExpanded={["album_parent"]}
            defaultSelected={"album_import_excel"}
          >
            <DocumentationImportFromExcel />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.imageGallery()}
        element={
          <DocumentationPage
            defaultExpanded={["album_parent"]}
            defaultSelected={"album_image_gallery"}
          >
            <DocumentationImageGallery />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.album.removeFilteredAlbums()}
        element={
          <DocumentationPage
            defaultExpanded={["album_parent"]}
            defaultSelected={"album_remove_filtered_albums"}
          >
            <DocumentationRemoveFilteredAlbums />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.albumAttributes.home()}
        element={
          <DocumentationPage
            defaultExpanded={["album_attributes_parent"]}
            defaultSelected={"album_attributes_home"}
          >
            <DocumentationAlbumAttributesHome />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.albumAttributes.artists()}
        element={
          <DocumentationPage
            defaultExpanded={["album_attributes_parent"]}
            defaultSelected={"album_attributes_artists"}
          >
            <DocumentationArtists />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.collections.home()}
        element={
          <DocumentationPage
            defaultExpanded={["collections_parent"]}
            defaultSelected={"collections_home"}
          >
            <DocumentationCollections />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.collections.attributes()}
        element={
          <DocumentationPage
            defaultExpanded={["collections_parent"]}
            defaultSelected={"collections_attributes"}
          >
            <DocumentationCollectionsAttributes />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.collections.publicCollections()}
        element={
          <DocumentationPage
            defaultExpanded={["collections_parent"]}
            defaultSelected={"collections_public_collections"}
          >
            <DocumentationPublicCollections />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.userProfile()}
        element={
          <DocumentationPage defaultExpanded={["userProfile"]} defaultSelected={"userProfile"}>
            <DocumentationUserProfile />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.subscriptions.home()}
        element={
          <DocumentationPage
            defaultExpanded={["subscriptions_parent"]}
            defaultSelected={"subscriptions_home"}
          >
            <DocumentationSubscriptions />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.subscriptions.mercadoPago()}
        element={
          <DocumentationPage
            defaultExpanded={["subscriptions_parent"]}
            defaultSelected={"subscriptions_mercado_pago"}
          >
            <DocumentationSubscriptionWithMercadoPago />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.policy()}
        element={
          <DocumentationPage defaultExpanded={["policy"]} defaultSelected={"policy"}>
            <PrivacyPolicy />
          </DocumentationPage>
        }
      />
      <Route
        path={RoutesBuilder.documentation.terms()}
        element={
          <DocumentationPage defaultExpanded={["terms"]} defaultSelected={"terms"}>
            <TermsOfService />
          </DocumentationPage>
        }
      />
      <Route key={"not_found"} path={"*"} element={<NotFoundPage />} />
    </Switch>
  );
};
