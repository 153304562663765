import { IImpact } from "../hooks/impacts";

export const ImpactHelper = {
  starsString: (impact: IImpact) => {
    let stars = "";
    for (let i = 0; i < 5; i++) {
      stars += i <= impact.id ? "\u2605" : "\u2606";
    }
    return stars;
  }
};
