import { useBackend } from "../../services";

export const useAddArtist = () => {
  const { post } = useBackend();
  const addArtist = (parameters: IAddArtistParameters) => post("artists", parameters);
  return { addArtist };
};

export interface IAddArtistParameters {
  name: string;
  realName?: string;
  website?: string;
  profile?: string;
  discogsArtistId?: string;
  birthDate?: number;
  deathDate?: number;
}
