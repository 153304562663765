export const terms_es = {
  title: "Términos de Servicio",
  title_short: "T. de Servicio",
  block1: `Piper comenzó como un proyecto personal que poco a poco fue creciendo y sigue haciéndolo día a día. Tanto Piper como su dominio ldelosheros.com son propiedad de Lautaro de los Heros ("Piper", "Nosotros", "Nuestro") y es provisto a vos ("El usuario", "Vos", "Tu") bajo estos términos de servicio. Las condiciones en este documento de Términos de Servicio rigen para todos los usuarios que utilicen la aplicación, tanto en su versión web como móvil.
  
  Si tenés preguntas adicionales o requerís más información sobre estos términos, no dudes en contactarnos.
  
  `,
  consent_title: "Consentimiento",
  consent: `Al usar nuestra aplicación aceptás nuestros términos de servicio sin objeciones.
  
  `,
  service_title: "Sobre el servicio",
  service: `Piper es una aplicación que permite a sus usuarios cargar información acerca de su colección de música, como ser álbumes, artistas, sellos discográficos y más, así como también de ciertos datos personales resguardados según nuestra Política de Privacidad.
  
  Piper ofrece también la posibilidad a sus usuarios de compartir de forma libre contenidos de sus colecciones con otros usuarios.
  
  `,
  data_title: "Sobre los datos",
  data: `Todos los datos guardados en Piper cuentan con un respaldo que es actualizado todos los días, salvo error o causas ajenas a Piper. Este respaldo sirve como punto de retorno en caso de extrema necesidad o para buscar datos históricos de algún usuario que así lo requiriera. Esto no significa que esta información esté disponible para su consulta, pero podés ponerte en contacto por más detalles.
  
  Podés consultar nuestra Política de Privacidad para saber cómo manejamos tus datos.
  
  Las responsabilidades de Piper respecto a estos datos se especifican en estos términos de servicio más adelante.
  
  `,
  users_title: "Sobre los usuarios",
  users: `Para poder ser un usuario de Piper se deben cumplir los siguientes requisitos sin excepción:
  `,
  users_1: "Ser mayor de 13 años (o la edad mínima legal en su territorio).",
  users_2: "Siempre actuar de buena fe.",
  users_3:
    "Siempre proporcionar información verdadera y que no vaya en contra del buen gusto y la buena ética, o que vulnere derechos de terceros.",
  users_4: "Bajo ningún caso se permite intentar vulnerar o engañar al sistema.",
  users_5: "No se debe abusar ni usar de manera indebida los canales de contacto y denuncia.",
  users_6:
    "No se debe acceder ni recoger datos de nuestro sistema de manera automatizada o sin el permiso correspondiente.",
  users_7:
    "No se debe intercambiar o solicitar nombres de usuario y/o contraseñas a otros usuarios ni apropiarse de indentificadores de acceso.",
  users_8:
    "Está prohibido vender o conceder licencias sobre productos adquiridos desde esta aplicación.",
  users_9:
    "No se debe incurrir en abuso, agresiones, o cualquier tipo de acción agresiva contra cualquier otro usuario, ya sea por mensajería o cualquier medio pertinente.",
  users_10:
    "Se debe cesar el uso de la aplicación inmediatamente si se incurre en cualquier acto ilegal, engañoso, discriminatorio o fraudulento, o en el caso de ayudar a un tercero a hacerlo.",
  users_11: `No se debe infringir ninguno de los puntos establecidos en estos términos de servicio.
  
  `,
  subscriptions_title: "Sobre las suscripciones",
  subscriptions: `Las suscripciones son el único medio para afrontar los gastos productivos de Piper y para poder mantener a la aplicación libre de publicidades.
  
  Todas las suscripciones tienen un cobro mensual automático. La modalidad y fecha exacta del cobro varía según la plataforma de pago que se haya elegido al momento de alta de la suscripción.
  
  El monto de suscripción vigente es reembolsable únicamente si el usuario manifiesta su intención de reembolso hasta 48 horas posteriores al cobro de la suscripción, y siempre que se estén cumpliendo los términos de servicio de este documento. En caso de reembolso, la suscripción quedará cancelada automáticamente y el usuario dejará de contar con los beneficios de la suscripción en ese mismo instante.
  
  Si el usuario desea cancelar la suscripción puede hacerlo desde la plataforma de pagos o contactando a soporte. En este caso la suscripción queda cancelada y no se procesarán más pagos a partir de la fecha, pero el usuario podrá seguir utilizando los beneficios de la suscripción hasta la fecha de vencimiento del período ya pagado. En caso de cancelación no existe la posibilidad de reembolso.
  
  El monto mensual de las suscripciones puede ser modificado y su modificación será informada a través del canal oficial de comunicaciones de Piper con hasta 7 días de anticipación. El nuevo monto estará vigente para todas las nuevas suscripciones y se cobrará a las suscripciones activas en el próximo cobro aplicable.
  
  El monto de las suscripciones puede contar con un descuento temporal. Este descuento puede ser modificado y su modificación será informada a través del canal oficial de comunicaciones de Piper con hasta 7 días de anticipación. El nuevo monto estará vigente para todas las nuevas suscripciones y se cobrará a las suscripciones activas en el próximo cobro aplicable.
  
  En el momento en el que un usuario cancela su suscripción activa y cuenta con una cantidad de álbumes o atributos mayor al límite del plan gratuito, va a notar que la aplicación no va a permitirle actualizar o modificar ninguno de esos recursos. El usuario no va a poder utilizar la funcionalidad de búsqueda de álbumes, el filtro de álbumes, ni la funcionalidad de álbumes aleatorios hasta que haga una de dos cosas: renovar su suscripción o eliminar álbumes y atributos hasta volver a estar por debajo del umbral del plan gratuito.
  
  `,
  warranties_title: "Descargo de garantías",
  warranties: `Al usar esta aplicación aceptás y entendés que:
  `,
  warranties_1: `Usás el servicio bajo tu propio riesgo. El servicio se ofrece "tal cual" y "según disponibilidad", nosotros renunciamos a cualquier tipo de garantía explícita o implícita.`,
  warranties_2:
    "No ofrecemos garantía de ningún tipo de que el servicio vaya a cumplir con tus expectativas, que el servicio sea interrumpido, oportuno, seguro o libre de errores, de que los resultados obtenidos por la utilización del servicio sean precisos o confiables.",
  warranties_3:
    "No ofrecemos garantía alguna respecto a la calidad del producto, sus servicios o la información brindada, o que el material comprado u obtenido a través del servicio cumpla con tus expectativas.",
  warranties_4:
    "No ofrecemos garantía que los errores presentes en el sistema sean arreglados en algún momento.",
  warranties_5: `No promovemos ni aprobamos ideas o mensajes en el contenido generado por los usuarios del servicio.
  
  `,
  rights_title: "Derechos y responsabilidades",
  rights: `Piper se reserva el derecho de inhabilitar o suspender de manera temporal o permanente a cualquier usuario que no cumpla con cualquiera de los términos expresados en este documento.
  
  Piper se reserva el derecho de eliminar cualquier contenido que crea inapropiado o que no cumpla con cualquiera de los términos expresados en este documento.
  
  Piper se reserva el derecho de modificar de manera parcial o total cualquier nombre de usuario si se identificara una situación de usurpación de identidad o cualquier otra razón que se crea pertinente, informando de manera pertinente al usuario involucrado.
  
  Piper se reserva el derecho de cancelar la suscripción del usuario si así lo considere apropiado, notificando al usuario de forma pertinente.
  
  Piper se reserva el derecho de decisión sobre tanto otorgar o no otorgar un reembolso evaluando el caso particular del usuario.
  
  Piper se reserva todos los derechos que no se hayan concedido de manera expresa.
  
  Piper ofrece sus productos "tal cual", por lo cual, en la medida en que la ley lo permita, no garantizamos que nuestros servicios funcionen de forma segura y sin errores, interrupciones, demoras o imperfecciones. De la misma manera, en la medida en que la ley lo permita, NOS EXIMIMOS DE TODA RESPONSABILIDAD, YA SEA EXPLÍCITA O IMPLÍCITA, INCLUIDAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIALIZACIÓN, ADECUACIÓN A UN USO CONCRETO, TÍTULO Y NO INFRACCIÓN. No tenemos control ni influencia sobre lo que las personas hacen o dicen. Asimismo, no somos responsables de sus comportamientos o acciones, ya sea dentro o fuera de internet, ni del contenido que comparten, incluido aquel que pueda resultar ofensivo, inapropiado, obsceno, ilegal o cuestionable. 
  
  Piper limita su responsabilidad en la máxima medida en que la ley aplicable lo permita, y bajo ninguna circunstancia asumiremos responsabilidad alguna por la pérdida de ganancias, ingresos, información o datos; y por los daños consecuenciales, especiales, indirectos, ejemplares, punitivos o eventuales que surjan como consecuencia de estos Términos de Servicio (por cualquier causa y cualquier tipo de responsabilidad, incluida la negligencia), incluso en el caso de que se nos haya advertido de la posibilidad de que se produzcan dichos daños.
  
  `,
  changes_title: "Cambios en estos Términos de Servicio",
  changes: `Es posible que actualicemos nuestros Términos de Servicio de vez en cuando. Por lo tanto, te recomendamos que revises esta página periódicamente por cualquier cambio. Te notificaremos cualquier cambio publicando los nuevos Términos de Servicio en esta página. Estos cambios entran en vigencia inmediatamente después de que se publiquen en esta página, cualquier usuario que continúe utilizando la aplicación luego de la publicación de los cambios quedará sujeto a los nuevos términos.
  
  `,
  contact_title: "Contactanos",
  contact: `Si tenés alguna pregunta o sugerencia sobre nuestros Términos de Servicio, no dudes en ponerte en contacto con nosotros.
  
  Si detectás alguna situación en la cual no se estén cumpliendo estos términos podés denunciarlo por el mismo canal de contacto.
  
  
  `,
  last_update: "Última actualización: 4 de enero, 2024"
};
