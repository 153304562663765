import React, { FunctionComponent, useEffect, useState } from "react";
import { IShelfSegment, IShelfSegmentAlbum } from "../../../hooks/shelfSegments";
import genericSpine from "../../../images/generic_spine.png";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import { ImageCache } from "../../../models/ImageCache";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { useGetImage } from "../../../hooks/images";
import { stringToColor } from "../../../models/StringFormat";
import { useTheme } from "@mui/material";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { IShelving } from "../../../hooks/shelvings";

export const ShelfSegmentAlbumRepresentation: FunctionComponent<
  IShelfSegmentAlbumRepresentationProps
> = ({ shelving, shelfSegment, shelfSegmentAlbum, isHighlighted }) => {
  const theme = useTheme();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const fetchImage = async () => {
    if (!shelfSegmentAlbum.spineImageId) return;
    const response = await getImage({
      name: shelfSegmentAlbum.spineImageId,
      width: ImageCache.bigAlbumImageSize,
      height: ImageCache.bigAlbumImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
  };
  useEffect(() => {
    fetchImage().then();
  }, [shelfSegmentAlbum.spineImageId]);
  return (
    <LazyImageAspectFit
      style={{
        height:
          shelving.orientation == "horizontal"
            ? shelfSegment.everyAlbumHasSameSize
              ? "96%"
              : AlbumUtilities.getShapeRelativeSize(shelfSegmentAlbum.shape)
            : undefined,
        width:
          shelving.orientation == "horizontal"
            ? undefined
            : shelfSegment.everyAlbumHasSameSize
              ? "96%"
              : AlbumUtilities.getShapeRelativeSize(shelfSegmentAlbum.shape),
        borderStyle: "solid",
        borderRadius: "4px",
        borderWidth: isHighlighted ? "3px" : "1px",
        borderColor: isHighlighted ? theme.palette.colors?.highlightSpine : "transparent",
        pointerEvents: "none"
      }}
      image={image}
      thumbnail={shelfSegmentAlbum.spineImageThumbnail}
      defaultImage={genericSpine}
      beforeLoad={fetchImage}
      alt={shelfSegmentAlbum.album.name}
      forceVertical={shelving.orientation == "horizontal"}
      forceHorizontal={shelving.orientation == "vertical"}
      tintColor={
        shelfSegmentAlbum.spineImageId ? undefined : stringToColor(shelfSegmentAlbum.album.name)
      }
      waitUntilImageIsFinal
    />
  );
};

export interface IShelfSegmentAlbumRepresentationProps {
  shelving: IShelving;
  shelfSegment: IShelfSegment;
  shelfSegmentAlbum: IShelfSegmentAlbum;
  isHighlighted: boolean;
}
