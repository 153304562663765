import React, { FunctionComponent } from "react";
import { Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetShareLink } from "../../../hooks/share";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { IPublicAlbum } from "../../../hooks/albums";
import { PublicAlbumCard } from "../PublicAlbumCard";
import styles from "./styles.module.scss";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { usePublicUserProfile } from "../../../hooks/session";
import { useIsMobile } from "../../../hooks/isMobile";

export const PublicAlbumsGrid: FunctionComponent<IPublicAlbumsGridProps> = ({
  publicAlbums,
  setIsLoading
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [, setCurrentPublicCollection] = useCurrentPublicCollection.useState();
  const [, setCurrentPublicUserProfile] = usePublicUserProfile.useState();
  const { getShareLink } = useGetShareLink();
  const showAlbum = async (publicAlbum: IPublicAlbum) => {
    setIsLoading(true);
    const { status, body } = await getShareLink({
      albumId: publicAlbum.album.uuid,
      collectionId: publicAlbum.collection.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrentPublicUserProfile(undefined);
      setCurrentPublicCollection(undefined);
      navigate(body.relativeShareLink);
    }
    setIsLoading(false);
  };
  return (
    <Grid container spacing={2}>
      {publicAlbums && publicAlbums.length > 0
        ? publicAlbums?.map((publicAlbum, index) => (
            <Grid key={index} item xs={isMobile ? 12 : 12 / 5}>
              <PublicAlbumCard
                album={publicAlbum.album}
                collection={publicAlbum.collection}
                userProfile={publicAlbum.userProfile}
                onClick={async () => {
                  await showAlbum(publicAlbum);
                }}
              />
            </Grid>
          ))
        : Array.from({ length: 5 }, (value, index) => (
            <Grid key={index} item xs={isMobile ? 12 : 12 / 5} className={styles.item}>
              <Skeleton variant={"rectangular"} className={styles.skeleton} />
            </Grid>
          ))}
    </Grid>
  );
};

export interface IPublicAlbumsGridProps {
  publicAlbums?: IPublicAlbum[];
  setIsLoading: (loading: boolean) => void;
}
