import React, { FunctionComponent } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Card, Stack } from "@mui/material";
import styles from "./styles.module.scss";

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card>
        <p
          style={{ margin: 10, fontWeight: "bold" }}
        >{`${payload[0].name}: ${payload[0].value}%`}</p>
      </Card>
    );
  }
  return null;
};

export const PieChartGraph: FunctionComponent<IPieChartGraphProps> = ({
  title,
  data,
  dataKey,
  colorForIndex
}) => (
  <div style={{ width: "100%", height: "100%", display: "flex" }}>
    <Stack sx={{ width: "100%", height: "100%", alignItems: "center" }}>
      <p className={styles.title}>{title}</p>
      <ResponsiveContainer className={styles.container} width={"100%"}>
        <PieChart data={data} className={styles.chart}>
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Pie
            data={data}
            dataKey={dataKey}
            isAnimationActive={true}
            innerRadius={"50%"}
            outerRadius={"80%"}
            paddingAngle={5}
          >
            {data.map((value, index) => (
              <Cell fill={colorForIndex(index)} key={`cell-${index}`} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Stack>
  </div>
);

export interface IPieChartGraphProps {
  title: string;
  data: any[];
  dataKey: string;
  colorForIndex: (index: number) => string;
}
