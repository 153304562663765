import React, { FunctionComponent, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grow,
  Toolbar,
  useTheme
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { strings } from "../../../localization/LocalizedStrings";
import { LengthTextField } from "../../LengthTextField";
import { ITrack } from "../../../hooks/albums";
import { CalculateOutlined, CleaningServicesRounded, CloseRounded } from "@mui/icons-material";
import { ContainedButton } from "../../ContainedButton";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { ToolbarBox } from "../../toolbar/ToolbarBox";
import { ToolbarRawButton } from "../../toolbar/ToolbarRawButton";

export const ModifyLengthAttributeDialog: FunctionComponent<IModifyLengthAttributeDialogProps> = ({
  title,
  description,
  missingText,
  open,
  setOpen,
  value,
  onChange,
  onClearValue,
  trackList
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const onCancel = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    length: Yup.number().required(missingText)
  });
  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);
  const formik = useFormik({
    initialValues: {
      length: value ?? 0
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ length }) => {
      if (formik.dirty && length) {
        onChange(length);
        setOpen(false);
      }
    },
    validationSchema: validationSchema
  });
  return (
    <FormikProvider value={formik}>
      <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onCancel} TransitionComponent={Grow}>
        <Toolbar>
          <h3>{title}</h3>
          <Box flexGrow={1} />
          <ToolbarBox>
            <ToolbarRawButton
              onClick={() => {
                if (!trackList) return;
                let length = 0;
                trackList.forEach(track => {
                  length += track.length ?? 0;
                });
                onChange(length);
                setOpen(false);
              }}
              disabled={!trackList}
              tooltip={strings.mtl_calculate}
              icon={CalculateOutlined}
            />
            <Divider flexItem orientation={"vertical"} />
            <ToolbarRawButton
              onClick={() => {
                onClearValue();
                setOpen(false);
              }}
              tooltip={strings.clear_value}
              icon={CleaningServicesRounded}
              color={theme.palette.colors?.remove}
            />
          </ToolbarBox>
          <ToolbarButton onClick={onCancel} tooltip={strings.close} icon={CloseRounded} />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <p className={styles.description}>{description}</p>
          <div className={styles.textField}>
            <LengthTextField
              id="length"
              name="length"
              size="small"
              defaultValue={formik.values.length}
              autoFocus={!isMobile}
              onChange={newValue => formik.setFieldValue("length", newValue)}
              enterAction={formik.submitForm}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
};

export interface IModifyLengthAttributeDialogProps {
  title: string;
  description: string;
  missingText: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  value?: number;
  onChange: (newValue: number) => void;
  onClearValue: () => void;
  trackList?: ITrack[];
}
