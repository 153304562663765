export const privacy_policy_en = {
  title: "Privacy Policy",
  title_short: "P. Policy",
  block1: `One of our main priorities at Piper is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by Piper and how we use it.
  
  If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
  
  This Privacy Policy applies only to our online activities and is valid for users of our app in regard to the information that they shared and/or collect in Piper. This policy is not applicable to any information collected offline or via channels other than this app.
  
  `,
  consent_title: "Consent",
  consent: `By using our app, you hereby consent to our Privacy Policy and agree to its terms.
  
  `,
  info_collect_title: "Information we collect",
  info_collect: `The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
  If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
  When you register for an Account we will only ask for your email address and a password.
  
  `,
  sensitive_info_title: "Sensitive information",
  sensitive_info_description: `We consider sensitive information your password, access tokens, and private messages you send to other users. All of those fields are saved encrypted in our database, so no one can read that data without the encryption key.
  
  `,
  info_use_title: "How we use your information",
  info_use: `We use the information we collect in various ways, including to:
  `,
  info_use_1: "Provide, operate, and maintain our app.",
  info_use_2: "Improve, personalize, and expand our app.",
  info_use_3: "Understand and analyze how you use our app.",
  info_use_4: "Develop new products, services, features, and functionalities.",
  info_use_5:
    "Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app, and for marketing and promotional purposes.",
  info_use_6: "Send you emails.",
  info_use_7: `Find and prevent fraud.
  
  `,
  log_files_title: "Log Files",
  log_files: `Piper follows a standard procedure of using log files. These files log visitors when they visit and use our app. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
  
  `,
  cookies_title: "Cookies",
  cookies: `Like any other website, Piper uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
  
  `,
  third_parties_title: "Third Party Privacy Policies",
  third_parties: `Piper's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

  You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
  
  `,
  ccpa_rights_title: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
  ccpa_rights: `Under the CCPA, among other rights, California consumers have the right to:
  `,
  ccpa_rights_1:
    "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.",
  ccpa_rights_2:
    "Request that a business delete any personal data about the consumer that a business has collected.",
  ccpa_rights_3:
    "Request that a business that sells a consumer's personal data, not sell the consumer's personal data.",
  ccpa_rights_end: `
  If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
  
  `,
  gdpr_rights_title: "GDPR Data Protection Rights",
  gdpr_rights: `We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
  `,
  gdpr_rights_1:
    "The right to access – You have the right to request copies of your personal data. We may charge you a fee for this service.",
  gdpr_rights_2:
    "The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.",
  gdpr_rights_3:
    "The right to erasure – You have the right to request that we erase your personal data, under certain conditions.",
  gdpr_rights_4:
    "The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.",
  gdpr_rights_5:
    "The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.",
  gdpr_rights_6:
    "The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
  gdpr_rights_end: `
  If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
  
  `,
  children_title: "Children's Information",
  children: `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
  
  Piper does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
  
  `,
  changes_title: "Changes to this Privacy Policy",
  changes: `We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
  
  `,
  contact_title: "Contact us",
  contact: `If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
  
  
  `,
  last_update: "Last update: January 4th, 2024"
};
