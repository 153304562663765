import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useTheme
} from "@mui/material";
import { AlbumImageKind, IAlbumImage } from "../../../../hooks/albumImages";
import { useGetImage, useUploadImage } from "../../../../hooks/images";
import { ImageCache } from "../../../../models/ImageCache";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { ImageDropzone } from "../../../ImageDropzone";
import { useAddAlbumImage } from "../../../../hooks/albumImages/useAddAlbumImage";
import { IAlbum } from "../../../../hooks/albums";
import { AlertDialog } from "../../../AlertDialog";
import { ModifyGenericAttributeDialog } from "../../../attributes/ModifyGenericAttributeDialog";
import { strings } from "../../../../localization/LocalizedStrings";
import { useModifyAlbumImage } from "../../../../hooks/albumImages/useModifyAlbumFormat";
import { CustomLoadingIndicator } from "../../../CustomLoadingIndicator";
import { useContextMenu } from "../../../../hooks/contextMenu";
import {
  DeleteForever,
  DriveFileRenameOutlineRounded,
  ImageSearchRounded,
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded
} from "@mui/icons-material";
import { useDeleteAlbumImage } from "../../../../hooks/albumImages/useDeleteAlbumImage";
import { toast } from "react-toastify";
import useLongPress from "../../../../hooks/longPress";
import { ResponsiveIcon } from "../../../ResponsiveIcon";

export const AlbumImageCard: FunctionComponent<IAlbumImageCardProps> = ({
  isPublic,
  album,
  albumImage,
  genericImage,
  kind,
  description,
  index,
  onAlbumImageUploaded,
  onAlbumImageDeleted,
  onOpenImageDetail,
  canMoveLeft,
  canMoveRight,
  onMoveLeft,
  onMoveRight
}) => {
  const theme = useTheme();
  const { getImage } = useGetImage();
  const { uploadImage } = useUploadImage();
  const { addAlbumImage } = useAddAlbumImage();
  const { modifyAlbumImage } = useModifyAlbumImage();
  const { deleteAlbumImage } = useDeleteAlbumImage();
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const [image, setImage] = useState<string>();
  const [isModifyDescriptionDialogOpen, setIsModifyDescriptionDialogOpen] = useState(false);
  const [shouldOpenFileDialog, setShouldOpenFileDialog] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);
  const [isDeleteConfirmationAlertOpen, setIsDeleteConfirmationAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const fetchImage = async () => {
    if (!albumImage || !albumImage.hasImage) {
      setImage(undefined);
      return;
    }
    setIsLoadingImage(true);
    const response = await getImage({
      name: albumImage.uuid,
      width: ImageCache.bigAlbumImageSize,
      height: ImageCache.bigAlbumImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
    setIsLoadingImage(false);
  };
  const createAlbumImage = async (file: File) => {
    const { status, body } = await addAlbumImage({
      albumId: album.uuid,
      kind: kind,
      description: "",
      order: index
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      await uploadAlbumImage(body, file);
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
  };
  const uploadAlbumImage = async (albumImage: IAlbumImage, file: File) => {
    const newThumbnail = await uploadImage(
      { name: albumImage.uuid, file },
      ImageCache.bigAlbumImageSize,
      ImageCache.bigAlbumImageSize,
      ImageCache.smallAlbumImageSize,
      ImageCache.smallAlbumImageSize
    );
    if (newThumbnail) {
      setImage(undefined);
      albumImage.hasImage = true;
      albumImage.thumbnail = newThumbnail;
      if (onAlbumImageUploaded) {
        onAlbumImageUploaded(albumImage);
      }
      await fetchImage();
    }
  };
  const removeAlbumImage = async () => {
    if (!albumImage) return;
    setIsLoadingDescription(true);
    const { status, body } = await deleteAlbumImage({ uuid: albumImage.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      if (onAlbumImageDeleted) {
        onAlbumImageDeleted();
      }
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingDescription(false);
  };
  const updateDescription = async (newValue: string) => {
    if (!albumImage) return;
    setIsLoadingDescription(true);
    const { status, body } = await modifyAlbumImage({
      uuid: albumImage.uuid,
      description: newValue
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      if (onAlbumImageUploaded) {
        onAlbumImageUploaded(body);
      }
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsLoadingDescription(false);
  };
  useEffect(() => {
    fetchImage().then();
  }, [albumImage]);
  const openImageDetail = () => {
    if (isLoadingImage) return;
    if (!image) {
      if (isPublic) return;
      setShouldOpenFileDialog(state => !state);
      return;
    }
    onOpenImageDetail(image, description, `${album.name} ${description}`);
  };
  const handleLongPress = useLongPress({ onLongPress: handleContextMenu });
  return (
    <Card
      className={styles.card}
      sx={{
        display: "flex"
      }}
      onContextMenu={handleContextMenu}
      {...handleLongPress}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <div className={styles.image}>
          <ImageDropzone
            className={styles.albumImage}
            disabled={isPublic || isLoadingDescription || isLoadingImage}
            onImageChanged={async file => {
              setIsLoadingImage(true);
              if (!albumImage) {
                await createAlbumImage(file);
              } else {
                await uploadAlbumImage(albumImage, file);
              }
              setIsLoadingImage(false);
            }}
            placeholderImage={genericImage}
            isLoading={isLoadingImage}
            initialImage={
              image
                ? `data:image/png;base64,${image}`
                : albumImage?.thumbnail
                  ? `data:image/png;base64,${albumImage.thumbnail}`
                  : undefined
            }
            shouldOpenFileDialog={shouldOpenFileDialog}
            onClick={openImageDetail}
          />
        </div>
        <CardActionArea
          className={styles.nameCard}
          disabled={isPublic || kind != "additional" || isLoadingDescription || isLoadingImage}
          onClick={() => setIsModifyDescriptionDialogOpen(true)}
        >
          {isLoadingDescription ? (
            <CustomLoadingIndicator className={styles.loadingDescription} />
          ) : (
            <p className={styles.name}>{description}</p>
          )}
        </CardActionArea>
      </Box>
      <ModifyGenericAttributeDialog
        title={strings.image_name}
        description={strings.image_name_message}
        placeholder={strings.image_name_placeholder}
        missingText={strings.field_missing}
        value={description}
        canClear
        open={isModifyDescriptionDialogOpen}
        setOpen={setIsModifyDescriptionDialogOpen}
        isNumber={false}
        onChange={updateDescription}
        onClearValue={() => updateDescription("")}
      />
      <Menu {...menuProps}>
        {image && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              openImageDetail();
            }}
            dense
          >
            <ListItemIcon>
              <ImageSearchRounded />
            </ListItemIcon>
            {strings.option_view_image}
          </MenuItem>
        )}
        {!isPublic && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setShouldOpenFileDialog(state => !state);
            }}
            dense
          >
            <ListItemIcon>
              <DriveFileRenameOutlineRounded />
            </ListItemIcon>
            {strings.modify_image}
          </MenuItem>
        )}
        {!isPublic && canMoveLeft && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              if (onMoveLeft) {
                onMoveLeft();
              }
            }}
            dense
          >
            <ListItemIcon>
              <KeyboardDoubleArrowLeftRounded />
            </ListItemIcon>
            {strings.move_left}
          </MenuItem>
        )}
        {!isPublic && canMoveRight && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              if (onMoveRight) {
                onMoveRight();
              }
            }}
            dense
          >
            <ListItemIcon>
              <KeyboardDoubleArrowRightRounded />
            </ListItemIcon>
            {strings.move_right}
          </MenuItem>
        )}
        {!isPublic && kind == "additional" && (
          <div>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                setIsModifyDescriptionDialogOpen(true);
              }}
              dense
            >
              <ListItemIcon>
                <DriveFileRenameOutlineRounded />
              </ListItemIcon>
              {strings.edit_image_name}
            </MenuItem>
            <MenuItem
              onClick={async () => {
                handleMenuClose();
                setIsDeleteConfirmationAlertOpen(true);
              }}
              sx={{ color: theme.palette.colors?.remove }}
              dense
            >
              <ListItemIcon sx={{ color: theme.palette.colors?.remove }}>
                <DeleteForever />
              </ListItemIcon>
              {strings.delete}
            </MenuItem>
          </div>
        )}
      </Menu>
      <Stack className={styles.hoverButtonsStack} spacing={1}>
        {!isPublic && (
          <Tooltip title={strings.modify_image} arrow disableInteractive>
            <IconButton
              className={styles.hoverButton}
              style={{ backgroundColor: theme.palette.colors?.counterBackground }}
              onClick={() => setShouldOpenFileDialog(state => !state)}
            >
              <ResponsiveIcon icon={DriveFileRenameOutlineRounded} />
            </IconButton>
          </Tooltip>
        )}
        {!isPublic && canMoveLeft && (
          <Tooltip title={strings.move_left} arrow disableInteractive>
            <IconButton
              className={styles.hoverButton}
              style={{ backgroundColor: theme.palette.colors?.counterBackground }}
              onClick={() => {
                if (onMoveLeft) {
                  onMoveLeft();
                }
              }}
            >
              <ResponsiveIcon icon={KeyboardDoubleArrowLeftRounded} />
            </IconButton>
          </Tooltip>
        )}
        {!isPublic && canMoveRight && (
          <Tooltip title={strings.move_right} arrow disableInteractive>
            <IconButton
              className={styles.hoverButton}
              style={{ backgroundColor: theme.palette.colors?.counterBackground }}
              onClick={() => {
                if (onMoveRight) {
                  onMoveRight();
                }
              }}
            >
              <ResponsiveIcon icon={KeyboardDoubleArrowRightRounded} />
            </IconButton>
          </Tooltip>
        )}
        {!isPublic && kind == "additional" && (
          <Tooltip title={strings.delete} arrow disableInteractive>
            <IconButton
              className={styles.hoverButton}
              style={{ backgroundColor: theme.palette.colors?.counterBackground }}
              onClick={() => setIsDeleteConfirmationAlertOpen(true)}
            >
              <ResponsiveIcon
                icon={DeleteForever}
                style={{ color: theme.palette.colors?.remove }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <AlertDialog
        message={strings.image_delete_confirmation}
        open={isDeleteConfirmationAlertOpen}
        setOpen={setIsDeleteConfirmationAlertOpen}
        isConfirm
        onConfirm={removeAlbumImage}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Card>
  );
};

export interface IAlbumImageCardProps {
  isPublic: boolean;
  album: IAlbum;
  albumImage?: IAlbumImage;
  genericImage: string;
  kind: AlbumImageKind | "front";
  description: string;
  index: number;
  onAlbumImageUploaded?: (albumImage: IAlbumImage) => void;
  onAlbumImageDeleted?: () => void;
  onOpenImageDetail: (image: string, title: string, filename: string) => void;
  canMoveLeft: boolean;
  canMoveRight: boolean;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
}
