import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Box, Card, Dialog, DialogContent, Grid, Grow, Skeleton, Toolbar } from "@mui/material";
import styles from "./styles.module.scss";
import { AlbumImageCard } from "../AlbumImageCard";
import { useGetAlbumImages } from "../../../../hooks/albumImages/useGetAlbumImages";
import { IAlbum } from "../../../../hooks/albums";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { useIsMobile } from "../../../../hooks/isMobile";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { strings } from "../../../../localization/LocalizedStrings";
import { AddCircleOutlineRounded, CloseRounded, HelpOutlineRounded } from "@mui/icons-material";
import { IAlbumImage, IAlbumImagesResponse } from "../../../../hooks/albumImages";
import genericSleeveImage from "../../../../images/generic_sleeve.jpg";
import genericSpineImage from "../../../../images/generic_spine.png";
import { useAddAlbumImage } from "../../../../hooks/albumImages/useAddAlbumImage";
import { AlertDialog } from "../../../AlertDialog";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";
import { useReorderAlbumImages } from "../../../../hooks/albumImages/useReorderAlbumImages";
import { useGetPublicAlbumImages } from "../../../../hooks/albumImages/useGetPublicAlbumImages";
import { useCurrentPublicCollection } from "../../../../hooks/collections/useCollectionManager";
import { RoutesBuilder } from "../../../../models/RoutesBuilder";

export const ImageGalleryDialog: FunctionComponent<IImageGalleryDialogProps> = ({
  album,
  isPublic,
  isOpen,
  setIsOpen,
  onAlbumImageUpdated,
  onOpenImageDetail
}) => {
  const isMobile = useIsMobile();
  const { getAlbumImages } = useGetAlbumImages();
  const { getPublicAlbumImages } = useGetPublicAlbumImages();
  const { addAlbumImage } = useAddAlbumImage();
  const { reorderAlbumImages } = useReorderAlbumImages();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const [frontAlbumImage, setFrontAlbumImage] = useState<IAlbumImage>();
  const [albumImages, setAlbumImages] = useState<IAlbumImagesResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const bottomReference = useRef<HTMLHeadingElement>(null);
  const [oldAlbumImagesLength, setOldAlbumImagesLength] = useState(0);
  const scrollToBottom = () => {
    bottomReference.current?.scrollIntoView({ behavior: "smooth" });
  };
  const fetchAlbumImages = async () => {
    setIsLoading(true);
    setAlbumImages(undefined);
    const { status, body } = await getAlbumImages({ albumId: album.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      setOldAlbumImagesLength(body.additional.length);
      setAlbumImages(body);
    } else {
      toast.error(body.message);
    }
    setIsLoading(false);
  };
  const fetchPublicAlbumImages = async () => {
    if (!currentPublicCollection) return;
    setIsLoading(true);
    setAlbumImages(undefined);
    const { status, body } = await getPublicAlbumImages({
      albumId: album.uuid,
      collectionId: currentPublicCollection.uuid
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setOldAlbumImagesLength(body.additional.length);
      setAlbumImages(body);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!isOpen) return;
    if (isPublic) {
      fetchPublicAlbumImages().then();
    } else {
      fetchAlbumImages().then();
    }
  }, [isOpen]);
  useEffect(() => {
    setFrontAlbumImage({
      uuid: album.uuid,
      kind: "additional",
      description: "",
      thumbnail: album.thumbnail,
      hasImage: album.hasImage
    });
  }, [album.uuid, album.hasImage, album.thumbnail]);
  useEffect(() => {
    if (!albumImages) return;
    if (oldAlbumImagesLength < albumImages.additional.length) {
      scrollToBottom();
    }
    setOldAlbumImagesLength(albumImages.additional.length);
  }, [albumImages?.additional.length]);
  const addNewAlbumImage = async () => {
    if (!albumImages) return;
    setIsAddLoading(true);
    const { status, body } = await addAlbumImage({
      albumId: album.uuid,
      kind: "additional",
      description: "",
      order: albumImages.additional.length
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setAlbumImages(albumImages => {
        if (!albumImages) return;
        return {
          ...albumImages,
          additional: [...albumImages.additional, body]
        };
      });
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsAddLoading(false);
  };
  const reorderImages = async (newlyOrderedAlbumImages: IAlbumImage[]) => {
    setIsAddLoading(true);
    const { status, body } = await reorderAlbumImages(newlyOrderedAlbumImages);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      setAlbumImages(albumImages => {
        if (!albumImages) return;
        return {
          ...albumImages,
          additional: newlyOrderedAlbumImages
        };
      });
    } else {
      setAlertMessage(body.message);
      setIsAlertOpen(true);
    }
    setIsAddLoading(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Grow}
    >
      <Toolbar>
        <h3 style={{ marginRight: 8 }}>{strings.image_gallery}</h3>
        {!isPublic && albumImages && (
          <ToolbarButton
            tooltip={strings.add_new_image}
            icon={AddCircleOutlineRounded}
            disabled={isAddLoading || albumImages.additional.length >= 50}
            loading={isAddLoading}
            onClick={addNewAlbumImage}
          />
        )}
        <Box flexGrow={1} />
        <ToolbarButton
          href={`${window.location.protocol}//${
            window.location.host
          }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.imageGallery()}`}
          target={"_blank"}
          tooltip={strings.help}
          icon={HelpOutlineRounded}
        />
        <ToolbarButton
          onClick={() => setIsOpen(false)}
          tooltip={strings.close}
          icon={CloseRounded}
          loading={isLoading}
        />
      </Toolbar>
      <DialogContent>
        <Card elevation={15} sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {isLoading ? (
              Array.from({ length: 3 }, (value, index) => (
                <Grid key={index} item xs={isMobile ? 12 : 12 / 4} className={styles.item}>
                  <Skeleton variant={"rectangular"} className={styles.skeleton} />
                </Grid>
              ))
            ) : (
              <>
                <Grid key={"front"} item xs={isMobile ? 12 : 12 / 4}>
                  <AlbumImageCard
                    isPublic={isPublic}
                    album={album}
                    albumImage={frontAlbumImage}
                    genericImage={genericSleeveImage}
                    kind={"front"}
                    description={strings.front_cover}
                    index={-1}
                    onAlbumImageUploaded={albumImage => {
                      setFrontAlbumImage(albumImage);
                      onAlbumImageUpdated(albumImage);
                    }}
                    onOpenImageDetail={onOpenImageDetail}
                    canMoveLeft={false}
                    canMoveRight={false}
                  />
                </Grid>
                {(!isPublic || albumImages?.back[0]) && (
                  <Grid key={"back"} item xs={isMobile ? 12 : 12 / 4}>
                    <AlbumImageCard
                      isPublic={isPublic}
                      album={album}
                      albumImage={albumImages?.back[0]}
                      genericImage={genericSleeveImage}
                      kind={"back"}
                      description={strings.back_cover}
                      index={-1}
                      onAlbumImageUploaded={albumImage =>
                        setAlbumImages(albumImages => {
                          if (!albumImages) return;
                          return {
                            ...albumImages,
                            back: [albumImage]
                          };
                        })
                      }
                      onOpenImageDetail={onOpenImageDetail}
                      canMoveLeft={false}
                      canMoveRight={false}
                    />
                  </Grid>
                )}
                {(!isPublic || albumImages?.spine[0]) && (
                  <Grid key={"spine"} item xs={isMobile ? 12 : 12 / 4}>
                    <AlbumImageCard
                      isPublic={isPublic}
                      album={album}
                      albumImage={albumImages?.spine[0]}
                      genericImage={genericSpineImage}
                      kind={"spine"}
                      description={strings.spine}
                      index={-1}
                      onAlbumImageUploaded={albumImage =>
                        setAlbumImages(albumImages => {
                          if (!albumImages) return;
                          return {
                            ...albumImages,
                            spine: [albumImage]
                          };
                        })
                      }
                      onOpenImageDetail={onOpenImageDetail}
                      canMoveLeft={false}
                      canMoveRight={false}
                    />
                  </Grid>
                )}
                {albumImages &&
                  albumImages.additional.length > 0 &&
                  albumImages.additional.map((albumImage, index) => (
                    <Grid key={index} item xs={isMobile ? 12 : 12 / 4}>
                      <AlbumImageCard
                        isPublic={isPublic}
                        album={album}
                        albumImage={albumImage}
                        genericImage={genericSleeveImage}
                        kind={albumImage.kind}
                        description={albumImage.description}
                        index={index}
                        onAlbumImageUploaded={albumImage =>
                          setAlbumImages(albumImages => {
                            if (!albumImages) return;
                            const additionalImages = albumImages.additional.map(image => {
                              return image.uuid == albumImage.uuid ? albumImage : image;
                            });
                            return {
                              ...albumImages,
                              additional: additionalImages
                            };
                          })
                        }
                        onAlbumImageDeleted={() =>
                          setAlbumImages(albumImages => {
                            if (!albumImages) return;
                            return {
                              ...albumImages,
                              additional: albumImages.additional.filter(
                                image => image.uuid != albumImage.uuid
                              )
                            };
                          })
                        }
                        onOpenImageDetail={onOpenImageDetail}
                        canMoveLeft={index > 0}
                        canMoveRight={index < albumImages.additional.length - 1}
                        onMoveLeft={async () => {
                          const newlyOrderedAlbumImages = arrayMoveImmutable(
                            albumImages.additional,
                            index,
                            index - 1
                          );
                          await reorderImages(newlyOrderedAlbumImages);
                        }}
                        onMoveRight={async () => {
                          const newlyOrderedAlbumImages = arrayMoveImmutable(
                            albumImages.additional,
                            index,
                            index + 1
                          );
                          await reorderImages(newlyOrderedAlbumImages);
                        }}
                      />
                    </Grid>
                  ))}
              </>
            )}
            <div ref={bottomReference} />
          </Grid>
        </Card>
      </DialogContent>
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </Dialog>
  );
};

export interface IImageGalleryDialogProps {
  album: IAlbum;
  isPublic: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAlbumImageUpdated: (albumImage: IAlbumImage) => void;
  onOpenImageDetail: (image: string, title: string, filename: string) => void;
}
