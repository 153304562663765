import { useBackend } from "../../services";

export const useDeleteStore = () => {
  const { deleteRequest } = useBackend();
  const deleteStore = (parameters: IDeleteStoreParameters) => deleteRequest("stores", parameters);
  return { deleteStore };
};

export interface IDeleteStoreParameters {
  uuid: string;
}
