import optionsImage from "../../../../images/documentation/options-es.png";
import drawerImage from "../../../../images/documentation/sign-in-drawer-es.png";

export const doc_users_guest_es = {
  title: "Ingresar como invitado",
  options_image: optionsImage,
  drawer_image: drawerImage,
  description: `Podés ingresar a Piper como invitado usando la opción dentro del menú desplegable. No vas a necesitar ninguna contraseña, vas a ingresar directamente a una colección pre-creada llena de álbumes, artistas y más.
  
  Mientras seas un invitado no vas a poder hacer modificaciones, pero sentite libre de explorar y revisar todas las cosas que podrías hacer con tu propia colección. La única sección bloqueada va a ser la de Estadísticas.
  
  Cuando quieras ingresar con tu propia cuenta, simplemente cerrá la sesión de invitado y se te va a mostrar la página de ingreso nuevamente.
    
`
};
