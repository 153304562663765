import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Card,
  CardActionArea,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  useTheme
} from "@mui/material";
import { IStore, useGetStores } from "../../../../hooks/stores";
import { strings } from "../../../../localization/LocalizedStrings";
import { ModifyStoreDialog } from "../ModifyStoreDialog";
import { AttributeDesktop } from "../../AttributeDesktop";
import { toast } from "react-toastify";
import { useContextMenu } from "../../../../hooks/contextMenu";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";
import { AttributeUtilities } from "../../../../models/AttributeUtilities";
import { AttributeType } from "../../AttributeList";

export const StoresDesktop: FunctionComponent<IStoresDesktopProps> = ({
  handleClose,
  isPublic,
  columnWidth,
  preselectedId,
  onStoreSelected,
  onClearValue
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [stores, resetStores, setStores, noMoreStores] = useGetStores(isPublic, setLoading);
  const [filteredStores, setFilteredStores] = useState<IStore[]>();
  const [isAddStoreDialogOpen, setIsAddStoreDialogOpen] = useState(false);
  const [isModifyStoreDialogOpen, setIsModifyStoreDialogOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<IStore>();
  const [searchText, setSearchText] = useState("");
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  useEffect(() => {
    refreshFilteredStores();
  }, [stores]);
  const refreshFilteredStores = () => {
    if (searchText == "") {
      setFilteredStores(stores);
      return;
    }
    setFilteredStores(
      stores?.filter(store => {
        return store.name.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  };
  useEffect(() => {
    refreshFilteredStores();
  }, [searchText]);
  return (
    <AttributeDesktop
      handleClose={handleClose}
      isPublic={isPublic}
      items={filteredStores}
      emptyIcon={AttributeUtilities.getAttributeIcon(AttributeType.STORES)}
      emptyString={isPublic ? strings.empty_stores_public : strings.empty_stores}
      addItemString={strings.add_attribute}
      title={strings.attribute_stores_title}
      loading={loading}
      onSearchRequested={searchText => setSearchText(searchText)}
      addAction={() => setIsAddStoreDialogOpen(true)}
      refreshAction={resetStores}
      onClearValue={onClearValue}
    >
      {filteredStores?.map(store => (
        <Grid item key={store.uuid} xs={columnWidth}>
          <Card sx={{ height: 64 }} elevation={5}>
            <CardActionArea
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
              style={{
                borderColor: theme.palette.colors?.highlightBorder,
                borderWidth: 4,
                borderStyle: preselectedId == store.uuid ? "double" : "none"
              }}
              onClick={() => {
                if (onStoreSelected) {
                  onStoreSelected(store);
                } else {
                  setSelectedStore(store);
                  setIsModifyStoreDialogOpen(true);
                }
              }}
              onContextMenu={event => {
                setSelectedStore(store);
                handleContextMenu(event);
              }}
            >
              <h6 className={styles.name}>{store.name}</h6>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {(loading || !noMoreStores) &&
        Array.from({ length: 40 }, (value, index) => (
          <Grid key={index} item xs={columnWidth}>
            <Skeleton variant={"rectangular"} sx={{ height: 64 }} className={styles.skeleton} />
          </Grid>
        ))}
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setIsModifyStoreDialogOpen(true);
          }}
          dense
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRounded fontSize={"small"} />
          </ListItemIcon>
          {strings.alert_modify}
        </MenuItem>
      </Menu>
      <ModifyStoreDialog
        open={isAddStoreDialogOpen}
        setOpen={setIsAddStoreDialogOpen}
        onNewItem={newStore => {
          const newStores = stores;
          newStores.push(newStore);
          newStores.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setStores(newStores);
          refreshFilteredStores();
          toast.success(strings.generic_update_success);
        }}
      />
      <ModifyStoreDialog
        open={isModifyStoreDialogOpen}
        setOpen={setIsModifyStoreDialogOpen}
        store={selectedStore}
        onModifiedItem={newStore => {
          const newStores = stores.map(store => (store.uuid == newStore.uuid ? newStore : store));
          newStores.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
          );
          setStores(newStores);
          toast.success(strings.generic_update_success);
        }}
        onDeletedItem={deletedStore => {
          const newStores = stores.filter(store => store.uuid != deletedStore.uuid);
          setStores(newStores);
          toast.success(strings.generic_update_success);
        }}
      />
    </AttributeDesktop>
  );
};

export interface IStoresDesktopProps {
  handleClose?: () => void;
  isPublic: boolean;
  columnWidth: number;
  preselectedId?: string;
  onStoreSelected?: (store: IStore) => void;
  onClearValue?: () => void;
}
