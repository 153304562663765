import { useBackend } from "../../services";

export const useModifyLabel = () => {
  const { put } = useBackend();
  const modifyLabel = (parameters: IModifyLabelParameters) => put("labels", parameters);
  return { modifyLabel };
};

export interface IModifyLabelParameters {
  uuid: string;
  name: string;
  hasImage: boolean;
  discogsId?: string;
  profile?: string;
  website?: string;
}
