import { Button, List, useTheme } from "@mui/material";
import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { AlbumMessageListItem } from "../AlbumMessageListItem";
import { useGetMessagesForAlbum } from "../../../../hooks/messages/useGetMessagesForAlbum";
import { IAlbum } from "../../../../hooks/albums";
import { strings } from "../../../../localization/LocalizedStrings";
import styles from "./styles.module.scss";
import { ExpandMoreRounded } from "@mui/icons-material";
import { IMessage } from "../../../../hooks/messages";

export const AlbumMessagesList: FunctionComponent<IAlbumMessagesListProps> = ({
  isPublic,
  className,
  style,
  album,
  parentMessage,
  isLoading,
  setIsLoading,
  newMessage
}) => {
  const theme = useTheme();
  const [fetchMoreMessages, setFetchMoreMessages] = useState(false);
  const [messages, setMessages, resetMessages, noMoreMessages] = useGetMessagesForAlbum(
    album.uuid,
    parentMessage?.uuid,
    setIsLoading,
    [fetchMoreMessages]
  );
  useEffect(() => {
    resetMessages();
    setFetchMoreMessages(state => !state);
  }, [album.uuid, parentMessage?.uuid]);
  useEffect(() => {
    if (newMessage) {
      setMessages(messages => [newMessage, ...messages]);
    }
  }, [newMessage]);
  return (
    <List className={className} style={style}>
      {messages.map(message => (
        <>
          <AlbumMessageListItem
            isPublic={isPublic}
            album={album}
            message={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onMessageRemoved={() => {
              message.isRemoved = true;
              message.body = strings.message_removed;
              setMessages(messages =>
                messages.map(otherMessage =>
                  otherMessage.uuid == message.uuid ? message : otherMessage
                )
              );
            }}
          />
        </>
      ))}
      {messages.length == 0 && !isLoading && !parentMessage && noMoreMessages && (
        <p className={styles.noMessagesText} style={{ color: theme.palette.secondary.main }}>
          {strings.album_no_messages}
        </p>
      )}
      {!noMoreMessages && !isLoading && (
        <Button
          startIcon={<ExpandMoreRounded />}
          size={"small"}
          className={styles.button}
          onClick={() => setFetchMoreMessages(state => !state)}
        >
          {strings.messages_more}
        </Button>
      )}
    </List>
  );
};

export interface IAlbumMessagesListProps {
  isPublic: boolean;
  className?: string;
  style?: CSSProperties;
  album: IAlbum;
  parentMessage?: IMessage;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  newMessage?: IMessage;
}
