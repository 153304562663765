export const PlaybackSpeedHelper = {
  getNameById: (id: number) => {
    switch (id) {
      case 0:
        return "33⅓ rpm";
      case 1:
        return "45 rpm";
      case 2:
        return "78 rpm";
      default:
        return "-";
    }
  }
};
