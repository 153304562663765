import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Dialog, DialogActions, DialogContent, Grow, Toolbar, useTheme } from "@mui/material";
import { strings } from "../../../../localization/LocalizedStrings";
import { TextField } from "../../../TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CloseRounded, Delete } from "@mui/icons-material";
import { useAddExchangeRate } from "../../../../hooks/exchangeRates/useAddExchangeRate";
import { useModifyExchangeRate } from "../../../../hooks/exchangeRates/useModifyExchangeRate";
import { useDeleteExchangeRate } from "../../../../hooks/exchangeRates/useDeleteExchangeRate";
import { IExchangeRate } from "../../../../hooks/exchangeRates";
import { DatePickerField } from "../../../DatePickerField";
import { AlertDialog } from "../../../AlertDialog";
import { StatusCodesHelper } from "../../../../models/StatusCodesHelper";
import { StringFormat } from "../../../../models/StringFormat";
import { FieldTitle } from "../../../Text/FieldTitle";
import { ContainedButton } from "../../../ContainedButton";
import { ToolbarButton } from "../../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../../hooks/isMobile";
import { useUserProfile } from "../../../../hooks/session";
import { getAnalytics, logEvent } from "firebase/analytics";

export const ModifyExchangeRateDialog: FunctionComponent<IModifyExchangeRateDialogProps> = ({
  open,
  setOpen,
  exchangeRate,
  onNewItem,
  onModifiedItem,
  onDeletedItem
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [currentUserProfile] = useUserProfile.useState();
  const [dialogTitle, setDialogTitle] = useState(strings.msv_new_title);
  const { addExchangeRate } = useAddExchangeRate();
  const { modifyExchangeRate } = useModifyExchangeRate();
  const { deleteExchangeRate } = useDeleteExchangeRate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };
  const validationSchema = Yup.object({
    rate: Yup.number().typeError(strings.mer_format_error).required(strings.field_missing),
    date: Yup.date().required(strings.field_missing)
  });
  const formik = useFormik({
    initialValues: {
      rate: exchangeRate?.rate,
      date: exchangeRate ? new Date(exchangeRate.unixTimestamp * 1000) : undefined
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ rate, date }) => {
      if (rate == undefined || date == undefined) {
        return;
      }
      setIsLoading(true);
      if (exchangeRate) {
        const { status, body } = await modifyExchangeRate({
          uuid: exchangeRate.uuid,
          unixTimestamp: date.getTime() / 1000,
          rate: rate
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_exchangerate_action", { isNew: false });
          if (onModifiedItem != undefined) {
            onModifiedItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      } else {
        const { status, body } = await addExchangeRate({
          unixTimestamp: date.getTime() / 1000,
          rate: rate
        });
        if (StatusCodesHelper.isSuccessful(status)) {
          logEvent(analytics, "attribute_exchangerate_action", { isNew: true });
          if (onNewItem != undefined) {
            onNewItem(body);
          }
          handleClose();
        } else {
          setAlertMessage(body.message);
          setAlertIsOpen(true);
        }
      }
      setIsLoading(false);
    },
    validationSchema: validationSchema
  });
  const onDelete = async () => {
    if (!exchangeRate) return;
    setIsLoading(true);
    const { status, body } = await deleteExchangeRate({ uuid: exchangeRate.uuid });
    if (StatusCodesHelper.isSuccessful(status)) {
      if (onDeletedItem != undefined) {
        onDeletedItem(exchangeRate);
      }
      handleClose();
    } else {
      setAlertMessage(body.message);
      setAlertIsOpen(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (!open) return;
    setDialogTitle(exchangeRate ? strings.exchange_rate_title : strings.mer_new_title);
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <Toolbar>
          <h3>{dialogTitle}</h3>
          <Box sx={{ flexGrow: 1 }} />
          {exchangeRate && (
            <ToolbarButton
              disabled={isLoading}
              onClick={onDelete}
              tooltip={strings.delete}
              icon={Delete}
              color={theme.palette.colors?.remove}
            />
          )}
          <ToolbarButton
            loading={isLoading}
            onClick={handleClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FieldTitle>
            {StringFormat(strings.mer_rate_title, currentUserProfile?.currency.code ?? "")}
          </FieldTitle>
          <div className={styles.textField}>
            <TextField
              id="rate"
              name="rate"
              size="small"
              autoFocus={!isMobile}
              placeholder={StringFormat(
                strings.mer_rate_placeholder,
                currentUserProfile?.currency.code ?? ""
              )}
              value={formik.values.rate}
              onChange={formik.handleChange}
              enterAction={formik.submitForm}
            />
          </div>
          <FieldTitle>{strings.mer_date_title}</FieldTitle>
          <DatePickerField
            className={styles.datePicker}
            title={strings.mer_date_title}
            value={formik.values.date ?? null}
            onChange={newValue => {
              formik.setFieldValue("date", newValue);
            }}
          />
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <AlertDialog message={alertMessage} open={alertIsOpen} setOpen={setAlertIsOpen} />
    </FormikProvider>
  );
};

export interface IModifyExchangeRateDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  exchangeRate?: IExchangeRate;
  onNewItem?: (item: IExchangeRate) => void;
  onModifiedItem?: (item: IExchangeRate) => void;
  onDeletedItem?: (item: IExchangeRate) => void;
}
