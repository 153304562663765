import { useBackend } from "../../services";

export const useModifyArtist = () => {
  const { put } = useBackend();
  const modifyArtist = (parameters: IModifyArtistParameters) => put("artists", parameters);
  return { modifyArtist };
};

export interface IModifyArtistParameters {
  uuid: string;
  name: string;
  realName?: string;
  website?: string;
  profile?: string;
  discogsArtistId?: string;
  birthDate?: number;
  deathDate?: number;
  thumbnail?: string;
  hasImage: boolean;
}
