import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";
import { Card, List, Stack, useTheme } from "@mui/material";
import { IStats } from "../../../hooks/stats/useCreateStats";
import { BarChartGraph } from "../BarChartGraph";
import { strings } from "../../../localization/LocalizedStrings";
import { PieChartGraph } from "../PieChartGraph";
import { ImpactHelper } from "../../../models/ImpactHelper";
import { useUserProfile } from "../../../hooks/session";

export const StatsGraphs: FunctionComponent<IStatsGraphsProps> = ({ stats }) => {
  const theme = useTheme();
  const [currentProfile] = useUserProfile.useState();
  const impactData = () => {
    const data: { name: string; value: number }[] = [];
    stats.impactCounts?.forEach((impactCount, index) => {
      if (index == 0) {
        return;
      }
      const name = currentProfile?.shouldShowImpactDescriptions
        ? impactCount.grade.description
        : ImpactHelper.starsString(impactCount.grade);
      data.push({ name: name, value: impactCount.count });
    });
    return data;
  };
  const sleeveGradeData = () => {
    const data: { name: string; value: number }[] = [];
    stats.sleeveGradeCounts?.forEach((sleeveGradeCount, index) => {
      if (index == 0) {
        return;
      }
      data.push({ name: sleeveGradeCount.grade.goldmineGrade, value: sleeveGradeCount.count });
    });
    return data;
  };
  const mediaGradeData = () => {
    const data: { name: string; value: number }[] = [];
    stats.mediaGradeCounts?.forEach((mediaGradeCount, index) => {
      if (index == 0) {
        return;
      }
      data.push({ name: mediaGradeCount.grade.goldmineGrade, value: mediaGradeCount.count });
    });
    return data;
  };
  const percentageData = (value: number) => {
    const noPercentage = stats.totalAlbumCount
      ? (stats.totalAlbumCount - value) / stats.totalAlbumCount
      : 1;
    const yesPercentage = stats.totalAlbumCount ? value / stats.totalAlbumCount : 0;
    return [
      { name: strings.stats_pie_yes, value: Math.round(yesPercentage * 100) },
      { name: strings.stats_pie_no, value: Math.round(noPercentage * 100) }
    ];
  };
  const pieColor = (index: number) => {
    switch (index) {
      case 0:
        return theme.palette.colors?.goldmineGreen ?? "";
      default:
        return theme.palette.colors?.goldmineRed ?? "";
    }
  };
  const impactColor = (index: number) => {
    switch (index) {
      case 0:
        return theme.palette.colors?.goldmineGray ?? "";
      case 1:
        return theme.palette.colors?.goldmineRed ?? "";
      case 2:
        return theme.palette.colors?.goldmineGreen ?? "";
      case 3:
        return theme.palette.colors?.goldmineBlue ?? "";
      default:
        return theme.palette.colors?.goldmineMint ?? "";
    }
  };
  const gradeColor = (index: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
      case 3:
        return theme.palette.colors?.goldmineRed ?? "";
      case 4:
      case 5:
      case 6:
        return theme.palette.colors?.goldmineGreen ?? "";
      case 7:
      case 8:
        return theme.palette.colors?.goldmineBlue ?? "";
      default:
        return theme.palette.colors?.goldmineMint ?? "";
    }
  };
  return (
    <Card className={styles.mainContainer} elevation={5}>
      <List sx={{ overflow: "auto", flexGrow: 1 }}>
        <Stack sx={{ width: "100%", height: 190 }} direction={"row"}>
          <div style={{ width: "24.9%" }}>
            <PieChartGraph
              title={strings.stats_clean_title}
              data={percentageData(stats.cleanCount)}
              dataKey={"value"}
              colorForIndex={pieColor}
            />
          </div>
          <div style={{ width: "24.9%" }}>
            <PieChartGraph
              title={strings.stats_insert_title}
              data={percentageData(stats.insertCount)}
              dataKey={"value"}
              colorForIndex={pieColor}
            />
          </div>
          <div style={{ width: "24.9%" }}>
            <PieChartGraph
              title={strings.stats_lyrics_title}
              data={percentageData(stats.lyricsCount)}
              dataKey={"value"}
              colorForIndex={pieColor}
            />
          </div>
          <div style={{ width: "24.9%" }}>
            <PieChartGraph
              title={strings.stats_gatefold_title}
              data={percentageData(stats.gatefoldCount)}
              dataKey={"value"}
              colorForIndex={pieColor}
            />
          </div>
        </Stack>
        <Stack sx={{ width: "100%", height: 420 }} direction={"row"}>
          <div style={{ width: "100%" }}>
            <BarChartGraph
              title={strings.stats_sleeve_grades_title}
              data={sleeveGradeData()}
              dataKey={"value"}
              colorForIndex={gradeColor}
            />
          </div>
        </Stack>
        <Stack sx={{ width: "100%", height: 420 }} direction={"row"}>
          <div style={{ width: "100%" }}>
            <BarChartGraph
              title={strings.stats_media_grades_title}
              data={mediaGradeData()}
              dataKey={"value"}
              colorForIndex={gradeColor}
            />
          </div>
        </Stack>
        <Stack sx={{ width: "100%", height: 420 }} direction={"row"}>
          <div style={{ width: "100%" }}>
            <BarChartGraph
              title={strings.stats_impacts_title}
              data={impactData()}
              dataKey={"value"}
              colorForIndex={impactColor}
            />
          </div>
        </Stack>
      </List>
    </Card>
  );
};

export interface IStatsGraphsProps {
  stats: IStats;
}
