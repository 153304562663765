import { useBackend } from "../../services";
import { ImageCache } from "../../models/ImageCache";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { useUser } from "../session";

export const useGetUser = () => {
  const { get } = useBackend();
  const [, setUser] = useUser.useState();
  const getUser = async () => {
    const { body, status } = await get("user");
    if (StatusCodesHelper.isSuccessful(status)) {
      setUser(body);
      if (ImageCache.shared.isCacheOlderThan(body.lastImageUpdateUnixTimestamp)) {
        await ImageCache.shared.clear();
      }
    }
    return { body, status };
  };
  return { getUser };
};
