import React, { FunctionComponent, useEffect, useState } from "react";
import { IAlbum } from "../../../hooks/albums";
import { strings } from "../../../localization/LocalizedStrings";
import { AlertDialog } from "../../AlertDialog";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  useTheme
} from "@mui/material";
import styles from "./styles.module.scss";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import { CollectionCard } from "../../collections/CollectionCard";
import {
  QueueRounded,
  LayersClearRounded,
  PublicRounded,
  MoreVertRounded,
  ShareRounded
} from "@mui/icons-material";
import { CollectionsDialog } from "../../collections/CollectionsDialog";
import { useAssignAlbumToCollection } from "../../../hooks/albums/useAssignAlbumToCollection";
import { useUnassignAlbumFromCollection } from "../../../hooks/albums/useUnassignAlbumFromCollection";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { toast } from "react-toastify";
import { ICollection } from "../../../hooks/collections";
import { useIsMobile } from "../../../hooks/isMobile";
import { StringFormat } from "../../../models/StringFormat";
import { useGetShareLink } from "../../../hooks/share";
import { useCurrentPublicCollection } from "../../../hooks/collections/useCollectionManager";
import { usePublicUserProfile } from "../../../hooks/session";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useContextMenu } from "../../../hooks/contextMenu";
import { useShareLink } from "../../../hooks/share/useShareLink";

export const AlbumCollectionsListSection: FunctionComponent<IAlbumCollectionsListSectionProps> = ({
  album
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [collections, setCollections] = useState<ICollection[] | undefined>(album.collections);
  const [selectedCollection, setSelectedCollection] = useState<ICollection | undefined>();
  const [, setCurrentPublicCollection] = useCurrentPublicCollection.useState();
  const [, setCurrentPublicUserProfile] = usePublicUserProfile.useState();
  const { assignAlbumToCollection } = useAssignAlbumToCollection();
  const { unassignAlbumFromCollection } = useUnassignAlbumFromCollection();
  const { getShareLink } = useGetShareLink();
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnassignAlertOpen, setIsUnassignAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { menuProps, handleContextMenu, handleMenuClose } = useContextMenu();
  const [shareLinkComponents, fetchShareLink, ,] = useShareLink(album.name);
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const showAlbum = async (collectionId: string) => {
    setIsLoading(true);
    const { status, body } = await getShareLink({
      albumId: album.uuid,
      collectionId: collectionId
    });
    if (StatusCodesHelper.isSuccessful(status)) {
      setCurrentPublicUserProfile(undefined);
      setCurrentPublicCollection(undefined);
      openInNewTab(body.shareLink);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(album.collections === undefined);
    setCollections(album.collections);
  }, [album.collections]);
  const onCollectionSelected = (
    collection: ICollection,
    event: React.TouchEvent | React.MouseEvent
  ) => {
    if (isLoading) return;
    setSelectedCollection(collection);
    handleContextMenu(event);
  };
  return (
    <div className={styles.itemDiv}>
      <Stack className={styles.header} direction={"row"} spacing={2} sx={{ width: "100%" }}>
        <ListItemText
          disableTypography
          className={styles.title}
          secondary={strings.collections_title}
          sx={{ flexGrow: 0 }}
        />
        <ToolbarButton
          tooltip={strings.collection_associate}
          icon={QueueRounded}
          onClick={() => setIsCollectionsDialogOpen(true)}
          loading={isLoading}
        />
      </Stack>
      <Grid container spacing={2} sx={{ minHeight: 136 }}>
        {collections && collections.length > 0 ? (
          collections.map(collection => (
            <Grid key={collection.uuid} item xs={isMobile ? 12 : 6}>
              <CollectionCard
                className={styles.collectionCard}
                key={collection.uuid}
                disabled={isLoading}
                collection={collection}
                onClick={event => onCollectionSelected(collection, event)}
                onContextMenu={event => onCollectionSelected(collection, event)}
              >
                <Stack className={styles.hoverButtonsStack} direction={"row"} spacing={1}>
                  <Tooltip title={strings.options} arrow disableInteractive>
                    <IconButton
                      className={styles.hoverButton}
                      style={{ backgroundColor: theme.palette.colors?.counterBackground }}
                      disabled={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        setSelectedCollection(collection);
                        handleContextMenu(event);
                      }}
                    >
                      <ResponsiveIcon className={styles.hoverButtonIcon} icon={MoreVertRounded} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </CollectionCard>
            </Grid>
          ))
        ) : isLoading ? (
          Array.from({ length: isMobile ? 1 : 2 }, (value, index) => (
            <Grid key={index} item xs={isMobile ? 12 : 6} className={styles.skeletonCollection}>
              <Skeleton variant={"rectangular"} className={styles.skeleton} />
            </Grid>
          ))
        ) : (
          <p className={styles.noCollectionText} style={{ color: theme.palette.secondary.main }}>
            {strings.album_no_collections}
          </p>
        )}
      </Grid>
      <Menu {...menuProps}>
        {selectedCollection?.visibility == "public" && (
          <MenuItem
            onClick={async () => {
              handleMenuClose();
              await showAlbum(selectedCollection?.uuid);
            }}
            dense
          >
            <ListItemIcon>
              <PublicRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.album_view_on_exhibition}
          </MenuItem>
        )}
        {selectedCollection?.visibility == "public" && (
          <MenuItem
            onClick={async () => {
              handleMenuClose();
              await fetchShareLink(selectedCollection?.uuid, album.uuid);
            }}
            dense
          >
            <ListItemIcon>
              <ShareRounded fontSize={"small"} />
            </ListItemIcon>
            {strings.share_album}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setSelectedCollection(selectedCollection);
            setIsUnassignAlertOpen(true);
          }}
          style={{ color: theme.palette.colors?.remove }}
          dense
        >
          <ListItemIcon>
            <LayersClearRounded
              fontSize={"small"}
              style={{ color: theme.palette.colors?.remove }}
            />
          </ListItemIcon>
          {strings.collection_disassociate}
        </MenuItem>
      </Menu>
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        canModifyCollections={false}
        displayStandardCollections={false}
        onCollectionSelected={async collection => {
          setIsCollectionsDialogOpen(false);
          setIsLoading(true);
          const { status, body } = await assignAlbumToCollection({
            uuid: album.uuid,
            collectionId: collection.uuid
          });
          setIsLoading(false);
          if (StatusCodesHelper.isSuccessful(status)) {
            toast.success(strings.album_update_success);
            setCollections(body.collections);
          } else {
            setAlertMessage(body.message);
            setIsAlertOpen(true);
          }
        }}
      />
      {shareLinkComponents()}
      <AlertDialog
        message={StringFormat(
          strings.collection_disassociate_confirmation,
          selectedCollection ? selectedCollection.name : ""
        )}
        open={isUnassignAlertOpen}
        setOpen={setIsUnassignAlertOpen}
        isConfirm
        onConfirm={async () => {
          if (!selectedCollection) return;
          setIsLoading(true);
          const { status, body } = await unassignAlbumFromCollection({
            uuid: album.uuid,
            collectionId: selectedCollection.uuid
          });
          setIsLoading(false);
          if (StatusCodesHelper.isSuccessful(status)) {
            toast.success(strings.album_update_success);
            setCollections(body.collections);
          } else {
            setAlertMessage(body.message);
            setIsAlertOpen(true);
          }
        }}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </div>
  );
};

export interface IAlbumCollectionsListSectionProps {
  album: IAlbum;
}
