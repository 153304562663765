import { useBackend } from "../../services";

export const useAddAlbumFromDiscogsRelease = () => {
  const { post } = useBackend();
  const addAlbumFromDiscogsRelease = (parameters: IAddAlbumFromDiscogsReleaseParameters) =>
    post("albums/discogs", parameters);
  return { addAlbumFromDiscogsRelease };
};

export interface IAddAlbumFromDiscogsReleaseParameters {
  releaseId: number;
  shouldUploadImage: boolean;
}
