import { useBackend } from "../../services";
import { FilterElement } from "../../models/FilterElement";
import { ShelfSegmentAlbumShape } from "./interfaces";

export const useAssignAlbumsToShelfSegmentByFilter = () => {
  const { post } = useBackend();
  const assignAlbumsToShelfSegmentByFilter = (
    parameters: IAssignAlbumsToShelfSegmentByFilterParameters,
    filterElements: FilterElement[],
    searchText: string
  ) => {
    const filters = FilterElement.queryItems(filterElements);
    if (!filters) return;
    return post("shelfSegments/albums/filter", { ...parameters, ...filters, searchText });
  };
  return { assignAlbumsToShelfSegmentByFilter };
};

export interface IAssignAlbumsToShelfSegmentByFilterParameters {
  assignToShelfSegmentId: string;
  order: number;
  shape: ShelfSegmentAlbumShape;
}
