import { useBackend } from "../../services";
import { ImageCache } from "../../models/ImageCache";
import { StatusCodes } from "http-status-codes";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";

export const useGetImage = () => {
  const { get } = useBackend();
  const getImage = async (parameters: IGetImageParameters) => {
    const name = `${parameters.name}_${parameters.width}_${parameters.height}`;
    const cachedImage = await ImageCache.shared.getCachedImage(name);
    if (cachedImage) {
      return { status: StatusCodes.OK, body: cachedImage };
    } else {
      const { status, body } = await get("images", parameters);
      if (StatusCodesHelper.isSuccessful(status)) {
        await ImageCache.shared.saveImage(name, body);
      }
      return { status, body };
    }
  };
  return { getImage };
};

export interface IGetImageParameters {
  name: string;
  width: number;
  height: number;
}
