import React, { FunctionComponent, ReactElement, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Card, Grid, Toolbar, useTheme } from "@mui/material";
import { SearchBar } from "../../SearchBar";
import {
  AddCircleOutlineRounded,
  CleaningServicesRounded,
  CloseRounded,
  RefreshRounded,
  SvgIconComponent
} from "@mui/icons-material";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMobile } from "../../../hooks/isMobile";
import { ContentUnavailableNotice } from "../ContentUnavailableNotice";

export const AttributeDesktop: FunctionComponent<IAttributeDesktopProps> = ({
  isPublic,
  items,
  emptyIcon,
  emptyString,
  addItemString,
  title,
  loading,
  onSearchRequested,
  addAction,
  refreshAction,
  handleClose,
  extraToolbarItems,
  onClearValue,
  timed,
  children
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [searchText, setSearchText] = useState<string>();
  const searchBar = () => (
    <SearchBar
      className={styles.searchBar}
      style={{ marginBottom: isMobile ? 12 : 0, marginLeft: isMobile ? 0 : 12 }}
      onSearchRequested={text => {
        onSearchRequested(text);
        setSearchText(text);
      }}
      onSearchDismissed={() => {
        onSearchRequested("");
        setSearchText(undefined);
      }}
      timed={timed ?? false}
      minimumSearchLength={0}
      autoFocus={!isMobile}
    />
  );
  return (
    <div className={styles.container}>
      <Toolbar className={styles.toolbar}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <h3 style={{ color: theme.palette.primary.main, flexShrink: 0 }}>{title}</h3>
            {!isMobile && searchBar()}
            {!isPublic && addAction && (
              <ToolbarButton
                onClick={addAction}
                tooltip={strings.add_attribute}
                icon={AddCircleOutlineRounded}
              />
            )}
            {refreshAction && !isMobile && (
              <ToolbarButton
                onClick={refreshAction}
                tooltip={strings.refresh_list}
                icon={RefreshRounded}
              />
            )}
            {extraToolbarItems}
            <Box sx={{ flexGrow: 1 }} />
            {onClearValue && (
              <ToolbarButton
                onClick={onClearValue}
                tooltip={strings.clear_value}
                icon={CleaningServicesRounded}
                color={theme.palette.colors?.remove}
              />
            )}
            {(loading || handleClose) && (
              <ToolbarButton
                loading={loading}
                onClick={handleClose}
                tooltip={loading ? "" : strings.close}
                icon={CloseRounded}
              />
            )}
          </div>
          {isMobile && searchBar()}
        </div>
      </Toolbar>
      <Card className={styles.gridCard}>
        <ContentUnavailableNotice
          isLoading={loading}
          items={items}
          emptyIcon={emptyIcon}
          emptyTitle={emptyString}
          emptyDescription={isPublic ? "" : strings.empty_attribute_description}
          searchText={searchText}
          addButtonTitle={addItemString}
          onAddItem={addAction}
        />
        <Grid container spacing={2} className={styles.grid}>
          {children}
        </Grid>
      </Card>
    </div>
  );
};

export interface IAttributeDesktopProps {
  children: React.ReactNode;
  isPublic: boolean;
  items: any[] | undefined;
  emptyIcon: SvgIconComponent;
  emptyString: string;
  addItemString?: string;
  title: string;
  loading: boolean;
  onSearchRequested: (searchText: string) => void;
  addAction?: () => void;
  refreshAction?: () => void;
  handleClose?: () => void;
  extraToolbarItems?: ReactElement;
  onClearValue?: () => void;
  timed?: boolean;
}
