import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Grow,
  IconButton,
  Stack,
  Toolbar,
  Tooltip
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import genericSleeveImage from "../../../images/generic_sleeve.jpg";
import { strings } from "../../../localization/LocalizedStrings";
import { useUploadImage } from "../../../hooks/images";
import { TextField } from "../../TextField";
import { DatePickerField } from "../../DatePickerField";
import { IArtist } from "../../../hooks/artists";
import { ILabel } from "../../../hooks/labels";
import { IStore } from "../../../hooks/stores";
import { useAddAlbum } from "../../../hooks/albums/useAddAlbum";
import { ArtistsDesktop } from "../../attributes/artists/ArtistsDesktop";
import { IAlbumFormat } from "../../../hooks/albumFormats";
import { IAlbumType } from "../../../hooks/albumTypes";
import { LabelsDesktop } from "../../attributes/labels/LabelsDesktop";
import { AlbumFormatsDesktop } from "../../attributes/albumFormats/AlbumFormatsDesktop";
import { AlbumTypesDesktop } from "../../attributes/albumTypes/AlbumTypesDesktop";
import { Counter } from "../../Counter";
import { StoresDesktop } from "../../attributes/stores/StoresDesktop";
import { BooleanAttributesListItem } from "../../attributes/BooleanAttributesListItem";
import { ISellValue } from "../../../hooks/sellValues";
import { SellValuesDesktop } from "../../attributes/sellValues/SellValuesDesktop";
import { ISleeveGrade } from "../../../hooks/sleeveGrades";
import { IMediaGrade } from "../../../hooks/mediaGrades";
import { IImpact } from "../../../hooks/impacts";
import { SleeveGradesDesktop } from "../../attributes/sleeveGrades/SleeveGradesDesktop";
import { MediaGradesDesktop } from "../../attributes/mediaGrades/MediaGradesDesktop";
import { ImpactsDesktop } from "../../attributes/impacts/ImpactsDesktop";
import { StatusCodes } from "http-status-codes";
import { AlertDialog } from "../../AlertDialog";
import { ImageDropzone } from "../../ImageDropzone";
import { LengthTextField } from "../../LengthTextField";
import { ImpactHelper } from "../../../models/ImpactHelper";
import { ICollection } from "../../../hooks/collections";
import { CollectionsDialog } from "../../collections/CollectionsDialog";
import { toast } from "react-toastify";
import { FieldTitle } from "../../Text/FieldTitle";
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  HelpOutlineRounded
} from "@mui/icons-material";
import { ContainedButton } from "../../ContainedButton";
import { RoutesBuilder } from "../../../models/RoutesBuilder";
import { ImageCache } from "../../../models/ImageCache";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { useIsMobile } from "../../../hooks/isMobile";
import { useUserProfile } from "../../../hooks/session";
import { IAlbum } from "../../../hooks/albums";
import { useAddAlbumContent } from "../../../hooks/albumContents/useAddAlbumContent";
import { getAnalytics, logEvent } from "firebase/analytics";

export const AddAlbumDialog: FunctionComponent<IAddAlbumDialogProps> = ({
  open,
  setOpen,
  callback
}) => {
  const isMobile = useIsMobile();
  const analytics = getAnalytics();
  const [currentUserProfile] = useUserProfile.useState();
  const { addAlbum } = useAddAlbum();
  const { uploadImage } = useUploadImage();
  const { addAlbumContent } = useAddAlbumContent();
  const [isLoading, setIsLoading] = useState(false);
  const [didChangeImage, setDidChangeImage] = useState(false);
  const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
  const [isArtistDesktopOpen, setIsArtistDesktopOpen] = useState(false);
  const [isLabelDesktopOpen, setIsLabelDesktopOpen] = useState(false);
  const [isAlbumFormatDesktopOpen, setIsAlbumFormatDesktopOpen] = useState(false);
  const [isAlbumTypeDesktopOpen, setIsAlbumTypeDesktopOpen] = useState(false);
  const [isStoreDesktopOpen, setIsStoreDesktopOpen] = useState(false);
  const [isSellValueDesktopOpen, setIsSellValueDesktopOpen] = useState(false);
  const [isSleeveGradeDesktopOpen, setIsSleeveGradeDesktopOpen] = useState(false);
  const [isMediaGradeDesktopOpen, setIsMediaGradeDesktopOpen] = useState(false);
  const [isImpactDesktopOpen, setIsImpactDesktopOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isConfirmCloseAlertOpen, setIsConfirmCloseAlertOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const tryToClose = () => {
    if (isLoading) return;
    if (formik.dirty) {
      setIsConfirmCloseAlertOpen(true);
      return;
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setImageFile(undefined);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(strings.field_missing_short),
    artist: Yup.object().required(strings.field_missing_short),
    purchasePrice: Yup.number().typeError(strings.field_invalid)
  });
  const initialValues: IFormProps = {
    name: "",
    artist: undefined,
    country: "",
    originalYear: "",
    releaseYear: "",
    label: undefined,
    catalogueNumber: "",
    albumFormat: undefined,
    albumType: undefined,
    color: "",
    discCount: undefined,
    store: undefined,
    discogsId: "",
    length: undefined,
    trackCount: undefined,
    collection: undefined,
    isClean: false,
    hasLyrics: false,
    hasInsert: false,
    isGatefold: false,
    purchasePrice: "",
    sellValue: undefined,
    sleeveGrade: undefined,
    mediaGrade: undefined,
    impact: undefined,
    purchaseDate: new Date()
  };
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      if (!values.artist) {
        return;
      }
      setIsLoading(true);
      let uuid = undefined;
      const { status, body } = await addAlbum({
        name: values.name,
        albumTypeId: values.albumType?.uuid,
        artistId: values.artist.uuid,
        catalogueNumber: values.catalogueNumber,
        collections: values.collection ? [values.collection] : undefined,
        country: values.country,
        discogsReleaseId: values.discogsId,
        hasInsert: values.hasInsert,
        hasLyrics: values.hasLyrics,
        impactId: values.impact?.id,
        isClean: values.isClean,
        isGatefold: values.isGatefold,
        labelId: values.label?.uuid,
        length: values.length,
        mediaGradeId: values.mediaGrade?.id,
        order: -1,
        originalYear: values.originalYear == "" ? undefined : values.originalYear,
        purchaseDate: values.purchaseDate
          ? Math.floor(values.purchaseDate.getTime() / 1000)
          : undefined,
        purchasePrice: values.purchasePrice ? +values.purchasePrice : undefined,
        releaseYear: values.releaseYear == "" ? undefined : values.releaseYear,
        sellValueId: values.sellValue?.uuid,
        sleeveGradeId: values.sleeveGrade?.id,
        storeId: values.store?.uuid,
        tracks: values.trackCount
      });
      if (status == StatusCodes.CREATED) {
        uuid = body.uuid;
        if (didChangeImage && imageFile && uuid) {
          const thumbnail = await uploadImage(
            { name: uuid, file: imageFile },
            ImageCache.bigAlbumImageSize,
            ImageCache.bigAlbumImageSize,
            ImageCache.smallAlbumImageSize,
            ImageCache.smallAlbumImageSize
          );
          if (thumbnail) {
            body.hasImage = true;
            body.thumbnail = thumbnail;
          }
        }
        if (values.discCount || values.albumFormat || values.color) {
          // Create an album content
          await addAlbumContent({
            albumId: uuid,
            albumFormatId: values.albumFormat?.uuid,
            color: values.color,
            quantity: values.discCount,
            type: "disc"
          });
        }
        toast.success(strings.album_created);
        logEvent(analytics, "new_album_manual", {
          user_id: currentUserProfile.userId,
          album_id: uuid
        });
        setIsLoading(false);
        if (callback != undefined) {
          callback(body);
        }
        handleClose();
      } else {
        setIsLoading(false);
        setAlertMessage(body.message);
        setIsAlertOpen(true);
      }
    },
    validationSchema: validationSchema
  });
  useEffect(() => {
    if (!open) return;
    formik.resetForm();
  }, [open]);
  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={tryToClose} TransitionComponent={Grow} maxWidth={"md"}>
        <Toolbar>
          <h3>{strings.ca_title}</h3>
          <Box flexGrow={1} />
          <ToolbarButton
            href={`${window.location.protocol}//${
              window.location.host
            }${RoutesBuilder.documentation.home()}${RoutesBuilder.documentation.album.home(
              "create"
            )}`}
            target={"_blank"}
            tooltip={strings.help}
            icon={HelpOutlineRounded}
          />
          <ToolbarButton
            loading={isLoading}
            onClick={tryToClose}
            tooltip={strings.close}
            icon={CloseRounded}
          />
        </Toolbar>
        <DialogContent
          dividers
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: isMobile ? 1 : 2,
            paddingRight: isMobile ? 1 : 2
          }}
        >
          <Stack direction={"row"} spacing={3}>
            <ImageDropzone
              className={styles.dropzone}
              placeholderImage={genericSleeveImage}
              onImageChanged={file => {
                setImageFile(file);
                setDidChangeImage(true);
              }}
              isLoading={false}
            />
            <Stack sx={{ width: "100%" }}>
              <FieldTitle className={styles.itemTitle}>{strings.name_title}*</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="name"
                  name="name"
                  size="small"
                  autoComplete="albumName"
                  placeholder={strings.name_placeholder}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoFocus={!isMobile}
                />
              </div>
              <FieldTitle className={styles.itemTitle}>{strings.artist_title}*</FieldTitle>
              <div className={styles.textField}>
                <TextField
                  id="artist"
                  name="artist"
                  size="small"
                  placeholder={strings.artist_placeholder}
                  value={formik.values.artist?.name ?? ""}
                  readOnly
                  onClick={() => {
                    setIsArtistDesktopOpen(true);
                  }}
                  enterAction={() => setIsArtistDesktopOpen(true)}
                />
              </div>
            </Stack>
          </Stack>
          <Collapse in={isExpanded} timeout={"auto"}>
            <Grid container spacing={1}>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.country_short_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="country"
                    name="country"
                    size="small"
                    autoComplete="country"
                    placeholder={strings.country_placeholder}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.original_year_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="originalYear"
                    name="originalYear"
                    size="small"
                    autoComplete="originalYear"
                    placeholder={strings.original_year_placeholder}
                    value={formik.values.originalYear}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.release_year_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="releaseYear"
                    name="releaseYear"
                    size="small"
                    autoComplete="releaseYear"
                    placeholder={strings.release_year_placeholder}
                    value={formik.values.releaseYear}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.label_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="label"
                    name="label"
                    size="small"
                    readOnly
                    placeholder={strings.ml_placeholder}
                    value={formik.values.label?.name ?? ""}
                    onClick={() => setIsLabelDesktopOpen(true)}
                    enterAction={() => setIsLabelDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>
                  {strings.catalogue_number_title}
                </FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="catalogueNumber"
                    name="catalogueNumber"
                    size="small"
                    autoComplete="catalogueNumber"
                    placeholder={strings.catalogue_number_placeholder}
                    value={formik.values.catalogueNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.album_format_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="albumFormat"
                    name="albumFormat"
                    size="small"
                    readOnly
                    placeholder={strings.maf_short_name_placeholder}
                    value={formik.values.albumFormat?.name ?? ""}
                    onClick={() => setIsAlbumFormatDesktopOpen(true)}
                    enterAction={() => setIsAlbumFormatDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.album_type_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="albumType"
                    name="albumType"
                    size="small"
                    readOnly
                    placeholder={strings.mat_placeholder}
                    value={formik.values.albumType?.name ?? ""}
                    onClick={() => setIsAlbumTypeDesktopOpen(true)}
                    enterAction={() => setIsAlbumTypeDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>
                  {strings.disc_color_singular_title}
                </FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="color"
                    name="color"
                    size="small"
                    autoComplete="color"
                    placeholder={strings.disc_color_placeholder}
                    value={formik.values.color}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.disc_count_title}</FieldTitle>
                <Counter
                  className={styles.counter}
                  initialValue={0}
                  minimumValue={1}
                  nullable={true}
                  onValueChanged={newValue => {
                    formik.setFieldValue("discCount", newValue);
                  }}
                />
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.store_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="store"
                    name="store"
                    size="small"
                    readOnly
                    placeholder={strings.ms_placeholder}
                    value={formik.values.store?.name ?? ""}
                    onClick={() => setIsStoreDesktopOpen(true)}
                    enterAction={() => setIsStoreDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.discogs_id_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="discogsId"
                    name="discogsId"
                    autoComplete="discogsId"
                    size="small"
                    placeholder={strings.ma_discogs_id_placeholder}
                    value={formik.values.discogsId}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.purchase_date_title}</FieldTitle>
                <div className={styles.textField}>
                  <DatePickerField
                    title={strings.purchase_date_title}
                    value={formik.values.purchaseDate ?? null}
                    onChange={newValue => {
                      formik.setFieldValue("purchaseDate", newValue);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.track_count_title}</FieldTitle>
                <Counter
                  className={styles.counter}
                  initialValue={0}
                  minimumValue={1}
                  nullable={true}
                  onValueChanged={newValue => {
                    formik.setFieldValue("trackCount", newValue);
                  }}
                />
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.album_length_title}</FieldTitle>
                <div className={styles.textField}>
                  <LengthTextField
                    id={"length"}
                    name={"length"}
                    size={"small"}
                    onChange={newValue => {
                      formik.setFieldValue("length", newValue);
                    }}
                    defaultValue={formik.values.length}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.impact_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="impact"
                    name="impact"
                    size="small"
                    readOnly
                    placeholder={strings.impact_placeholder}
                    value={
                      formik.values.impact
                        ? (currentUserProfile?.shouldShowImpactDescriptions
                            ? `${formik.values.impact.description}  `
                            : "") + ImpactHelper.starsString(formik.values.impact)
                        : ""
                    }
                    onClick={() => setIsImpactDesktopOpen(true)}
                    enterAction={() => setIsImpactDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.sleeve_grade_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="sleeveGrade"
                    name="sleeveGrade"
                    size="small"
                    readOnly
                    placeholder={strings.sleeve_grade_placeholder}
                    value={formik.values.sleeveGrade?.goldmineGrade ?? ""}
                    onClick={() => setIsSleeveGradeDesktopOpen(true)}
                    enterAction={() => setIsSleeveGradeDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>
                  {strings.media_grade_singular_title}
                </FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="mediaGrade"
                    name="mediaGrade"
                    size="small"
                    readOnly
                    placeholder={strings.media_grade_placeholder}
                    value={formik.values.mediaGrade?.goldmineGrade ?? ""}
                    onClick={() => setIsMediaGradeDesktopOpen(true)}
                    enterAction={() => setIsMediaGradeDesktopOpen(true)}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.collection_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="collection"
                    name="collection"
                    size="small"
                    readOnly
                    placeholder={strings.collection_name_placeholder}
                    value={formik.values.collection?.name ?? ""}
                    onClick={() => setIsCollectionsDialogOpen(true)}
                    enterAction={() => setIsCollectionsDialogOpen(true)}
                  />
                </div>
              </Grid>
            </Grid>
            <BooleanAttributesListItem
              isPublic={false}
              className={styles.booleansSection}
              hideIsGatefold={false}
              hideIsClean={false}
              isCleanLoading={false}
              isGatefoldLoading={false}
              hasLyricsLoading={false}
              hasInsertLoading={false}
              originalIsGatefold={false}
              originalHasInsert={false}
              originalHasLyrics={false}
              originalIsClean={false}
              onIsGatefoldChanged={newValue => {
                formik.setFieldValue("isGatefold", newValue);
              }}
              onHasInsertChanged={newValue => {
                formik.setFieldValue("hasInsert", newValue);
              }}
              onHasLyricsChanged={newValue => {
                formik.setFieldValue("hasLyrics", newValue);
              }}
              onIsCleanChanged={newValue => {
                formik.setFieldValue("isClean", newValue);
              }}
            />
            <Grid container spacing={1}>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.purchase_price_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="purchasePrice"
                    name="purchasePrice"
                    size="small"
                    autoComplete="purchasePrice"
                    placeholder={strings.purchase_price_placeholder}
                    value={formik.values.purchasePrice}
                    onChange={formik.handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs className={styles.itemContainer}>
                <FieldTitle className={styles.itemTitle}>{strings.sell_value_title}</FieldTitle>
                <div className={styles.textField}>
                  <TextField
                    id="sellValue"
                    name="sellValue"
                    size="small"
                    readOnly
                    placeholder={strings.sell_value_title}
                    value={formik.values.sellValue?.group ?? ""}
                    onClick={() => setIsSellValueDesktopOpen(true)}
                    enterAction={() => setIsSellValueDesktopOpen(true)}
                  />
                </div>
              </Grid>
            </Grid>
          </Collapse>
          <div className={styles.expandIconDiv}>
            <Tooltip
              title={isExpanded ? strings.shrink : strings.optional_attributes}
              arrow
              disableInteractive
            >
              <IconButton onClick={() => setIsExpanded(state => !state)}>
                {isExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogActions>
          <ContainedButton type={"submit"} disabled={isLoading} onClick={formik.handleSubmit}>
            {strings.alert_confirm}
          </ContainedButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isArtistDesktopOpen}
        onClose={() => setIsArtistDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ArtistsDesktop
            handleClose={() => {
              setIsArtistDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={formik.values.artist?.uuid}
            onArtistSelected={async artist => {
              setIsArtistDesktopOpen(false);
              await formik.setFieldValue("artist", artist);
            }}
            onClearValue={async () => {
              setIsArtistDesktopOpen(false);
              await formik.setFieldValue("artist", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isLabelDesktopOpen}
        onClose={() => setIsLabelDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <LabelsDesktop
            handleClose={() => {
              setIsLabelDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={formik.values.label?.uuid}
            onLabelSelected={async label => {
              setIsLabelDesktopOpen(false);
              await formik.setFieldValue("label", label);
            }}
            onClearValue={async () => {
              setIsLabelDesktopOpen(false);
              await formik.setFieldValue("label", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumFormatDesktopOpen}
        onClose={() => setIsAlbumFormatDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumFormatsDesktop
            handleClose={() => {
              setIsAlbumFormatDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 3}
            preselectedId={formik.values.albumFormat?.uuid}
            onAlbumFormatSelected={async albumFormat => {
              setIsAlbumFormatDesktopOpen(false);
              await formik.setFieldValue("albumFormat", albumFormat);
            }}
            onClearValue={async () => {
              setIsAlbumFormatDesktopOpen(false);
              await formik.setFieldValue("albumFormat", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isAlbumTypeDesktopOpen}
        onClose={() => setIsAlbumTypeDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <AlbumTypesDesktop
            handleClose={() => {
              setIsAlbumTypeDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={formik.values.albumType?.uuid}
            onAlbumTypeSelected={async albumType => {
              setIsAlbumTypeDesktopOpen(false);
              await formik.setFieldValue("albumType", albumType);
            }}
            onClearValue={async () => {
              setIsAlbumTypeDesktopOpen(false);
              await formik.setFieldValue("albumType", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isStoreDesktopOpen}
        onClose={() => setIsStoreDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <StoresDesktop
            handleClose={() => {
              setIsStoreDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={formik.values.store?.uuid}
            onStoreSelected={async store => {
              setIsStoreDesktopOpen(false);
              await formik.setFieldValue("store", store);
            }}
            onClearValue={async () => {
              setIsStoreDesktopOpen(false);
              await formik.setFieldValue("store", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSellValueDesktopOpen}
        onClose={() => setIsSellValueDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SellValuesDesktop
            handleClose={() => {
              setIsSellValueDesktopOpen(false);
            }}
            isPublic={false}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={formik.values.sellValue?.uuid}
            onSellValueSelected={async sellValue => {
              setIsSellValueDesktopOpen(false);
              await formik.setFieldValue("sellValue", sellValue);
            }}
            onClearValue={async () => {
              setIsSellValueDesktopOpen(false);
              await formik.setFieldValue("sellValue", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isSleeveGradeDesktopOpen}
        onClose={() => setIsSleeveGradeDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <SleeveGradesDesktop
            handleClose={() => {
              setIsSleeveGradeDesktopOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={formik.values.sleeveGrade?.id}
            onSleeveGradeSelected={async sleeveGrade => {
              setIsSleeveGradeDesktopOpen(false);
              await formik.setFieldValue("sleeveGrade", sleeveGrade);
            }}
            onClearValue={async () => {
              setIsSleeveGradeDesktopOpen(false);
              await formik.setFieldValue("sleeveGrade", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isMediaGradeDesktopOpen}
        onClose={() => setIsMediaGradeDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <MediaGradesDesktop
            handleClose={() => {
              setIsMediaGradeDesktopOpen(false);
            }}
            columnWidth={isMobile ? 12 : 6}
            preselectedId={formik.values.mediaGrade?.id}
            onMediaGradeSelected={async mediaGrade => {
              setIsMediaGradeDesktopOpen(false);
              await formik.setFieldValue("mediaGrade", mediaGrade);
            }}
            onClearValue={async () => {
              setIsMediaGradeDesktopOpen(false);
              await formik.setFieldValue("mediaGrade", undefined);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={isImpactDesktopOpen}
        onClose={() => setIsImpactDesktopOpen(false)}
        TransitionComponent={Grow}
      >
        <div className={styles.dialog}>
          <ImpactsDesktop
            handleClose={() => {
              setIsImpactDesktopOpen(false);
            }}
            columnWidth={isMobile ? 6 : 4}
            preselectedId={formik.values.impact?.id}
            onImpactSelected={async impact => {
              setIsImpactDesktopOpen(false);
              await formik.setFieldValue("impact", impact);
            }}
            onClearValue={async () => {
              setIsImpactDesktopOpen(false);
              await formik.setFieldValue("impact", undefined);
            }}
          />
        </div>
      </Dialog>
      <CollectionsDialog
        open={isCollectionsDialogOpen}
        setOpen={setIsCollectionsDialogOpen}
        canModifyCollections={false}
        displayStandardCollections={false}
        onCollectionSelected={async collection => {
          setIsCollectionsDialogOpen(false);
          await formik.setFieldValue("collection", collection);
        }}
      />
      <AlertDialog
        message={strings.alert_cancel_confirmation}
        open={isConfirmCloseAlertOpen}
        setOpen={setIsConfirmCloseAlertOpen}
        isConfirm={true}
        onConfirm={handleClose}
        confirmTitle={strings.yes}
        cancelTitle={strings.no}
      />
      <AlertDialog message={alertMessage} open={isAlertOpen} setOpen={setIsAlertOpen} />
    </FormikProvider>
  );
};

interface IFormProps {
  name: string;
  artist?: IArtist;
  country?: string;
  originalYear?: string;
  releaseYear?: string;
  label?: ILabel;
  catalogueNumber: string;
  albumFormat?: IAlbumFormat;
  albumType?: IAlbumType;
  color?: string;
  discCount?: number;
  store?: IStore;
  discogsId?: string;
  length?: number;
  trackCount?: number;
  isClean: boolean;
  hasInsert: boolean;
  hasLyrics: boolean;
  isGatefold: boolean;
  purchasePrice?: string;
  sellValue?: ISellValue;
  sleeveGrade?: ISleeveGrade;
  mediaGrade?: IMediaGrade;
  impact?: IImpact;
  purchaseDate: Date;
  collection?: ICollection;
}

export interface IAddAlbumDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  callback?: (newAlbum: IAlbum) => void;
}
