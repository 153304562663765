import React, { FunctionComponent, useState } from "react";
import { IAlbum } from "../../../../hooks/albums";
import { Divider, List } from "@mui/material";
import styles from "./styles.module.scss";
import { AlbumLocationItem } from "../AlbumLocationItem";
import { AlbumLocationDialog } from "../AlbumLocationDialog";
import { IShelfSegmentAlbum } from "../../../../hooks/shelfSegments";

export const AlbumLocationTable: FunctionComponent<IAlbumLocationTableProps> = ({ album }) => {
  const [selectedShelfSegmentAlbum, setSelectedShelfSegmentAlbum] = useState<IShelfSegmentAlbum>();
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  return (
    <List className={styles.list}>
      {album.shelfSegments?.map((shelfSegmentAlbum, index) => (
        <div key={index}>
          <AlbumLocationItem
            shelfSegmentAlbum={shelfSegmentAlbum}
            onClick={() => {
              setSelectedShelfSegmentAlbum(shelfSegmentAlbum);
              setIsLocationDialogOpen(true);
            }}
          />
          {index < album.shelfSegments?.length - 1 && <Divider />}
        </div>
      ))}
      <AlbumLocationDialog
        album={album}
        shelfSegmentAlbum={selectedShelfSegmentAlbum}
        isOpen={isLocationDialogOpen}
        setIsOpen={setIsLocationDialogOpen}
      />
    </List>
  );
};

export interface IAlbumLocationTableProps {
  album: IAlbum;
}
