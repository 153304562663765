import signUpThirdPartyImage from "../../../../images/documentation/sign-up-third-party-es.png";
import newUserImage from "../../../../images/documentation/new-user-es.png";

export const doc_users_sign_up_es = {
  title: "Crear un nuevo usuario",
  title_users: "Usuarios",
  home_title: "Registrarse",
  description: `Para usar Piper primero necesitás un usuario. Para esto tenés tres opciones:
`,
  description_1: "Crear un usuario con Piper.",
  description_2: "Ingresar con Google.",
  description_3: "Ingresar con Apple.",
  sign_up_third_party_title: "Ingresar con Google/Apple",
  sign_up_third_party_image: signUpThirdPartyImage,
  sign_up_third_party_description: `La forma más sencilla para registrarse es usar uno de los botones para ingresar con Google o Apple. Simplemente vas a tener que usar tus credenciales existentes para alguno de esos servicios y automáticamente vas a estar registrado en Piper. ¡Es así de fácil!

  El usuario creado va a tener tu correo electrónico como nombre de usuario pero vas a poder cambiarlo a través de tu perfil de usuario. Cuando tengas que volver a ingresar podés usar nuevamente el mismo botón que antes, o podés crear una contraseña e ingresar usándola también, todos los métodos de autenticación son intercambiables.  
  
`,
  sign_up_piper_title: "Crear un usuario con Piper",
  sign_up_piper_image: newUserImage,
  sign_up_piper_description: `Para registrarse en Piper seleccioná el botón de "crear un nuevo usuario", o seleccioná esa opción desde el menú desplegable.

  Una ventana va a aparecer donde vas a tener que ingresar una dirección de correo electrónico y una contraseña. Una vez que ingreses esos campos y aceptes los términos de servicio, se va a enviar un código de verificación a tu correo electrónico.

  Cuando intentes ingresar por primera vez, el sistema te va a pedir que ingreses el código de verificación. ¡Una vez que lo ingreses vas a estar listo para empezar!
  
  Si no recibiste el código o lo perdiste podés pedir que se envíe uno nuevo usando el botón de reenviar.  
  
`
};
