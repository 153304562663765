import { useBackend } from "../../services";

export const useAddSellValue = () => {
  const { post } = useBackend();
  const addSellValue = (parameters: IAddSellValueParameters) => post("sellValues", parameters);
  return { addSellValue };
};

export interface IAddSellValueParameters {
  group: string;
  dollars: number;
  description: string;
}
