import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Card, CardActionArea, List, ListSubheader, Stack, useTheme } from "@mui/material";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";
import { useIsMobile } from "../../../hooks/isMobile";
import { useBackend } from "../../../services";
import { FreeTextField } from "../../FreeTextField";
import { FormikProvider, useFormik } from "formik";
import { ToolbarButton } from "../../toolbar/ToolbarButton";
import { ForumRounded, SendRounded } from "@mui/icons-material";
import { ChatBubble } from "../ChatBubble";
import { IMessage } from "../../../hooks/messages";
import { useUser } from "../../../hooks/session";
import { IChat, IChatUser } from "../../../hooks/chats";
import { useGetMessagesForChat } from "../../../hooks/messages/useGetMessagesForChat";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { UserAvatar } from "../../UserAvatar";
import { PublicUserProfileDialog } from "../../userProfiles/PublicUserProfileDialog";
import { ContentUnavailableNotice } from "../../attributes/ContentUnavailableNotice";
import _ from "lodash";

export const ChatBox: FunctionComponent<IChatBoxProps> = ({ chat, setIsChatLoading }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { socket, emitEvent } = useBackend();
  const [currentUser] = useUser.useState();
  const [otherUser, setOtherUser] = useState<IChatUser>();
  const [isPublicUserProfileDialogOpen, setIsPublicUserProfileDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchMoreMessages, setFetchMoreMessages] = useState(false);
  const [groupedMessages, setGroupedMessages] = useState<_.Dictionary<IMessage[]>>();
  const [messages, setMessages, resetMessages, noMoreMessages] = useGetMessagesForChat(
    chat.uuid,
    setIsLoading,
    [fetchMoreMessages]
  );
  const [lastMessage, setLastMessage] = useState<IMessage>(undefined);
  useEffect(() => {
    setGroupedMessages(
      _.groupBy(messages, message => format(message.createdUnixTimestamp * 1000, "yyyyMMdd"))
    );
  }, [messages]);
  useEffect(() => {
    setIsChatLoading(isLoading);
  }, [isLoading]);
  useEffect(() => {
    setLastMessage(undefined);
    setIsLoading(true);
    resetMessages();
    setFetchMoreMessages(state => !state);
    const otherUser = chat.chatUsers.filter(
      chatUser => chatUser.userProfile.userId != currentUser?.uuid
    )[0];
    setOtherUser(otherUser);
  }, [chat.uuid]);
  useEffect(() => {
    if (messages.length == 0) return;
    if (lastMessage === undefined || messages[messages.length - 1].uuid !== lastMessage.uuid) {
      scrollToBottom(lastMessage !== undefined);
    }
    setLastMessage(messages[messages.length - 1]);
  }, [groupedMessages]);
  useEffect(() => {
    const onNewMessage = (data: { message: IMessage }) => {
      const { message } = data;
      if (message.chatId != chat?.uuid) return;
      setMessages(array => [...array, message]);
    };
    socket.on("new_message", onNewMessage);
    return () => {
      socket.off("new_message", onNewMessage);
    };
  });
  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey && !event.altKey) {
      event.preventDefault();
      await formik.submitForm();
    }
  };
  const formattedDate = (date: number) => {
    return format(date * 1000, "EEEE, d MMMM yyyy", {
      locale: strings.locale == "en-US" ? enUS : es
    });
  };
  const dates = new Set();
  const renderDate = (createdUnixTimestamp: number, date: string) => {
    // Add to Set so it does not render again
    dates.add(date);
    return (
      <ListSubheader className={styles.dateDiv}>
        <div
          className={styles.dateContainer}
          style={{ backgroundColor: theme.palette.colors?.chatSubheader }}
        >
          <p className={styles.date} style={{ color: theme.palette.primary.main }}>
            {formattedDate(createdUnixTimestamp)}
          </p>
        </div>
      </ListSubheader>
    );
  };
  const formik = useFormik({
    initialValues: {
      text: ""
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ text }) => {
      if (text == "") return;
      formik.resetForm();
      await emitEvent("send_message", { chatId: chat?.uuid, message: text });
    }
  });
  const isOwn = (message: IMessage) => {
    return message.authorUserProfile.userId == currentUser?.uuid;
  };
  const getChatBubblePosition = (
    isOwnMessage: boolean,
    isPreviousOwned: boolean,
    isNextOwned: boolean
  ) => {
    if (isPreviousOwned == isOwnMessage && isNextOwned == isOwnMessage) {
      return "middle";
    } else if (isPreviousOwned != isOwnMessage && isNextOwned != isOwnMessage) {
      return "unique";
    } else if (isNextOwned != isOwnMessage) {
      return "last";
    }
    return "first";
  };
  const bottomReference = useRef<HTMLHeadingElement>(null);
  const scrollToBottom = (animated: boolean) => {
    bottomReference.current?.scrollIntoView({ behavior: animated ? "smooth" : "auto" });
  };
  let oldScrollY = 0;
  const handleScroll = (element: React.UIEvent) => {
    if (noMoreMessages) return;
    if (
      oldScrollY - element.currentTarget.scrollTop > 0 &&
      element.currentTarget.scrollTop <= element.currentTarget.clientHeight
    ) {
      setFetchMoreMessages(state => !state);
    }
    oldScrollY = element.currentTarget.scrollTop;
  };
  return (
    <FormikProvider value={formik}>
      <div className={styles.container}>
        <Card elevation={5} className={styles.headerCard}>
          <CardActionArea onClick={() => setIsPublicUserProfileDialogOpen(true)}>
            <Stack direction={"row"} spacing={1} className={styles.headerStack}>
              <UserAvatar className={styles.avatar} userProfile={otherUser?.userProfile} />
              <p className={styles.headerUsername}>{otherUser?.userProfile.username}</p>
            </Stack>
          </CardActionArea>
        </Card>
        <Card elevation={5} className={styles.chatCard}>
          <List className={styles.list} onScroll={handleScroll}>
            {isLoading && <CustomLoadingIndicator style={{ justifyContent: "center" }} />}
            {groupedMessages &&
              Object.keys(groupedMessages)
                .sort((a, b) => +a - +b)
                .map(formattedDate => {
                  return (
                    <Stack key={formattedDate}>
                      {renderDate(
                        groupedMessages[formattedDate][0].createdUnixTimestamp,
                        formattedDate
                      )}
                      {groupedMessages[formattedDate].map((message, index) => (
                        <div
                          key={`${message.uuid}_${formattedDate}_${index}`}
                          className={styles.chatBubbleDiv}
                          style={{ justifyContent: isOwn(message) ? "flex-end" : "flex-start" }}
                        >
                          <ChatBubble
                            message={message}
                            position={getChatBubblePosition(
                              isOwn(message),
                              index > 0
                                ? isOwn(groupedMessages[formattedDate][index - 1])
                                : !isOwn(message),
                              index < groupedMessages[formattedDate].length - 1
                                ? isOwn(groupedMessages[formattedDate][index + 1])
                                : !isOwn(message)
                            )}
                          />
                        </div>
                      ))}
                    </Stack>
                  );
                })}
            <ContentUnavailableNotice
              isLoading={isLoading}
              items={messages}
              emptyIcon={ForumRounded}
              emptyTitle={strings.chat_messages_empty_title}
              emptyDescription={strings.chat_messages_empty_description}
              searchText={undefined}
            />
            <div ref={bottomReference} />
          </List>
        </Card>
        <Stack className={styles.textStack} direction={"row"}>
          <FreeTextField
            id="text"
            name="text"
            className={styles.textField}
            autoFocus={!isMobile}
            placeholder={strings.chat_placeholder}
            value={formik.values.text}
            onChange={formik.handleChange}
            onKeyDown={onKeyDown}
          />
          <ToolbarButton
            className={styles.sendButton}
            onClick={() => formik.handleSubmit()}
            icon={SendRounded}
            tooltip={strings.chat_send}
          />
        </Stack>
        {otherUser && (
          <PublicUserProfileDialog
            userProfile={otherUser.userProfile}
            isOpen={isPublicUserProfileDialogOpen}
            setIsOpen={setIsPublicUserProfileDialogOpen}
            showCollectionInfo={false}
            showSendMessageButton={false}
          />
        )}
      </div>
    </FormikProvider>
  );
};

interface IChatBoxProps {
  chat: IChat;
  setIsChatLoading: (isLoading: boolean) => void;
}
