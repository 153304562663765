import { useBackend } from "../../services";

export const useGetPublicAlbumImages = () => {
  const { get } = useBackend();
  const getPublicAlbumImages = (parameters: IGetPublicAlbumImagesProps) =>
    get("albumImages/shared/public", parameters);
  return { getPublicAlbumImages };
};

export interface IGetPublicAlbumImagesProps {
  albumId: string;
  collectionId: string;
}
